import { InputBase, alpha, withStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

export const TGInputText = withStyles((theme) => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${colors.black25}`,
    fontSize: 16,
    fontFamily: 'Inter,"Roboto","Helvetica","Arial",sans-serif',
    width: '100%',
    padding: '11px 14px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);
