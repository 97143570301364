import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import {
  RECORDS_ROUTE,
  SHARED_ROUTE,
  USERGUIDE_ROUTE,
  UI_ROUTE,
  UI_MENU_SETTINGS,
  UI_MENU_RECORDS,
  UI_MENU_ACTIONS,
  UI_MENU_CUSTOM_PAGES,
} from './utils/Utils';
import { Records } from './views/pages/records/Records';
import { SharedUtilities } from './views/pages/sharedUtilities/components/Functions';
import UserGuide from 'views/pages/help/userGuide';
import Settings from 'views/pages/records/Settings';
import AggregateProperties from 'views/pages/records/AggregateProperties/AggregateProperties';
import Configuration from 'views/pages/records/components/commands/Configuration';
import Commands from 'views/pages/records/Commands';
import EventConfiguration from 'views/pages/records/components/events/EventConfiguration';
import Events from 'views/pages/records/Events';
import { ComputedMapping } from 'views/pages/records/components/ComputedMapping';
import CommentEditor from 'views/pages/records/components/common/CommentEditor';
import { Overview } from './pages/Overview/Overview';
import ProtectedRoute from 'components/ProtectedRoute';
import { EventSchemaEditor } from 'pages/aggregates/pages/EventSchemaEditor';
import { EventMapper } from 'pages/aggregates/pages/EventMapper';
import IAppLoginScreenContainer from 'pages/Login/LoginScreenContainer';
import OptionsContainer from 'views/pages/sharedUtilities/components/OptionContainer';
import { ActionsRoute } from 'views/pages/actions/ActionsRoute';
import { CustomPages } from 'pages/customPages/CustomPages';
import { CustomPageEditor } from 'views/pages/customPages/pages/CustomPageEditor';
import SymbolLegend from 'views/pages/settings/components/SymbolLegend';
import ActionsContainer from 'views/pages/actions/ActionsContainer';
import { FunctionInputSchemaEditor } from 'views/pages/sharedUtilities/Tabs/InputSchema';
import { AggrComments } from 'views/pages/sharedUtilities/Tabs/AggrComments';
import { FunctionOutputSchemaEditor } from 'views/pages/sharedUtilities/Tabs/OutputSchema';
import { EventMapper as SharedEventMapper } from 'views/pages/sharedUtilities/Tabs/FunctionMap';
import UIPage from 'views/pages/ui/UIPage';
import UISettings from 'views/pages/ui/UISettings';
import General from 'views/pages/ui/components/settings/General';
import MapOptions from 'views/pages/ui/components/settings/MapOptions';
import RecordsUI from 'views/pages/ui/RecordsUI';
import { ActionButtonEditor } from 'components/ActionButtonEditor/ActionButtonEditor';
import GeneralDialog from 'components/GeneralDialog/GeneralDialog';
import { CardDefinition } from 'components/CardDefinition/CardDefinition';
import { MapLabels } from 'components/MapLabels/MapLabels';
import InitialRoute from 'views/pages/InitialRoute';

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<ProtectedRoute />}>
        <Route index element={<InitialRoute />} />
        <Route path={RECORDS_ROUTE} element={<Records />}>
          <Route path={':aggregate'} element={<Settings />} />
          <Route path={`:aggregate/properties`} element={<AggregateProperties autoHeight />} />
          <Route
            path={`:aggregate/commands/:commandName/version/:commandVersion`}
            element={<Configuration />}
          />
          <Route path={`:aggregate/commands`} element={<Commands />}>
            <Route path={`:commandName`} element={<CommentEditor />} />
          </Route>
          <Route
            path={`:aggregate/events/:eventName/version/:eventVersion`}
            element={<EventConfiguration />}
          >
            <Route path={`eventSchema`} element={<EventSchemaEditor />} />
            <Route path={`mapping`} element={<EventMapper />} />
          </Route>
          <Route path={`:aggregate/events`} element={<Events />}>
            <Route path={`:eventName`} element={<CommentEditor />} />
          </Route>
          <Route path={`:aggregate/computedMapping`} element={<ComputedMapping />} />
        </Route>
        <Route path={UI_ROUTE} element={<UIPage />}>
          <Route path={UI_MENU_SETTINGS} element={<UISettings />}>
            <Route path={`general`} element={<General />} />
            <Route path={`symbolLegend`} element={<SymbolLegend />} />
            <Route path={`mapOptions`} element={<MapOptions />} />
          </Route>
          <Route path={UI_MENU_RECORDS} element={<RecordsUI />}>
            <Route path={`:aggrUICustomization`} element={<GeneralDialog />} />
            <Route path={`:aggrUICustomization/:action`} element={<ActionButtonEditor />} />
            <Route path={`:aggrUICustomization/cardDefinition`} element={<CardDefinition />} />
            <Route path={`:aggrUICustomization/mapLabelProperties`} element={<MapLabels />} />
          </Route>
          <Route path={UI_MENU_ACTIONS} element={<ActionsRoute />}>
            <Route path={`:currentActionPage`} element={<ActionsContainer />} />
          </Route>
          <Route path={`${UI_MENU_CUSTOM_PAGES}`} element={<CustomPages />}>
            <Route path=":groupName/:customPage" element={<CustomPageEditor />} />
          </Route>
        </Route>
        <Route path={SHARED_ROUTE} element={<SharedUtilities />}>
          <Route path={`:aggregate/version/:version`} element={<OptionsContainer />}>
            <Route path="internalText" element={<AggrComments />} />
            <Route path="inputSchema" element={<FunctionInputSchemaEditor />} />
            <Route path="outputSchema" element={<FunctionOutputSchemaEditor />} />
            <Route path="functionMap" element={<SharedEventMapper />} />
          </Route>
        </Route>
        <Route path={USERGUIDE_ROUTE} element={<UserGuide />} />
        <Route path="/overview" element={<Overview />} />
      </Route>
      <Route path="/login" element={<IAppLoginScreenContainer />} />
    </>
  )
);
