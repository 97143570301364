import { AggregateConfig, Gen5Config, getPropertyDefinitions } from '@terragotech/gen5-config-lib';
import {
  DEFAULT_DATETIME_FORMAT
} from '@terragotech/gen5-shared-utilities';
import { WebUIConfig, AggregateDefinition } from '@terragotech/gen5-shared-components';

export const getConfigContextValue = (jsonConfig: Gen5Config): Partial<WebUIConfig> => {
  return {
    defaultDateTimeFormat: {
      dateFormatType:
        jsonConfig.webUIConfig?.defaultDateTimeFormat?.dateFormatType ||
        DEFAULT_DATETIME_FORMAT.DateFormatType,
      dateFormat:
        jsonConfig.webUIConfig?.defaultDateTimeFormat?.dateFormat ||
        DEFAULT_DATETIME_FORMAT.DateFormat,
      dateSeperator:
        jsonConfig.webUIConfig?.defaultDateTimeFormat?.dateSeperator ||
        DEFAULT_DATETIME_FORMAT.DateSeperator,
      timeFormat:
        jsonConfig.webUIConfig?.defaultDateTimeFormat?.timeFormat ||
        DEFAULT_DATETIME_FORMAT.TimeFormat,
      isSwitch:
        jsonConfig.webUIConfig?.defaultDateTimeFormat?.isSwitch || DEFAULT_DATETIME_FORMAT.IsSwitch,
    },
    line: { editColor: jsonConfig.webUIConfig.line?.editColor || '' },
    geographic: {
      showPolylineMeasurement: !!jsonConfig.webUIConfig?.geographic?.showPolylineMeasurement,
      polylineUnitOfMeasurement: jsonConfig.webUIConfig?.geographic?.polylineUnitOfMeasurement,
      polylineRoundingPrecision: jsonConfig.webUIConfig?.geographic?.polylineRoundingPrecision,
    },
    aggregateDefinitions: jsonConfig.aggregates
      .filter((someClass) => someClass.excludeInWebUI !== true)
      .map((aggDef) => {
        return getAggregateDefinitionFromConfig(aggDef, jsonConfig);
      }),
    functionDefinitions: jsonConfig.functions,
    initialMapExtents: jsonConfig.webUIConfig?.initialMapExtents as any,
  };
};

export const getAggregateDefinitionFromConfig = (
  aggDef: AggregateConfig,
  jsonConfig: Gen5Config
): AggregateDefinition => {
  const customizations =
    jsonConfig.webUIConfig.aggregateUICustomizations &&
    jsonConfig.webUIConfig.aggregateUICustomizations[aggDef.typeName];

  const definition: AggregateDefinition = {
    queryKey: aggDef.objectStore.table,
    name: aggDef.typeName,
    plural: aggDef.pluralName,
    singular: aggDef.singularName,
    labelProperty: aggDef.labelProperty,
    detailPage: customizations?.detailPage,
    isReferenceType: aggDef.isReferenceType,
    tableActions: [], // TODO: Add proper mapping when necessary
    multiSelectActions: [], // TODO: Add proper mapping when necessary
    cardDefinition: {} as any, // TODO: Add proper mapping when necessary
    editorWorkflows: [], // TODO: Add proper mapping when necessary
    propertyDefinitions: getPropertyDefinitions(aggDef) as any,
    hiddenFromMapView: customizations?.hiddenFromMapView,
    hiddenFromTableView: customizations?.hiddenFromTableView,
    mapLabelProperties: (customizations?.mapLabelProperties || []).map((x) => {
      return { name: x };
    }),
    showMapLabelTitles:
      typeof customizations?.showMapLabelTitles === 'undefined' ||
      customizations?.showMapLabelTitles === null
        ? true
        : customizations.showMapLabelTitles,
    showEmptyMapLabels:
      typeof customizations?.showEmptyMapLabels === 'undefined' ||
      customizations?.showEmptyMapLabels === null
        ? true
        : customizations.showEmptyMapLabels,
    showMeasurementOnMap:
      typeof aggDef.showMeasurementOnMap === 'undefined' || aggDef.showMeasurementOnMap === null
        ? true
        : aggDef.showMeasurementOnMap,
  };
  return definition;
};
