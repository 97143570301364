import * as React from 'react';
import { Divider, Box, Typography, Grid } from '@material-ui/core';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ConfigContext } from '../../context/ConfigContext';
import { useParams } from 'react-router-dom';
import { WebAggrUICustomization, MobileAggrUICustomization } from '../../utils/types';
import { errorMsg, successMsg } from '../SnackbarUtilsConfigurator';
import { getCustomPageList } from 'utils/helpers';
import PageHeader from 'views/components/PageHeader';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { colors } from 'utils/colors';
import useCommonStyles from 'views/useCommonStyles';
import { TGIosSwitch, TGSelect } from 'views/components/formElements';
import _ from 'lodash';
import useRouteBlocker from '../../common/useBlocker';
import { useBlocker } from 'react-router-dom';

const GeneralDialog: React.FunctionComponent = () => {
  const classes = useCommonStyles();
  const { getUICustomization, setUICustomization, config } = useContext(ConfigContext);
  const { aggrUICustomization } = useParams() as { aggrUICustomization: string };
  const currentCustomizations = useMemo(
    () => getUICustomization('mobileUIConfig', aggrUICustomization) || null,
    [aggrUICustomization, getUICustomization]
  );
  const currentCustomizationsOnWeb = useMemo(
    () => getUICustomization('webUIConfig', aggrUICustomization) || null,
    [aggrUICustomization, getUICustomization]
  );
  const [showOnMap, setShowOnMap] = useState(
    currentCustomizations === null ? true : !currentCustomizations.hiddenFromMapView
  );
  const [isDirty, setIsDirty] = useState(false);
  const [showOnMapWeb, setShowOnMapWeb] = useState(
    currentCustomizationsOnWeb === null
      ? true
      : currentCustomizationsOnWeb && !currentCustomizationsOnWeb.hiddenFromMapView
  );
  const [showOnList, setShowOnList] = useState(
    currentCustomizations === null
      ? true
      : !(currentCustomizations as { hiddenFromListView: boolean }).hiddenFromListView
  );
  const [showOnTable, setShowOnTable] = useState(
    currentCustomizationsOnWeb === null
      ? true
      : !(currentCustomizationsOnWeb as { hiddenFromTableView: boolean }).hiddenFromTableView
  );
  const [attributesType, setAttributesType] = useState<string>(
    !!currentCustomizations?.detailPage ? 'customPage' : 'attributes'
  );
  const [enableCustomPage, setEnableCustomPage] = useState<boolean>(() => {
    const storedState = window.localStorage.getItem('Enable_CustomPageonMobile');
    return storedState === 'Enable_CustomPageonMobile';
  });
  const [pageName, setPageName] = useState(currentCustomizations?.detailPage ?? '');
  const [pageNameOnWeb, setPageNameOnWeb] = useState(currentCustomizationsOnWeb?.detailPage ?? '');

  const [enableCustomPageOnWeb, setEnableCustomPageOnWeb] = React.useState(() => {
    const storedState = window.localStorage.getItem('Enable_CustomPageOnWeb');
    return storedState === 'Enable_CustomPageOnWeb';
  });

  const toggleChecked = (val: boolean, type: string) => {
    const newState = !val;
    if (type === 'web') {
      setEnableCustomPageOnWeb(newState);
      window.localStorage.setItem(
        'Enable_CustomPageOnWeb',
        newState ? 'Enable_CustomPageOnWeb' : 'Disable_CustomPageOnWeb'
      );
    } else {
      setEnableCustomPage(newState);
      window.localStorage.setItem(
        'Enable_CustomPageonMobile',
        newState ? 'Enable_CustomPageonMobile' : 'Disable_CustomPageonMobile'
      );
    }
  };
  useEffect(() => {
    setShowOnMap(currentCustomizations === null ? true : !currentCustomizations.hiddenFromMapView);
    setShowOnList(
      currentCustomizations === null
        ? true
        : !(currentCustomizations as { hiddenFromListView: boolean }).hiddenFromListView
    );
    setAttributesType(!!currentCustomizations?.detailPage ? 'customPage' : 'attributes');
    setPageName(currentCustomizations?.detailPage ?? '');
  }, [currentCustomizations]);

  useEffect(() => {
    setShowOnMapWeb(
      currentCustomizationsOnWeb === null
        ? true
        : currentCustomizationsOnWeb && !currentCustomizationsOnWeb.hiddenFromMapView
    );
    setShowOnTable(
      currentCustomizationsOnWeb === null
        ? true
        : !(currentCustomizationsOnWeb as { hiddenFromTableView: boolean }).hiddenFromTableView
    );
    setPageNameOnWeb(currentCustomizationsOnWeb?.detailPage ?? '');
  }, [currentCustomizationsOnWeb]);
  useEffect(() => {
    setEnableCustomPageOnWeb(pageNameOnWeb.length > 0);
    setEnableCustomPage(pageName.length > 0);
  }, [pageNameOnWeb, pageName]);
  const handleSave = useCallback(() => {
    if (attributesType === 'customPage' && !pageName)
      return errorMsg('Custom Page name is required');
    setUICustomization('webUIConfig', aggrUICustomization, {
      ...(currentCustomizationsOnWeb as WebAggrUICustomization),
      hiddenFromMapView: !showOnMapWeb,
      hiddenFromTableView: !showOnTable,
      detailPage: pageNameOnWeb || undefined,
    });
    setUICustomization('mobileUIConfig', aggrUICustomization, {
      ...(currentCustomizations as MobileAggrUICustomization),
      hiddenFromMapView: !showOnMap,
      hiddenFromListView: !showOnList,
      detailPage: pageName || undefined,
    });
    setIsDirty(false);
    return successMsg('Visibility settings have been saved.');
  }, [
    aggrUICustomization,
    currentCustomizations,
    setUICustomization,
    showOnList,
    showOnMap,
    showOnTable,
    pageName,
    pageNameOnWeb,
    attributesType,
    showOnMapWeb,
    currentCustomizationsOnWeb,
  ]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && isDirty
  );
  useRouteBlocker({ blocker, onSave: handleSave });

  const generalData = [
    {
      title: 'Show on map (Web)',
      description:
        'Disabling this option will hide this aggregate type from the map view on the Web device for all users',
      onClick: () => {
        setIsDirty(true);
        setShowOnMapWeb((cur) => !cur);
      },
      checked: showOnMapWeb,
    },
    {
      title: 'Show on map (Mobile)',
      description:
        'Disabling this option will hide this aggregate type from the map view on the Mobile device for all users',
      onClick: () => {
        setIsDirty(true);
        setShowOnMap((cur) => !cur);
      },
      checked: showOnMap,
    },
    {
      title: 'Show on Table (Web)',
      description:
        'Disabling this option will hide this aggregate type from the table view on the Web device for all users',
      onClick: () => {
        setIsDirty(true);
        setShowOnTable((cur) => !cur);
      },
      checked: showOnTable,
    },
    {
      title: 'Show on List (Mobile)',
      description:
        'Disabling this option will hide this aggregate type from the list view on the Mobile device for all users',
      onClick: () => {
        setIsDirty(true);
        setShowOnList((cur) => !cur);
      },
      checked: showOnList,
    },
  ];
  const detailPageLayout = [
    {
      title: 'Enable Custom Page (Web)',
      description: 'Disabling this option will display the default attribute view',
      onclick: () => {
        setIsDirty(true);
        toggleChecked(enableCustomPageOnWeb, 'web');
        setEnableCustomPageOnWeb(!enableCustomPageOnWeb);
        setAttributesType((val) => val);
      },
      checked: enableCustomPageOnWeb,
      otherComponent: (() => (
        <>
          {enableCustomPageOnWeb ? (
            <TGSelect
              id="mutiple-select-label"
              options={getCustomPageList(config, aggrUICustomization)}
              onChange={(value) => {
                setIsDirty(true);
                setPageNameOnWeb(value as string);
              }}
              value={pageNameOnWeb}
              containerCss={styles.selectContainer}
              type="Custom Page"
            />
          ) : (
            <></>
          )}
        </>
      ))(),
    },
    {
      title: 'Enable Custom Page (Mobile)',
      description: 'Disabling this option will display the default attribute view',
      onclick: () => {
        setIsDirty(true);
        toggleChecked(enableCustomPage, 'mobile');
        setEnableCustomPage(!enableCustomPage);
        setAttributesType((val) => val);
      },
      checked: enableCustomPage,
      otherComponent: (() => (
        <>
          {enableCustomPage ? (
            <TGSelect
              id="mutiple-select-label"
              options={getCustomPageList(config, aggrUICustomization)}
              onChange={(value) => {
                setIsDirty(true);
                setPageName(value as string);
              }}
              value={pageName}
              containerCss={styles.selectContainer}
              type="Custom Page"
            />
          ) : (
            <></>
          )}
        </>
      ))(),
    },
  ];
  return (
    <Box className={classes.innerContainer}>
      <PageHeader
        title={'General'}
        icon={faSave}
        buttonText="Save"
        onSave={handleSave}
        showDivider
        showLinkUnlink
      />
      <Grid container style={styles.topDivider}>
        <Grid item md={4} style={styles.leftContent}>
          <Typography style={styles.headText}>Visibility</Typography>
          <Typography style={styles.description}>
          </Typography>
        </Grid>
        <Grid item md={8}>
          {_.map(generalData, (items) => {
            return (
              <TGIosSwitch
                title={items.title}
                description={items.description}
                checked={items.checked}
                onChange={items.onClick}
              />
            );
          })}
        </Grid>
      </Grid>
      <Divider style={styles.divider} />
      <Grid container>
        <Grid item md={4} style={styles.leftContent}>
          <Typography style={styles.headText}>Detail Page Layout</Typography>
          <Typography style={styles.description}>
          </Typography>
        </Grid>
        <Grid item md={8}>
          {_.map(detailPageLayout, (items) => {
            return (
              <TGIosSwitch
                title={items.title}
                description={items.description}
                checked={items.checked}
                onChange={items.onclick}
                otherComponent={items.otherComponent}
              />
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
};
export default GeneralDialog;

const styles = {
  leftContent: {
    paddingRight: 30,
  },
  headText: {
    fontSize: 18,
    color: colors.black,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '100%',
    marginBottom: 7,
  },
  description: {
    fontSize: 15,
    color: colors.black75,
    lineHeight: '100%',
  },
  innerContainer: {
    marginTop: 0,
  },
  topDivider: {
    marginTop: 28,
  },
  divider: {
    margin: '61px 0',
  },
  selectContainer: {
    marginBottom: 29,
    marginTop: 17,
    width: '100%',
  },
};
