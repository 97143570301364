import React from 'react';
import { CustomColorTableInput } from '../../pages/MobileUI/components/CustomColorTableInput';
import { Gen5ColorDefinition } from '@terragotech/gen5-config-lib/dist/Gen5ColorDefinition';

interface Props {
  open: boolean;
  onClose: () => void;
  aggregates: string[] | object;
  color: Gen5ColorDefinition | undefined;
  onSetColor: (color: Gen5ColorDefinition | undefined) => void;
}

export const ColorPickerDialog: React.FC<Props> = ({ aggregates, color, onSetColor }) => {
  return <CustomColorTableInput aggregates={aggregates} color={color} setColor={onSetColor} />;
};
