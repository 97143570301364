import { useContext, useRef } from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { grey } from '@material-ui/core/colors';
import { CSVResult, MaterialTableRef } from 'components/EditableTable';
import { ValidOptions } from 'pages/aggregates/components/ValidOptionsCustomInput';
import { AggrPropertyRow } from 'utils/types';
import { ConfigContext } from '../../../../context/ConfigContext';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';
import { useParams } from 'react-router-dom';
import { getAggregateIndex } from 'utils/navigationUtils';
import { propertiesObjsToArray } from 'pages/aggregates/utils/propertiesObjsToArray';
import { cloneDeep, startCase } from 'lodash';
import Papa from 'papaparse';
import { errorMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { aggregateTableRowToProperty } from 'pages/aggregates/utils/aggregateTableRowToProperty';
import { aggregatePropertiesTableColumns } from 'pages/aggregates/components/aggregatePropertiesTableColumns';
import { PropertyEditableTable } from 'components/PageDialog/PropertyEditableTable';
import useCommonStyles from 'views/useCommonStyles';

export interface OtherProps {
  tableData?: number;
  validOptions: ValidOptions | string;
}
const PROPERTY_TABLE_HEIGHT = 180;
export type PartialAggrPropertyRowWithOtherProps = Partial<AggrPropertyRow> & OtherProps;
export default function AggregateProperties(props: { autoHeight: boolean }) {
  const commonClasses = useCommonStyles();
  const { config, setConfig } = useContext(ConfigContext);
  const AggregateAPI = useAggregateAPI();
  const { aggregate: aggrName } = useParams() as { aggregate: string };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const tableRef = useRef<MaterialTableRef>(null);
  const propertiesArray = propertiesObjsToArray(
    cloneDeep(config?.aggregates?.[aggrIndex]?.properties)
  );
  const rowToPropertyObj = (row: AggrPropertyRow) => {
    const { name, ...property } = row;
    return { [name]: property } as object;
  };

  const addProperty = async (
    row: object,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    const property = aggregateTableRowToProperty(row as AggrPropertyRow);
    const { error } = await AggregateAPI.addAggregateProperty(aggrIndex, property);
    if (error) return reject();
    successMsg(`Property "${(row as AggrPropertyRow).name}" has been successfully added`);
    return resolve(null);
  };

  const updateProperty = async (
    newRow: object,
    oldRow: object | undefined,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    const oldPropertyName = (oldRow as AggrPropertyRow).name;
    const property = aggregateTableRowToProperty(newRow as AggrPropertyRow);
    const { error } = await AggregateAPI.updateAggregateProperty(
      aggrIndex,
      oldPropertyName,
      property
    );
    if (error) return reject();

    successMsg(`Property "${(newRow as AggrPropertyRow).name}" has been successfully updated`);
    return resolve(null);
  };
  const CSVFileImport = (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    if (aggrIndex !== null && config?.aggregates?.[aggrIndex]?.properties) {
      const newConfig = cloneDeep(config);
      const newProps = Object.assign(
        {},
        ...rows.map((row) => {
          return rowToPropertyObj(row as AggrPropertyRow);
        })
      );

      newConfig.aggregates[aggrIndex].properties = newProps;
      setConfig(newConfig);
      successMsg(`CSV file successfully uploaded`);
      return resolve(null);
    }
    errorMsg(`Error while uploading CSV file`);
    return reject();
  };

  const deleteProperty = async (rowToDel: object) => {
    const property = aggregateTableRowToProperty(rowToDel as AggrPropertyRow);
    const { error } = await AggregateAPI.deleteAggregateProperty(aggrIndex, property);
    if (error) return;
    successMsg(`Property "${(rowToDel as AggrPropertyRow).name}" has been successfully deleted`);
  };

  const getAggregateTypes = () => {
    const aggrTypes: { [key: string]: string } = {};
    config?.aggregates.forEach((aggr: { typeName: string }) => {
      aggrTypes[aggr.typeName] = aggr.typeName;
    });
    return aggrTypes;
  };

  const getDateTime = () => {
    const dateIn = new Date();
    const yyyy = dateIn.getFullYear();
    const mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    const dd = dateIn.getDate();
    return `${yyyy}${mm}${dd}-${dateIn.getHours()}:${dateIn.getMinutes()}`;
  };

  const removeUnwantedProp = (data: PartialAggrPropertyRowWithOtherProps[]) => {
    const columnsToCheck = ['relation', 'foreignColumn', 'filterOptions', 'validOptions', 'uiType'];
    columnsToCheck.forEach((column) => {
      const columnPropExists = data.some(
        (rowData: PartialAggrPropertyRowWithOtherProps) => rowData.hasOwnProperty(column) === true
      );

      if (columnPropExists) {
        data.forEach((rowData: PartialAggrPropertyRowWithOtherProps) => {
          switch (column) {
            case 'relation':
              if (!rowData.relation) rowData.relation = undefined;
              break;

            case 'foreignColumn':
              if (!rowData.foreignColumn) rowData.foreignColumn = undefined;
              break;

            case 'filterOptions':
              if (!rowData.filterOptions) rowData.filterOptions = undefined;
              break;

            case 'validOptions':
              if (!rowData.validOptions) (rowData.validOptions as string) = '';
              break;

            case 'uiType':
              if (!rowData.uiType) rowData.uiType = undefined;
              break;
          }
        });
      }
    });
  };

  const handleExport = (columns: object[], data: object[]) => {
    const clonedData = cloneDeep(data);
    const aggrPropsData = clonedData as PartialAggrPropertyRowWithOtherProps[];
    const reStructuredData = aggrPropsData.map((rowData, rowNo) => {
      const obj = {} as PartialAggrPropertyRowWithOtherProps;
      delete rowData.tableData;
      obj.order = rowData.order || rowNo++;
      !rowData.hasOwnProperty('label') ? (obj.label = '') : (obj.label = rowData.label);
      !rowData.hasOwnProperty('name') ? (obj.name = '') : (obj.name = rowData.name);
      !rowData.hasOwnProperty('type') ? (obj.type = undefined) : (obj.type = rowData.type);
      !rowData.hasOwnProperty('nullable')
        ? (obj.nullable = false)
        : (obj.nullable = rowData.nullable);
      !rowData.hasOwnProperty('filterable')
        ? (obj.filterable = false)
        : (obj.filterable = !rowData.filterable ? false : rowData.filterable);
      !rowData.hasOwnProperty('editable')
        ? (obj.editable = false)
        : (obj.editable = !rowData.editable ? false : rowData.editable);
      !rowData.hasOwnProperty('indexed')
        ? (obj.indexed = false)
        : (obj.indexed = !rowData.indexed ? false : rowData.indexed);
      !rowData.hasOwnProperty('hideFromAssetAttributes')
        ? (obj.hideFromAssetAttributes = false)
        : (obj.hideFromAssetAttributes = !rowData.hideFromAssetAttributes
            ? false
            : rowData.hideFromAssetAttributes);
      !rowData.hasOwnProperty('includeInFullText')
        ? (obj.includeInFullText = false)
        : (obj.includeInFullText = rowData.includeInFullText);

      if (rowData.hasOwnProperty('filterOptions')) {
        if (rowData.filterOptions !== undefined) obj.filterOptions = rowData.filterOptions;
      }
      if (rowData.hasOwnProperty('relation')) {
        if (rowData.relation !== undefined) obj.relation = rowData.relation;
      }

      if (rowData.hasOwnProperty('foreignColumn')) {
        if (rowData.foreignColumn !== undefined) obj.foreignColumn = rowData.foreignColumn;
      }

      if (rowData.validOptions) {
        if ((rowData.validOptions as ValidOptions).enum) {
          if ((rowData.validOptions as ValidOptions).enum.toString().length > 0) {
            (obj.validOptions as string) = (rowData.validOptions as ValidOptions).enum.toString();
          }
        }
      }

      if (rowData.hasOwnProperty('uiType')) {
        if (rowData.uiType !== undefined) obj.uiType = rowData.uiType;
      }

      return obj;
    });

    removeUnwantedProp(reStructuredData as PartialAggrPropertyRowWithOtherProps[]);

    const exportData = reStructuredData.map((obj) =>
      Object.fromEntries(Object.entries(obj).map(([key, value]) => [startCase(key), value]))
    );

    const fileName = `${aggrName}-properties-${getDateTime()}`;

    const csv = Papa.unparse(exportData, { header: true, skipEmptyLines: true });
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    let csvURL = null;
    if (navigator?.msSaveBlob) {
      csvURL = navigator.msSaveBlob(csvData, `${fileName}.csv`);
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    const tempLink = document.createElement('a') as HTMLAnchorElement;
    tempLink.href = csvURL as string;
    tempLink.setAttribute('download', `${fileName}.csv`);
    tempLink.click();
  };
  const classes = useStyles();
  const tableHeaderStyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: colors.greyBackground,
  };
  const cellStyle = {
    height: 55,
    color: colors.black,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 15,
    lineHeight: '100%',
  };
  return (
    <Box
      className={
        props.autoHeight
          ? [commonClasses.container, commonClasses.containerroot].join(' ')
          : commonClasses.container
      }
    >
      <Box className={classes.innerContainer}>
        <PropertyEditableTable
          title="Properties"
          options={{
            maxBodyHeight: `calc(100vh - ${PROPERTY_TABLE_HEIGHT}px)`,
            padding: 'dense',
            headerStyle: tableHeaderStyle,
            cellStyle: cellStyle,
            paging: false,
            showTitle: false,
            toolbar: false,
            search: false,
          }}
          columns={aggregatePropertiesTableColumns(getAggregateTypes())}
          data={propertiesArray}
          onAdd={addProperty}
          onFileImport={CSVFileImport}
          onExport={handleExport}
          fileType="propertyList"
          onUpdate={updateProperty}
          onDelete={deleteProperty}
          tableRef={tableRef}
          AggregateProperties
        />
      </Box>
    </Box>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContainer: {
      padding: `32px 50px 0`,
      backgroundColor: colors.white,
      width: '100%',
    },
    header: {
      fontSize: 24,
      color: colors.black,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    header1: {
      display: 'flex',
      alignItems: 'center',
      padding: 20,
    },
    para: {
      fontSize: 15,
      fontWeight: 400,
      color: grey[700],
    },
    table: {
      minWidth: 650,
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.greyBorder,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    headerContainer: {
      display: 'flex',
      gap: 3,
      alignItems: 'center',
    },
    buttonIcon: {
      marginRight: 10,
      height: 21,
      width: 21,
      color: colors.greyText,
    },
    buttonContainer: {
      position: 'absolute',
      right: 40,
    },
    actionIconContainer: {
      width: 110,
    },
    actionIconButton: {
      padding: 8,
    },
    tableheader: {
      fontWeight: 'bold',
      fontSize: 15,
      fontStyle: 'normal',
      color: colors.black,
      width: 'max-content',
    },
    tableRows: {
      display: 'flex',
    },
    mainContainer: {
      height: '100%',
      backgroundColor: colors.white,
    },
    tableCellText: {
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 400,
      color: colors.black,
    },
    icon: {
      height: 20,
      width: 20,
    },
  })
);
