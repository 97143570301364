import React, { FocusEventHandler, useState } from 'react';
import { ReactComponent as CloseIcon } from '../icons/CloseIcon.svg';
import styled, { css } from 'styled-components';
import { colors } from 'utils/colors';
import { TextareaAutosize, makeStyles } from '@material-ui/core';
import { compact } from 'lodash';

interface TinyTextInputProps {
  value: string | null | undefined;
  onChange: (value: string | null | undefined) => void;
  onBlur?: FocusEventHandler;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  undefinable?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  type?: 'text' | 'number';
  isDescriptionFocus?: boolean;
  placeholderColor?: string;
  isDescription?: boolean;
}

export const TinyTextInput: React.FC<TinyTextInputProps> = ({
  value,
  onChange,
  style,
  containerStyle,
  undefinable,
  placeholder,
  autoFocus,
  type = 'text',
  onBlur,
  isDescriptionFocus = false,
  placeholderColor,
  isDescription,
}) => {
  const [tempValue, setTempValue] = useState(value);
  const classes = useStyles();
  const toggleNull = () => {
    if (value === null) {
      onChange(tempValue || '');
    } else {
      setTempValue(value);
      onChange(null);
    }
  };
  const setUndefined = () => {
    setTempValue(undefined);
    onChange(undefined);
  };

  return undefinable ? (
    <span style={{ ...inputContainerStyle, ...containerStyle }}>
      <NullableButton isNull={value === null} onClick={toggleNull}>
        <NullableButtonLabel isNull={value === null}>N</NullableButtonLabel>
      </NullableButton>
      <InputComponent
        autoFocus={autoFocus}
        type={value === null ? 'text' : type}
        style={{ ...style }}
        value={value === null ? 'null' : value || ''}
        onChange={(e: any) => onChange(e.target.value)}
        disabled={value === null}
        isNull={value === null}
        nullable={undefinable}
        placeholder={placeholder || 'undefined'}
        onBlur={onBlur}
      />
      <div onClick={setUndefined} style={setUndefinedButtonStyle} title="Set undefined">
        <CloseIcon style={setUndefinedIconStyle} />
      </div>
    </span>
  ) : isDescription ? (
    <TextareaAutosize
      autoFocus={autoFocus}
      style={{ ...style }}
      value={value as string}
      onChange={(e: any) => onChange(e.target.value)}
      className={compact([
        classes.textArea,
        !!undefinable && value === null && classes.nullable,
      ]).join(' ')}
      onBlur={onBlur}
      placeholder={placeholder}
    />
  ) : (
    <InputComponent
      type={type}
      autoFocus={autoFocus}
      style={{ ...style }}
      value={value as string}
      onChange={(e: any) => onChange(e.target.value)}
      nullable={!!undefinable}
      isNull={value === null}
      isDescription={isDescriptionFocus}
      onBlur={onBlur}
      placeholder={placeholder}
      placeholderColor={placeholderColor}
    />
  );
};

const useStyles = makeStyles({
  textArea: {
    resize: 'none',
    border: `1px solid ${colors.white50}`,
    backgroundColor: colors.white,
    borderRadius: 3,
    outline: 'none',
    '&:focus': {
      borderColor: colors.black15,
    },
    '&::-webkit-inner-spin-button': {
      marginRight: 10,
    },
    '&::-webkit-outer-spin-button': {
      marginRight: 10,
    },
    '&::placeholder': {
      color: colors.black35,
    },
  },
  nullable: {
    backgroundColor: colors.gray,
    borderRadius: '0 5px 5px 0',
  },
});

const NullableButton = styled.span<{ isNull: boolean }>`
  width: 25px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.alto};
  border-radius: 2px 0 0 2px;
  cursor: pointer;
  box-shadow: isNull ? '${colors.dimGray} 1px 1px 13px 0px inset' : undefined;
`;

const NullableButtonLabel = styled.p<{ isNull: boolean }>`
  margin-left: 1px;
  color: ${(props) => (props.isNull ? `${colors.blueBorder}` : `${colors.black}`)};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

const inputContainerStyle = {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${colors.alto}`,
  borderRadius: 3,
} as const;

const setUndefinedButtonStyle = {
  padding: 2,
  marginBottom: 1,
  position: 'absolute',
  right: 0,
} as const;

const setUndefinedIconStyle = {
  fill: 'grey',
  fontSize: '1.1rem',
  width: '1rem',
  height: '1rem',
  marginTop: '0.3rem',
  cursor: 'pointer',
};

const sharedStyle = (props: {
  nullable: boolean;
  isNull: boolean;
  isDescription?: boolean;
  placeholderColor?: string;
}) => css`
  font-size: 12px;
  height: 20px;
  color: ${props.isNull && props.nullable ? colors.black54 : colors.black};
  border: 1px solid ${colors.white50};
  padding: 2px 10px;
  width: 100%;
  background-color: ${props.nullable && props.isNull ? `${colors.gray10}` : ` ${colors.white}`};
  outline: none;
  border-radius: ${props.nullable ? '0 5px 5px 0' : '3px'};
  ::-webkit-inner-spin-button {
    margin-right: 10px;
  }
  ::-webkit-outer-spin-button {
    margin-right: 10px;
  }
  :focus {
    border-color: ${props.isDescription ? `${colors.black15}` : `${colors.white50}`};
  }
  ::placeholder {
    color: ${props.placeholderColor ? props.placeholderColor : colors.black59};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
`;

const InputComponent = styled.input`
  ${sharedStyle}
`;
