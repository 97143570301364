import React from 'react';
import OptionEditor, { QueryBuilderProperty } from './controls/OptionEditor';
import { OperatorName } from './defaults/defaultOperators';
import { AttributeBasedFilterSimpleOptionType } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { Translations } from './defaults/defaultTranslations';
import { QueryBuilderSchema } from './QueryBuilder';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { TableCell, TableRow, styled } from '@material-ui/core';
import { colors } from 'utils/colors';

interface ConditionProps {
  id: string;
  parentId: string;
  _key: string;
  operator: OperatorName;
  options: AttributeBasedFilterSimpleOptionType;
  translations: Translations;
  schema: QueryBuilderSchema;
  allowValue?: boolean;
}

const TRow = styled(TableRow)({
  paddingRight: 10,
  '&:last-child .MuiTableCell-body': {
    borderBottom: 'none',
  },
});

const TCell = styled(TableCell)({
  padding: 6,
  height: 50,
  paddingLeft: 0,
  borderBottom: `1px solid ${colors.black10}`,
  '&:first-child': {
    paddingLeft: 28,
    textAlign: 'left',
  },
  '&:last-child': {
    paddingRight: 30,
  },
});

const Condition: React.FC<ConditionProps> = ({
  id,
  parentId,
  _key,
  operator,
  options,
  translations,
  schema: {
    controls: { KeySelector, OperatorSelector, RemoveConditionAction },
    keys,
    operators,
    onPropChange,
    onConditionRemove,
  },
  allowValue,
}) => {
  const onElementChanged = (property: string, option: QueryBuilderProperty) => {
    onPropChange(property, option, id);
  };

  const onKeyChanged = (key: string) => {
    onElementChanged('key', key);
  };

  const onOperatorChanged = (operator: OperatorName) => {
    onElementChanged('operator', operator);
  };

  const onOptionChanged = (options: QueryBuilderProperty) => {
    onElementChanged('options', options);
  };

  const removeCondition = () => onConditionRemove(id, parentId);

  const getKeyType = () => keys.find((key) => key.name === _key)?.type;
  return (
    <TRow>
      <TCell scope="row" align="center">
        <RemoveConditionAction icon={faTrashCan} handleOnClick={removeCondition} />
      </TCell>
      <TCell>
        <KeySelector
          options={keys}
          option={_key}
          handleOnChange={onKeyChanged}
          style={{ width: 180 }}
        />
      </TCell>
      <TCell>
        <OperatorSelector
          options={operators}
          option={operator}
          handleOnChange={onOperatorChanged}
        />
      </TCell>
      <TCell>
        <OptionEditor
          operator={operator}
          option={options}
          handleOnChange={onOptionChanged}
          keyType={getKeyType()}
          allowValue={allowValue}
        />
      </TCell>
    </TRow>
  );
};

export default Condition;
