import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

export const useCustomPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    Root: {
      fontWeight: 500,
    },
    bottomContainer: {
      marginTop: '22px !important',
    },
    header: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      padding: '13px 32px',
      height: 57,
      '& .MuiDialogTitle-root': {
        padding: 0,
      },
      '& .MuiDialogTitle-root .MuiTypography-h6': {
        color: colors.black,
        fontSize: 20,
        fontWeight: 500,
        lineHeight: '100%',
      },
    },
    closeBtn: {
      position: 'absolute',
      right: 35,
      '& .MuiIconButton-root': {
        padding: 0,
      },
      height: 32,
      width: 32,
      color: colors.black54,
    },
    text: {
      fontSize: 15,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: '100%',
      margin: '10px 0',
      color: colors.black,
    },
    titleText: {
      marginTop: 0,
    },
    mapperContainer: {
      margin: '10px 0px !important',
    },
    textInput: {
      margin: '10px 0',
      width: '100%',
    },
    groupContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 3,
      marginTop: 5,
    },
    dialogActions: {
      boxShadow: `0px -2px 4px 0px ${colors.black5}`,
      height: 77,
      padding: 20,
    },
    body: {
      width: '100%',
      height: '60vh',
      display: 'flex' as const,
      flexDirection: 'column' as const,
      padding: '14px 31px',
    },
    customEditor: {
      minHeight: 300,
    },
    button: {
      width: 110,
      fontSize: 15,
      fontWeight: 500,
    },
    btn: {
      height: 36,
      width: 138,
      borderRadius: 5,
      background: colors.black5,
      boxShadow: `0px 2px 4px 0px ${colors.black15}`,
      fontWeight: 400,
      fontSize: 15,
    },
    trashBtn: {
      height: 20,
      width: 17,
      color: colors.black54,
    },
    root: {
      height: 57,
      display: 'flex' as const,
      justifyContent: 'space-between' as const,
      alignItems: 'center' as const,
      padding: '0px 8px 0px 6px',
    },
    divider: {
      marginInline: 0,
    },
    checkbox: {
      '& .MuiFormControlLabel-root': {
        marginLeft: -5,
      },
      '& .MuiCheckbox-root': {
        width: 28,
        height: 28,
        marginRight: 9,
      },
    },
    mt35: {
      marginTop: 35,
    },
    aggregatesHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    addButton: {
      width: 110,
      height: 40,
      fontSize: 16,
      fontWeight: 500,
    },
    itemContainer: {
      marginBottom: 29,
    },
    aggregatesBottom: {
      marginBottom: 29,
    },
  })
);
