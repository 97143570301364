import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Divider,
  Typography,
  Button,
  IconButton,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Checkbox from '@material-ui/core/Checkbox';
import { colors } from 'utils/colors';
import _ from 'lodash';
import { AggrPropertyRow } from 'utils/types';
import { TGSelect, TextInputTG } from 'views/components/formElements';
import pluralize from 'pluralize';
import { ONE_TO_MANY_RELATION } from '../../../../utils/Utils';
import { ConfigContext } from 'context/ConfigContext';
import { errorMsg } from 'components/SnackbarUtilsConfigurator';
import { Gen5ColorAttribute, Gen5ColorMap } from '@terragotech/gen5-config-lib';
import { useParams } from 'react-router-dom';
import { getAggregateIndex } from 'utils/navigationUtils';

export interface FormDataProps {
  [key: string]: string | boolean | unknown;
}
type CurrentField = 'component' | 'checkbox' | 'object' | 'textBox' | 'gridView' | '';
const ModalComponent = styled.div``;
interface rowDataProps {
  aggregateName?: string;
  action?: string;
}
interface PropertyModalProps {
  openAddModal: boolean;
  fabActions?: boolean;
  setOpenAddModal: (open: boolean) => void;
  data: object[];
  title: string;
  onAdd: (row: object, resolve: (data: AggrPropertyRow | null) => void, reject: () => void) => void;
  onUpdate: (
    row: object,
    oldRow: object,
    resolve: (data: object | null) => void,
    reject: () => void
  ) => void;
  type: string;
  editingData: object;
  enableBlurBackground?: boolean;
  showProperties?: boolean;
  viewTitle?: string;
}
interface Column {
  title: string;
  field: string;
  type: string;
  lookup?: object;
  option?: object;
  editComponent?: (props: {
    rowData?: object;
    value: string;
    onChange: (value: string) => void;
  }) => JSX.Element;
  gridView?: (props: {
    onChange: (vlaue: React.ChangeEvent<HTMLInputElement>) => void;
  }) => JSX.Element;
  validate?: any;
}
const TABLE_HEIGHT = 310;
const textStyle = {
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '100%',
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: 400,
      '@media all and (-ms-high-contrast: none)': {
        display: 'none',
      },
    },
    rootgrid: {
      flexGrow: 1,
    },
    helperText: {
      color: colors.red,
      marginLeft: 0,
    },
    modal: {
      display: 'flex',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      backdropFilter: 'blur(2px)',
    },
    disableblur: {
      backdropFilter: 'blur(0px)',
    },
    paper: {
      width: 637,
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      maxHeight: '90%',
      overflow: 'hidden',
    },
    header: {
      height: 57,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: 5,
    },
    modalDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 22,
    },
    mt0: {
      marginTop: 0,
    },
    modalDataContainers: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 10,
      marginLeft: -10,
    },
    modalDataMain: {
      overflowY: 'scroll',
      padding: '3px 34px 23px',
      height: `calc(100% - ${TABLE_HEIGHT}px)`,
      maxHeight: `calc(100vh - ${TABLE_HEIGHT}px)`,
    },
    headerText: {
      fontWeight: 500,
      fontSize: 20,
      marginLeft: 32,
    },
    bottomContainer: {
      position: 'relative',
      zIndex: 9,
      display: 'flex',
      padding: '17px 20px',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      gap: 15,
      boxShadow: `0px -2px 4px 0px ${colors.black5}`,
      height: 77,
    },
    buttonContainer: {
      cursor: 'pointer',
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      border: `1.5px solid ${theme.palette.primary.main}`,
      height: 42,
      borderRadius: 5,
      width: 144,
    },
    cancelBtn: {
      color: theme.palette.primary.main,
      width: 134,
      backgroundColor: colors.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    textContainer: {
      marginTop: '9px !important',
    },
    inputField: {
      borderRadius: 4,
      height: 36,
      fontSize: 15,
      '& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
        color: colors.black54,
        zIndex: 10,
        fontSize: 14,
        fontWeight: 400,
      },
    },
    closeIcon: {
      height: 20,
      width: 20,
    },
    iconCloseContainer: {
      position: 'relative',
      right: 10,
    },
    checkboxText: {
      ...textStyle,
      '& p': {
        ...textStyle,
      },
    },
    text: {
      fontWeight: 500,
      fontSize: 15,
      fontStyle: 'normal',
      display: 'flex',
      lineHeight: '100%',
    },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    selectBtn: {
      height: 35,
      marginTop: 5,
      '& .MuiOutlinedInput-input': {
        paddingInline: 6,
      },
    },
    selectPlaceHolder: {
      color: colors.black30,
    },
    selctBox: {
      marginTop: 10,
    },
    checkboxFieldContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    checkboxContainer: {
      '&.MuiCheckbox-root': {
        position: 'relative',
        marginLeft: -9,
      },
    },
    saveBtn: {
      width: 145,
    },
    disableButton: {
      border: 'none',
    },
    optionalContainer: {
      marginTop: 5,
    },
    optionalTitle: {
      marginTop: 18,
      fontSize: 15,
      fontWeight: 500,
      color: colors.black,
    },
    additionalTitle: {
      marginTop: 5,
    },
  })
);
export default function PropertyModal(props: PropertyModalProps) {
  const classes = useStyles();
  const {
    openAddModal,
    setOpenAddModal,
    data,
    title,
    type,
    editingData,
    enableBlurBackground = false,
    showProperties,
    viewTitle,
  } = props;
  const [columnHeader, setColumnHeader] = React.useState<object>({});
  const { config } = useContext(ConfigContext);
  React.useEffect(() => {
    const tableObjectValue = Object.fromEntries(
      data.map((item: object) => {
        const temp = item as { type: string | boolean; field: string, initialEditValue?: boolean };
        const defaultValue = temp.type !== 'boolean' ? '' : temp?.initialEditValue || false;
        return [temp.field, defaultValue];
      })
    );
    setColumnHeader(tableObjectValue);
  }, [openAddModal, data]);
  const initialFormData = type === 'Edit' ? (editingData as FormDataProps) : columnHeader;
  const [formData, setFormData] = useState<FormDataProps>(() => initialFormData as FormDataProps);
  const { aggregate: aggrName } = useParams() as { aggregate: string };
  const aggrIndex = getAggregateIndex(config, aggrName);

  useEffect(() => {
    if (type === 'Add') {
      setFormData(columnHeader as FormDataProps);
    }
  }, [columnHeader, type]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };
  const isFormInvalid =
    _.isEmpty(formData.name) ||
    _.isEmpty(formData.type) ||
    _.isEmpty(formData.label) ||
    (!_.isEmpty(formData.name) &&
      _.first(formData.name as string) === _.capitalize(_.first(formData.name as string))) ||
    (formData.relation &&
      formData.relation === ONE_TO_MANY_RELATION &&
      !pluralize.isPlural(formData.name));
  const handleInputTGChange = ({ name, value }: { name: string; value: string }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (prop: { name?: string; value: string }) => {
    const { value, name } = prop;
    setFormData((prevData) => ({
      ...prevData,
      [`${name}`]: value,
    }));
  };
  const clearEmptyProperties = (row: object) =>
    Object.fromEntries(Object.entries(row).map(([key, value]) => [key, value || undefined]));

  const closeModal = () => {
    setOpenAddModal(false);
  };
  const isValidColor = (formData: FormDataProps) => {
    if (typeof formData?.color === 'object' && formData?.color?.hasOwnProperty('hexColorKey')) {
      if (!(formData?.color as Gen5ColorAttribute).hexColorKey) {
        errorMsg('Color Attribute property is required');
        return false;
      }
    } else if (typeof formData?.color === 'object' && formData?.color?.hasOwnProperty('mapping')) {
      if (!(formData?.color as Gen5ColorMap).colorKey) {
        errorMsg('Map Color Key property is required');
        return false;
      }
      if ((formData?.color as Gen5ColorMap).mapping.some((item) => !item.value)) {
        errorMsg('Map Color Key values are required');
        return false;
      }
    } else if (typeof formData?.color === 'string' && !formData?.color) {
      errorMsg('Fixed Color property is required');
      return false;
    }
    return true;
  };
  const isPropertyValid = (row: object, oldRow?: object) => {
    if (oldRow) {
      if (
        (row as AggrPropertyRow).name in config.aggregates[aggrIndex]?.properties &&
        (row as AggrPropertyRow).name !== (oldRow as AggrPropertyRow)?.name
      )
        return errorMsg(`Property "${(row as AggrPropertyRow).name}" already exists`);
    } else {
      if ((row as AggrPropertyRow).name in config.aggregates[aggrIndex]?.properties)
        return errorMsg(`Property "${(row as AggrPropertyRow).name}" already exists`);
    }
    return true;
  };
  const saveData = (newData: FormDataProps) => {
    const addData = () => {
      return new Promise((resolve, reject) => {
        props.onAdd(clearEmptyProperties(newData), () => setOpenAddModal(false), reject);
      });
    };
    if (showProperties) {
      if (isPropertyValid(newData)) {
        addData();
      }
    } else {
      if (isValidColor(newData as FormDataProps)) {
        addData();
      }
    }
  };

  const saveChanges = () => {
    const updatePropertyWithPromise = () => {
      return new Promise((resolve, reject) => {
        props.onUpdate(
          clearEmptyProperties(formData),
          editingData,
          () => setOpenAddModal(false),
          reject
        );
      });
    };
    if (showProperties) {
      if (isPropertyValid(formData, editingData)) {
        updatePropertyWithPromise();
      }
    } else {
      if (isValidColor(formData as FormDataProps)) {
        updatePropertyWithPromise();
      }
    }
  };
  const handleFieldChange = (field: string, value: string) => {
    setFormData((prevData) => ({
      ...prevData,
      [`${field}`]: value,
    }));
  };
  const rowData = {
    aggregateName: formData.aggregateName as string,
    action: formData.action as string,
  };

  const [filteredActions, otherData] = _.partition(
    data,
    (o) => (o as { gridView?: boolean })?.gridView
  );

  let othersTitle = '';
  let othersField = '';
  let othersLabel = '';
  let insertKey = '';
  const isPropertiesView = viewTitle === 'Properties';
  switch (viewTitle) {
    case 'Properties':
      othersTitle = 'Optional Features';
      othersField = 'optionalFeatures';
      othersLabel = 'Optional Features';
      insertKey = 'uiType';
      break;
    case 'Buttons': {
      othersTitle = 'Additional Option';
      othersField = 'additionalOption';
      othersLabel = 'Additional Option';
      insertKey = 'action';
      break;
    }
    default:
  }

  const gridData =
    filteredActions.length > 0
      ? {
          title: (
            <Typography
              className={`${classes.optionalTitle} ${
                !isPropertiesView ? classes.additionalTitle : ''
              }`}
            >
              {othersTitle}
            </Typography>
          ),
          field: othersField,
          label: othersLabel,
          gridView: ({
            onChange,
          }: {
            onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
          }) => {
            return (
              <Grid container className={isPropertiesView ? classes.optionalContainer : ''}>
                {_.orderBy(filteredActions, 'gOrder').map((fColumn: any) => {
                  const fieldValue = formData[fColumn.field];
                  const title = isPropertiesView ? fColumn.title : fColumn.label;
                  return (
                    <Grid item xs={6}>
                      <Box className={classes.checkboxFieldContainer}>
                        <Checkbox
                          name={fColumn.field}
                          checked={(fieldValue as unknown) as boolean || false}
                          color="primary"
                          size="small"
                          onChange={onChange}
                          className={classes.checkboxContainer}
                        />
                        <Typography className={classes.checkboxText}>{title}</Typography>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            );
          },
        }
      : null;

  if (Boolean(gridData)) {
    const insertPosition = otherData.findIndex((o) => (o as { field: string }).field === insertKey);
    otherData.splice(insertPosition + 1, 0, gridData!);
  }

  const getComponent = ({
    column,
    currentField,
    rowValue,
  }: {
    column: Column;
    currentField: CurrentField;
    rowValue: rowDataProps | AggrPropertyRow;
  }) => {
    const temp = column.lookup || (column.option as object);
    const fieldValue = formData[column.field];
    switch (currentField) {
      case 'component': {
        return column?.editComponent!({
          rowData: rowValue,
          value: fieldValue as string,
          onChange: (value: string) => handleFieldChange(column.field, value),
        });
      }
      case 'gridView': {
        return column?.gridView!({
          onChange: (value: React.ChangeEvent<HTMLInputElement>) => handleInputChange(value),
        });
      }
      case 'checkbox': {
        return (
          <Checkbox
            name={column.field}
            checked={(fieldValue as unknown) as boolean}
            color="primary"
            size="small"
            onChange={handleInputChange}
            className={classes.checkboxContainer}
          />
        );
      }
      case 'object': {
        return (
          <>
            <TGSelect
              id={column.field}
              value={(fieldValue as string) || ''}
              options={_.keys(temp)}
              onChange={(v) => handleSelectChange({ name: column.field, value: v as string })}
              containerStyle={classes.selctBox}
              type={column.field === 'aggregateName' ? 'aggregate' : column.field}
              canShowEmpty={!_.includes('type, aggregateName, action', column.field)}
            />
            {column.validate && (
              <FormHelperText className={classes.helperText}>
                {column.validate(formData).helperText}
              </FormHelperText>
            )}
          </>
        );
      }
      case 'textBox': {
        return (
          <TextInputTG
            value={formData[column.field] as string}
            id={column.field}
            inputStyle={classes.inputField}
            inputRoot={classes.textContainer}
            onChange={(value) => handleInputTGChange({ name: column.field, value })}
            name={column.field}
            helperText={column.validate && column.validate(formData).helperText}
            FormHelperTextProps={{
              classes: {
                root: classes.helperText,
              },
            }}
            placeholder={`Enter ${column.field}`}
          />
        );
      }
      default:
        return '';
    }
  };

  const renderFields = () => {
    return _.map(otherData, (column: Column) => {
      const isBoolean = column.type === 'boolean';
      const originalColumnTitles = [column.title];
      const isObject = _.isObject(column.lookup || column.option);

      let currentField: CurrentField = _.hasIn(column, 'editComponent')
        ? 'component'
        : isBoolean && !showProperties
        ? 'checkbox'
        : isObject
        ? 'object'
        : !isBoolean
        ? 'textBox'
        : '';
      currentField = _.hasIn(column, 'gridView') ? 'gridView' : currentField;
      const isComponent = currentField === 'component';
      let canShow = true;
      const rowValue = _.isEqual(column.field, 'action')
        ? (rowData as rowDataProps)
        : (formData as AggrPropertyRow);
      if (isComponent && _.hasIn(column, 'canShow')) {
        const key = _.get(column, 'canShow.key');
        const value = _.get(column, 'canShow.value');
        canShow = rowValue[key!] === value;
        if (column.field === 'foreignColumn') {
          const isTypeAggregate = () =>
            config.aggregates.some(
              (aggr) => aggr.typeName === (rowValue as { type: string })?.type
            );
          canShow = isTypeAggregate() && canShow;
        }
      }

      const modifiedTempHeader = _.map(originalColumnTitles, (value) => {
        if (value === 'Records Name') {
          return 'Aggregate Name';
        } else if (value === 'Primary') {
          return 'Can be primary';
        } else if (value === 'Color') {
          return 'Label Property Color';
        }
        return value;
      });

      const isOrderRemove = !_.isEqual(column.field, 'order');

      return (
        <Box
          className={`${
            isOrderRemove ? classes[`modalDataContainer${isBoolean ? 's' : ''}`] : ''
          } ${!canShow ? classes.mt0 : ''}`}
          key={column.title}
        >
          {isOrderRemove && (
            <>
              {canShow && <Typography className={classes.text}>{modifiedTempHeader}</Typography>}
              {getComponent({ column, currentField, rowValue })}
            </>
          )}
        </Box>
      );
    });
  };

  return (
    <ModalComponent className={classes.root}>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={openAddModal}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={
          !enableBlurBackground ? classes.modal : `${classes.modal} ${classes.disableblur}`
        }
      >
        <>
          <Box className={classes.paper}>
            <Box className={classes.header}>
              <Typography className={classes.headerText}>{title}</Typography>
              <IconButton className={classes.iconCloseContainer}>
                <FontAwesomeIcon
                  icon={faTimes}
                  color="grey"
                  className={classes.closeIcon}
                  onClick={closeModal}
                />
              </IconButton>
            </Box>
            <Divider />

            <Box className={classes.modalDataMain}>{renderFields()}</Box>

            <Box className={classes.bottomContainer}>
              <Button
                variant="contained"
                color="default"
                disableElevation
                className={`${classes.buttonContainer} ${classes.cancelBtn}`}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                disabled={showProperties ? (isFormInvalid as boolean) : false}
                className={
                  isFormInvalid
                    ? `${classes.buttonContainer} ${classes.saveBtn} ${classes.disableButton}`
                    : `${classes.buttonContainer} ${classes.saveBtn}`
                }
                onClick={() => {
                  type === 'Add' ? saveData(formData) : saveChanges();
                }}
              >
                {type === 'Add' ? 'Save' : 'Save Changes'}
              </Button>
            </Box>
          </Box>
        </>
      </Modal>
    </ModalComponent>
  );
}
