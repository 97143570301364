import { AggregateConfig } from '@terragotech/gen5-config-lib';
import { JSONSchema6 } from 'json-schema';
import React, { createContext, useContext, useMemo } from 'react';
import { propertiesToSchema } from '../../../pages/aggregates/utils/PropertiesToSchemaConverter';

interface ProviderProps {
  aggregateConfig: AggregateConfig;
  children: React.ReactElement;
}
interface ContextProps {
  aggregateSchema: JSONSchema6;
  aggregateConfig: AggregateConfig;
}
export const AggregateContext = createContext<ContextProps>({} as ContextProps);
export const AggregateContextProvider: React.FC<ProviderProps> = ({
  children,
  aggregateConfig,
}) => {
  const value = useMemo(() => {
    const aggregateSchema = aggregateConfig ? propertiesToSchema(aggregateConfig.properties) : {};
    return { aggregateConfig, aggregateSchema };
  }, [aggregateConfig]);
  return <AggregateContext.Provider value={value}>{children}</AggregateContext.Provider>;
};
export const useCurrentAggregate = (): ContextProps => useContext<ContextProps>(AggregateContext);
