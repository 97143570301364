import React from 'react';
import { FormControl, InputLabel, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Variant } from './index';
import { colors } from 'utils/colors';
import { TGInputText } from '../../views/pages/records/components/common/TGInputText';

interface TextInputProps {
  id: string;
  value: string | number | undefined;
  onChange?: (value: string | undefined) => void;
  onBlur?: (value: string | undefined) => void;
  label?: string;
  style?: React.CSSProperties;
  inputProps?: object;
  inputLabelProps?: object;
  variant?: Variant;
  fullWidth?: boolean;
  autoFocus?: boolean;
  type?: 'text' | 'number';
  noLabel?: boolean;
  disabled?: boolean;
  isInt?: boolean;
  error?: boolean;
  multiline?: boolean;
  helperText?: string;
  rows?: number;
  className?: string;
  containerClass?: string;
  placeholder?: string;
}

export const camelCaseToSpaceSeparated = (camelCase: string) =>
  camelCase
    .replace(/([A-Z])/g, (match: string) => ` ${match}`)
    .replace(/^./, (match: string) => match.toUpperCase());

export const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  value,
  onChange,
  onBlur,
  style,
  inputProps,
  inputLabelProps,
  variant,
  fullWidth,
  autoFocus,
  type,
  noLabel,
  disabled,
  isInt,
  error,
  helperText,
  multiline,
  rows,
  containerClass = '',
  placeholder,
  ...props
}) => {
  const classes = useStyles();
  return (
    <FormControl className={`${classes.input} ${containerClass}`} style={style}>
      {!noLabel && (
        <InputLabel shrink htmlFor="bootstrap-input">
          {label || camelCaseToSpaceSeparated(id)}
        </InputLabel>
      )}
      <TGInputText
        classes={{ input: classes.textInput }}
        type={type || 'text'}
        id={id}
        placeholder={placeholder || id}
        margin="dense"
        error={error || false}
        fullWidth={fullWidth ?? true}
        value={value || ''}
        onChange={(e) => onChange && onChange(e.target.value ? e.target.value : undefined)}
        onBlur={(e) => onBlur && onBlur(e.target.value ? e.target.value : undefined)}
        autoFocus={autoFocus}
        disabled={disabled}
        multiline={multiline}
        minRows={rows}
        {...props}
      />
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: '100%',
      fontSize: 15,
      color: colors.black,
      '& .MuiFormLabel-root': {
        fontSize: 14,
        color: colors.black,
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    textInput: {
      width: '100%',
      border: `1px solid ${colors.cyanBlue}`,
      fontSize: 15,
      padding: '12px',
      fontFamily: 'Inter Variable',
      height: 10,
    },
  })
);
