export type Translations = {
  [translation: string]: {
    [title: string]: string;
  };
};

export const defaultTranslations: Translations = {
  keys: {
    title: 'Keys',
  },
  operators: {
    title: 'Operators',
  },
  options: {
    title: 'Options',
  },
  removeCondition: {
    label: 'Delete',
    title: 'Remove condition',
  },
  removeGroup: {
    label: 'Delete',
    title: 'Remove Group',
  },
  addCondition: {
    label: ' Add Condition',
    title: 'Add Gule',
  },
  addGroup: {
    label: ' Add Group',
    title: 'Add Group',
  },
  conjunctions: {
    title: 'Conjunction',
  },
  notToggle: {
    title: 'Invert this group',
  },
};
