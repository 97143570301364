import { InputBase, InputBaseProps, alpha, withStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

interface Props extends Omit<InputBaseProps, 'onChange' | 'onBlur'> {
  fullWidth?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

export const TextInput = withStyles((theme) => ({
  root: {
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${colors.black25}`,
    fontSize: 16,
    fontFamily: 'Inter Variable,"Roboto","Helvetica","Arial",sans-serif',
    width: '100%',
    height: 36,
    padding: 0,
    paddingInline: 14,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

export const TGTextInput = ({ onChange, onBlur, ...props }: Props) => {
  return (
    <TextInput
      margin="dense"
      onChange={(e) => onChange && onChange(e.target.value ? e.target.value : '')}
      onBlur={(e) => onBlur && onBlur(e.target.value ? e.target.value : '')}
      fullWidth={props?.fullWidth ?? true}
      placeholder={props.placeholder || 'Enter Text'}
      multiline={props.multiline || false}
      {...props}
    />
  );
};
