import { FunctionComponent } from 'react';
import { V2BarcodeComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label, Placeholder } from './Common';
import qrCodeImage from './img/QRCode.png';
import { FormBadges } from './FormBadge';

const PlaceHolderFrame = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;
`;
const QrCodeFrame = styled.div`
  margin-left: 18px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const styles = {
  placeholder: {
    marginLeft: 8,
  },
};

const BarcodeControl: FunctionComponent<NestedFormControlProps<V2BarcodeComponent>> = (props) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />

      <PlaceHolderFrame>
        <QrCodeFrame>
          <img src={qrCodeImage} alt="QrCode icon" />
        </QrCodeFrame>

        <Placeholder style={styles.placeholder}>
          {controlDefinition.placeholder || 'Scan QR code or Barcode'}
        </Placeholder>
      </PlaceHolderFrame>
      <FormBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default BarcodeControl;
