import { AggregateLoaderType, SimpleOptionType } from '@terragotech/form-renderer';
import { Gen5Config } from '@terragotech/gen5-config-lib';
import { DATE_FORMAT_ISO, defaultProjectRecordsLimit } from '@terragotech/gen5-shared-utilities';

export const configExample: Gen5Config = {
  aggregates: [
    {
      labelProperty: 'title',
      typeName: 'Project',
      excludeInWebUI: false,
      isReferenceType:false,
      singularName: 'Project',
      pluralName: 'Projects',
      objectStore: {
        type: 'Postgres',
        schema: 'public',
        table: 'projects',
        rebuildOnStartup: true,
        reducerName: 'EVENT_MAP',
      },
      replayEventsOnStartup: true,
      properties: {
        id: { label: 'Project Id', type: 'ID', nullable: true, filterable: true },
        title: { label: 'Title', type: 'String', nullable: true },
        description: { label: 'Description', type: 'String', nullable: true },
        createdBy: { label: 'Created By', type: 'String', nullable: true },
        folder: {
          label: 'Folder',
          relation: 'ONE-TO-ONE',
          type: 'Folder',
          filterable: false,
          nullable: true,
        },
        assets: {
          label: 'none',
          foreignColumn: 'project',
          relation: 'ONE-TO-MANY',
          type: 'Asset',
          filterable: false,
          nullable: true,
        },
        messages: {
          label: 'Messages',
          type: 'Message',
          relation: 'ONE-TO-MANY',
          foreignColumn: 'project',
          nullable: true,
        },
      },
      events: {
        projectCreated: {
          versions: [
            {
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', name: 'Event' },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    name: 'Output State',
                    inputs: {
                      title: { sourceObject: 'EVENT', sourcePath: '$.data.title' },
                      description: { sourceObject: 'EVENT', sourcePath: '$.data.description' },
                      createdBy: { sourceObject: 'EVENT', sourcePath: '$.data.createdBy' },
                      folder: { sourceObject: 'EVENT', sourcePath: '$.data.folderId' },
                    },
                    config: { objectSchema: 'STATE' },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  type: { type: 'string', enum: ['projectCreated'] },
                  version: { type: 'number' },
                  timestamp: {
                    description: 'Timestamp',
                    type: 'string',
                    format: 'date-time',
                  },
                  aggregateId: { type: 'string' },
                  data: {
                    type: 'object',
                    properties: {
                      description: { $id: '#/properties/Lon', type: 'number' },
                      title: { $id: '#/properties/Tags', type: 'string' },
                      createdBy: { $id: '#/properties/Label', type: 'string' },
                      folderId: { $id: '#/properties/Id', type: 'string' },
                    },
                  },
                },
              },
            },
          ],
        },
        projectAddedToFolder: {
          versions: [
            {
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  type: { type: 'string', enum: ['projectAddedToFolder'] },
                  version: { type: 'number' },
                  timestamp: {
                    description: 'Timestamp',
                    type: 'string',
                    format: 'date-time',
                  },
                  aggregateId: { type: 'string' },
                  metadata: {
                    type: 'object',
                    properties: {
                      timeFormOpened: { type: 'string', format: 'date-time' },
                      aggregateType: { type: 'string' },
                      userInfo: {
                        type: 'object',
                        properties: {
                          userName: { type: 'string' },
                          email: { type: 'string' },
                          authExpiration: { type: 'string', format: 'date-time' },
                          familyName: { type: 'string' },
                          givenName: { type: 'string' },
                          authPayload: { type: 'object', properties: {} },
                          roles: {
                            type: 'array',
                            items: {
                              type: 'object',
                              properties: { id: { type: 'string' }, name: { type: 'string' } },
                            },
                          },
                          roleString: { type: 'string' },
                        },
                      },
                    },
                  },
                  data: {
                    type: 'object',
                    properties: { folderId: { type: 'string' } },
                    required: ['folderId'],
                  },
                },
              },
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', name: 'Event' },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    name: 'Output State',
                    inputs: {
                      id: { sourceObject: 'EVENT', sourcePath: '$.aggregateId' },
                      folder: { sourceObject: 'EVENT', sourcePath: '$.data.folderId' },
                    },
                    config: { objectSchema: 'STATE' },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
            },
          ],
        },
      },
      derivedPropertyMapping: {
        nodes: {
          OUTPUT: {
            type: 'OBJECT-BUILDER',
            name: 'Output State',
            inputs: {},
            config: { objectSchema: 'STATE' },
          },
        },
        outputDefinition: { outputNode: 'OUTPUT' },
      },
    },
    {
      typeName: 'Folder',
      pluralName: 'Folders',
      singularName: 'Folder',
      objectStore: {
        rebuildOnStartup: true,
        table: 'folders',
        type: 'Postgres',
        reducerName: 'EVENT_MAP',
        schema: 'public',
      },
      replayEventsOnStartup: true,
      labelProperty: 'title',
      derivedPropertyMapping: {
        nodes: {
          OUTPUT: {
            type: 'OBJECT-BUILDER',
            name: 'Output State',
            inputs: {},
            config: { objectSchema: 'STATE' },
          },
        },
        outputDefinition: { outputNode: 'OUTPUT' },
      },
      properties: {
        title: { label: 'Title', type: 'String', uiType: 'Title', nullable: true },
        id: {
          label: 'Id',
          type: 'ID',
          uiType: 'Hidden',
          hideFromAssetAttributes: true,
          nullable: true,
        },
        projects: {
          label: 'Projects',
          type: 'Project',
          nullable: true,
          relation: 'ONE-TO-MANY',
          foreignColumn: 'folder',
        },
        parentFolder: {
          nullable: true,
          label: 'Parent Folder',
          type: 'Folder',
          relation: 'ONE-TO-ONE',
        },
        assetAttributes: {
          label: 'Asset Attributes',
          type: 'JSON',
          uiType: 'Hidden',
          nullable: true,
        },
      },
      events: {
        folderCreated: {
          versions: [
            {
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  type: { type: 'string', enum: ['folderCreated'] },
                  version: { type: 'number' },
                  timestamp: {
                    description: 'Timestamp',
                    type: 'string',
                    format: 'date-time',
                  },
                  aggregateId: { type: 'string' },
                  metadata: {
                    type: 'object',
                    properties: {
                      timeFormOpened: { type: 'string', format: 'date-time' },
                      aggregateType: { type: 'string' },
                      userInfo: {
                        type: 'object',
                        properties: {
                          userName: { type: 'string' },
                          email: { type: 'string' },
                          authExpiration: { type: 'string', format: 'date-time' },
                          familyName: { type: 'string' },
                          givenName: { type: 'string' },
                          authPayload: { type: 'object', properties: {} },
                          roles: {
                            type: 'array',
                            items: {
                              type: 'object',
                              properties: { id: { type: 'string' }, name: { type: 'string' } },
                            },
                          },
                          roleString: { type: 'string' },
                        },
                      },
                    },
                  },
                  data: {
                    type: 'object',
                    properties: {
                      folderName: { type: 'string' },
                      parentFolder: { type: 'string' },
                    },

                    required: ['folderName'],
                  },
                },
              },
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', name: 'Event' },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    name: 'Output State',
                    inputs: {
                      title: { sourceObject: 'EVENT', sourcePath: '$.data.folderName' },
                      id: { sourceObject: 'EVENT', sourcePath: '$.aggregateId' },
                      parentFolder: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.parentFolder',
                      },
                    },
                    config: { objectSchema: 'STATE' },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
            },
          ],
        },
      },
    },
    {
      typeName: 'Asset',
      labelProperty: 'title',
      pluralName: 'Assets',
      singularName: 'Asset',
      objectStore: {
        rebuildOnStartup: true,
        table: 'assets',
        type: 'Postgres',
        reducerName: 'EVENT_MAP',
        schema: 'public',
      },
      replayEventsOnStartup: true,
      properties: {
        symbolKey: {
          label: 'Symbol Key',
          type: 'String',
          uiType: 'Symbol Key',
          filterOptions: 'DISTINCT',
          filterable: true,
          hideFromAssetAttributes: true,
          nullable: true,
        },
        title: {
          label: 'Title',
          type: 'String',
          uiType: 'Title',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        id: {
          label: 'Asset',
          type: 'ID',
          uiType: 'Hidden',
          filterOptions: 'DISTINCT',
          filterable: true,
          hideFromAssetAttributes: true,
          nullable: true,
        },
        project: {
          label: 'Project',
          type: 'Project',
          filterable: true,
          nullable: true,
          relation: 'ONE-TO-ONE',
        },
        createdBy: {
          label: 'Created By',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        createdDateTime: {
          label: 'Created Date/Time',
          type: 'DateTime',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        modifiedDateTime: {
          label: 'Modified Date/Time',
          type: 'DateTime',
          uiType: 'Hidden',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        editUrl: {
          label: 'Edit URL',
          type: 'String',
          uiType: 'Hidden',
          filterOptions: 'DISTINCT',
          filterable: true,
          hideFromAssetAttributes: true,
          nullable: true,
        },
        lat: {
          label: 'Latitude',
          type: 'Float',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lon: {
          label: 'Longitude',
          type: 'Float',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        assetAttributes: {
          label: 'Asset Attributes',
          type: 'JSON',
          uiType: 'Hidden',
          filterOptions: 'DISTINCT',
          filterable: false,
          nullable: true,
        },
        premiseNodeLlc: {
          label: 'Premise/Node/LLC',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        streetAddress: {
          label: 'Street Address',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        llcGrid: {
          label: 'LLC Grid',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        taxDistrict: {
          label: 'Tax District',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        equipmentType: {
          label: 'Equipment Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        fixtureOwnershipCode: {
          label: 'Fixture Ownership Code',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        fixtureCompatibleUnit: {
          label: 'Fixture Compatible Unit',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        armCompatibleUnit: {
          label: 'Arm Compatible Unit',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        supplyType: {
          label: 'Supply Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        fixtureStyle: {
          label: 'FIXTURE_STYLE',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lightInstallationDate: {
          label: 'Light Installation Date',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        armSize: {
          label: 'Arm Size',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        armType: {
          label: 'Arm Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lightLocationType: {
          label: 'Light Location Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        associatedTransformer: {
          label: 'Associated Transformer',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        llcVoltage: {
          label: 'LLC Voltage',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        shade: {
          label: 'Shade',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        height: {
          label: 'Height',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        poleInstallationDate: {
          label: 'Pole Installation Date',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        poleColor: {
          label: 'Pole Color',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        material: {
          label: 'Material',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        streetLightOnlyPoleStyle: {
          label: 'Street Light Only Pole Style',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        streetLightOnlyPoleType: {
          label: 'Street Light Only Pole Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        slopShroud: {
          label: 'Slop Shroud',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        poleOwnershipCode: {
          label: 'Pole Ownership Code',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        maintenancePlan: {
          label: 'Maintenance Plan',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        fixtureWattage: {
          label: 'Fixture Wattage',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        poleNumber: {
          label: 'Pole #',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lampType: {
          label: 'Lamp Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        fixtureType: {
          label: 'Fixture Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        fixtureColor: {
          label: 'Fixture Color',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        controllerType: {
          label: 'Controller Type',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        macAddress: {
          label: 'MAC address',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        fixtureQrScan: {
          label: 'Fixture QR Scan',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        installDate: {
          label: 'Install Date',
          type: 'DateTime',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        installStatus: {
          label: 'Install status',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        attemptedInstallDate: {
          label: 'Attempted Install Date',
          type: 'DateTime',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        installComments: {
          label: 'Install Comments',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        configStatus: {
          label: 'Config Status',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        communicationStatus: {
          label: 'Communication Status',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        meteredPower: {
          label: 'Metered Power',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        luminaireWattage: {
          label: 'Luminaire Wattage',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        meteredCurrent: {
          label: 'Metered Current',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        meteredVoltage: {
          label: 'Metered Voltage',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        failure: {
          label: 'Failure',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        failedSince: {
          label: 'Failed Since',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        burningHours: {
          label: 'Burning Hours',
          type: 'Float',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lifetime: {
          label: 'Lifetime %',
          type: 'Float',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        installIssueReason: {
          label: 'Complete with Issues Reason',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        latestRemovalReason: {
          label: 'Reason for removal',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lastRemovalDate: {
          label: 'Removal Date',
          type: 'DateTime',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lastReplacementDate: {
          label: 'Replacement Date',
          type: 'DateTime',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lastReplacementComments: {
          label: 'Replace Comments',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        lastOutageUpdate: {
          label: 'Last Update',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
      },
      events: {
        locationChangedEvent: {
          versions: [
            {
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  type: { type: 'string', enum: ['locationChangedEvent'] },
                  data: {
                    type: 'object',
                    properties: {
                      lat: { type: 'number' },
                      long: { type: 'number' },
                      horizontalAccuracy: { type: 'string' },
                      altitude: { type: 'string' },
                      altitudeAccuracy: { type: 'string' },
                      satellitesCount: { type: 'number' },
                      bearing: { type: 'string' },
                      gpsTime: { type: 'string' },
                      speed: { type: 'string' },
                      corrected: { type: 'string' },
                      sourceType: { type: 'string' },
                      bearingTrueNorth: { type: 'string' },
                      bearingAccuracy: { type: 'string' },
                      lockType: { type: 'string' },
                      locationProvider: { type: 'string' },
                      pdop: { type: 'string' },
                      vdop: { type: 'string' },
                      hdop: { type: 'string' },
                      geoJSON: { type: 'string' },
                      geometry: { type: 'string' },
                    },
                  },
                  metadata: { type: 'object', properties: { revisionId: { type: 'string' } } },
                  version: { type: 'number' },
                  source: { type: 'string' },
                  timestamp: {
                    description: 'Timestamp',
                    type: 'string',
                    format: 'date-time',
                  },
                },
                $schema: 'http://json-schema.org/draft-07/schema#',
              },
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', name: 'Event' },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    name: 'Output State',
                    inputs: {
                      lat: { sourceObject: 'EVENT', sourcePath: '$.data.lat' },
                      lon: { sourceObject: 'EVENT', sourcePath: '$.data.long' },
                    },
                    config: { objectSchema: 'STATE' },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
            },
          ],
        },
        nodeInstalled: {
          versions: [
            {
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', name: 'Event' },
                  newNode1: {
                    name: 'DateString Parser',
                    type: 'DATE-STRING-PARSER',
                    inputs: { source: { sourceObject: 'EVENT', sourcePath: '$.installDate' } },
                    config: {},
                  },
                  newNode3: {
                    name: 'Concatenate',
                    type: 'STRING-CONCAT',
                    inputs: {
                      strings: [
                        { sourceObject: 'EVENT', sourcePath: '$.couldNotCompleteReason' },
                        { sourceObject: 'EVENT', sourcePath: '$.completeWithIssuesReason' },
                      ],
                    },
                    config: {},
                  },
                  symbolNode: {
                    name: 'Symbol Key Mapping',
                    type: 'VALUE-MAPPER',
                    inputs: {},
                    config: {
                      mappings: [
                        ['Complete', 'symbol_circle_39b310'],
                        ['Could Not Complete', 'symbol_circle_c90000'],
                        ['Complete with Issues', 'symbol_circle_3388FF'],
                      ],
                    },
                  },
                  newNode2: {
                    name: 'DateString Parser',
                    type: 'DATE-STRING-PARSER',
                    inputs: {
                      source: { sourceObject: 'EVENT', sourcePath: '$.attemptedInstallDate' },
                    },
                    config: {},
                  },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    name: 'Output State',
                    inputs: {
                      symbolKey: { sourceObject: 'symbolNode', sourcePath: '$.output' },
                      controllerType: {
                        sourcePath: '$.data.controllerType',
                        sourceObject: 'EVENT',
                      },
                      macAddress: { sourcePath: '$.data.macAddress', sourceObject: 'EVENT' },
                      fixtureQrScan: { sourcePath: '$.data.fixtureQRScan', sourceObject: 'EVENT' },
                      installDate: { sourceObject: 'newNode1', sourcePath: 'output' },
                      installStatus: { sourcePath: '$.data.installStatus', sourceObject: 'EVENT' },
                      attemptedInstallDate: { sourceObject: 'newNode2', sourcePath: 'output' },
                      installComments: {
                        sourcePath: '$.data.installComments',
                        sourceObject: 'EVENT',
                      },
                      installIssueReason: { sourceObject: 'newNode3', sourcePath: '$.output' },
                    },
                    config: { objectSchema: 'STATE' },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  controllerType: { type: 'string', enum: ['Smart Controller', 'Photocell'] },
                  macAddress: { type: 'string' },
                  fixtureScannable: { type: 'string', enum: ['Yes', 'No'] },
                  fixtureQRScan: { type: 'string' },
                  fixturePhoto: { type: 'array', items: { type: 'string' } },
                  installDate: { type: 'string' },
                  installStatus: {
                    type: 'string',
                    enum: [
                      'Not Yet Complete',
                      'Complete with Issues',
                      'Could Not Complete',
                      'Complete',
                    ],
                  },
                  completeWithIssuesReason: { type: 'string' },
                  couldNotCompleteReason: { type: 'string' },
                  attemptedInstallDate: { type: 'string' },
                  installComments: { type: 'string' },
                },
              },
            },
          ],
        },
        nodeReplaced: {
          currentVersion: 1,
          versions: [
            {
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  newMacAddress: { type: 'string' },
                  physicalDamage: { enum: ['Yes', 'No'], type: 'string' },
                  replaceComments: { type: 'string' },
                  replacementDate: { type: 'string' },
                  existingMacAddress: { type: 'string' },
                },
              },
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', name: 'Event' },
                  newNode3: {
                    name: 'Concatenate',
                    type: 'STRING-CONCAT',
                    inputs: { strings: ['Complete'] },
                    config: {},
                  },
                  newNode1: {
                    name: 'DateString Parser',
                    type: 'DATE-STRING-PARSER',
                    inputs: { source: { sourceObject: 'EVENT', sourcePath: '$.replacementDate' } },
                    config: {},
                  },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    name: 'Output State',
                    inputs: {
                      macAddress: { sourceObject: 'EVENT', sourcePath: '$.newMacAddress' },
                      installStatus: { sourceObject: 'newNode3', sourcePath: '$.output' },
                      lastReplacementDate: { sourceObject: 'newNode1', sourcePath: 'output' },
                      lastReplacementComments: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.replaceComments',
                      },
                    },
                    config: { objectSchema: 'STATE' },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
            },
          ],
        },
        nodeRemoved: {
          currentVersion: 1,
          versions: [
            {
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  removalDate: { type: 'string' },
                  removalReason: { type: 'string' },
                  physicalDamage: { enum: ['Yes', 'No'], type: 'string' },
                  removedMacAddress: { type: 'string' },
                },
              },
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', name: 'Event' },
                  newNode2: {
                    name: 'Concatenate',
                    type: 'STRING-CONCAT',
                    inputs: { strings: [] },
                    config: {},
                  },
                  newNode3: {
                    name: 'Concatenate',
                    type: 'STRING-CONCAT',
                    inputs: { strings: ['Not Yet Complete'] },
                    config: {},
                  },
                  newNode1: {
                    name: 'DateString Parser',
                    type: 'DATE-STRING-PARSER',
                    inputs: { source: { sourceObject: 'EVENT', sourcePath: '$.removalDate' } },
                    config: {},
                  },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    name: 'Output State',
                    inputs: {
                      macAddress: { sourceObject: 'newNode2', sourcePath: '$.output' },
                      installStatus: { sourceObject: 'newNode3', sourcePath: '$.output' },
                      latestRemovalReason: { sourceObject: 'EVENT', sourcePath: '$.removalReason' },
                      lastRemovalDate: { sourceObject: 'newNode1', sourcePath: 'output' },
                    },
                    config: { objectSchema: 'STATE' },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
            },
          ],
        },
      },
      commands: {
        updateAssetLocation: {
          defaultLabel: 'Update Location',
          versions: [
            {
              commandType: 'INTERNAL',
              isCreationCommand: false,
              version: 1,
              cmdToEventsMapping: { eventName: 'locationChangedEvent', mappingType: 'COPY' },
            },
          ],
        },
        installNode: {
          defaultLabel: 'Install Node',
          versions: [
            {
              version: 1,
              isCreationCommand: false,
              commandType: 'FORM',
              cmdToEventsMapping: { mappingType: 'COPY', eventName: 'nodeInstalled' },
              formDefinition: {
                order: [
                  'controllerType',
                  'macAddress',
                  'fixtureScannable',
                  'fixtureQRScan',
                  'fixturePhoto',
                  'installDate',
                  'installStatus',
                  'completeWithIssuesReason',
                  'couldNotCompleteReason',
                  'attemptedInstallDate',
                  'installComments',
                ],
                components: {
                  controllerType: {
                    type: 'radio',
                    label: 'Controller Type',
                    required: true,
                    repeats: { min: 1, max: 1 },
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Smart Controller', label: 'Smart Controller' },
                        { value: 'Photocell', label: 'Photocell' },
                      ],
                    },
                  },
                  macAddress: {
                    type: 'barcode',
                    required: true,
                    label: 'MAC address',
                    repeats: { min: 1, max: 1 },
                    conditionalMap: {
                      nodes: {
                        FORM: { type: 'FORM', name: 'Form' },
                        isSmartController: {
                          name: 'Is Smart Controller',
                          type: 'STRING-EQUALS',
                          inputs: {
                            firstSource: { sourcePath: '$.controllerType', sourceObject: 'FORM' },
                            secondSource: {
                              sourcePath: '$.output',
                              sourceObject: 'smartControllerString',
                            },
                          },
                          config: {},
                        },
                        smartControllerString: {
                          name: 'Smart Controller Text',
                          type: 'STRING-CONCAT',
                          inputs: { strings: ['Smart Controller'] },
                          config: {},
                        },
                        FORM_CONDITIONAL: {
                          type: 'FORM-CONDITIONAL',
                          inputs: {
                            isVisible: {
                              sourceObject: 'isSmartController',
                              sourcePath: '$.output',
                            },
                          },
                        },
                      },
                      outputDefinition: { outputNode: 'FORM_CONDITIONAL' },
                    },
                  },
                  fixtureScannable: {
                    type: 'radio',
                    label: 'Is the fixture ID a scannable QR code?',
                    repeats: { min: 1, max: 1 },
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Yes', label: 'Yes' },
                        { value: 'No', label: 'No' },
                      ],
                    },
                  },
                  fixtureQRScan: {
                    type: 'barcode',
                    label: 'Fixture QR Scan',
                    repeats: { min: 1, max: 1 },
                    conditionalMap: {
                      nodes: {
                        FORM: { type: 'FORM', name: 'Form' },
                        isScannable: {
                          name: 'Is Scannable Yes',
                          type: 'STRING-EQUALS',
                          inputs: {
                            firstSource: { sourcePath: '$.fixtureScannable', sourceObject: 'FORM' },
                            secondSource: { sourcePath: '$.output', sourceObject: 'yesString' },
                          },
                          config: {},
                        },
                        yesString: {
                          name: 'Yes string',
                          type: 'STRING-CONCAT',
                          inputs: { strings: ['Yes'] },
                          config: {},
                        },
                        FORM_CONDITIONAL: {
                          type: 'FORM-CONDITIONAL',
                          inputs: {
                            isVisible: { sourceObject: 'isScannable', sourcePath: '$.output' },
                          },
                        },
                      },
                      outputDefinition: { outputNode: 'FORM_CONDITIONAL' },
                    },
                  },
                  fixturePhoto: {
                    type: 'imageupload',
                    label: 'Fixture Photo',
                  },
                  installDate: {
                    type: 'date',
                    label: 'Install Date',
                    required: true,
                    repeats: { min: 1, max: 1 },
                  },
                  installStatus: {
                    type: 'radio',
                    label: 'Install Status',
                    repeats: { min: 1, max: 1 },
                    placeholder: 'placeholder',
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Not Yet Complete', label: 'Not Yet Complete' },
                        { value: 'Complete with Issues', label: 'Complete with Issues' },
                        { value: 'Could Not Complete', label: 'Could Not Complete' },
                        { value: 'Complete', label: 'Complete' },
                      ],
                    },
                  },
                  completeWithIssuesReason: {
                    type: 'textArea',
                    required: true,
                    label: 'Complete With Issues Reason',
                    repeats: { min: 1, max: 1 },
                    conditionalMap: {
                      nodes: {
                        FORM: { type: 'FORM', name: 'Form' },
                        hasIssues: {
                          name: 'Complete With Issues is true',
                          type: 'STRING-EQUALS',
                          inputs: {
                            firstSource: { sourcePath: '$.installStatus', sourceObject: 'FORM' },
                            secondSource: {
                              sourcePath: '$.output',
                              sourceObject: 'completeWIssues',
                            },
                          },
                          config: {},
                        },
                        completeWIssues: {
                          name: 'Complete with Issues',
                          type: 'STRING-CONCAT',
                          inputs: { strings: ['Complete with Issues'] },
                          config: {},
                        },
                        FORM_CONDITIONAL: {
                          type: 'FORM-CONDITIONAL',
                          inputs: {
                            isVisible: { sourceObject: 'hasIssues', sourcePath: '$.output' },
                          },
                        },
                      },
                      outputDefinition: { outputNode: 'FORM_CONDITIONAL' },
                    },
                  },
                  couldNotCompleteReason: {
                    type: 'textArea',
                    required: true,
                    label: 'Could Not Complete Reason',
                    repeats: { min: 1, max: 1 },
                    conditionalMap: {
                      nodes: {
                        FORM: { type: 'FORM', name: 'Form' },
                        couldntComplete: {
                          name: 'Could Not Complete is true',
                          type: 'STRING-EQUALS',
                          inputs: {
                            firstSource: { sourcePath: '$.installStatus', sourceObject: 'FORM' },
                            secondSource: {
                              sourcePath: '$.output',
                              sourceObject: 'couldntCompleteString',
                            },
                          },
                          config: {},
                        },
                        couldntCompleteString: {
                          name: 'Couldnt Complete Text',
                          type: 'STRING-CONCAT',
                          inputs: { strings: ['Could Not Complete'] },
                          config: {},
                        },
                        FORM_CONDITIONAL: {
                          type: 'FORM-CONDITIONAL',
                          inputs: {
                            isVisible: { sourceObject: 'couldntComplete', sourcePath: '$.output' },
                          },
                        },
                      },
                      outputDefinition: { outputNode: 'FORM_CONDITIONAL' },
                    },
                  },
                  attemptedInstallDate: {
                    type: 'date',
                    label: 'Attempted Install Date',
                    repeats: { min: 1, max: 1 },
                    required: true,
                    conditionalMap: {
                      nodes: {
                        FORM: { type: 'FORM', name: 'Form' },
                        couldntComplete: {
                          name: 'Could Not Complete is true',
                          type: 'STRING-EQUALS',
                          inputs: {
                            firstSource: { sourcePath: '$.installStatus', sourceObject: 'FORM' },
                            secondSource: {
                              sourcePath: '$.output',
                              sourceObject: 'couldntCompleteString',
                            },
                          },
                          config: {},
                        },
                        couldntCompleteString: {
                          name: 'Couldnt Complete Text',
                          type: 'STRING-CONCAT',
                          inputs: { strings: ['Could Not Complete'] },
                          config: {},
                        },
                        FORM_CONDITIONAL: {
                          type: 'FORM-CONDITIONAL',
                          inputs: {
                            isVisible: { sourceObject: 'couldntComplete', sourcePath: '$.output' },
                          },
                        },
                      },
                      outputDefinition: { outputNode: 'FORM_CONDITIONAL' },
                    },
                  },
                  installComments: {
                    type: 'textArea',
                    label: 'Install Comments',
                    repeats: { min: 1, max: 1 },
                  },
                },
              },
            },
          ],
        },
        replaceNode: {
          defaultLabel: 'Replace Node',
          versions: [
            {
              version: 1,
              isCreationCommand: false,
              commandType: 'FORM',
              cmdToEventsMapping: { mappingType: 'COPY', eventName: 'nodeReplaced' },
              formDefinition: {
                order: [
                  'Name',
                  'Show Attributes',
                  'Existing Attributes',
                  'Select Action',
                  'Install Node',
                  'Replace Node',
                  'Remove Node',
                  'Set Retrofit Status',
                  '**Please Specify Issue Below**',
                  'Comments',
                  'Reason',
                  'Metering Data',
                  'Outage Data',
                ],
                components: {
                  Name: {
                    type: 'textInput',
                    label: 'Name',
                    repeats: { min: 1, max: 1 },
                    placeholder: 'Enter text',
                  },
                  'Show Attributes': {
                    type: 'radio',
                    label: 'Show Attributes',
                    repeats: { min: 1, max: 1 },
                    placeholder: 'placeholder',
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Hide', label: 'Hide' },
                        { value: 'Show', label: 'Show' },
                      ],
                    },
                  },
                  'Existing Attributes': {
                    type: 'group',
                    conditionalMap: {
                      nodes: {
                        FORM: { type: 'FORM', name: 'Form' },
                        newNode1: {
                          name: 'Is Install',
                          type: 'STRING-EQUALS',
                          inputs: {
                            firstSource: { sourcePath: '$.Select Action', sourceObject: 'FORM' },
                            secondSource: {
                              sourcePath: '$.newNode2_output',
                              sourceObject: 'newNode2',
                            },
                          },
                          config: {},
                        },
                        newNode2: {
                          name: 'Is Install',
                          type: 'STRING-CONCAT',
                          inputs: { strings: ['Replace Node'] },
                          config: {},
                        },
                        FORM_CONDITIONAL: {
                          type: 'FORM-CONDITIONAL',
                          inputs: {
                            isVisible: {
                              sourceObject: 'newNode1',
                              sourcePath: '$.newNode1_output',
                            },
                          },
                        },
                      },
                      outputDefinition: { outputNode: 'FORM_CONDITIONAL' },
                    },
                    label: 'Existing Attributes',
                    repeats: { min: 1, max: 1 },
                    template: {
                      order: [
                        'idOnController',
                        'Client Number',
                        'Site Name',
                        'Feature ID',
                        'Central Asset ID',
                        'Site Code',
                        'GeoZone Path',
                        'Feature Location',
                        'Lamp Type',
                        'Lamp Wattage',
                        'Lamp Change',
                        'Gear Type',
                        'Column Type',
                        'Column Material',
                        'Mounting Height',
                        'Column Fixing',
                        'Lantern MFG',
                        'Lantern Install Date',
                        'Lantern Type',
                        'Light Class',
                        'Bracket Type',
                      ],
                      components: {
                        idOnController: {
                          type: 'textInput',
                          required: false,
                          label: 'idOnController',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Client Number': {
                          type: 'textInput',
                          required: false,
                          label: 'Client Number',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Site Name': {
                          type: 'textInput',
                          required: false,
                          label: 'Site Name',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Feature ID': {
                          type: 'textInput',
                          required: false,
                          label: 'Feature ID',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Central Asset ID': {
                          type: 'textInput',
                          required: false,
                          label: 'Central Asset ID',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Site Code': {
                          type: 'textInput',
                          required: false,
                          label: 'Site Code',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'GeoZone Path': {
                          type: 'textInput',
                          required: false,
                          label: 'GeoZone Path',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Feature Location': {
                          type: 'textArea',
                          required: false,
                          label: 'Feature Location',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Lamp Type': {
                          type: 'textInput',
                          required: false,
                          label: 'Lamp Type',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Lamp Wattage': {
                          type: 'textInput',
                          required: false,
                          label: 'Lamp Wattage',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Lamp Change': {
                          type: 'textInput',
                          required: false,
                          label: 'Lamp Change',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Gear Type': {
                          type: 'textInput',
                          required: false,
                          label: 'Gear Type',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Column Type': {
                          type: 'textInput',
                          required: false,
                          label: 'Column Type',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Column Material': {
                          type: 'textInput',
                          required: false,
                          label: 'Column Material',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Mounting Height': {
                          type: 'textInput',
                          required: false,
                          label: 'Mounting Height',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Column Fixing': {
                          type: 'textInput',
                          required: false,
                          label: 'Column Fixing',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Lantern MFG': {
                          type: 'textInput',
                          required: false,
                          label: 'Lantern MFG',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Lantern Install Date': {
                          type: 'textInput',
                          required: false,
                          label: 'Lantern Install Date',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Lantern Type': {
                          type: 'textInput',
                          required: false,
                          label: 'Lantern Type',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Light Class': {
                          type: 'textInput',
                          required: false,
                          label: 'Light Class',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Bracket Type': {
                          type: 'textInput',
                          required: false,
                          label: 'Bracket Type',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                      },
                    },
                  },
                  'Select Action': {
                    type: 'radio',
                    label: 'Select Action',
                    repeats: { min: 1, max: 1 },
                    placeholder: 'placeholder',
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Install Node', label: 'Install Node' },
                        { value: 'Replace Node', label: 'Replace Node' },
                        { value: 'Remove Node', label: 'Remove Node' },
                      ],
                    },
                  },
                  'Install Node': {
                    type: 'group',
                    label: 'Install Node',
                    repeats: { min: 1, max: 1 },
                    template: {
                      order: ['Scan Node', 'Install Date'],
                      components: {
                        'Scan Node': {
                          type: 'barcode',
                          required: true,
                          label: 'Scan Node',
                          repeats: { min: 1, max: 1 },
                        },
                        'Install Date': {
                          type: 'date',
                          required: true,
                          label: 'Install Date',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Select date',
                        },
                      },
                    },
                  },
                  'Replace Node': {
                    type: 'group',
                    label: 'Replace Node',
                    repeats: { min: 1, max: 1 },
                    template: {
                      order: [
                        'Existing Node',
                        'Replacement Date',
                        'New Node',
                        'Replacement Comments (Opt)',
                      ],
                      components: {
                        'Existing Node': {
                          type: 'barcode',
                          required: true,
                          label: 'Existing Node',
                          repeats: { min: 1, max: 1 },
                        },
                        'Replacement Date': {
                          type: 'date',
                          required: true,
                          label: 'Replacement Date',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Select date',
                        },
                        'New Node': {
                          type: 'barcode',
                          required: true,
                          label: 'New Node',
                          repeats: { min: 1, max: 1 },
                        },
                        'Replacement Comments (Opt)': {
                          type: 'textArea',
                          required: false,
                          label: 'Replacement Comments (Opt)',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                      },
                    },
                  },
                  'Remove Node': {
                    type: 'group',
                    label: 'Remove Node',
                    repeats: { min: 1, max: 1 },
                    template: {
                      order: [
                        'Reason for Removal',
                        '**Fixture will be reset to Not Yet Complete**',
                        'Scan Removed Node',
                        'Removal Date',
                        'Removal Comments (Opt)',
                      ],
                      components: {
                        'Reason for Removal': {
                          type: 'select',
                          required: true,
                          label: 'Reason for Removal',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'placeholder',
                          options: {
                            type: SimpleOptionType,
                            items: [
                              {
                                value: 'Installed Node on Wrong Lantern',
                                label: 'Installed Node on Wrong Lantern',
                              },
                              { value: 'Column Knocked Down', label: 'Column Knocked Down' },
                              { value: 'Column Being Removed', label: 'Column Being Removed' },
                              { value: 'No Service to Lantern', label: 'No Service to Lantern' },
                            ],
                          },
                        },
                        '**Fixture will be reset to Not Yet Complete**': {
                          type: 'textheader',
                          label: '**Fixture will be reset to Not Yet Complete**',
                        },
                        'Scan Removed Node': {
                          type: 'barcode',
                          required: false,
                          label: 'Scan Removed Node',
                          repeats: { min: 1, max: 1 },
                        },
                        'Removal Date': {
                          type: 'date',
                          required: true,
                          label: 'Removal Date',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Select date',
                        },
                        'Removal Comments (Opt)': {
                          type: 'textArea',
                          required: false,
                          label: 'Removal Comments (Opt)',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                      },
                    },
                  },
                  'Set Retrofit Status': {
                    type: 'radio',
                    required: true,
                    label: 'Set Retrofit Status',
                    repeats: { min: 1, max: 1 },
                    placeholder: 'placeholder',
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Not Yet Complete', label: 'Not Yet Complete' },
                        { value: 'Complete with Issues', label: 'Complete with Issues' },
                        { value: 'Unable to Complete', label: 'Unable to Complete' },
                        { value: 'Removed', label: 'Removed' },
                        { value: 'Completed', label: 'Completed' },
                      ],
                    },
                  },
                  '**Please Specify Issue Below**': {
                    type: 'textheader',
                    label: '**Please Specify Issue Below**',
                  },
                  Comments: {
                    type: 'textArea',
                    required: false,
                    label: 'Comments',
                    repeats: { min: 1, max: 1 },
                    placeholder: 'Enter text',
                  },
                  Reason: {
                    type: 'textArea',
                    required: true,
                    label: 'Reason',
                    repeats: { min: 1, max: 1 },
                    placeholder: 'Enter text',
                  },
                  'Metering Data': {
                    type: 'group',
                    label: 'Metering Data',
                    repeats: { min: 1, max: 1 },
                    template: {
                      order: [
                        'Display',
                        'Config Status',
                        'Communication Status',
                        'Metered Power',
                        'Luminaire Wattage',
                        'Metered Current',
                        'Metered Voltage',
                      ],
                      components: {
                        Display: {
                          type: 'radio',
                          required: false,
                          label: 'Display',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'placeholder',
                          options: {
                            type: SimpleOptionType,
                            items: [
                              { value: 'Hide ', label: 'Hide ' },
                              { value: 'Show', label: 'Show' },
                            ],
                          },
                        },
                        'Config Status': {
                          type: 'textInput',
                          required: false,
                          label: 'Config Status',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Communication Status': {
                          type: 'textInput',
                          required: false,
                          label: 'Communication Status',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Metered Power': {
                          type: 'textInput',
                          required: false,
                          label: 'Metered Power',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Luminaire Wattage': {
                          type: 'textInput',
                          required: false,
                          label: 'Luminaire Wattage',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Metered Current': {
                          type: 'textInput',
                          required: false,
                          label: 'Metered Current',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Metered Voltage': {
                          type: 'textInput',
                          required: false,
                          label: 'Metered Voltage',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                      },
                    },
                  },
                  'Outage Data': {
                    type: 'group',
                    label: 'Outage Data',
                    repeats: { min: 1, max: 1 },
                    template: {
                      order: [
                        'Display',
                        'Type',
                        'Failure',
                        'Last Update',
                        'Failed Since',
                        'Burning Hours',
                        'Lifetime %',
                      ],
                      components: {
                        Display: {
                          type: 'radio',
                          required: false,
                          label: 'Display',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'placeholder',
                          options: {
                            type: SimpleOptionType,
                            items: [
                              { value: 'Hide', label: 'Hide' },
                              { value: 'Show', label: 'Show' },
                            ],
                          },
                        },
                        Type: {
                          type: 'checkbox',
                          label: 'Type',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'placeholder',
                          options: {
                            type: SimpleOptionType,
                            items: [
                              { value: 'Warning', label: 'Warning' },
                              { value: 'Outage', label: 'Outage' },
                            ],
                          },
                        },
                        Failure: {
                          type: 'textInput',
                          required: false,
                          label: 'Failure',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Last Update': {
                          type: 'textInput',
                          required: false,
                          label: 'Last Update',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Failed Since': {
                          type: 'textInput',
                          required: false,
                          label: 'Failed Since',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter text',
                        },
                        'Burning Hours': {
                          type: 'numberInput',
                          required: false,
                          label: 'Burning Hours',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter number',
                        },
                        'Lifetime %': {
                          type: 'numberInput',
                          required: false,
                          label: 'Lifetime %',
                          repeats: { min: 1, max: 1 },
                          placeholder: 'Enter number',
                        },
                      },
                    },
                  },
                },
              },
            },
          ],
        },
        removeNode: {
          defaultLabel: 'Remove Node',
          versions: [
            {
              version: 1,
              isCreationCommand: false,
              commandType: 'FORM',
              cmdToEventsMapping: { mappingType: 'COPY', eventName: 'nodeRemoved' },
              formDefinition: {
                order: ['removedMacAddress', 'removalReason', 'physicalDamage', 'removalDate'],
                components: {
                  removedMacAddress: {
                    type: 'barcode',
                    required: true,
                    label: 'Scan Existing MAC address',
                    repeats: { min: 1, max: 1 },
                  },
                  removalReason: {
                    type: 'textArea',
                    label: 'Reason for removal',
                    repeats: { min: 1, max: 1 },
                  },
                  physicalDamage: {
                    type: 'radio',
                    label: 'Is the node physically damaged?',
                    repeats: { min: 1, max: 1 },
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Yes', label: 'Yes' },
                        { value: 'No', label: 'No' },
                      ],
                    },
                  },
                  removalDate: {
                    type: 'date',
                    label: 'Removal Date',
                    required: true,
                    repeats: { min: 1, max: 1 },
                  },
                },
              },
            },
          ],
        },
      },
      derivedPropertyMapping: {
        nodes: {
          OUTPUT: {
            type: 'OBJECT-BUILDER',
            name: 'Output State',
            inputs: {},
            config: { objectSchema: 'STATE' },
          },
        },
        outputDefinition: { outputNode: 'OUTPUT' },
      },
    },
    {
      typeName: 'Message',
      pluralName: 'Messages',
      singularName: 'Message',
      objectStore: {
        rebuildOnStartup: true,
        table: 'messages',
        type: 'Postgres',
        reducerName: 'EVENT_MAP',
        schema: 'public',
      },
      replayEventsOnStartup: false,
      labelProperty: 'title',
      derivedPropertyMapping: {
        nodes: {
          OUTPUT: {
            type: 'OBJECT-BUILDER',
            config: { objectSchema: 'STATE' },
            inputs: {
              symbolKey: {
                sourceObject: 'newNode5',
                sourcePath: '$.output',
              },
              title: { sourceObject: 'newNode6', sourcePath: '$.output' },
            },
          },
          newNode1: { type: 'STATE', config: {} },
          newNode2: {
            type: 'FIXED-REGEX',
            config: {},
            inputs: {
              regex: '^(\\d)',
              text: {
                sourceObject: 'newNode1',
                sourcePath: '$.responseCode',
              },
            },
          },
          newNode3: {
            type: 'CONDITIONAL-MAPPER',
            config: {},
            inputs: {
              booleanSource: {
                sourceObject: 'newNode2',
                sourcePath: '$.match',
              },
              trueSource: {
                sourceObject: 'newNode2',
                sourcePath: '$.group1',
              },
              falseSource: 'ZZZ_NO_RC',
            },
          },
          newNode4: {
            type: 'VALUE-MAPPER',
            config: {
              mappings: [
                ['2', '008000'],
                ['4', 'FFFF00'],
                ['5', 'FF0000'],
              ],
            },
            inputs: {
              source: {
                sourceObject: 'newNode3',
                sourcePath: '$.output',
              },
              fallback: 'FFA500',
            },
          },
          newNode5: {
            type: 'STRING-CONCAT',
            config: {},
            inputs: {
              strings: ['symbol_node_', { sourceObject: 'newNode4', sourcePath: '$.output' }],
            },
          },
          newNode6: {
            type: 'STRING-CONCAT',
            config: {},
            inputs: {
              strings: [
                {
                  sourceObject: 'newNode1',
                  sourcePath: '$.integrationInterface',
                },
                '-',
                {
                  sourceObject: 'newNode1',
                  sourcePath: '$.integrationType',
                },
              ],
            },
          },
        },
        outputDefinition: { outputNode: 'OUTPUT' },
      },
      properties: {
        symbolKey: {
          label: 'Symbol Key',
          type: 'String',
          uiType: 'Symbol Key',
          hideFromAssetAttributes: true,
          nullable: true,
        },
        title: {
          label: 'Title',
          type: 'String',
          uiType: 'Title',
          filterable: true,
          nullable: true,
          filterOptions: 'DISTINCT',
        },
        integrationType: {
          nullable: true,
          label: 'Integration Type',
          type: 'String',
          filterable: true,
          filterOptions: 'DISTINCT',
          validOptions: { enum: ['Inbound', 'Outbound'] },
        },
        requestDate: {
          nullable: true,
          label: 'Request Timestamp',
          type: 'DateTime',
          filterable: true,
        },
        requestMethod: {
          nullable: true,
          label: 'Request Method',
          type: 'String',
          filterable: true,
          filterOptions: 'DISTINCT',
          validOptions: { enum: ['GET', 'POST', 'PUT', 'DELETE', 'HEAD'] },
        },
        requestUrl: {
          nullable: true,
          label: 'Request URL',
          type: 'String',
        },
        requestHeader: {
          nullable: true,
          label: 'Request Header',
          type: 'String',
        },
        requestBody: {
          nullable: true,
          label: 'Request Body',
          type: 'String',
        },
        responseCode: {
          nullable: true,
          label: 'Response Code',
          type: 'String',
          filterable: true,
          filterOptions: 'DISTINCT',
        },
        responseDate: {
          nullable: true,
          label: 'Response Timestamp',
          type: 'DateTime',
          filterable: true,
        },
        aggregatesImpacted: {
          nullable: true,
          label: 'Aggregates Impacted',
          type: 'String',
        },
        responseHeader: {
          nullable: true,
          label: 'Response Header',
          type: 'String',
        },
        responseBody: {
          nullable: true,
          label: 'Response Body',
          type: 'String',
        },
        awsXrayTrace: {
          nullable: true,
          label: 'AWS X-Ray ID',
          type: 'String',
          filterable: true,
        },
        tookInMillis: {
          nullable: true,
          label: 'Took Time (ms)',
          type: 'Int',
          filterable: true,
        },
        comments: {
          nullable: true,
          label: 'Comments',
          type: 'String',
          editable: true,
        },
        createdBy: {
          label: 'Created By',
          type: 'String',
          filterOptions: 'DISTINCT',
          filterable: true,
          nullable: true,
        },
        createdDateTime: {
          label: 'Created Date/Time',
          type: 'DateTime',
          filterable: true,
          nullable: true,
        },
        modifiedDateTime: {
          label: 'Modified Date/Time',
          type: 'DateTime',
          uiType: 'Hidden',
          filterable: true,
          nullable: true,
        },
        id: {
          label: 'Id',
          type: 'ID',
          filterable: true,
          nullable: true,
          indexed: true,
          includeInFullText: true,
        },
        integrationInterface: {
          nullable: true,
          label: 'Interface',
          type: 'String',
          filterable: true,
          filterOptions: 'DISTINCT',
          validOptions: { enum: ['AS8', 'GIS', 'SLV', 'UIQ', 'VZN'] },
        },
        lat: {
          label: 'Latitude',
          type: 'Float',
          nullable: true,
          uiType: 'Hidden',
        },
        lon: {
          label: 'Longitude',
          type: 'Float',
          nullable: true,
          uiType: 'Hidden',
        },
        assetAttributes: {
          label: 'Asset Attributes',
          type: 'JSON',
          uiType: 'Hidden',
          filterOptions: 'DISTINCT',
          filterable: false,
          nullable: true,
        },
        project: {
          label: 'Project',
          type: 'Project',
          nullable: true,
          relation: 'ONE-TO-ONE',
        },
      },
      events: {
        newMessageInt: {
          versions: [
            {
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  type: { type: 'string', enum: ['newMessageInt'] },
                  version: { type: 'number' },
                  timestamp: {
                    description: 'Timestamp',
                    type: 'string',
                    format: 'date-time',
                  },
                  aggregateId: { type: 'string' },
                  metadata: {
                    type: 'object',
                    properties: {
                      timeFormOpened: {
                        type: 'string',
                        format: 'date-time',
                      },
                      latitude: { type: 'number' },
                      longitude: { type: 'number' },
                      locationAccuracy: { type: 'number' },
                      aggregateType: { type: 'string' },
                      userInfo: {
                        type: 'object',
                        properties: {
                          userName: { type: 'string' },
                          email: { type: 'string' },
                          authExpiration: {
                            type: 'string',
                            format: 'date-time',
                          },
                          familyName: { type: 'string' },
                          givenName: { type: 'string' },
                          authPayload: { type: 'object', properties: {} },
                          roles: {
                            type: 'array',
                            items: {
                              type: 'object',
                              properties: {
                                id: { type: 'string' },
                                name: { type: 'string' },
                              },
                            },
                          },
                          roleString: { type: 'string' },
                        },
                      },
                      isOnline: { type: 'boolean' },
                    },
                  },
                  data: {
                    type: 'object',
                    properties: {
                      project: { type: 'string', enum: [] },
                      integrationType: {
                        type: 'string',
                        enum: ['Inbound', 'Outbound'],
                      },
                      integrationInterface: {
                        type: 'string',
                        enum: ['SLV', 'AS8', 'GIS', 'VZN'],
                      },
                      requestDate: { type: 'string' },
                      requestMethod: {
                        type: 'string',
                        enum: ['GET', 'POST', 'PUT', 'DELETE', 'HEAD'],
                      },
                      requestUrl: { type: 'string' },
                      requestHeader: { type: 'string' },
                      requestBody: { type: 'string' },
                      responseDate: { type: 'string' },
                      responseCode: { type: 'string' },
                      responseHeader: { type: 'string' },
                      responseBody: { type: 'string' },
                      tookInMillis: { type: 'number' },
                      aggregatesImpacted: { type: 'string' },
                      awsXrayTrace: { type: 'string' },
                      comments: { type: 'string' },
                    },
                    required: [
                      'project',
                      'integrationType',
                      'integrationInterface',
                      'requestUrl',
                      'tookInMillis',
                      'requestDate',
                    ],
                  },
                },
              },
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', config: {}, inputs: {} },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    config: { objectSchema: 'STATE' },
                    inputs: {
                      project: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.project',
                      },
                      type: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.type',
                      },
                      requestUrl: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.requestUrl',
                      },
                      requestHeader: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.requestHeader',
                      },
                      requestBody: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.requestBody',
                      },
                      responseCode: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.responseCode',
                      },
                      responseHeader: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.responseHeader',
                      },
                      responseBody: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.responseBody',
                      },
                      aggregatesImpacted: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.aggregatesImpacted',
                      },
                      comments: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.comments',
                      },
                      integrationType: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.integrationType',
                      },
                      tookInMillis: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.tookInMillis',
                      },
                      requestMethod: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.requestMethod',
                      },
                      awsXrayTrace: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.awsXrayTrace',
                      },
                      integrationInterface: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.integrationInterface',
                      },
                      createdDateTime: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.timestamp',
                      },
                      createdBy: 'Integration',
                      requestDate: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.requestDate',
                      },
                      responseDate: {
                        sourceObject: 'EVENT',
                        sourcePath: '$.data.responseDate',
                      },

                      title: {
                        sourceObject: 'newNode1',
                        sourcePath: '$.output',
                      },
                    },
                  },
                  newNode1: {
                    type: 'STRING-CONCAT',
                    config: {},
                    inputs: {
                      strings: [
                        {
                          sourceObject: 'EVENT',
                          sourcePath: '$.data.integrationInterface',
                        },
                        '-',
                        {
                          sourceObject: 'EVENT',
                          sourcePath: '$.data.integrationType',
                        },
                      ],
                    },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
            },
          ],
          $internalDescription:
            '**Since:** v5.2.13\n\n**ToDo:** n/a\n\n**Purpose:**\n---------- \n- Capture the integration messages that are bridged between the backend Gen5 eventstore and the external system call(s).\n\n**Pre-Conditions:**\n----------\n- The "Integration" folder will be assigned the UUID of 00000000-0000-0000-0000-000000000000\n- The integration service shall create the a project as "<INTEGRATION_INTERFACE>-YYYY-MM" under the Integration folder if one doesn\'t exist; so AS8 project label would like so: AS8-2021-01.\n- The INTEGRATION_INTERFACE will be coded as: AS8, GIS, SLV, VZN, etc.\n\n**Triggered By:**\n----------\n1. When the integration service receives/process an inbound/outbound integration call.\n\n**Implementation Notes:**\n----------\n- Capture the **tookInMillis** as:\n  - On Inbound = New Event Time – Request Received Time\n  - On Outbound = Triggered Event Time – Time Response Is Ready to Send Out\n- Capture the **aggregatesImpacted** as the comma delimited list of aggregateids.\n- Capture the **awsXrayTrace** as available to the inbound API gateway call.\n\n',
        },
        assignMessageToProject: {
          versions: [
            {
              versionNumber: 1,
              eventSchema: {
                type: 'object',
                properties: {
                  type: { type: 'string', enum: ['assignMessageToProject'] },
                  version: { type: 'number' },
                  timestamp: { description: 'Timestamp', type: 'string', format: 'date-time' },
                  aggregateId: { type: 'string' },
                  metadata: {
                    type: 'object',
                    properties: {
                      timeFormOpened: { type: 'string', format: 'date-time' },
                      latitude: { type: 'number' },
                      longitude: { type: 'number' },
                      locationAccuracy: { type: 'number' },
                      aggregateType: { type: 'string' },
                      userInfo: {
                        type: 'object',
                        properties: {
                          userName: { type: 'string' },
                          email: { type: 'string' },
                          authExpiration: { type: 'string', format: 'date-time' },
                          familyName: { type: 'string' },
                          givenName: { type: 'string' },
                          authPayload: { type: 'object', properties: {} },
                          roles: {
                            type: 'array',
                            items: {
                              type: 'object',
                              properties: { id: { type: 'string' }, name: { type: 'string' } },
                            },
                          },
                          roleString: { type: 'string' },
                        },
                      },
                      isOnline: { type: 'boolean' },
                    },
                  },
                  data: {
                    type: 'object',
                    properties: { projectId: { type: 'string' } },
                    required: ['projectId'],
                  },
                },
              },
              aggregateMap: {
                nodes: {
                  EVENT: { type: 'EVENT', config: {}, inputs: {} },
                  STATE: { type: 'STATE', config: {}, inputs: {} },
                  OUTPUT: {
                    type: 'OBJECT-BUILDER',
                    config: { objectSchema: 'STATE' },
                    inputs: {
                      project: { sourceObject: 'EVENT', sourcePath: '$.data.projectId' },
                      modifiedDateTime: { sourceObject: 'EVENT', sourcePath: '$.timestamp' },
                    },
                  },
                },
                outputDefinition: { outputNode: 'OUTPUT' },
              },
            },
          ],
          $internalDescription:
            '**Since:** v5.2.13\n\n**ToDo:** n/a\n\n**Purpose:**\n---------- \n- Reassign the project of a Message record for cleanup purposes.\n\n**Pre-Conditions:**\n----------\n- Message and Project records exist.\n\n**Triggered By:**\n----------\n1. Backoffice cleanup scripts, etc.\n',
        },
      },
      commands: {
        testNewMessage: {
          defaultLabel: 'Test New Message',
          versions: [
            {
              version: 1,
              isCreationCommand: true,
              cmdToEventsMapping: {
                mappingType: 'ONE-TO-MANY',
                mapping: {
                  nodes: {
                    newNode1: {
                      type: 'OUTPUT-EVENT',
                      config: {
                        aggregateType: 'Message',
                        objectSchema: 'Message::newMessageInt::1',
                      },
                      inputs: {
                        data: {
                          project: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.project',
                          },
                          integrationType: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.integrationType',
                          },
                          integrationInterface: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.integrationInterface',
                          },
                          requestMethod: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.requestMethod',
                          },
                          requestUrl: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.requestUrl',
                          },
                          requestHeader: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.requestHeader',
                          },
                          requestBody: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.requestBody',
                          },
                          responseCode: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.responseCode',
                          },
                          responseHeader: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.responseHeader',
                          },
                          responseBody: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.responseBody',
                          },
                          tookInMillis: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.tookInMillis',
                          },
                          aggregatesImpacted: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.aggregatesImpacted',
                          },
                          awsXrayTrace: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.awsXrayTrace',
                          },
                          comments: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.comments',
                          },
                          requestDate: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.requestDate',
                          },
                          responseDate: {
                            sourceObject: 'newNode2',
                            sourcePath: '$.data.responseDate',
                          },
                        },
                        type: 'newMessageInt',
                        aggregateId: {
                          sourceObject: 'newNode3',
                          sourcePath: '$.newId',
                        },
                      },
                    },
                    newNode2: { type: 'COMMAND_DATA', config: {} },
                    newNode3: { type: 'ID-GENERATOR', config: {} },
                  },
                  outputDefinition: {
                    outputQuery: { types: ['OUTPUT-EVENT'] },
                  },
                },
              },
              commandType: 'FORM',
              formDefinition: {
                order: [
                  'project',
                  'integrationType',
                  'integrationInterface',
                  'requestDate',
                  'requestMethod',
                  'requestUrl',
                  'requestHeader',
                  'requestBody',
                  'responseDate',
                  'responseCode',
                  'responseHeader',
                  'responseBody',
                  'tookInMillis',
                  'aggregatesImpacted',
                  'awsXrayTrace',
                  'comments',
                ],
                initialDataMap: {
                  nodes: {
                    STATE: { type: 'STATE', config: {}, inputs: {} },
                    OUTPUT: {
                      type: 'OBJECT-BUILDER',
                      config: { objectSchema: 'FORM' },
                      inputs: {
                        requestMethod: 'GET',
                        integrationType: 'Inbound',
                        integrationInterface: 'SLV',
                        responseCode: '200',
                        requestDate: {
                          sourceObject: 'newNode1',
                          sourcePath: '$.timeFormOpened',
                        },
                        responseDate: {
                          sourceObject: 'newNode1',
                          sourcePath: '$.timeFormOpened',
                        },
                      },
                    },
                    newNode1: { type: 'METADATA', config: {} },
                  },
                  outputDefinition: { outputNode: 'OUTPUT' },
                },
                components: {
                  project: {
                    type: 'select',
                    label: 'project',
                    placeholder: 'placeholder',
                    required: true,
                    repeats: { min: 1, max: 1 },
                    options: {
                      type: AggregateLoaderType,
                      aggregateType: 'Project',
                    },
                  },
                  integrationType: {
                    type: 'radio',
                    label: 'integrationType',
                    placeholder: 'placeholder',
                    required: true,
                    repeats: { min: 1, max: 1 },
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'Inbound', label: 'Inbound' },
                        { value: 'Outbound', label: 'Outbound' },
                      ],
                    },
                  },
                  integrationInterface: {
                    type: 'select',
                    label: 'integrationInterface',
                    placeholder: 'placeholder',
                    required: true,
                    repeats: { min: 1, max: 1 },
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'SLV', label: 'SLV' },
                        { value: 'AS8', label: 'AS8' },
                        { label: 'GIS', value: 'GIS' },
                        { label: 'VZN', value: 'VZN' },
                      ],
                    },
                  },
                  requestDate: {
                    type: 'textInput',
                    label: 'requestDate (timestamp)',
                    placeholder: DATE_FORMAT_ISO.YYYY_MM_DDTHH_MM_SS_SSSZ,
                    required: true,
                    readOnly: false,
                    repeats: { min: 1, max: 1 },
                  },
                  requestMethod: {
                    type: 'radio',
                    label: 'requestMethod',
                    placeholder: 'placeholder',
                    required: false,
                    repeats: { min: 1, max: 1 },
                    options: {
                      type: SimpleOptionType,
                      items: [
                        { value: 'GET', label: 'GET' },
                        { value: 'POST', label: 'POST' },
                        { label: 'PUT', value: 'PUT' },
                        { label: 'DELETE', value: 'DELETE' },
                        { label: 'HEAD', value: 'HEAD' },
                      ],
                    },
                  },
                  requestUrl: {
                    type: 'textInput',
                    label: 'requestUrl',
                    placeholder: 'URL + querystring',
                    required: true,
                    readOnly: false,
                    repeats: { min: 1, max: 1 },
                  },
                  requestHeader: {
                    type: 'textInput',
                    label: 'requestHeader',
                    placeholder: 'name1=value1,name2=value2,etc.',
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                  requestBody: {
                    type: 'textArea',
                    label: 'requestBody',
                    placeholder: 'Enter text',
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                  responseDate: {
                    type: 'textInput',
                    label: 'responseDate (timestamp)',
                    placeholder: DATE_FORMAT_ISO.YYYY_MM_DDTHH_MM_SS_SSSZ,
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                  responseCode: {
                    type: 'textInput',
                    label: 'responseCode',
                    placeholder: '2XX',
                    required: true,
                    repeats: { min: 1, max: 1 },
                  },
                  responseHeader: {
                    type: 'textInput',
                    label: 'responseHeader',
                    placeholder: 'name1=value1,name2=value2,etc.',
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                  responseBody: {
                    type: 'textArea',
                    label: 'responseBody',
                    placeholder: 'Enter text',
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                  tookInMillis: {
                    type: 'numberInput',
                    label: 'tookInMillis',
                    placeholder: 'Enter number',
                    required: true,
                    repeats: { min: 1, max: 1 },
                  },
                  aggregatesImpacted: {
                    type: 'textInput',
                    label: 'aggregatesImpacted',
                    placeholder: 'uuid1,uuid2,etc.',
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                  awsXrayTrace: {
                    type: 'textInput',
                    label: 'awsXrayTrace',
                    placeholder: 'AWS X-Ray Trace ID',
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                  comments: {
                    type: 'textArea',
                    label: 'comments',
                    placeholder: 'Enter text',
                    required: false,
                    repeats: { min: 1, max: 1 },
                  },
                },
              },
            },
          ],
          $internalDescription:
            '**Since:** v5.2.13\n\n**ToDo:** n/a\n\n**Purpose:**\n---------- \n- Test adding a message record type to simulate integration usage of the mapped event.\n\n**Pre-Conditions:**\n----------\n- Integration folder and projets exists.\n\n**Triggered By:**\n----------\n1. AppIntegration testing from Web FAB.\n\n**Implementation Notes:**\n----------\n- N/A',
        },
      },
    },
  ],
  mobileUIConfig: {
    mapSettings: {
      isMandatoryClusteringEnabled: true,
    },
    fabActions: [],
    mobileDefaultDateTimeFormat: {
      dateFormatType: 'ISO 8601',
      dateFormat: 'yyyy-MM-DDThh:mm:ssZ',
      dateSeperator: 'Dash -',
      timeFormat: '12hr clock',
      isSwitch: false,
    },
    initialMapExtents: { lat: 38.9784, lon: -76.4922, zoom: 10 },
    line: { editColor: '#6CF3E9' },
    labels: {
      project: { singular: 'project', plural: 'projects' },
      applicationName: 'BGE Demo',
      copyrightInfo: 'Copyright 2019 TerraGo Technologies, Inc.',
    },
    theme: { primary: '#e2211c', secondary: '#585858', logoUrl: '' },
    symbolLegend: [
      { name: 'Complete', symbolKey: 'symbol_circle_39b310' },
      { name: 'Could Not Complete', symbolKey: 'symbol_circle_c90000' },
      { name: 'Complete with Issues', symbolKey: 'symbol_circle_00FF00' },
      { name: 'Not Yet Complete', symbolKey: 'symbol_circle_3388FF' },
    ],
    aggregateUICustomizations: {},
    enabledFeatures: {
      refocusToCurrentLocationAfterDirections: true,
      splitScreen: {
        enabled: false,
        defaultOn: false,
      },
    },
    selectedProjectsMaxRecords: defaultProjectRecordsLimit.MOBILE,
  },
  webUIConfig: {
    fabActions: [],
    initialMapExtents: { lat: 38.9784, lon: -76.4922, zoom: 10 },
    line: { editColor: '#6CF3E9' },
    defaultDateTimeFormat: {
      dateFormatType: 'ISO 8601',
      dateFormat: 'yyyy-mm-ddThh:mm:ss',
      dateSeperator: 'Dash -',
      timeFormat: '12hr clock',
      isSwitch: false,
    },
    labels: {
      project: { singular: 'project', plural: 'projects' },
      applicationName: 'BGE Demo',
      copyrightInfo: 'Copyright 2019 TerraGo Technologies, Inc.',
    },
    theme: { primary: '#e2211c', secondary: '#585858', logoUrl: '' },
    symbolLegend: [
      { name: 'Complete', symbolKey: 'symbol_circle_39b310' },
      { name: 'Could Not Complete', symbolKey: 'symbol_circle_c90000' },
      { name: 'Complete with Issues', symbolKey: 'symbol_circle_00FF00' },
      { name: 'Not Yet Complete', symbolKey: 'symbol_circle_3388FF' },
    ],
    aggregateUICustomizations: {},
    enabledFeatures: { attributeSearchOnMap: true },
    analyticsMaxSeatCount: 0,
    selectedProjectsMaxRecords: defaultProjectRecordsLimit.WEB,
  },
  functions: {},
};
