import { LocalSchemaDefinition } from '../../../utils/useSchemaLookup';
import { metadataSchema } from '../../../utils/jsonPartsGenerators';
import { usePage } from '../contexts/PageContext';
import { useCurrentAggregate } from '../../FormDialog/contexts/AggregateContext';

export const usePageSchemas = (
  additionalSchemas?: LocalSchemaDefinition
): LocalSchemaDefinition => {
  const { aggregateSchema } = useCurrentAggregate();
  const { pageSchema } = usePage();

  return {
    ...additionalSchemas,
    METADATA: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
    STATE: {
      schema: aggregateSchema,
      schemaLabel: 'Properties',
    },
    FORM: {
      schema: pageSchema,
      schemaLabel: 'FORM',
    },
  };
};
