import { Box, Switch, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { ChangeEvent } from 'react';
import { colors } from 'utils/colors';

interface Props {
  title: string;
  description: string;
  checked: boolean;
  onChange: (val: boolean) => void;
  otherComponent?: JSX.Element;
  containerClass?: string;
}

export const TGIosSwitch = ({
  title,
  description,
  checked,
  onChange,
  otherComponent,
  containerClass = '',
}: Props) => {
  const classes = useIosSwitch();
  return (
    <>
      <Box className={`${classes.inputFieldContainer} ${containerClass}`}>
        <Box className={classes.gridContainer}>
          <Switch
            focusVisibleClassName={classes.focusVisible}
            checked={checked}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
          />
        </Box>
        <Box>
          <Typography className={classes.optionTitle}>{title}</Typography>
          <Typography className={classes.optionDescription} variant="body2">
            {description}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.inputBase}>{otherComponent}</Box>
    </>
  );
};

const useIosSwitch = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 45.5,
      height: 26,
      padding: 0,
    },
    inputBase: {
      marginLeft: 70,
      position: 'relative',
      bottom: 20,
    },
    gridContainer: {
      width: 45,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
        border: `3px solid ${colors.white}`,
      },
    },
    thumb: {
      width: 23,
      height: 23,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: colors.black25,
      opacity: 1,
      transition: theme.transitions.create(['background-color']),
    },
    checked: {},
    focusVisible: {},

    inputFieldContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gap: 25,
      marginBottom: 20,
    },
    optionTitle: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.black,
      lineHeight: '100%',
      marginBottom: 9,
    },
    optionDescription: {
      fontSize: 14,
      color: colors.black54,
      fontWeight: 400,
      lineHeight: '100%',
    },
    textArea: {
      marginTop: 18,
    },
  })
);
