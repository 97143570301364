import React from 'react';
import { Header } from './Header';
import { Box, makeStyles } from '@material-ui/core';
import { HEADER_HEIGHT } from 'utils/Utils';

interface Props {
  children: React.ReactNode;
}

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'hidden',
  },
});

export const AppContainer: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <Header />
      <Box className={classes.container}>{children}</Box>
    </>
  );
};
