import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

interface NotToggleProps {
  title: string;
  checked: boolean | undefined;
  handleOnChange: (value: boolean) => void;
}

const NotToggle: React.FC<NotToggleProps> = ({ title, checked, handleOnChange }) => {
  return (
    <label title={title}>
      <Checkbox
        style={{ background: 'white' }}
        checked={!!checked}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        onChange={(e) => handleOnChange(e.target.checked)}
      />
      Not
    </label>
  );
};

export default NotToggle;
