import { Gen5Config, OneToOneCommandToEventMapping } from '@terragotech/gen5-config-lib';

export const useEventRefChanger = () => {
  // const isAnyReference = (eventName: string) => {
  //   const config = getConfig();
  //   if (config.aggregates[aggrIndex].commands)
  //     return Object.entries(config.aggregates[aggrIndex].commands!).some(([_, command]) => {
  //       return command.versions.some((version) => {
  //         return version.cmdToEventsMapping.eventName === eventName;
  //       });
  //     });
  // };

  const removeReferences = (config: Gen5Config, aggrIndex: number, eventName: string) => {
    const aggregate = config.aggregates[aggrIndex];
    if (aggregate.commands)
      Object.entries(aggregate.commands).forEach(([commandName, command]) => {
        command &&
          command.versions.forEach((version, versionIndex) => {
            if (
              aggregate.commands &&
              'eventName' in version.cmdToEventsMapping &&
              version.cmdToEventsMapping.eventName === eventName
            ) {
              (aggregate.commands[commandName].versions[versionIndex]
                .cmdToEventsMapping as OneToOneCommandToEventMapping).eventName = '';
            }
          });
      });
    return config;
  };
  return { removeReferences };
};
