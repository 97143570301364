import React, { useRef } from 'react';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { faArrowProgress, faSave } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VersionSelect from '../common/VersionSelect';

import useCommonStyles from 'views/useCommonStyles';
import { colors } from '../../../../../utils/colors';
import Papa from 'papaparse';
import { v4 } from 'uuid';
import { camelCase } from 'change-case';
import { CSVEvent } from '../../../../../pages/aggregates/pages/EventSchemaEditor';
import { Csv2EventsForm } from '../../../../../components/FormDialog/Csv2EventsForm';
import { cloneDeep } from 'lodash';
import { JSONSchema6 } from 'json-schema';
import { errorMsg, successMsg } from '../../../../../components/SnackbarUtilsConfigurator';
import { useConfirm } from 'material-ui-confirm';
import { useFormDialog } from '../../../../../components/FormDialog/FormDialogService';
import { useAggregateAPI } from '../../../../../context/fakeAPIHooks/useAggregateAPI';

interface Row {
  [key: string]: string | boolean | number | undefined;
}

interface ConfigurationHeaderProps {
  name: string;
  versionNos: number[];
  versionChangePath: string;
  selectedVersion: number;
  handleAddVersion: (name: string, versionIndex: number) => void;
  handleRemoveVersion: (name: string, versionIndex: number, versionNumber: number) => void;
  handleCancelChanges?: () => void;
  showImportButton?: boolean;
  hideCancelButton?: boolean;
  aggregate: string;
  aggregateIndex: number;
  schemaVersion: number;
  actualSchema: JSONSchema6;
  save: () => void;
  showSimulateButton?: boolean;
  onSimulate?: () => Promise<void>;
  type?: string;
}
const ConfigurationHeader = ({
  type,
  name,
  versionNos,
  versionChangePath,
  selectedVersion,
  handleAddVersion,
  handleRemoveVersion,
  handleCancelChanges,
  showImportButton,
  hideCancelButton,
  aggregate,
  aggregateIndex,
  schemaVersion,
  actualSchema,
  save,
  showSimulateButton,
  onSimulate,
}: ConfigurationHeaderProps) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const confirm = useConfirm();
  const formDialog = useFormDialog();
  const AggregateAPI = useAggregateAPI();
  const inputFile = useRef<HTMLInputElement>(null);

  const openCSVDataset = () => {
    inputFile.current?.click();
  };

  const onCSVImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      Papa.parse(file, {
        skipEmptyLines: true,
        complete: function (results: Papa.ParseResult<Row>) {
          const csvData: Row[] = results.data;
          const csvEvents = csvData.map((record: Row) => {
            const aggregateId = v4();
            const camelCasedRow: Record<string, string | null> = {};
            Object.keys(record).forEach((key) => {
              let value = null;
              if (record[key]) {
                if ((record[key] as string).trim() !== '')
                  value = (record[key] as string)
                    .trim()
                    .replace(new RegExp('"', 'g'), '\\"')
                    .replace(new RegExp('\\n', 'g'), '');
              }

              camelCasedRow[camelCase(key)] = value;
            });

            return {
              type: name,
              aggregateId: aggregateId,
              eventId: v4(),
              aggregateType: aggregate,
              data: {
                ...camelCasedRow,
              },
              source: 'configEditorIngest',
              metadata: {},
              eventSchemaVersion: +schemaVersion,
            };
          });

          const csv2EventsResults = async (csvEvents: CSVEvent[]) => {
            const eventsDataProperties = await formDialog<typeof Csv2EventsForm>((props) => (
              <Csv2EventsForm
                events={csvEvents}
                aggrName={aggregate}
                eventName={name}
                version={+schemaVersion}
                {...props}
              />
            ));

            const eventSchemaData = cloneDeep(actualSchema);
            if (!(eventSchemaData.properties?.data as JSONSchema6)?.properties)
              return errorMsg('Current event schema object is invalid');

            const csvTableMatchingRecords: string[] = [];
            Object.keys(eventsDataProperties).forEach((csvEvent: string) => {
              const matchedEvent = Object.keys(
                (eventSchemaData.properties?.data as JSONSchema6).properties || {}
              ).find((tableEvent: string) => csvEvent === tableEvent);
              if (matchedEvent) csvTableMatchingRecords.push(matchedEvent);
            });

            if (csvTableMatchingRecords.length > 0) {
              await confirm({
                description:
                  'This will append and/or overwrite the existing eventSchemas data structure. ',
                confirmationText: 'Update',
              });
            }
            if (eventSchemaData.properties) {
              (eventSchemaData.properties.data as JSONSchema6).properties = {
                ...(eventSchemaData.properties.data as JSONSchema6).properties,
                ...eventsDataProperties,
              };
            }

            const { error } = await AggregateAPI.updateEventSchema(
              aggregateIndex,
              name,
              +selectedVersion,
              eventSchemaData
            );
            if (error) return;
            successMsg('The event schema has been saved');
          };
          csv2EventsResults(csvEvents);
        },
        header: true,
      });
    }
  };

  return (
    <Box className={commonClasses.headerContainer}>
      <Box className={commonClasses.leftContent}>
        <Typography className={commonClasses.title} variant="h1">
          {name}
        </Typography>
        <VersionSelect
          type={type}
          versionNos={versionNos}
          name={name}
          onDeleteVersion={handleRemoveVersion}
          onAddVersion={handleAddVersion}
          selectedVersionIndex={selectedVersion}
          versionChangePath={versionChangePath}
        />
      </Box>
      <Box className={commonClasses.rightContent}>
        {showImportButton && (
          <>
            <input
              id="csv2Events"
              accept=".csv"
              onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                if (e) {
                  (e.target as HTMLInputElement).value = '';
                }
              }}
              ref={inputFile}
              onChange={onCSVImportClick}
              className={classes.hide}
              type="file"
            />
            <Button
              startIcon={<FontAwesomeIcon icon={faFileCsv} color={colors.black54} />}
              className={`${commonClasses.cancelBtn} ${classes.cancelBtn}`}
              onClick={openCSVDataset}
            >
              Import
            </Button>
          </>
        )}
        {showSimulateButton && (
          <Button
            className={commonClasses.cancelBtn}
            onClick={() => onSimulate && onSimulate()}
            startIcon={<FontAwesomeIcon icon={faArrowProgress} />}
          >
            Simulate
          </Button>
        )}
        {!hideCancelButton && (
          <Button
            startIcon={<FontAwesomeIcon icon={faTimes} />}
            className={commonClasses.cancelBtn}
            onClick={handleCancelChanges}
          >
            Cancel
          </Button>
        )}
        <Button
          startIcon={<FontAwesomeIcon icon={faSave} />}
          color="primary"
          className={commonClasses.saveBtn}
          onClick={save}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelBtn: {
      color: colors.black54,
      fontSize: 16,
    },
    hide: { display: 'none' },
  })
);

export default ConfigurationHeader;
