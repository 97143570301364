import { useContext } from 'react';
import { ConfigContext } from '../ConfigContext';
import { generateCustomPage } from '../../utils/jsonPartsGenerators';
import { errorMsg } from '../../components/SnackbarUtilsConfigurator';
import { PagesConfig } from '@terragotech/gen5-config-lib';
import { V2PageTemplate } from '@terragotech/page-renderer';
import { FabAction } from 'utils/types';

export const useCustomPageAPI = () => {
  const {
    getCustomPages,
    setCustomPages,
    getCustomPage,
    setCustomPage,
    getCustomPageFabActions,
    setCustomPageFabActions,
  } = useContext(ConfigContext);

  const promisify = <T extends Function>(
    child: T
  ): Promise<{ error: Error | null; data: T | null }> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ error: null, data: child() });
      } catch (e) {
        if (e instanceof Error) {
          errorMsg(e.message);
        }
        reject({ error: e as Error, data: null });
      }
    });
  };

  const addPage = (name: string, init?: Partial<V2PageTemplate>) =>
    promisify(() => {
      const pages = getCustomPages();
      pages[name] = generateCustomPage(init);
      setCustomPages(pages);
    });

  const deletePage = (name: string) =>
    promisify(() => {
      const pages = getCustomPages();
      delete pages[name];
      setCustomPages(pages);
    });

  const updatePage = (name: string, page: PagesConfig[keyof PagesConfig]) =>
    promisify(() => setCustomPage(name, page));
  
  const getPage = (name: string) =>
    promisify(() => getCustomPage(name));

  // #region Actions
  const getFabActions = (pageName: string) => promisify(() => getCustomPage(pageName).fabActions ?? []);
  const setFabActions = (pageName: string, fabAction: FabAction[]) => promisify(() => {
    const page = getCustomPage(pageName);
    page.fabActions = fabAction;
    setCustomPage(pageName, page);
  });

  const addFabAction = (pageName: string, fabAction: FabAction) => promisify(() => {
    const fabActions = getCustomPageFabActions(pageName) || [];
    fabActions.push(fabAction);
    setCustomPageFabActions(pageName, fabActions);
  });

  const updateFabAction = (pageName: string, fabActionIndex: number, fabAction: FabAction) => promisify(() => {
    const fabActions = getCustomPageFabActions(pageName);
    fabActions[fabActionIndex] = fabAction;
    setCustomPageFabActions(pageName, fabActions);
  });

  const deleteFabAction = (pageName: string, fabActionIndex: number) => promisify(() => {
    const fabActions = getCustomPageFabActions(pageName);
    fabActions.splice(fabActionIndex, 1);
    setCustomPageFabActions(pageName, fabActions);
  });
  // #endregion

  return {
    addPage,
    deletePage,
    getPage,
    updatePage,
    getFabActions,
    setFabActions,
    addFabAction,
    updateFabAction,
    deleteFabAction,
  };
};
