import { V2PageTemplate, V2PageComponentDef } from '@terragotech/page-renderer';

interface PageComponents {
  [name: string]: {
    columnSpan: number;
    row: number;
    column: number;
    component: V2PageComponentDef
  };
}

export const cleanPageTemplate = (template: V2PageTemplate): V2PageTemplate => {
  //Given a page template, recusively remove any components that aren't used(based on the order attribute)
  const keptComponents: Record<string, {
    columnSpan: number;
    row: number;
    column: number;
    component: V2PageComponentDef
  } > = {};
  // cycle through order and add any component found there to the keptComponents
  Object.keys(template.elements).forEach((key) => {
    const component = template.elements[key].component;
    if (component.type === 'group') {
      const newComponent = { ...component };
      newComponent.template = cleanPageTemplate(component.template);
      keptComponents[key] = { ...(template.elements[key]), component: newComponent };
    } else {
      keptComponents[key] = { ...(template.elements[key]), component: component };
    }
  });
  // if the component is a group type, run its template through this

  return {
    rows: template.rows,
    columns: template.columns,
    allowDynamicSizing: template.allowDynamicSizing,
    aggregateType: template.aggregateType,

    ...(template.errorMaps && { errorMaps: template.errorMaps }),
    ...(template.warningMaps && { warningMaps: template.warningMaps }),
    ...(template.initialDataMap && { initialDataMap: template.initialDataMap }),
    elements: keptComponents,
  };
};

const checkComponentNamesFrmGroup = (
  name: string,
  component: { name: string },
  pagecomponents: PageComponents
) => {
  let duplicateNameExistsInGroup = false;
  let componentNamesFromGroup: string[] = [];
  Object.values(pagecomponents).forEach((component) => {
    if (component.component.type === 'group') {
      componentNamesFromGroup = componentNamesFromGroup.concat(
        Object.keys(component.component.template.elements)
      );
    }
  });

  const nameExists = componentNamesFromGroup.some((componentName) => name === componentName);
  if (nameExists && component.name !== name) {
    duplicateNameExistsInGroup = true;
  }
  return duplicateNameExistsInGroup;
};

export const checkDuplicatePageName = (
  name: string,
  component: { name: string },
  pagecomponents: PageComponents
) => {
  let duplicateNameExists = false;
  const components = Object.keys(pagecomponents);
  const nameExists = components.some((componentName) => name === componentName);
  const duplicateNameExistsInGroup = checkComponentNamesFrmGroup(name, component, pagecomponents);
  if ((nameExists && component.name !== name) || duplicateNameExistsInGroup) {
    duplicateNameExists = true;
  }
  return duplicateNameExists;
};
