import React, { Fragment } from 'react';
import { Box, Divider, IconButton, Typography, makeStyles } from '@material-ui/core';
import { TextInput } from '../../../components/FormElements';
import cloneDeep from 'lodash/cloneDeep';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';
import { TextInputTG } from 'views/components/formElements';
import FormLegend from 'components/FormLegend';

export interface ValidOptions {
  minimum?: number;
  maximum?: number;
  enum: string[];
}

interface ValidOptionsCustomInputProps {
  validOptions: ValidOptions | undefined;
  setValidOptions: (validOptions: ValidOptions) => void;
}

export const ValidOptionsCustomInput: React.FC<ValidOptionsCustomInputProps> = ({
  validOptions = validOptionsDefault,
  setValidOptions,
}) => {
  const classes = useStyles();

  const handleAddValidOptionsEnum = () => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.enum.push('');
    setValidOptions(validOptionsCopy);
  };

  const handleValidOptionsEnumChange = (value: string, index: number) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.enum[index] = value;
    setValidOptions(validOptionsCopy);
  };

  const handleDeleteValidOpttionsEnum = (index: number) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.enum.splice(index, 1);
    setValidOptions(validOptionsCopy);
  };

  const handleMinimumValue = (value: number | undefined) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.minimum = value;
    setValidOptions(validOptionsCopy);
  };

  const handleMaximumValue = (value: number | undefined) => {
    const validOptionsCopy = cloneDeep(validOptions);
    validOptionsCopy.maximum = value;
    setValidOptions(validOptionsCopy);
  };

  return (
    <ValidOptionsContainer>
      <Box className={classes.mainContainer}>
        <TextInputTG
          value={validOptions.minimum}
          onChange={(value) => handleMinimumValue(value ? +value : undefined)}
          id="Minimum"
          placeholder="Minimum"
          className={classes.minTextBox}
        />
        <TextInputTG
          value={validOptions.maximum}
          onChange={(value) => handleMaximumValue(value ? +value : undefined)}
          id="Maximum"
          placeholder="Maximum"
        />
      </Box>
      <FormLegend
        title="Value"
        legendContainer={classes.legendContainer}
        headerContainer={classes.legendTitle}
        rightContent={
          <IconButton component="span" onClick={handleAddValidOptionsEnum}>
            <AddIcon />
          </IconButton>
        }
        titleStyle={classes.title}
        body={
          <Box className={classes.valueContainer}>
            {validOptions.enum?.length > 0 ? (
              validOptions.enum.map((item, index) => (
                <Fragment key={index}>
                  <Box
                    className={`${classes.itemContainer} ${
                      validOptions.enum?.length === 1 ? classes.mr25 : ''
                    }`}
                  >
                    <TextInput
                      value={item}
                      onChange={(value) => handleValidOptionsEnumChange(value || '', index)}
                      id={item}
                      variant="standard"
                      noLabel
                    />
                    {validOptions.enum.length > 1 && (
                      <IconButton
                        className={classes.removeIcon}
                        component="span"
                        onClick={() => handleDeleteValidOpttionsEnum(index)}
                      >
                        <FontAwesomeIcon icon={faMinus} />
                      </IconButton>
                    )}
                  </Box>
                  {validOptions.enum?.length - 1 !== index && <Divider />}
                </Fragment>
              ))
            ) : (
              <Box className={classes.emptyContainer}>
                <Typography className={classes.empty}>No values to display</Typography>
              </Box>
            )}
          </Box>
        }
      />
    </ValidOptionsContainer>
  );
};

const validOptionsDefault: ValidOptions = {
  enum: [],
};

const useStyles = makeStyles({
  mainContainer: {
    marginTop: 17,
    width: '100%',
  },
  minTextBox: {
    marginBottom: 14,
  },
  legendContainer: {
    marginTop: 14,
    '& .MuiIconButton-root': {
      padding: 6,
    },
  },
  title: {
    fontWeight: 500,
  },
  legendTitle: {
    paddingRight: 10,
  },
  valueContainer: {
    padding: '14px 0',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '12px 22px',
    marginRight: 12,
  },
  emptyContainer: {
    padding: '0 24px',
  },
  empty: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black54,
  },
  removeIcon: {
    color: colors.black54,
    marginLeft: 10,
    fontSize: '1.3rem',
  },
  mr25: {
    marginRight: 25,
  },
});

const ValidOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
