import { Box } from '@material-ui/core';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  value: number;
  index: number;
  className?: string;
  padding?: number;
  height?: string;
}

const TabPanel = (props: Props) => {
  const { children, value, index, ...other } = props;
  return (
    value === index && (
      <Box role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
        {children}
      </Box>
    )
  );
};

export default TabPanel;
