import {
  Box,
  MenuItem,
  Theme,
  alpha,
  createStyles,
  makeStyles,
  Select,
  FormControl,
} from '@material-ui/core';
import { useConfig } from 'context/ConfigContext';
import { configType } from 'utils/Utils';
import { colors } from 'utils/colors';
import { UIConfigType } from 'utils/types';
import React from 'react';
import { isUndefined } from 'lodash';
interface ConfigMenuProps {
  definitionName: string;
}

const ConfigMenu: React.FunctionComponent<ConfigMenuProps> = (props) => {
  const { definitionName } = props;
  const classes = useStyles();
  const { configDetails, handleConfigTypeChange } = useConfig();
  const onMenuClick = (configType: { type: string; config: UIConfigType }) => {
    handleConfigTypeChange(definitionName, configType, configDetails[definitionName]?.type);
  };
  const selectedConfigType =
    !isUndefined(configDetails[definitionName]?.type) && configDetails[definitionName]?.type;
  return (
    <Box className={classes.container}>
      <div>
        {configDetails[definitionName]?.type !== configType.ALL.type ? (
          <Box>
            <FormControl className={classes.formControl} variant="outlined">
              <Select
                value={selectedConfigType}
                className={classes.selectTxt}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  className={`${classes.menuItem} ${
                    configDetails[definitionName]?.type === configType.WEB.type &&
                    classes.activeMenuItem
                  }`}
                  key={configType.WEB.type}
                  value={configType.WEB.type}
                  onClick={() => onMenuClick(configType.WEB)}
                >
                  Web
                </MenuItem>
                <MenuItem
                  className={` ${classes.menuItem} ${
                    configDetails[definitionName]?.type === configType.MOBILE.type &&
                    classes.activeMenuItem
                  }`}
                  key={configType.MOBILE.type}
                  value={configType.MOBILE.type}
                  onClick={() => onMenuClick(configType.MOBILE)}
                >
                  Mobile
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        ) : (
          <></>
        )}
      </div>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingLeft: 13,
    },
    iconContainer: {
      background: colors.white,
      height: '36px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
    },
    unlinkContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formControl: {
      width: 156,
      height: 35,
      '& .MuiSelect-root': {
        fontSize: 15,
        color: colors.black,
      },
    },
    selectTxt: {
      height: 35,
      '& .MuiSelect-select:focus': {
        borderRadius: 5,
        height: 35,
      },
      '& .MuiSelect-select.MuiSelect-select': {
        display: 'flex',
        alignItems: 'center',
      },
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 5,
      marginInline: 6,
      fontSize: 15,
      color: colors.black,
      '&:hover': {
        color: colors.black,
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
    },
    menuLabel: {
      marginLeft: '10px',
    },
    activeMenuItem: {
      color: colors.black,
      backgroundColor: `${alpha(theme.palette.primary.main, 0.2)} !important`,
    },
  })
);
export default ConfigMenu;
