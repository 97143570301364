import React, { Dispatch, SetStateAction } from 'react';
import { Button, makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import styled from 'styled-components';
import { EventMapDiagram } from '../../../../map-editor/src';
import { FullScreen } from '../../../../components/FullScreen';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { MapperShortCutInfoDialog } from '../../../../components/FormDialog/MapperShortCutInfoDialog';
import { colors } from 'utils/colors';
import { EventMapDiagramModel } from 'map-editor/src/EventMapDiagramModel';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard } from '@fortawesome/pro-light-svg-icons';
import { useBlocker, useOutletContext } from 'react-router-dom';
import useRouteBlocker from 'common/useBlocker';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';

interface eventMapProps {
  model?: EventMapDiagramModel;
  engine: DiagramEngine;
  checkModelHasChanged: () => boolean;
  hasSetPosition: boolean;
  onDiscard: () => void;
  setInitialDataMap: Dispatch<SetStateAction<NodeMapDefinition | undefined>>;
}
interface props {
  eventMapProps: eventMapProps;
  onSave: () => void;
}
export const EventMapper: React.FC = () => {
  const { eventMapProps, onSave } = useOutletContext() as props;
  const classes = useStyles();
  const formDialog = useFormDialog();
  const handleShortCutInfo = () => {
    formDialog<typeof MapperShortCutInfoDialog>((props) => <MapperShortCutInfoDialog {...props} />);
  };
  const { model, engine, checkModelHasChanged, hasSetPosition, onDiscard, setInitialDataMap } = eventMapProps;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && checkModelHasChanged()
  );

  useRouteBlocker({ blocker, onSave, onDiscard });

  return (
    <Box className={classes.tabContainer}>
      <EventMapperContainer>
        <FullScreen>
          <EventMapDiagram
            model={model}
            engine={engine}
            hasSetPosition={hasSetPosition}
            mapScenario={'FUNCTION_MAPPING'}
            setInitialDataMap={setInitialDataMap}
          />
          <Button
            style={styles.shortcutButton}
            onClick={handleShortCutInfo}
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faKeyboard} />}
          >
            Shortcuts
          </Button>
        </FullScreen>
      </EventMapperContainer>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      height: 'auto',
      paddingBottom: 10,
    },
  })
);
const styles = {
  shortcutButton: {
    position: 'absolute' as const,
    right: 13,
    top: 16,
    color: colors.black54,
    backgroundColor: colors.white,
    zIndex: 10,
    fontWeight: 500,
    fontSize: 16,
  },
  cardTitle: {
    padding: '10px 20px 0px',
  },
};
const EventMapperContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 100vh;
  border: 1px solid ${colors.black10};
`;
