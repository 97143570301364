import * as React from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { DragDropContext, Droppable, DropResult, DragStart } from 'react-beautiful-dnd';
import {
  V2FormComponentDef,
  V2FormTemplate,
  V2GroupComponentDef,
} from '@terragotech/form-renderer';
import styled from 'styled-components';
import { createFormItem, generateFormItemName } from './defaultFormItems';
import { errorMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { useMapperRefChanger } from '../../../../utils/useMapperRefChanger';
import { CommandFormList, V2FormComponentDefWithName } from './CommandFormList';
import { cloneDeep } from 'lodash';
import { Box, Button, Container, Theme, createStyles, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'utils/colors';
import { FormPalette } from 'views/pages/records/components/commands/tabs/FormPalette';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { FormEditorEditedComponentProps } from '../../../../views/pages/records/components/commands/tabs/FormEditor';

interface FormBuildProps {
  formDefinition: V2FormTemplate;
  isImportCommand?: boolean;
  isImport: boolean;
  setFormDefinition: (val: V2FormTemplate) => void;
  handleSelectedItem: (
    event: React.MouseEvent | null,
    formComponentName: string,
    group?: string,
    item?: V2FormComponentDefWithName
  ) => void;
  editItem: (
    component: V2FormComponentDef,
    name: string,
    droppableId: string,
    index: number
  ) => void;
  deleteItem: (id: string, droppableId: string, index: number) => void;
  focusedItem: string;
  formEditorEditedData?: null | V2FormComponentDefWithName;
  formEditorEditedComponent?: FormEditorEditedComponentProps | null;
  setFormEditorEditedData?: (val: V2FormComponentDefWithName | null) => void;
  setFormEditorEditedComponent?: (val: FormEditorEditedComponentProps | null) => void;
  hasError?: boolean;
  selectNextComponent?: (droppableId: string, index: number, componentDef: V2FormTemplate, formDef?: V2FormTemplate) => void;
}

const emptySelectedItems = {
  order: [],
  components: {},
};

export const CommandFormEditor: React.FC<FormBuildProps> = ({
  formDefinition,
  isImportCommand,
  isImport,
  setFormDefinition,
  handleSelectedItem,
  editItem,
  deleteItem,
  focusedItem,
  formEditorEditedData,
  formEditorEditedComponent,
  setFormEditorEditedData,
  setFormEditorEditedComponent,
  hasError,
  selectNextComponent,
}) => {
  const boxRef = React.useRef<HTMLDivElement>(null);
  const mapperRefChanger = useMapperRefChanger();
  const classes = useStyles();
  const [groupDragging, setGroupDragging] = React.useState(false);
  const [selectedItems, setSelectedItems] = React.useState<V2FormTemplate>(emptySelectedItems);
  const [lastPastedFormTemplate] = React.useState<V2FormTemplate | null>(null);
  const [refresh, setRefresh] = React.useState(true);
  const [treeShake, setTreeShake] = React.useState<number>(0);
  const openDialog = useFormDialog();

  React.useEffect(() => {
    if (!refresh) {
      setRefresh(true);
    }
  }, [refresh]);

  const handleItem = (obj: V2FormComponentDefWithName) => {
    const droppableId = obj?.droppableId === 'form' ? '' : obj?.droppableId;
    handleSelectedItem(null, obj.name, droppableId, obj);
  };

  const moveItemFromForm = (result: DropResult) => {
    if (!result.destination) return;
    const reorderItemInForm = () => {
      form.order.splice(sourceIndex, 1);
      form.order.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId: 'form',
        index,
      });
    };
    const addItemToGroup = () => {
      dropComponents[name] = item;
      dropOrder.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId,
        index,
      });
    };
    const removeItemFromForm = () => {
      form.order.splice(sourceIndex, 1);
      delete form.components[result.draggableId];
    };
    const getObjFromId = (id: string) => {
      return id.split('.').reduce((acc: any, eachId: string) => {
        return acc && acc.components && acc.components[eachId] ? acc.components[eachId].template : undefined;
      }, form);
    };

    let form = cloneDeep(formDefinition);
    const { droppableId, index } = result.destination;
    const { index: sourceIndex } = result.source;
    const name = form.order[sourceIndex];
    if (
      setFormEditorEditedComponent &&
      formEditorEditedComponent &&
      name === formEditorEditedComponent.name
    ) {
      setFormEditorEditedComponent({
        ...formEditorEditedComponent,
        index: index,
        droppableId: droppableId,
      });
    }
    const item = form.components[name];
    if (droppableId === 'form') {
      reorderItemInForm();
      return setFormDefinition(form);
    }
    const destObj = getObjFromId(droppableId);
    const { order: dropOrder, components: dropComponents } = destObj;

    if (dropOrder.includes(name))
      return errorMsg(
        'An element with the same name already exists in this group. Rename it before drag'
      );
    removeItemFromForm();
    addItemToGroup();
    form = mapperRefChanger.moveFormReferences(form, name, undefined, droppableId);
    setFormDefinition(form);
  };

  const moveItemFromGroup = (result: DropResult) => {
    if (!result.destination) return;
    const removeItemFromGroup = () => {
      sourceOrder.splice(sourceIndex, 1);
      delete sourceComponents[result.draggableId];
      handleItem({
        ...item,
        name,
        droppableId,
        index,
      });
    };
    const addItemToGroup = () => {
      dropComponents[name] = item;
      dropOrder.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId,
        index,
      });
    };
    const addItemToForm = () => {
      form.components[name] = item;
      form.order.splice(index, 0, name);
      handleItem({
        ...item,
        name,
        droppableId: 'form',
        index,
      });
    };
    const getObjFromId = (id: string) => {
      return id.split('.').reduce((acc: any, eachId: string) => {
        return acc && acc.components && acc.components[eachId] ? acc.components[eachId].template : undefined;
      }, form);
    };

    let form = cloneDeep(formDefinition);
    const { droppableId, index } = result.destination;
    const { droppableId: sourceId, index: sourceIndex } = result.source;
    const sourceObj = sourceId.indexOf('.')
      ? getObjFromId(sourceId)
      : (form.components[sourceId] as V2GroupComponentDef).template;
    const { order: sourceOrder, components: sourceComponents } = sourceObj;
    const name = sourceOrder[sourceIndex];
    const item = sourceComponents[name];
    if (droppableId === 'form') {
      if (form.order.includes(name))
        return errorMsg(
          'An element with the same name already exists in the form. Rename it before drag.'
        );
      if (isImportCommand && item.type !== 'group') {
        return errorMsg('Only groups are allowed at the base level of an import form.');
      }
      removeItemFromGroup();
      addItemToForm();
      form = mapperRefChanger.moveFormReferences(form, name, sourceId);
      setFormDefinition(form);
      return;
    }
    const destObj = droppableId.indexOf('.')
      ? getObjFromId(droppableId)
      : (form.components[droppableId] as V2GroupComponentDef).template;
    const { order: dropOrder, components: dropComponents } = destObj;

    if (droppableId === sourceId) {
      removeItemFromGroup();
      addItemToGroup();
    } else {
      if (dropOrder.includes(name))
        return errorMsg(
          'An element with the same name already exists in this group. Rename it before drag'
        );
      removeItemFromGroup();
      addItemToGroup();
    }
    form = mapperRefChanger.moveFormReferences(form, name, sourceId, droppableId);
    setFormDefinition(form);
  };

  const handleDragStart = (initial: DragStart) => {
    const ids = initial.draggableId.split('.');
    const finalItem = ids.reduce((acc: any, id: string, index: number) => {
      if (index === ids.length - 1) {
        return acc ? acc[id] : undefined;
      } else {
        return acc && acc[id] && (acc[id] as V2GroupComponentDef).template ? (acc[id] as V2GroupComponentDef).template.components : undefined;
      }
    }, formDefinition.components);
    const isGroupDragging = finalItem?.type === 'group';
    setGroupDragging(isGroupDragging);
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!source || !destination) return;
    if (source.droppableId === 'form') {
      moveItemFromForm(result);
    } else {
      moveItemFromGroup(result);
    }
    setGroupDragging(false);
  };

  const addItem = (e: React.MouseEvent, type: string) => {
    const form = cloneDeep(formDefinition);
    const name = generateFormItemName(type, form.order);
    const item = createFormItem(type, name, isImportCommand);
    form.components[name] = item;
    form.order.push(name);
    setFormDefinition(form);
    handleSelectedItem(e, name, undefined, {
      ...item,
      name,
      droppableId: 'form',
      index: form.order.length - 1,
    });
  };

  const openPalette = async (e: React.MouseEvent) => {
    const actionButtons = (await openDialog<typeof FormPalette>((props) => (
      <FormPalette isImportCommand={isImportCommand} isImport={isImport} {...props} />
    ))) as {
      type: string;
    };
    const { type } = actionButtons;
    addItem(e, type);
    if (type === 'group') {
      setTreeShake((x) => x + 1);
    }
  };

  const scrollDown = () => {
    if (boxRef.current) {
      const scrollHeight = boxRef.current.scrollHeight;
      const clientHeight = boxRef.current.clientHeight;
      const scrollTop = scrollHeight - clientHeight;
      boxRef.current.scrollTop = scrollTop > 0 ? scrollTop : 0;
    }
  };

  React.useEffect(() => {
    if (treeShake) {
      scrollDown();
    }
  }, [treeShake]);

  const OuterBox = treeShake % 2 ? Container : Box;

  return refresh ? (
    <OuterBox className={classes.container} ref={boxRef}>
      {formDefinition?.order?.length > 0 && (
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <FlexRowContainer>
            <Box className={classes.formContainer}>
              <Droppable droppableId="form">
                {(droppableProvided) => (
                  //@ts-ignore
                  <FormInner {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                    <CommandFormList
                      formDefinition={formDefinition}
                      setFormDefinition={setFormDefinition}
                      selectedItems={selectedItems}
                      setSelectedItems={setSelectedItems}
                      lastPastedFormTemplate={lastPastedFormTemplate}
                      groupDragging={groupDragging}
                      isImportCommand={isImportCommand}
                      handleSelectedItem={handleSelectedItem}
                      hasError={hasError}
                      editItem={editItem}
                      deleteItem={deleteItem}
                      focusedItem={focusedItem}
                      formEditorEditedData={formEditorEditedData}
                      formEditorEditedComponent={formEditorEditedComponent}
                      setFormEditorEditedData={setFormEditorEditedData}
                      isImport={isImport}
                      selectNextComponent={selectNextComponent}
                    />
                    {droppableProvided.placeholder}
                  </FormInner>
                )}
              </Droppable>
            </Box>
          </FlexRowContainer>
        </DragDropContext>
      )}

      <Button className={classes.emptyButtonCtn} onClick={openPalette}>
        <Box className={classes.plusBox}>
          <FontAwesomeIcon icon={faPlus} className={classes.plusIcon} />
        </Box>
      </Button>
    </OuterBox>
  ) : null;
};

const FlexRowContainer = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
`;

const FormInner = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '13px 9px',
      overflow: 'auto',
    },
    formContainer: {
      width: '100%',
    },
    emptyButtonCtn: {
      width: '100%',
      height: 68,
      padding: '20px 0',
      border: `1px dashed ${colors.black25}`,
    },
    plusBox: {
      width: 29,
      height: 29,
      borderRadius: 29 / 2,
      border: `1px dashed ${colors.black25}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    plusIcon: {
      fontSize: 15,
      color: theme.palette.primary.main,
    },
  })
);
