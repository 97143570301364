import React, { useMemo, useCallback, useContext, useState, useEffect } from 'react';
import { EditableTable, CSVResult } from '../EditableTable';
import { cloneDeep } from 'lodash';
import {
  V2OptionsType,
  V2SimpleOption,
  V2AggregateLoader,
  DynamicLoaderType,
  SimpleOptionType,
  AggregateLoaderType,
  UserLoaderType,
  V2FormOptionsGroup,
} from '@terragotech/form-renderer';
import { ConfigContext } from '../../context/ConfigContext';
import { QueryItemWithID } from '../FormDialog/QueryFilterEditorDialog';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { successMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import { propertiesObjsToArray } from '../../pages/aggregates/utils/propertiesObjsToArray';
import { SimpleOption, SimpleOptionsUpload } from '../SimpleOptionsUpload';
import { Box, Button, IconButton, Typography, makeStyles } from '@material-ui/core';
import SCVUploadResultsDialog from '../CSVUploadResultsDialog/CSVUploadResultsDialog';
import DataMapperDialog from 'components/FormDialog/DataMapperDialog';
import _ from 'lodash';
import { LocalSchemaDefinition } from 'utils/useSchemaLookup';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { TGSelect } from 'views/components/formElements';
import FormLegend from 'components/FormLegend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';
import {
  AttributeBasedFilterWithID,
  QueryBuilder,
} from 'pages/aggregates/components/QueryEditor/QueryBuilder';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';

let CSVResultData: CSVResult;

interface Props {
  options: V2OptionsType;
  setOptions: (value: V2OptionsType) => void;
  aggregateOnly?: boolean;
  onDelete?: () => void;
  localSchemas?: LocalSchemaDefinition;
  isMapEdit?: boolean;
}

interface Option {
  value: string;
  label: string;
}

type V2OptionsTypes =
  | typeof SimpleOptionType
  | typeof AggregateLoaderType
  | typeof UserLoaderType
  | typeof DynamicLoaderType;

type QueryFilterType = 'mandatoryFilter' | 'defaultFilter';

export const isSimpleOption = (options: V2OptionsType): options is V2SimpleOption =>
  options.type === SimpleOptionType;

export const isAggregateLoader = (options: V2OptionsType): options is V2AggregateLoader =>
  options.type === AggregateLoaderType;

export const V2OptionsInput: React.FC<Props> = ({
  options,
  setOptions,
  aggregateOnly,
  onDelete,
  localSchemas,
  isMapEdit,
}: Props) => {
  const classes = useLocalStyles();
  const { config } = useContext(ConfigContext);
  const [CSVResultOpen, setcSVResultOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const filters = (options as V2AggregateLoader)?.mandatoryFilter?.filter;
  const [query, setQuery] = useState<V2FormOptionsGroup | undefined>(filters);
  const [dynamicMap, setDynamicMap] = useState(
    options.type === DynamicLoaderType ? options.optionsMap || undefined : undefined
  );
  const [dynamicOpen, setDynamicOpen] = useState(false);
  const { openConfirmation } = useConfirmDialog();

  useEffect(() => {
    if (options.type === DynamicLoaderType) {
      setDynamicMap(options.optionsMap || undefined);
    }
  }, [options]);

  useEffect(() => {
    setQuery(filters);
  }, [filters]);

  const doesDynamicHaveValue = useCallback(() => {
    return !_.isEmpty(dynamicMap);
  }, [dynamicMap]);
  const isAggregateType = options.type === AggregateLoaderType || options.type === UserLoaderType;

  const handleClearDynamicMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDynamicMap(undefined);
      if (options.type === DynamicLoaderType) {
        const optionsCopy = cloneDeep(options);
        optionsCopy.optionsMap = undefined;
        setOptions(optionsCopy);
      }
    }
  };

  const handleQueryChange = (query: AttributeBasedFilterWithID) => {
    const withoutIds = removeIDs(cloneDeep(query)) as V2FormOptionsGroup;
    setQuery(withoutIds);
    if (isAggregateType) {
      const optionsCopy = cloneDeep(options);
      optionsCopy['mandatoryFilter'] = { filter: withoutIds };
      setOptions(optionsCopy);
    }
  };

  const removeIDs = (item: QueryItemWithID) => {
    //@ts-ignore
    delete item.id;
    if (item.type === 'GroupFilter') {
      item.condition.map((item) => removeIDs(item as QueryItemWithID));
    }
    return item;
  };

  const handleClearQueryEditor = async (filterType: QueryFilterType) => {
    if (isAggregateType) {
      const optionsCopy = cloneDeep(options);
      delete optionsCopy[filterType];
      setOptions(optionsCopy);
      setQuery(undefined);
      if (onDelete) onDelete();
    }
  };

  const onRemove = () => {
    handleClearQueryEditor('mandatoryFilter');
  };

  const getOptions = useMemo(() => {
    if (isSimpleOption(options)) return cloneDeep(options.items || []);
    return [];
  }, [options]);

  const isOptionInvalid = useCallback((row: object) => {
    if (!(row as Option).label) {
      errorMsg('Property "Label" is required');
      return true;
    }
    if (!(row as Option).value) {
      errorMsg('Property "Value" is required');
      return true;
    }
    return false;
  }, []);

  const addOption = useCallback(
    (option: object, resolve: (data: any) => void, reject: () => void) => {
      if (isSimpleOption(options)) {
        if (isOptionInvalid(option)) return reject();
        const optionsCopy = cloneDeep(options);
        optionsCopy.items = [...(options.items || []), option as Option];
        setOptions(optionsCopy);
        resolve(null);
      }
    },
    [options, setOptions, isOptionInvalid]
  );

  const deleteOption = useCallback(
    (option: object) => {
      if (isSimpleOption(options)) {
        const optionsCopy = cloneDeep(options);
        optionsCopy.items = optionsCopy.items.filter(
          (item) => item.value !== (option as Option).value
        );
        setOptions(optionsCopy);
      }
    },
    [options, setOptions]
  );

  const CSVFileImport = (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: any) => void,
    reject: () => void
  ) => {
    if (isSimpleOption(options)) {
      setcSVResultOpen(true);
      CSVResultData = uploadedResults;
      setOptions({ items: rows as SimpleOption[], type: SimpleOptionType });
      successMsg(`CSV file successfully uploaded`);
      return resolve(null);
    }
    errorMsg(`Error while uploading CSV file`);
    return reject();
  };

  const updateOption = useCallback(
    (option: object, oldOption: object, resolve: (data: any) => void, reject: () => void) => {
      if (isSimpleOption(options)) {
        if (isOptionInvalid(option)) return reject();
        const optionsCopy = cloneDeep(options);
        optionsCopy.items = optionsCopy.items.map((item) =>
          item.value === (oldOption as Option).value ? (option as Option) : item
        );
        setOptions(optionsCopy);
        resolve(null);
      }
    },
    [options, setOptions, isOptionInvalid]
  );

  const handleChangeOptionType = (value: V2OptionsTypes) => {
    const optionsCopy = cloneDeep(options);
    optionsCopy.type = value;
    // if (isSimpleOption(optionsCopy) && value === AggregateLoaderType) delete optionsCopy.items;
    setOptions(optionsCopy);
  };

  const handleChangeAggregateType = useCallback(
    async (value: string) => {
      if (isAggregateLoader(options)) {
        let isConfirmed = true;
        if (options.mandatoryFilter || options.defaultFilter) {
          const confirmProps = CONFIRMATION.formEditor.aggregateTypeChange;
          const status = await openConfirmation(confirmProps);
          isConfirmed = status === 'confirm' ? true : false;
        }
        if (isConfirmed) {
          const optionsCopy = cloneDeep(options);
          delete optionsCopy.mandatoryFilter;
          delete optionsCopy.defaultFilter;
          optionsCopy.aggregateType = value;
          setOptions(optionsCopy);
          setQuery(undefined);
        }
      }
    },
    [openConfirmation, options, setOptions]
  );

  const getAggregateList = useCallback(() => config.aggregates.map((item) => item.typeName), [
    config,
  ]);

  const getAggregateProperties = () => {
    return config.aggregates[
      getAggregateIndex(config, (options as V2AggregateLoader).aggregateType)
    ]?.properties;
  };

  const handleChangeMap = (map?: NodeMapDefinition) => {
    if (options.type === DynamicLoaderType) {
      const optionsCopy = cloneDeep(options);
      optionsCopy.optionsMap = map;
      setOptions(optionsCopy);
      setDynamicMap(map);
    }
  };

  const handleReorder = (options: unknown) => {
    const simpleOptions: V2OptionsType = {
      type: 'SimpleOption',
      items: options as Array<{
        value: string;
        label: string;
      }>,
    };
    setOptions(simpleOptions);
  };

  const getQueryFilterKeys = (type: typeof UserLoaderType | typeof AggregateLoaderType) => {
    switch (type) {
      case UserLoaderType:
        return [
          { name: 'username', type: 'String' },
          { name: 'email', type: 'String' },
          { name: 'family_name', type: 'String' },
          { name: 'given_name', type: 'String' },
          { name: 'roles', type: 'StringArray' },
        ];
      case AggregateLoaderType:
        return propertiesObjsToArray(getAggregateProperties());
    }
  };

  const isDynamicValue = doesDynamicHaveValue();

  const SelctInput = useCallback(
    ({ value }: { value: string }) => (
      <TGSelect
        id="Aggregate type"
        type="Aggregate type"
        value={value}
        options={getAggregateList()}
        onChange={(v) => handleChangeAggregateType(v as string)}
      />
    ),
    [handleChangeAggregateType, getAggregateList]
  );

  return (
    <Box className={isAggregateType ? classes.mb5 : classes.container}>
      {!aggregateOnly && (
        <TGSelect
          id="Options type"
          label="Options"
          type="Options type"
          value={options.type}
          options={[SimpleOptionType, AggregateLoaderType, UserLoaderType, DynamicLoaderType]}
          onChange={(v) => handleChangeOptionType(v as V2OptionsTypes)}
          containerStyle={classes.selectContainer}
        />
      )}
      {options.type === SimpleOptionType && (
        <FormLegend
          title="Options"
          legendContainer={classes.legendContainer}
          rightContent={
            <div className={classes.rightContainer}>
              <IconButton onClick={() => setOpenAdd(true)} className={classes.iconButton}>
                <FontAwesomeIcon className={classes.addIcon} icon={faPlus} />
              </IconButton>
              <SimpleOptionsUpload
                data={getOptions as SimpleOption[]}
                fileImportResults={CSVFileImport}
              />
            </div>
          }
          body={
            <>
              <EditableTable
                columns={optionsColumns}
                data={getOptions}
                toolbarStyle={otherAttributesTableToolbarStyle}
                fileType="simpleOptions"
                onFileImport={CSVFileImport}
                onAdd={addOption}
                onUpdate={updateOption}
                onReOrder={handleReorder}
                onDelete={deleteOption}
                options={tableOptions}
                hasHeader={false}
                onOpenAddModal={openAdd}
                onCloseAddModal={setOpenAdd}
                tableContainer={classes.tableContainer}
              />
              <SCVUploadResultsDialog
                CSVResultOpen={CSVResultOpen}
                setcSVResultOpen={setcSVResultOpen}
                CSVResultData={CSVResultData}
              />
            </>
          }
        />
      )}
      {options.type === DynamicLoaderType && (
        <>
          <FormLegend
            title="Advanced"
            legendContainer={classes.legendContainer}
            rightContent={
              <IconButton
                className={classes.iconButton}
                disabled={isDynamicValue}
                onClick={() => setDynamicOpen(true)}
              >
                <FontAwesomeIcon
                  className={`${classes.addIcon} ${isDynamicValue ? classes.disabled : ''}`}
                  icon={faPlus}
                />
              </IconButton>
            }
            body={
              isDynamicValue ? (
                <Box className={classes.row}>
                  <Button
                    color="primary"
                    className={classes.mapperButton}
                    onClick={() => setDynamicOpen(true)}
                  >
                    Mapper
                  </Button>
                  <IconButton
                    className={classes.iconButton}
                    component="span"
                    onClick={handleClearDynamicMapper}
                  >
                    <FontAwesomeIcon icon={faMinus} className={classes.minusIcon} />
                  </IconButton>
                </Box>
              ) : (
                <Box className={classes.row}>
                  <Typography className={classes.emptyText}>Mapping list is empty</Typography>
                </Box>
              )
            }
          />

          <DataMapperDialog
            mapScenario="DROPDOWN_OPTIONS"
            localSchemaDefinitions={localSchemas}
            onClose={() => {
              setDynamicOpen(false);
            }}
            open={dynamicOpen}
            datamap={dynamicMap}
            setDatamap={handleChangeMap}
          />
        </>
      )}
      {isAggregateType && (
        <Box className={classes.aggregates}>
          {isMapEdit ? (
            <>
              <QueryBuilder
                pannelHeaderStyle={classes.pannelHeaderStyle}
                allowValue={!!aggregateOnly}
                keys={getQueryFilterKeys(options.type)}
                onQueryChange={handleQueryChange}
                query={query}
                panelTitle="Mandatory Filter"
                panelTitleStyle={classes.panelTitle}
                onRemove={onRemove}
                aggregateType={
                  options.type === AggregateLoaderType ? (
                    <FormLegend
                      title="Aggregate type"
                      legendContainer={classes.aggreLegend}
                      headerContainer={classes.aggreHeaderContainer}
                      body={
                        <Box className={classes.aggreBody}>
                          <SelctInput value={options.aggregateType} />
                        </Box>
                      }
                    />
                  ) : null
                }
              />
            </>
          ) : (
            <>
              {options.type === AggregateLoaderType && (
                <FormLegend
                  title="Aggregate type"
                  body={
                    <Box className={classes.aggregateBody}>
                      <SelctInput value={options.aggregateType} />
                    </Box>
                  }
                />
              )}
              {(options.type === UserLoaderType || options.aggregateType) && (
                <Box className={`${classes.legendContainer} ${classes.mb16}`}>
                  <QueryBuilder
                    allowValue={!!aggregateOnly}
                    keys={getQueryFilterKeys(options.type)}
                    onQueryChange={handleQueryChange}
                    query={query}
                    panelTitle="Mandatory Filter"
                    panelTitleStyle={classes.panelTitle}
                  />
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

const optionsColumns = [
  { title: 'Label', field: 'label' },
  { title: 'Value', field: 'value' },
];

const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
};

const useLocalStyles = makeStyles({
  container: {
    marginBottom: 14,
  },
  mb5: {
    marginBottom: 5,
  },
  selectContainer: {
    '& .MuiInputBase-root': {
      marginTop: 26,
    },
    '& .MuiFormLabel-root': {
      fontWeight: 500,
      lineHeight: '100%',
    },
  },
  options: { margin: '10px 0', paddingTop: 15, overflowY: 'auto' },
  remove: { justifyContent: 'center', display: 'grid' },
  aggregates: {
    marginTop: 16,
  },
  addIcon: {
    fontSize: 22,
    color: colors.black54,
  },
  legendContainer: {
    marginTop: 16,
  },
  mb16: {
    marginBottom: 16,
  },
  tableContainer: {
    marginTop: 0,
    '& .MuiTableCell-root.MuiTableCell-head': {
      backgroundColor: colors.white,
    },
  },
  aggregateBody: {
    padding: '16px 14px 18px 21px',
  },
  aggreBody: {
    padding: '0 20px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 15px 3px 16px',
    backgroundColor: colors.white,
    height: 55,
  },
  minusIcon: {
    color: colors.black54,
    fontSize: 22,
  },
  iconButton: {
    color: colors.black54,
  },
  mapperButton: {
    fontSize: 15,
    fontWeight: 500,
  },
  disabled: {
    color: colors.black10,
  },
  panelTitle: {
    fontWeight: 400,
  },
  emptyText: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black54,
    paddingLeft: 8,
  },
  aggreLegend: {
    margin: 0,
    border: 'none',
  },
  aggreHeaderContainer: {
    height: '10px !important',
    marginTop: 20,
    marginBottom: 13,
    backgroundColor: `${colors.white} !important`,
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  pannelHeaderStyle: {
    paddingLeft: 19,
  },
  rightContainer: {
    display: 'flex',
    gap: 10,
  },
});
