import { useRedirect } from 'context/RedirectModalContext';
import { useCallback, useEffect } from 'react';
import type { Blocker } from '@remix-run/router';

interface Props {
  blocker: Blocker;
  onSave: () => void;
  onDiscard?: () => void;
}

const useRouteBlocker = ({ blocker, onSave, onDiscard }: Props) => {
  const { openRedirect } = useRedirect();

  const openBlockerAlert = useCallback(async () => {
    if (blocker.state === 'blocked') {
      const resp = (await openRedirect()) as 'save' | 'discard' | 'cancel';
      switch (resp) {
        case 'save':
          onSave();
          return blocker.proceed();
        case 'discard':
          onDiscard && onDiscard();
          return blocker.proceed();
        case 'cancel':
          return blocker.reset();
      }
    }
  }, [blocker, openRedirect, onSave, onDiscard]);

  useEffect(() => {
    openBlockerAlert();
  }, [blocker.state, openBlockerAlert]);
};

export default useRouteBlocker;
