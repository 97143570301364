import React, { useState } from 'react';
import { Card, IconButton, Collapse, Box, Typography, makeStyles } from '@material-ui/core';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { colors } from 'utils/colors';

interface InputGroupProps {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  titleStyle?: string;
  collapsible?: boolean;
  startCollapsed?: boolean;
  cardContainer?: string;
}

const RotatingButton = styled(IconButton)<{ rotated: number }>`
  padding: 5px;
  transform: rotate(${(props) => (props.rotated ? -90 : 0)}deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const InputGroup: React.FC<InputGroupProps> = ({
  title,
  children,
  style,
  titleStyle,
  collapsible,
  startCollapsed = false,
  cardContainer,
}) => {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(startCollapsed);
  return (
    <Box className={classes.container}>
      <Typography
        className={`
          ${classes.groupTitleStyle}
          ${titleStyle ?? ''}
          ${collapsible ? classes.collapse : ''}
        `}
      >
        {collapsible && (
          <RotatingButton
            rotated={collapsed ? 1 : 0}
            onClick={() => setCollapsed((current) => !current)}
          >
            <ExpandMoreIcon />
          </RotatingButton>
        )}
        {title}
      </Typography>
      <Collapse in={!collapsed}>
        <Card variant="outlined" className={`${classes.card} ${cardContainer ?? ''}`} style={style}>
          {children}
        </Card>
      </Collapse>
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    position: 'relative' as const,
    margin: '10px 0',
  },
  groupTitleStyle: {
    backgroundColor: colors.white,
    color: colors.black,
    fontSize: 14,
    margin: '10px 0',
  },
  card: {
    padding: '16px 20px',
    marginTop: 10,
    boxShadow: `0px 1px 4px 1px ${colors.black10}`,
    border: 'none',
    position: 'relative',
  },
  collapse: { marginBlockStart: 6 },
});
