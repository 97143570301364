import React from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Input, InputLabel, IconButton } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Papa from 'papaparse';
import { CSVResult } from './EditableTable';
import { errorMsg } from './SnackbarUtilsConfigurator';
import { objectKeysToLowerCase } from '../utils/objectKeysToLowerCase';

interface SimpleOptionListTableProps {
  data: SimpleOption[];
  fileImportResults?: (
    rows: object[],
    uploadedResults: CSVResult,
    resolve: (data: any) => void,
    reject: () => void
  ) => void;
}

interface Row {
  [key: string]: string | boolean | number | undefined;
}

export interface SimpleOption {
  label: string;
  value: string;
}

export const SimpleOptionsUpload: React.FC<SimpleOptionListTableProps> = ({
  fileImportResults,
}) => {
  const isSimpleOptionProperty = (value: object[]): value is SimpleOption[] =>
    'label' in value[0] && 'value' in value[0];

  const convertObjsKeysToLowerCase = (csvData: Row[]) =>
    csvData.map((row) => objectKeysToLowerCase(row));

  const csvDataCorrection = (csvData: SimpleOption[]) =>
    csvData.filter((item: SimpleOption) => item.label && item.value);

  const onCSVImportClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (!file) return;
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: onParseComplete,
      header: true,
    });
  };

  const onParseComplete = (results: Papa.ParseResult<Row>) =>
    new Promise((resolve, reject) => {
      const csvData = convertObjsKeysToLowerCase(results.data);
      if (isSimpleOptionProperty(csvData)) {
        const filteredData = csvDataCorrection(csvData);
        if (fileImportResults) {
          const uploadedResults: CSVResult = {
            newRecordCount: filteredData.length,
            badRecordCount: csvData.length - filteredData.length,
          };
          fileImportResults(filteredData, uploadedResults, resolve, reject);
        }
      } else {
        errorMsg(`Label/Value column header are missing`);
      }
    });

  return (
    <Tooltip title="Import CSV file from Simple Option list">
      <InputLabel htmlFor="csvImportBtn" style={styles.inputLabelStyle}>
        <IconButton component="span" style={styles.cloudIcon}>
          <Input
            id="csvImportBtn"
            inputProps={{
              accept: '.csv',
            }}
            onChange={onCSVImportClick}
            style={{ display: 'none' }}
            type="file"
          />
          <CloudUploadIcon />
        </IconButton>
      </InputLabel>
    </Tooltip>
  );
};

const styles = {
  cloudIcon:{
    position: 'relative' as const,
    right: 5,
  },
  inputLabelStyle: {
    alignSelf: 'center',
    cursor: 'pointer',
    marginLeft: -30,
    position: 'relative' as const,
    left: 20,
  }
};
