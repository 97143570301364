import { useState } from 'react';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { Box, Divider, Theme, createStyles, makeStyles } from '@material-ui/core';
import PageHeader from 'views/components/PageHeader';
import RowItem from 'views/components/RowItem';
import useCommonStyles from 'views/useCommonStyles';
import { ColorPickerProps, CommonSettings, IosSwitchProps, TextInputProps } from './commonTypes';
import { useConfig } from '../../../../../context/ConfigContext';
import {
  DATE_FORMAT_TYPE,
  DateFormatFormArray,
  DateFormatIsoArray,
  DateFormatTypeArray,
  DateSeparatorLabelArray,
  DATETIME_TOKEN_CONVERSION,
  DEFAULT_DATETIME_FORMAT,
  getDateFormat,
  getTimeFormat,
  TimeFormatArray,
  validateDateFormat,
} from '@terragotech/gen5-shared-utilities';
import moment from 'moment';
import { successMsg } from '../../../../../components/SnackbarUtilsConfigurator';
import { colors } from '../../../../../utils/colors';
import { useBlocker } from 'react-router-dom';
import useRouteBlocker from 'common/useBlocker';
import { compact, isEqual } from 'lodash';

const General = () => {
  const {
    config,
    setConfig,
    getLegalDisclaimer,
    setLegalDisclaimer: saveLegalDisclaimer,
  } = useConfig();
  const [mobileUIConfig, setMobileUIConfig] = useState(config.mobileUIConfig);
  const [webUIConfig, setWebUIConfig] = useState(config.webUIConfig);
  const [isLegalDisclaimer, setIsLegalDisclaimer] = useState(!!getLegalDisclaimer());
  const [legalDisclaimer, setLegalDisclaimer] = useState(getLegalDisclaimer());
  const [isDirty, setIsDirty] = useState(false);
  const [dateFormatType, setDateFormatType] = useState(
    webUIConfig?.defaultDateTimeFormat?.dateFormatType || DEFAULT_DATETIME_FORMAT.DateFormatType
  );
  const [dateFormat, setDateFormat] = useState(
    webUIConfig?.defaultDateTimeFormat?.dateFormat || DEFAULT_DATETIME_FORMAT.DateFormat
  );
  const [dateSeperator, setDateSeperator] = useState(
    webUIConfig?.defaultDateTimeFormat?.dateSeperator || DEFAULT_DATETIME_FORMAT.DateSeperator
  );
  const [timeFormat, setTimeFormat] = useState(
    webUIConfig?.defaultDateTimeFormat?.timeFormat || DEFAULT_DATETIME_FORMAT.TimeFormat
  );
  const [dateFormatSwitch, setDateFormatSwitch] = useState(
    webUIConfig?.defaultDateTimeFormat?.isSwitch || false
  );

  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const updateLabelsConfig = (key: string, value: string) => {
    setWebUIConfig({
      ...webUIConfig,
      labels: { ...webUIConfig.labels, [key]: value },
    });
    setMobileUIConfig({
      ...mobileUIConfig,
      labels: { ...mobileUIConfig.labels, [key]: value },
    });
  };

  const updateThemeConfig = (key: string, value: string) => {
    setWebUIConfig({
      ...webUIConfig,
      theme: { ...webUIConfig.theme, [key]: value },
    });
    setMobileUIConfig({
      ...mobileUIConfig,
      theme: { ...mobileUIConfig.theme, [key]: value },
    });
  };
  const updateLineConfig = (key: string, value: string) => {
    setWebUIConfig({
      ...webUIConfig,
      line: { ...webUIConfig.line, [key]: value } as { editColor: string },
    });
    setMobileUIConfig({
      ...mobileUIConfig,
      line: { ...mobileUIConfig.line, [key]: value } as { editColor: string },
    });
  };

  const updateWebEnableFeatures = (key: string, value: boolean) => {
    setWebUIConfig({
      ...webUIConfig,
      enabledFeatures: { ...webUIConfig.enabledFeatures, [key]: value },
    });
  };

  const updateMapExtentsConfig = (key: string, value: number | undefined) => {
    setWebUIConfig({
      ...webUIConfig,
      initialMapExtents: { ...webUIConfig.initialMapExtents, [key]: value },
    });
    setMobileUIConfig({
      ...mobileUIConfig,
      initialMapExtents: { ...mobileUIConfig.initialMapExtents, [key]: value },
    });
  };

  const updateSplitScreen = (value: boolean) => {
    setMobileUIConfig({
      ...mobileUIConfig,
      enabledFeatures: {
        ...mobileUIConfig.enabledFeatures,
        splitScreen: {
          enabled: value,
          defaultOn: false,
        },
      },
    });
  };
  const generalSettings: CommonSettings[] = [
    {
      title: 'Labels',
      description: '',
      components: [
        {
          type: 'textInput' as const,
          key: 'application',
          label: 'Application Name',
          value: webUIConfig.labels.applicationName,
          action: (value) => {
            updateLabelsConfig('applicationName', value);
          },
        } as TextInputProps,
        {
          type: 'textInput' as const,
          key: 'copyright',
          label: 'Copyright Info',
          value: webUIConfig.labels.copyrightInfo,
          action: (value) => {
            updateLabelsConfig('copyrightInfo', value);
          },
        } as TextInputProps,
        {
          type: 'iosSwitch' as const,
          key: 'legalDisclaimer',
          title: 'Enable Legal Disclaimer',
          description:
            'Provide the legal disclaimer text that the user will be required to accept before using this app',
          checked: isLegalDisclaimer,
          hasTextArea: true,
          action: (checked) => {
            setIsDirty(true);
            setIsLegalDisclaimer(checked);
          },
          textAreaValue: legalDisclaimer,
          onChangeTextArea: (value) => {
            setIsDirty(true);
            setLegalDisclaimer(value);
          },
          container: classes.legalDisclaimerContainer,
        } as IosSwitchProps,
      ],
    },
    {
      title: 'Branding',
      description: '',
      components: [
        {
          type: 'textInput' as const,
          key: 'theme',
          label: 'Theme',
          value: webUIConfig.theme.logoUrl,
          action: (value) => {
            updateThemeConfig('logoUrl', value);
          },
        } as TextInputProps,
        {
          type: 'colorPicker' as const,
          key: 'primaryColor',
          title: 'Primary Color',
          value: webUIConfig.theme.primary,
          description: '',
          action: (value) => {
            updateThemeConfig('primary', value);
          },
        } as ColorPickerProps,
        {
          type: 'colorPicker' as const,
          key: 'secondaryColor',
          title: 'Secondary Color',
          value: webUIConfig.theme.secondary,
          description: '',
          action: (value) => {
            updateThemeConfig('secondary', value);
          },
        } as ColorPickerProps,
        {
          type: 'colorPicker' as const,
          key: 'lineEditColor',
          title: 'Line Edit Color',
          value: webUIConfig?.line?.editColor || colors.electricBlue,
          description: '',
          action: (value) => {
            updateLineConfig('editColor', value);
          },
        } as ColorPickerProps,
      ],
    },
    {
      title: 'Enabled Features',
      description: '',
      components: [
        {
          type: 'iosSwitch' as const,
          key: 'attribute',
          title: 'Attribute Search On Map (Web Only)',
          description:
            'Enabling this will we denounce with righteous indignation and dislike men who are so beguiled.',
          checked: webUIConfig?.enabledFeatures?.attributeSearchOnMap || false,
          action: (checked) => {
            updateWebEnableFeatures('attributeSearchOnMap', checked);
          },
        } as IosSwitchProps,
        {
          type: 'iosSwitch' as const,
          key: 'analytics',
          title: 'Analytics Module (Web Only)',
          description:
            'Enabling this will we denounce with righteous indignation and dislike men who are so beguiled.',
          checked: webUIConfig?.enabledFeatures?.analyticsModule || false,
          action: (checked) => {
            updateWebEnableFeatures('analyticsModule', checked);
          },
        } as IosSwitchProps,
        {
          type: 'iosSwitch' as const,
          key: 'split',
          title: 'Enable Spilt Screen on wide devices in landscape (Mobile)',
          description:
            'Enabling this will we denounce with righteous indignation and dislike men who are so beguiled.',
          checked: mobileUIConfig?.enabledFeatures?.splitScreen?.enabled || false,
          action: (checked) => updateSplitScreen(checked),
        } as IosSwitchProps,
      ],
    },
    {
      title: 'Initial Map Extends',
      description: '',
      components: [
        {
          type: 'textInput' as const,
          key: 'lat',
          label: 'Latitude',
          value: webUIConfig.initialMapExtents.lat,
          action: (value) => updateMapExtentsConfig('lat', value ? +value : undefined),
          textInputType: 'number',
        } as TextInputProps,
        {
          type: 'textInput' as const,
          key: 'long',
          label: 'Longitude',
          value: webUIConfig.initialMapExtents.lon,
          textInputType: 'number',
          action: (value) => {
            updateMapExtentsConfig('lon', value ? +value : undefined);
          },
        } as TextInputProps,
        {
          type: 'textInput' as const,
          key: 'zoom',
          label: 'Zoom Level',
          value: webUIConfig.initialMapExtents.zoom,
          textInputType: 'number',
          action: (value) => {
            updateMapExtentsConfig('zoom', value ? +value : undefined);
          },
        } as TextInputProps,
      ],
    },
    {
      title: 'Date/Time Format Options',
      description: '',
      components: [
        {
          type: 'select' as const,
          key: 'dateType',
          label: 'Date Format Type',
          value: dateFormatType,
          options: DateFormatTypeArray,
          action: (value: string | number) => {
            setIsDirty(true);
            setDateFormatType(value as string);
            setDateFormat(
              validateDateFormat(value as string, dateFormat) ??
                validateDateFormat(dateFormatType, dateFormat)
            );
          },
        },
        {
          type: 'select' as const,
          key: 'dateFormat',
          label: 'Date Format',
          value: dateFormat,
          options:
            dateFormatType === DATE_FORMAT_TYPE.GeneralForm
              ? DateFormatFormArray
              : DateFormatIsoArray,
          action: (value: string | number) => {
            setIsDirty(true);
            setDateFormat(value as string);
          },
          info: `The date format will be displayed : ${moment(new Date()).format(
            getDateFormat(dateFormatType, dateFormat, dateSeperator, {
              tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS,
            })
          )}`,
        },
        {
          type: 'select' as const,
          key: 'dateSeperator',
          label: 'Date Seperator',
          value: dateSeperator,
          options: DateSeparatorLabelArray,
          action: (value: string | number) => {
            setIsDirty(true);
            setDateSeperator(value as string);
          },
          isHide: dateFormatType !== 'General Form',
        },
        {
          type: 'select' as const,
          key: 'timeFormat',
          label: 'Time Format',
          value: timeFormat,
          options: TimeFormatArray,
          action: (value: string | number) => {
            setIsDirty(true);
            setTimeFormat(value as string);
          },
          isHide: dateFormatType !== 'General Form',
          info: `The time format will be displayed : ${moment(new Date()).format(
            getTimeFormat(dateFormatType, dateFormat, timeFormat, {
              tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS,
            })
          )}`,
        },
        {
          type: 'iosSwitch' as const,
          key: 'enableIos',
          title: 'Enable ISO 8601 Format Always for CSV Export',
          description:
            'For CSV exports, ignore the display setting above, and always export using ISO 8601: yyyy-mm-ddThh:mm:ss-hh:mm.',
          checked: dateFormatSwitch,
          action: (checked) => {
            setIsDirty(true);
            setDateFormatSwitch(checked);
          },
          container: classes.switchContainer,
        } as IosSwitchProps,
      ],
    },
  ];

  const onSave = async () => {
    const dateTimeData = {
      dateFormatType: dateFormatType,
      dateFormat: dateFormat,
      dateSeperator: dateSeperator,
      timeFormat: timeFormat,
      isSwitch: dateFormatSwitch,
    };
    const configCopy = { ...config };
    configCopy.webUIConfig = { ...configCopy.webUIConfig, ...webUIConfig };
    configCopy.mobileUIConfig = { ...configCopy.mobileUIConfig, ...mobileUIConfig };
    configCopy.webUIConfig = { ...configCopy.webUIConfig, defaultDateTimeFormat: dateTimeData };
    configCopy.mobileUIConfig = {
      ...configCopy.mobileUIConfig,
      mobileDefaultDateTimeFormat: dateTimeData,
    };
    setConfig(configCopy);
    setMobileUIConfig(configCopy.mobileUIConfig);
    setWebUIConfig(configCopy.webUIConfig);
    isLegalDisclaimer ? saveLegalDisclaimer(legalDisclaimer) : saveLegalDisclaimer('');
    setIsDirty(false);
    successMsg('Branding configuration has been successfully saved');
  };

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname !== nextLocation.pathname) {
      const isConfigChanged =
        !isEqual(config.mobileUIConfig, mobileUIConfig) ||
        !isEqual(config.webUIConfig, webUIConfig);
      return isConfigChanged || isDirty;
    }
    return false;
  });

  useRouteBlocker({ blocker, onSave: onSave });

  return (
    <Box className={commonClasses.container}>
      <Box className={`${commonClasses.innerContainer} ${classes.innerPadding}`}>
        <PageHeader
          title={'General'}
          icon={faSave}
          buttonText="Save"
          onSave={onSave}
          showDivider
          showLinkUnlink
        />
        {generalSettings.map((generalSetting, index) => (
          <Box
            key={generalSetting.title}
            className={index === 0 ? commonClasses.firstSettingsContainer : ''}
          >
            <RowItem
              title={generalSetting.title}
              description={generalSetting.description}
              components={generalSetting.components}
            />
            {index !== generalSettings.length - 1 && (
              <Divider
                className={compact([
                  commonClasses.settingsDivider,
                  index === 0 && classes.firstDivider,
                ]).join(' ')}
              />
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
    },
    footer: {
      height: 1,
    },
    innerPadding: {
      paddingBottom: 74,
    },
    switchContainer: {
      marginTop: 50,
    },
    firstDivider: {
      marginBottom: 38,
    },
    legalDisclaimerContainer: {
      marginTop: 35,
      width: '100%',
      '& .MuiTypography-root.MuiTypography-body2': {
        color: colors.black60,
      },
    },
  })
);

export default General;
