import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { colors } from 'utils/colors';

const useStyles = makeStyles({
  btnIcon: {
    color: colors.black54,
    fontSize: 20,
  },
});

interface ActionElementProps {
  icon: IconProp;
  handleOnClick: () => void;
}

const ActionElement: React.FC<ActionElementProps> = ({ handleOnClick, icon }) => {
  const classes = useStyles();
  return (
    <IconButton onClick={() => handleOnClick()}>
      <FontAwesomeIcon icon={icon} className={classes.btnIcon} />
    </IconButton>
  );
};

export default ActionElement;
