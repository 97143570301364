import { useLazyQuery } from '@apollo/client';
import { GET_AGGREGATE_TITLES } from '../queries/aggregate';

export type GetLazyAggregateTitlesQuery = {
    [key: string]: Array<{
        id: string;
        title: string;
    }> | null;
};

export function useLazyGetAggregateTitles(aggregateType: string, aggregateQueryKey: string, aggregateLabelProperty: string) {
    return useLazyQuery<GetLazyAggregateTitlesQuery, {}>(
        GET_AGGREGATE_TITLES(aggregateType, aggregateQueryKey, aggregateLabelProperty),
        {
            fetchPolicy: 'network-only',
        }
    );
}