import { FC, useState, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLazyGetAggregateTitles } from 'graphql/hooks';
import debounce from 'lodash/debounce';

type WorkflowSimulatorModalProps = {
    aggregateType: string;
    aggregateQueryKey: string;
    aggregateLabelProperty: string;
    onRun: (aggregateId: string) => void;
    onClose: () => void;
};

const WorkflowSimulatorModal: FC<WorkflowSimulatorModalProps> = ({
                                                                     aggregateType,
                                                                     aggregateQueryKey,
                                                                     aggregateLabelProperty,
                                                                     onRun,
                                                                     onClose,
                                                                 }) => {
    const [aggregate, setAggregate] = useState<{
        id: string;
        [key: string]: string;
    } | null>(null);
    const [getAggregates, { loading, data: aggregates, error }] = useLazyGetAggregateTitles(
        aggregateType,
        aggregateQueryKey,
        aggregateLabelProperty
    );

    const debouncedGetAggregates = useMemo(() => debounce(getAggregates, 300), [getAggregates]);

    return (
        <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Simulate</DialogTitle>
            <DialogContent>
                <Autocomplete
                    style={{ width: 400 }}
                    options={aggregates?.[aggregateQueryKey] || []}
                    getOptionLabel={(option) => option[aggregateLabelProperty]}
                    loading={loading}
                    value={aggregate}
                    onChange={(_e, value) => {
                        setAggregate(value);
                    }}
                    onInputChange={(_e, newInputValue, reason) => {
                        if (reason === 'input' && newInputValue.length >= 3) {
                            debouncedGetAggregates({
                                variables: {
                                    filter: {
                                        [aggregateLabelProperty]: {
                                            includesInsensitive: newInputValue,
                                        },
                                    },
                                },
                            });
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Target Aggregate"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                            error={!!error}
                            helperText={!!error ? error.message : null}
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    disabled={!aggregate}
                    onClick={() => aggregate && onRun(aggregate.id)}
                    color="primary"
                >
                    Run
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WorkflowSimulatorModal;