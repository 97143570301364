import * as React from 'react';
import { ReactComponent as CloseIcon } from '../../icons/CloseIcon.svg';
import {
  NodeFrame,
  NodeTitleBox,
  NodeTitle,
  NodeCollapseArea,
  NodeDescriptionBox,
  NodeDescription,
} from './NodeComponents';
import { ReactComponent as ExpandLessIcon } from '../../icons/ExpandLessIcon.svg';
import { ReactComponent as ExpandMoreIcon } from '../../icons/ExpandMoreIcon.svg';
import { TinyTextInput } from '../../InputControls/TinyTextInput';
import { useState } from 'react';
import { colors } from 'utils/colors';

const style = {
  nodeFrame: {
    background: colors.white,
  },
  nodeFrameConatiner: {
    position: 'absolute' as const,
    top: '-24px',
    right: '-24px',
    width: 24,
    height: 24,
    cursor: 'pointer' as const,
  },
  closeIcon: {
    color: colors.black54,
  },
  tinytextInput: {
    fontSize: 14,
    width: '100%',
  },
  tinyTextFont: {
    fontSize: 12,
    fontWeight: 400,
    width: '100%',
    paddingLeft: 13,
  },
};
interface TransformNodeWidgetProps {
  title: string;
  onTitleChange?: (newTitle: string) => void;
  onDescriptionChange?: (newTitle: string) => void;
  onConfigureClicked?: () => void;
  onOkConfigClicked?: () => void;
  onCancelConfigClicked?: () => void;
  onRemoveClicked?: () => void;
  onCollapseClicked?: () => void;

  description?: string;
  configMode?: boolean;
  isConfigurable?: boolean;
  isRemovable?: boolean;
  isTitleEditable?: boolean;
  isSelected?: boolean;
  isCollapsible?: boolean;
  isCollapsed?: boolean;
  children?: React.ReactNode;
  categoryColor?: string;
}

export const TransformNodeWidget: React.FunctionComponent<TransformNodeWidgetProps> = (props) => {
  const {
    title,
    description,
    onTitleChange,
    onDescriptionChange,
    onRemoveClicked,
    onCollapseClicked,
    isRemovable,
    isSelected,
    isTitleEditable,
    isCollapsible,
    isCollapsed,
    children,
    onOkConfigClicked,
    categoryColor,
  } = props;
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [blur, setBlur] = useState(false);
  const placeholderColor = colors.black35;
  const getTitleBoxBackground = () => {
    const { dimGray10 } = colors;
    const bgColor = categoryColor || dimGray10;
    return { background: bgColor };
  };
  const styleObject = { ...style.tinytextInput, background: `${colors.white20}` };
  const descStyle = { ...style.tinyTextFont, color: `${categoryColor}` };

  return (
    <NodeFrame isSelected={isSelected} style={style.nodeFrame}>
      {isSelected && isRemovable && (
        <div onClick={onRemoveClicked} style={style.nodeFrameConatiner}>
          <CloseIcon fill={colors.white} style={style.closeIcon} />
        </div>
      )}
      <NodeTitleBox style={getTitleBoxBackground()}>
        {isCollapsible && (
          <NodeCollapseArea onClick={onCollapseClicked}>
            {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </NodeCollapseArea>
        )}
        {!titleEditMode && (
          <NodeTitle onDoubleClick={() => setTitleEditMode(true)}>{title}</NodeTitle>
        )}

        {titleEditMode && isTitleEditable && (
          <NodeTitle isTitle={titleEditMode}>
            <TinyTextInput
              value={title}
              autoFocus
              onChange={(val) => onTitleChange && onTitleChange(val as string)}
              style={styleObject}
              onBlur={() => {
                setTitleEditMode(false);
                onOkConfigClicked && onOkConfigClicked();
              }}
            />
          </NodeTitle>
        )}
      </NodeTitleBox>
      {!isCollapsed && (
        <NodeDescriptionBox>
          {isTitleEditable && (
            <NodeDescription
              isDesc={blur}
              onDoubleClick={() => setBlur(true)}
              color={categoryColor}
            >
              <TinyTextInput
                isDescription
                value={description}
                onChange={(val) => onDescriptionChange && onDescriptionChange(val as string)}
                style={descStyle}
                placeholder="No description to display"
                placeholderColor={placeholderColor}
                onBlur={() => {
                  setBlur(false);
                  onOkConfigClicked && onOkConfigClicked();
                }}
                isDescriptionFocus
              />
            </NodeDescription>
          )}
        </NodeDescriptionBox>
      )}
      {children}
    </NodeFrame>
  );
};
