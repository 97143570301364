import { Box, Divider, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import TabPanel from '../../TabPanel';
import { AggregateConfig, LocationProperty } from '@terragotech/gen5-config-lib';
import { getPropertiesArray } from '../../../../../../utils/Utils';
import { AggregateInfoValueProp, LocationTypeProp } from '../../../Settings';
import { TextInputTG, TGIosSwitch, TGSelect } from '../../../../../components/formElements';
import _ from 'lodash';

type PrimaryLocationTypeProps = 'GEOGRAPHY' | 'LATLON';
interface Props {
  tabValue: number;
  aggrInfo: Partial<AggregateConfig>;
  primaryLocationType: PrimaryLocationTypeProps;
  showMeasurement?: boolean;
  setPrimaryLocationType: (val: PrimaryLocationTypeProps) => void;
  handlePrimaryLocationChange: (value: string, type: LocationTypeProp) => void;
  handleMeasurementChange: (value: boolean) => void;
  aggregateInfoOnChange: (key: string, value: AggregateInfoValueProp) => void;
}
export const GeneralTab = ({
  tabValue,
  aggrInfo,
  primaryLocationType,
  showMeasurement,
  setPrimaryLocationType,
  handlePrimaryLocationChange,
  handleMeasurementChange,
  aggregateInfoOnChange,
}: Props) => {
  const classes = useStyles();
  const primaryLocation = {
    'Point (Lat/Long)': 'LATLON',
    'Polyline (Geography)': 'GEOGRAPHY',
  };
  const invertedObject = _.invert(primaryLocation);
  const resultKey = invertedObject[primaryLocationType];
  return (
    <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
      <Grid container>
        <Grid item md={4}>
          <Box className={classes.leftSection}>
            <Typography className={classes.header1}>Property Naming Format</Typography>
          </Box>
        </Grid>
        <Grid item md={8} className={classes.gridItem}>
          <TextInputTG
            label="Name"
            value={aggrInfo.typeName}
            onChange={(val) => aggregateInfoOnChange('typeName', val as string)}
            containerStyle={classes.mb22}
          />
          <TextInputTG
            label="Singular Name"
            value={aggrInfo.singularName}
            onChange={(val) => aggregateInfoOnChange('singularName', val as string)}
            containerStyle={classes.mb22}
          />
          <TextInputTG
            label="Plural Name"
            value={aggrInfo.pluralName}
            onChange={(val) => aggregateInfoOnChange('pluralName', val as string)}
            containerStyle={classes.mb22}
          />
          <TGSelect
            id="Label Property"
            label="Label Property"
            value={aggrInfo.labelProperty as string}
            options={getPropertiesArray(aggrInfo.properties)}
            onChange={(value) => aggregateInfoOnChange('labelProperty', value as string)}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item md={4}>
          <Box className={classes.leftSection}>
            <Typography className={classes.header1}>Location</Typography>
            <Typography className={classes.para}></Typography>
          </Box>
        </Grid>
        <Grid item md={8} className={classes.gridItem}>
          <TGSelect
            id={'Type'}
            label="Type"
            value={resultKey}
            options={primaryLocation}
            onChange={(value) => setPrimaryLocationType(value as PrimaryLocationTypeProps)}
            containerStyle={classes.mb22}
          />
          {primaryLocationType === 'LATLON' ? (
            <>
              <TGSelect
                id={'Latitude'}
                label="Latitude"
                value={(aggrInfo.primaryLocationProperty as LocationProperty)?.latitude || ''}
                options={[...getPropertiesArray(aggrInfo.properties, 'Float')]}
                onChange={(value) => handlePrimaryLocationChange(value, 'latitude')}
                containerStyle={classes.selectContainer}
                type="Latitude"
                canShowEmpty
              />
              <TGSelect
                id={'Longitude'}
                label="Longitude"
                value={(aggrInfo.primaryLocationProperty as LocationProperty)?.longitude || ''}
                options={[...getPropertiesArray(aggrInfo.properties, 'Float')]}
                onChange={(value) => handlePrimaryLocationChange(value, 'longitude')}
                containerStyle={classes.selectContainer}
                type="Longitude"
                canShowEmpty
              />
            </>
          ) : (
            <>
              <TGSelect
                id={'Geography aggregate property'}
                type="Geography aggregate property"
                options={[
                  'Some Property',
                  ...getPropertiesArray(aggrInfo.properties, 'Geography'),
                  ...getPropertiesArray(aggrInfo.properties, 'AnchoredLine'),
                ]}
                value={
                  aggrInfo.primaryLocationProperty &&
                  typeof aggrInfo.primaryLocationProperty !== 'string'
                    ? ''
                    : (aggrInfo.primaryLocationProperty as string) || 'Some Property'
                }
                label="Geography aggregate property"
                onChange={(value) => handlePrimaryLocationChange(value, 'geography')}
              />
              <Typography data-shrink="true" className={classes.locationPropertyLabel}>
                Select the aggregate property that defines the location for this aggregate
              </Typography>
              <TGIosSwitch
                title="Show measurements on Polylines"
                description="Enabling this will show the length of each polyline in the selected unit of
                measurement"
                checked={showMeasurement || false}
                onChange={(checked) => handleMeasurementChange(checked)}
              />
            </>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container>
        <Grid item md={4}>
          <Box className={classes.leftSection}>
            <Typography className={classes.header1}>Advanced Options</Typography>
            <Typography className={classes.para}></Typography>
          </Box>
        </Grid>
        <Grid item md={8} className={classes.gridItem}>
          <TGIosSwitch
            title="Replay events on startup"
            description="Enabling this will we denounce with righteous indignation and dislike men who are so beguiled."
            checked={aggrInfo.replayEventsOnStartup || false}
            onChange={(checked) => aggregateInfoOnChange('replayEventsOnStartup', checked || false)}
          />
          <TGIosSwitch
            title="Is Reference Type (Project agnostic)"
            description="Enabling this will we denounce with righteous indignation and dislike men who are so beguiled."
            checked={aggrInfo.isReferenceType || false}
            onChange={(checked) => aggregateInfoOnChange('isReferenceType', checked || undefined)}
          />
        </Grid>
      </Grid>
    </TabPanel>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      paddingTop: '40px',
    },
    leftSection: {
      paddingRight: 30,
    },
    header1: { fontSize: 18, fontWeight: 500, color: colors.black, lineHeight: '100%' },
    para: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black75,
      lineHeight: '100%',
      marginTop: 7,
    },
    gridItem: {
      '& :last-child': {
        marginBottom: 0,
      },
    },
    inputFieldContainer: {
      display: 'flex',
      width: '100%',
      marginBottom: 20,
    },
    formControl: {
      width: '100%',
    },
    inputLabel: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -3px) scale(1)',
      },
    },
    divider: {
      margin: '40px 0',
    },
    locationPropertyLabel: {
      color: colors.oldSilver,
      fontSize: 13,
      marginBottom: 20,
    },
    showMeasurementContainer: {
      marginTop: 5,
    },
    mb22: {
      marginBottom: 22,
    },
    selectContainer: {
      marginBottom: 20,
    },
  })
);
