import { ListenerHandle } from '@projectstorm/react-canvas-core';
import { DefaultPortModel, DefaultPortModelOptions, PortModel } from '@projectstorm/react-diagrams';
import { NodeSource } from '@terragotech/gen5-datamapping-lib';
import { DataMapperNodeModel } from '../DiagramNodes/DataMapperNode/DataMapperNodeModel';
import SchemaDefinedPortModel from './SchemaDefinedPort';
export interface Gen5PortModelOptions extends DefaultPortModelOptions {
  fixedValue?: NodeSource;
}
export abstract class Gen5PortModel extends DefaultPortModel {
  protected fixedValue?: NodeSource;
  protected linkConnectionHandle: ListenerHandle;
  constructor(options: Gen5PortModelOptions) {
    super(options);
    this.fixedValue = options.fixedValue;
  }
  getConnectedPorts(): Gen5PortModel[] {
    // what are all of the ports connected to this port via links
    let connectedPorts: Gen5PortModel[] = [];
    const links = this.getLinks();
    Object.keys(links).forEach((linkKey) => {
      const sourcePort = links[linkKey].getSourcePort();
      const targetPort = links[linkKey].getTargetPort();
      connectedPorts = [
        ...connectedPorts,
        ...[sourcePort as Gen5PortModel, targetPort as Gen5PortModel].filter(
          (port) => port !== this
        ),
      ];
    });
    return connectedPorts;
  }
  getSingleConnectedPort(): Gen5PortModel | undefined {
    const ports = this.getConnectedPorts();
    if (ports && ports.length > 0) {
      return ports[0];
    }
  }

  abstract validateConnection(port: PortModel, twoWayValidation?: boolean): boolean;

  isInputPortFree(port: Gen5PortModel): boolean {
    return port.getOptions().in
      ? Object.keys(port.links).length < 1 ||
          Object.values(port.links).every((link) => link.getTargetPort() === null)
      : true;
  }

  canLinkToPort(port: Gen5PortModel): boolean {
    if (this.isInputPortFree(port)) {
      return this.validateConnection(port, true);
    }
    return false;
  }
  public isFixedValueAllowed(): boolean {
    return true;
  }
  public updateFixedValue(val: string): void {}

  setFixedValue(value: NodeSource | undefined): void {
    this.fixedValue = value;
    const node = this.getNode() as DataMapperNodeModel;
    if (node) {
      //TODO: This is terrible. Seriously needs a refactor
      node.updateInputFixedValue((this as unknown) as SchemaDefinedPortModel);
    }
  }
  getFixedValue(): NodeSource {
    return this.fixedValue;
  }
  getLabel(): string | undefined {
    return this.options.label;
  }
}
