import { Box, Grid, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { TGColorPicker, TGIosSwitch, TGSelect, TextInputTG } from './formElements';
import { CommonSettings } from '../pages/ui/components/settings/commonTypes';
import _ from 'lodash';

const RowItem = (props: CommonSettings) => {
  const classes = useStyles();
  const { title, description, components } = props;
  return (
    <Grid container>
      <Grid item md={4}>
        <Box className={classes.leftSection}>
          <Typography className={classes.header1}>{title}</Typography>
          <Typography className={classes.para}>{description}</Typography>
        </Box>
      </Grid>
      <Grid item md={8} className={classes.gridItem}>
        {components.map((comp) => {
          return (
            <Box key={comp.key} className={classes.childContainer}>
              {comp.type === 'textInput' && (
                <TextInputTG
                  label={comp.label}
                  value={comp.value}
                  id={comp.label}
                  onChange={comp.action}
                  type={comp.textInputType}
                />
              )}
              {comp.type === 'iosSwitch' && (
                <TGIosSwitch
                  title={comp.title}
                  description={comp.description}
                  checked={comp.checked || false}
                  onChange={(checked) => comp.action(checked)}
                  containerClass={comp.container}
                  otherComponent={
                    <>
                      {comp.title === 'Enable Legal Disclaimer' && comp.checked ? (
                        <TextInputTG
                          value={comp?.textAreaValue}
                          className={classes.textArea}
                          onChange={comp?.onChangeTextArea}
                          multiline
                          minRows={3}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  }
                />
              )}
              {comp.type === 'header' && (
                <Typography id={comp?.label} className={classes.headerText}>
                  {comp?.label}
                </Typography>
              )}
              {comp.type === 'select' && !comp.isHide && (
                <>
                  <TGSelect
                    id={comp?.label}
                    label={comp.label}
                    value={comp.value as string}
                    options={comp?.options || []}
                    onChange={(value) => comp.action(value as string)}
                  />
                  {_.size(comp?.info) > 0 && (
                    <Typography className={classes.infoMessage}>{comp?.info}</Typography>
                  )}
                </>
              )}
              {comp.type === 'colorPicker' && (
                <TGColorPicker
                  title={comp.title}
                  value={comp.value as string}
                  summary={comp.description}
                  onChange={(color: string) => comp.action(color)}
                />
              )}
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftSection: {
      paddingRight: 30,
    },
    header1: {
      fontSize: 18,
      fontWeight: 500,
      color: colors.black,
      marginBottom: 7,
      lineHeight: '100%',
    },
    para: { fontSize: 15, fontWeight: 400, color: colors.black75, lineHeight: '100%' },
    gridItem: {
      '& :last-child': {
        marginBottom: 0,
      },
    },
    childContainer: {
      marginBottom: 25,
    },
    headerText: {
      fontSize: 16,
      fontWeight: 500,
      color: colors.black,
      marginTop: 32,
    },
    infoMessage: {
      color: colors.black60,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      marginTop: 6,
    },
    textArea: {
      marginTop: 18,
      '& .MuiInputBase-root': {
        padding: '15px 6px 17px 6px',
      },
    },
  })
);

export default RowItem;
