import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: 20,
      fontWeight: 500,
      color: colors.black,
      marginBottom: 33,
    },
    selectContainer: {
      '& .MuiInputBase-multiline': {
        marginTop: 23,
        padding: 0,
        marginBottom: 26,
      },
      '& .MuiInputLabel-outlined': {
        fontSize: 15,
        fontWeight: 500,
      },
      '& .MuiInputLabel-shrink': {
        transform: 'translate(0, 0) scale(1)',
      },
    },
    baseLineFlex: {
      display: 'flex',
      alignItems: 'baseline',
    },
    cardContainer: { height: 70 },
    bottom0: {
      marginBottom: '0px !important',
    },
    bottom10: {
      marginBottom: '10px !important',
    },
    bottom20: {
      marginBottom: '20px !important',
    },
    bottom30: {
      marginBottom: '30px !important',
    },
    bottom42: {
      marginBottom: 32,
    },
    label: {
      fontSize: 15,
      fontWeight: 500,
      color: colors.black,
      lineHeight: '100%',
    },
    itemContainer: {
      marginBottom: 30,
    },
    checkBoxContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingTop: 3,
      gap: '10px 113px',
    },
    mapButtons: {
      display: 'flex',
      marginTop: 12,
    },
    mapperBtn: {
      width: '138px',
      height: '36px',
      borderRadius: '5px',
      background: colors.black5,
      boxShadow: `0px 2px 4px 0px ${colors.black15}`,
      fontSize: 15,
      fontWeight: 400,
    },
    deleteBtn: {
      minWidth: 0,
      padding: 0,
      marginLeft: 15,
    },
    deleteIcon: {
      width: '18px',
      height: '20px',
      color: colors.black54,
    },
    firstMapper: {
      marginTop: 12,
    },
    secondMapper: {
      marginTop: '16px !important',
    },
    mt0: {
      marginTop: 0,
    },
  })
);
