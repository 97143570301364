import { AbstractReactFactory } from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AllNodeMap, BaseNode } from '@terragotech/gen5-datamapping-lib';

import {
  createBasicNodeFactoryClass,
  NodeExternalLookups,
} from './DataMapperNode/DataMapperNodeFactory';
import { DataMapperNodeModel } from './DataMapperNode/DataMapperNodeModel';

interface TransformNodeDef {
  [index: string]: AbstractReactFactory<DataMapperNodeModel, DiagramEngine>;
}
//TODO: Update this to use only the keys available in a given map scenario
const basicNodeFactoryBuilder = (lookups: NodeExternalLookups): TransformNodeDef => {
  return Object.keys(AllNodeMap).reduce((acc, nodeKey) => {
    const definition = (AllNodeMap as any)[nodeKey];
    return {
      ...acc,
      [nodeKey]: definition
        ? createBasicNodeFactoryClass({ nodeDef: definition as typeof BaseNode, lookups: lookups })
        : undefined,
    };
  }, {});
};

export const buildNodeFactories = (lookups: NodeExternalLookups): TransformNodeDef => {
  return { ...basicNodeFactoryBuilder(lookups) };
};
