import { JSONSchema6 } from 'json-schema';
import * as React from 'react';
import { PropertyCollection } from '../PropertyCollection';
import { ConfigProperty } from './ConfigPropertyComponents';
import styled from 'styled-components';

const buildNewEntryFromSchema = (schema: JSONSchema6): unknown => {
  return [];
};

const FramedBox = styled.div`
  display: flex;
  width: 100%;
  margin-left: 10px;
  flex-direction: column;
`;

export interface ConfigPropertySectionProps {
  configSchema: JSONSchema6;
  configValues: Record<string, unknown>;
  configMode: boolean;
  onConfigValueChange: (configKey: string, newVal: unknown) => void;
}

const ConfigPropertySection: React.FunctionComponent<ConfigPropertySectionProps> = (props) => {
  const { configSchema, configValues, configMode, onConfigValueChange } = props;
  // read through all the keys of the config schema and present configProperties for each
  return (
    <FramedBox>
      {Object.keys(configSchema?.properties || []).map((key) => {
        const schemaProp = (configSchema.properties as any)[key] as JSONSchema6;
        //if the current property is an array, we need to make this a collection of its child types
        //TODO: consider making this recursive, but it's probably not necessary for a long time
        if (schemaProp.type === 'array') {
          //we'll need to wrap in our collection frame
          const currentValues = configValues ? (configValues[key] as unknown[]) : [];

          return (
            <PropertyCollection
              key={key}
              configMode={configMode}
              label={schemaProp.description || ''}
              onAddClick={() => {
                onConfigValueChange(
                  key,
                  (currentValues || []).concat([
                    buildNewEntryFromSchema(schemaProp.items as JSONSchema6),
                  ])
                );
              }}
              collectionLength={(currentValues || []).length || 0}
            >
              {currentValues &&
                currentValues.map((val, index) => {
                  return (
                    <ConfigProperty
                      key={index}
                      isEditing={configMode}
                      value={currentValues ? currentValues[index] : undefined}
                      showLabel={true}
                      onChange={(val) =>
                        onConfigValueChange(key, [
                          ...currentValues.slice(0, index),
                          val,
                          ...currentValues.slice(index + 1),
                        ])
                      }
                      onRemove={() => {
                        onConfigValueChange(key, [
                          ...currentValues.slice(0, index),
                          ...currentValues.slice(index + 1),
                        ]);
                      }}
                      schemaType={schemaProp.items as JSONSchema6}
                    />
                  );
                })}
            </PropertyCollection>
          );
          // handle add and remove
          // and cycle through all current values
        } else {
          return (
            <ConfigProperty
              key={key}
              isEditing={configMode}
              value={configValues ? String(configValues[key]) : undefined}
              showLabel={true}
              onChange={(val) => onConfigValueChange(key, val)}
              schemaType={schemaProp}
            />
          );
        }
      })}
    </FramedBox>
  );
};
export default ConfigPropertySection;
