import * as React from 'react';
import { useConfig } from '../../context/ConfigContext';
import { Box, Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import JSONTree from 'react-json-tree';
import { colors } from '../../utils/colors';
import { useState } from 'react';
import { HEADER_HEIGHT } from 'utils/Utils';

const TITLE = 'JSON Overview';
const EXPAND_ALL = 'Expand All';
const COLLAPSE_ALL = 'Collapse All';
const PADDING_HEIGHT = 20;

export const Overview: React.FC = () => {
  const { config } = useConfig();
  const classes = useStyles();
  const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography className={classes.title}>{TITLE}</Typography>
        <Box>
          <Button className={classes.button} onClick={() => setExpandAll(true)}>
            {EXPAND_ALL}
          </Button>
          <Button
            className={`${classes.button} ${classes.buttonPadding}`}
            onClick={() => setExpandAll(false)}
          >
            {COLLAPSE_ALL}
          </Button>
        </Box>
      </Box>
      <Box className={classes.jsonContainer}>
        <JSONTree
          data={cloneDeep(config)}
          shouldExpandNode={(_, __, level) =>
            expandAll === undefined ? level < 4 : expandAll
          }
          hideRoot
          theme={jsonTreeTheme}
          invertTheme={false}
        />
      </Box>
    </Box>
  );
};

const jsonTreeTheme = {
  base00: colors.white,
  base09: colors.redLight,
  base0B: colors.delGreen,
  base03: colors.doveGray,
  base0D: colors.blueBorder,
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: colors.black1,
      width: '100%',
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      overflowY: 'hidden',
    },
    header: {
      display: 'flex',
      margin: '40px 50px 0px 50px',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    title: {
      color: colors.black87,
      fontSize: 24,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
      borderRadius: 5,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.greyBorder,
      background: colors.white,
      color: colors.greyText,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      width: 120,
      height: 40,
    },
    jsonContainer: {
      backgroundColor: colors.white,
      height: `calc(100% - ${HEADER_HEIGHT + PADDING_HEIGHT}px)`,
      margin: '10px 50px 0px 50px',
      paddingLeft: 20,
      paddingTop: 10,
      display: 'flex',
      overflowX: 'hidden',
      overflowY: 'auto',
      flexDirection: 'column',
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
    },
    buttonPadding: {
      marginLeft: 8,
    },
  })
);
