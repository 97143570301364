import { Box } from '@material-ui/core';
import useCommonStyles from 'views/useCommonStyles';
import { Outlet } from 'react-router-dom';

const RecordsUI = () => {
  const commonClasses = useCommonStyles();
  return (
    <Box className={commonClasses.container}>
      <Outlet  />
    </Box>
  );
};
export default RecordsUI;
