import { Property, AggrPropertyRow } from '../../../utils/types';
import { cloneDeep } from 'lodash';
import { migrateGeoGraphyUiType } from './MigrationUtils';

export const aggregateTableRowToProperty = (row: AggrPropertyRow): Property => {
  const property = cloneDeep(migrateGeoGraphyUiType(row));
  const { name } = row;
  //@ts-ignore
  delete property.name;
  return { [name]: property };
};
