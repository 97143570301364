import { camelCase } from 'change-case';
import { ValidOptions } from '../pages/aggregates/components/ValidOptionsCustomInput';

interface Obj {
  [key: string]: string | string[] | boolean | number | undefined | ValidOptions;
}

export const objectKeysToLowerCase = (object: Obj): Obj =>
  Object.keys(object).reduce(
    (prev, current) => ({ ...prev, [camelCase(current.toLowerCase())]: object[current] }),
    {}
  );
