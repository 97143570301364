import { IconButton } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import React, { CSSProperties, useRef, useState } from 'react';
import { colors } from 'utils/colors';

interface FullScreenProps {
  children: React.ReactNode;
  zoomButton?: React.CSSProperties;
}

let styleBeforeFullScreen: object;

const openStyle = {
  transitionProperty: 'height, width, left, top',
  transitionDuration: '0.4s',
  transitionTimingFunction: 'ease-out',
  position: 'absolute',
  zIndex: 1300,
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
};

const style: { [key: string]: CSSProperties } = {
  zoomButton: {
    position: 'absolute' as const,
    left: 29,
    top: 25,
    zIndex: 99,
  },
  iconColor: {
    color: colors.philippineGray10,
    width: 30,
    height: 30,
  },
};

export const FullScreen: React.FC<FullScreenProps> = ({ children, zoomButton = {} }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenDivStyle, setFullScreenDivStyle] = useState<object>({
    height: '100%',
    width: '100%',
    position: 'relative',
  });
  const ref = useRef<HTMLInputElement>(null);

  const handleFullScreen = () => {
    if (ref?.current) {
      styleBeforeFullScreen = {
        position: 'absolute',
        transitionProperty: 'height, width, left, top',
        transitionDuration: '0.4s',
        transitionTimingFunction: 'ease-out',
        height: ref.current.offsetHeight,
        left: ref.current.offsetLeft,
        top: ref.current.offsetTop,
        width: ref.current.offsetWidth,
        zIndex: 1300,
      };
      setFullScreenDivStyle(styleBeforeFullScreen);
      setTimeout(() => {
        setFullScreenDivStyle(openStyle);
      }, 0);
    }
    setIsFullScreen(true);
  };
  const handleExitFullScreen = () => {
    setFullScreenDivStyle(styleBeforeFullScreen);
    setTimeout(() => {
      setFullScreenDivStyle({
        height: '100%',
        width: '100%',
        position: 'relative',
      });
      setIsFullScreen(false);
    }, 400);
  };

  return (
    <div style={fullScreenDivStyle} ref={ref}>
      {isFullScreen ? (
        <IconButton
          onClick={handleExitFullScreen}
          style={{ ...style.zoomButton, ...zoomButton }}
          title="Exit full-screen mode"
        >
          <FullscreenExitIcon style={style.iconColor} />
        </IconButton>
      ) : (
        <IconButton
          onClick={handleFullScreen}
          style={{ ...style.zoomButton, ...zoomButton }}
          title="Full-screen mode"
        >
          <FullscreenIcon style={style.iconColor} />
        </IconButton>
      )}
      {children}
    </div>
  );
};
