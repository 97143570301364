import { AggrPropertyRow } from '../../../utils/types';
import { PropertyCollection } from '@terragotech/gen5-config-lib';

export const propertiesArrtoObj = (propArr: AggrPropertyRow[]) => {
  const obj = {} as PropertyCollection;
  propArr.forEach((item) => {
    // object doesn't need name in attributes, since it's the key, nor does it need the UI-only order value
    const { name, order, ...newObject } = item;
    obj[name] = newObject;
  });
  return obj;
};
