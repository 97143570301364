import React, { useEffect, useState } from 'react';
import { ColorPickerDialog } from '../../../components/FormDialog/ColorPickerDialog';
import {
  Gen5ColorAttribute,
  Gen5ColorDefinition,
  Gen5ColorMap,
} from '@terragotech/gen5-config-lib/dist/Gen5ColorDefinition';
import { ColorPicker } from 'components/FormElements';
import _ from 'lodash';

export interface Gen5ColorPickerProps {
  color: Gen5ColorDefinition | undefined;
  setColor?: (value: Gen5ColorDefinition | undefined) => void;
  aggregates?: object | string[];
  showColor?: boolean;
}
const styles = {
  root: {
    marginRight: 10,
  },
  title: { marginTop: 5, fontSize: 14 },
  value: { margin: 0, fontSize: 14 },
  picker: { paddingTop: 7, paddingBottom: 7, paddingLeft: 10, width: 50 },
  colorKey: { margin: 0 },
};

export const Gen5ColorPicker: React.FC<Gen5ColorPickerProps> = ({
  color,
  setColor,
  aggregates,
  showColor,
}) => {
  const handleCloseColorPicker = () => {};
  const [aggregate, setAggregate] = useState<object | string[] | undefined>(aggregates);
  const type = () => {
    if (color) {
      if (typeof color === 'string') return 'string';
      if (typeof color === 'object' && color.hasOwnProperty('mapping')) return 'map';
      if (typeof color === 'object') return 'object';
    }
    return 'unknown';
  };
  const getMap = () => (
    <>
      {(color as Gen5ColorMap).colorKey && (
        <p style={styles.title}>Color Key: {(color as Gen5ColorMap).colorKey}</p>
      )}
      <table>
        {(color as Gen5ColorMap).mapping.map((row) => (
          <tr>
            <td>
              <p style={styles.value} key={row.value}>
                {row.value}:
              </p>
            </td>
            <td style={styles.picker}>
              <ColorPicker value={row.hexColor} disabled onlyColorEnabled />
            </td>
          </tr>
        ))}
      </table>
    </>
  );

  const getObject = () => (
    <p style={styles.colorKey}>{(color as Gen5ColorAttribute).hexColorKey}</p>
  );

  const getString = () => <ColorPicker value={color as string} disabled onlyColorEnabled />;

  const getColor = () => {
    switch (type()) {
      case 'map':
        return getMap();
      case 'string':
        return getString();
      case 'object':
        return getObject();
      default:
        return null;
    }
  };

  const handleSetColor = (color: Gen5ColorDefinition | undefined) => {
    if (setColor) {
      setColor(color);
      handleCloseColorPicker();
    }
  };
  useEffect(() => {
    if (!_.isUndefined(aggregates)) {
      const removeEmptyValue = _.pickBy(aggregates, _.identity);
      setAggregate(removeEmptyValue);
    }
  }, [aggregates]);

  return (
    <div>
      {getColor() && showColor && <div style={styles.root}>{getColor()}</div>}
      {setColor && aggregates && (
        <>
          <ColorPickerDialog
            aggregates={aggregate as object | string[]}
            color={color}
            open
            onSetColor={handleSetColor}
            onClose={handleCloseColorPicker}
          />
        </>
      )}
    </div>
  );
};
