import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { V2TextHeaderComponent } from '@terragotech/form-renderer';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame } from './Common';
import { colors } from 'utils/colors';
import { FormBadges } from './FormBadge';

export const HeaderText = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  text-align: left;
  margin-left: 18px;
  color: ${colors.black};
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const TextHeader: FunctionComponent<NestedFormControlProps<V2TextHeaderComponent>> = (props) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <HeaderText>{controlDefinition.label}</HeaderText>
      <FormBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default TextHeader;
