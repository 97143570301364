import { Gen5Config } from '@terragotech/gen5-config-lib';
export interface MenuItemDefinition {
  text: string;
  link: string;
}
export interface MenuGroupDefinition {
  title: string;
  items: MenuItemDefinition[];
}

export const menuGroups = [
  {
    title: '',
    items: [{ text: 'JSON Overview', link: '/' }],
  },
  {
    title: 'Aggregate Data Modeling',
    items: [
      { text: 'Aggregates', link: '/aggregates' },
      { text: 'Functions', link: '/functions' },
    ],
  },
  {
    title: 'UI Customization',
    items: [
      { text: 'Mobile UI', link: '/mobileUI/general' },
      { text: 'Web UI', link: '/webUI/general' },
      { text: 'Custom Pages', link: '/customPages' },
    ],
  },
  {
    title: 'Features',
    items: [
      { text: 'General', link: '/features/general/legalDisclaimer' },
      { text: 'Integrations', link: '/features/integrations/mapOptions' },
    ],
  },
];

export const getPathToLevel = (path: string, level: number) => {
  const result = path
    .split('/')
    .slice(0, level + 1)
    .join('/');
  return result;
};

export const getAggregateIndex = (config: Gen5Config, aggrName: string | undefined) =>
  config.aggregates.findIndex((aggregate) => aggregate.typeName === aggrName);

export const getAggregateName = (config: Gen5Config, aggrIndex: number) =>
  config.aggregates[aggrIndex].typeName;

export const getExpandedURLArr = (url: string) =>
  url
    .split('/')
    .slice(1)
    .map((_, i, initialArray) => '/' + initialArray.slice(0, i + 1).join('/'));
