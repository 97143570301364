import { Box, Button, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'utils/colors';
import useCommonStyles from 'views/useCommonStyles';
interface RecordsHeaderProps {
  title: string;
  onAdd: () => void;
}

const RecordsHeader = ({ title, onAdd }: RecordsHeaderProps) => {
  const classes = useStyles();
  const useClasses = useCommonStyles();
  return (
    <Box className={useClasses.titleBarContainer}>
      <Typography className={classes.headerText}>{title}</Typography>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={onAdd}
        className={classes.button}
      >
        Add
      </Button>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    button: {
      width: 110,
      fontSize: 16,
      fontWeight: 500,
    },
    headerText: {
      fontSize: 24,
      fontWeight: 500,
      color: colors.black,
    },
  })
);

export default RecordsHeader;
