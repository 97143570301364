export const eventEditorSchema = {
  type: 'object',
  properties: {
    type: {
      type: 'string',
      enum: ['projectCreated'],
    },
    version: {
      type: 'number',
    },
    timestamp: {
      description: 'Timestamp',
      type: 'string',
      format: 'date-time',
    },
    aggregateId: {
      type: 'string',
    },
    data: {
      type: 'object',
      properties: {
        description: {
          $id: '#/properties/Lon',
          type: 'number',
        },
        title: {
          $id: '#/properties/Tags',
          type: 'string',
        },
        createdBy: {
          $id: '#/properties/Label',
          type: 'string',
        },
      },
    },
  },
};
