import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';

export interface DemoCanvasWidgetProps {
  color?: string;
  background?: string;
  children: React.ReactNode;
}

export const Container = styled.div<{ color: string; background?: string }>`
  height: 100%;
  background-color: ${(p) => (p.background ? p.background : colors.white)};
  background-size: 50px 50px;
  display: flex;
  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
  background-image: radial-gradient(${colors.chineseSilver} 1px, transparent 0);
  background-size: 50px 50px;
  background-position: -19px -19px;
`;

export class CanvasBackground extends React.Component<DemoCanvasWidgetProps> {
  render() {
    return (
      //@ts-ignore
      <Container background={this.props.background} color={this.props.color}>
        {this.props.children}
      </Container>
    );
  }
}
