import { V2FormTemplate } from '@terragotech/form-renderer';
import { JSONSchema6 } from 'json-schema';
import { convertV2FormTemplateToJsonSchema } from './V2FormTemplateToJsonSchema';

export const convertV2FormTemplateToCommandDataSchema = (
  v2FormTemplate: V2FormTemplate
): JSONSchema6 => {
  const outputSchema: JSONSchema6 = {
    type: 'object',
    properties: {
      timestamp: { type: 'string', format: 'date-time' },
      type: { type: 'string' },
      source: { type: 'string' },
      version: { type: 'number' },
      metadata: { type: 'object' },
      eventId: { type: 'string' },
      target: { type: 'string' },
      aggregateType: { type: 'string' },
      data: convertV2FormTemplateToJsonSchema(v2FormTemplate),
    },
  };
  return outputSchema;
};
