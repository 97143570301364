import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  makeStyles,
  Theme,
  createStyles,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowDropDown } from '@material-ui/icons';
import prettier from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApolloQueryResult, QueryResult, useQuery } from '@apollo/client';
import { colors } from 'utils/colors';
import logo from 'images/logo.png';
import { ConfigContext } from 'context/ConfigContext';
import { GET_RAW_CONFIG } from 'graphql/queries/config';
import { migrateInputJson } from 'pages/aggregates/utils/MigrationUtils';
import { configExample } from 'utils/configExample';
import {
  MAIN_MENU,
  MENU_BUTTONS,
  HEADER_HEIGHT,
  UI_ROUTE,
  RECORDS_ROUTE,
  UI_SETTINGS_LEFT_MENU,
  ROOT_RECORDS_ROUTE,
  SHARED_ROUTE,
  CONFIRMATION,
} from '../../../utils/Utils';
import packageJson from '../../../../package.json';
import Avatar from '@material-ui/core/Avatar';
import { useAuthContext } from '@terragotech/gen5-shared-components';
import { chain, get, toLower, isUndefined, first, capitalize } from 'lodash';
import { StorageHelper, StorageKeys } from 'utils/StorageManagement';
import { middleTruncate } from '@terragotech/gen5-shared-utilities';
import { useConfirmDialog } from 'context/ConfirmContext';
import { usePageHistory } from 'context/HistoryContext';

type ConfigQueryResult = {
  gen5Config: { raw: string };
};

const isResultAString = (result: string | ArrayBuffer | null | undefined): result is string =>
  !!result && typeof result === 'string';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const inputFile = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const [jsonanchorEl, setJsonAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const { config, getAggregates, getFunctions, configName, setConfig, setConfigName } = useContext(
    ConfigContext
  );
  const { givenName, email, clearToken } = useAuthContext();

  const { openConfirmation } = useConfirmDialog();

  const packageJsonFile = packageJson as unknown;
  const result = useQuery<ConfigQueryResult, {}>(GET_RAW_CONFIG(), {
    fetchPolicy: 'network-only',
  });
  const { pageSelectionHistory, setPageSelectionHistory, storeData } = usePageHistory();

  const aggregate = useMemo(() => {
    const [firstAggregate] = getAggregates();
    return firstAggregate?.typeName || '';
  }, [getAggregates]);

  const sharedFunction = useMemo(() => {
    const [firstFuction] = chain(getFunctions())
      .entries()
      .map(([name, value]) => ({ name, ...value }))
      .orderBy((o) => toLower(o.name))
      .value();
    return firstFuction;
  }, [getFunctions]);

  const recordsInitialPath = `${ROOT_RECORDS_ROUTE}${aggregate}`;
  const sharedUtilsInitalPath = sharedFunction
    ? `${SHARED_ROUTE}/${sharedFunction?.name}/version/${sharedFunction?.versions?.[0]?.versionNumber}/internalText`
    : SHARED_ROUTE;
  const loadData = useCallback(
    (newData: QueryResult<ConfigQueryResult, {}> | ApolloQueryResult<ConfigQueryResult>) => {
      if (newData.data?.gen5Config.raw) {
        const fileJson = JSON.parse(newData.data.gen5Config.raw);
        setConfig(migrateInputJson(fileJson));
        setConfigName('gen5Config.json');
      }
    },
    [setConfig, setConfigName]
  );

  const loadConfigJson = useCallback(
    (skipRefetch?: boolean): void => {
      navigate('/');
      if (skipRefetch) {
        loadData(result);
      } else {
        result.refetch().then(loadData);
      }
    },
    [navigate, result, loadData]
  );

  useEffect(() => {
    const configData = StorageHelper.getByKey(StorageKeys.config);
    if (!configData && result) {
      loadConfigJson(true);
    }
  }, [result, loadConfigJson]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setJsonAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setJsonAnchorEl(null);
  };

  const isItemSelected = (link: string) => {
    return location.pathname.startsWith(link);
  };

  const goTo = (path: string) => {
    let finalPath = path;
    const nextPageType = path.replace(/\//, '');
    const lastPath = pageSelectionHistory[nextPageType] || '';
    switch (path) {
      case RECORDS_ROUTE:
        finalPath = lastPath || recordsInitialPath;
        break;
      case UI_ROUTE:
        finalPath = lastPath || get(UI_SETTINGS_LEFT_MENU, '[0].path', UI_ROUTE);
        break;
      case SHARED_ROUTE:
        finalPath = lastPath || sharedUtilsInitalPath;
        break;
      default:
    }
    const currentPath = location.pathname;
    const matches = currentPath.match(/\/(.+?)(\/.*)/);

    if (matches) {
      const [, pageType] = matches;

      setPageSelectionHistory((config) => {
        const data = { ...config, [pageType]: currentPath };
        storeData(data);
        return data;
      });
    }

    navigate(finalPath);
  };

  const onGenerateJson = () => {
    const json = prettier.format(
      JSON.stringify({ ...config, version: (packageJsonFile as { version: string }).version }),
      {
        parser: 'json',
        plugins: [parserBabel],
      }
    );
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = configName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const loadJson = (e: React.ChangeEvent<HTMLInputElement>): void => {
    navigate('/');
    if (!e) {
      alert('Failed to load file');
      return;
    }
    const f = e.target?.files?.[0];
    if (f) {
      const fr = new FileReader();
      fr.onload = (ev) => {
        try {
          setConfigName(f.name);
          const x = ev.target?.result;
          if (isResultAString(x)) {
            const fileJson = JSON.parse(x);
            setConfig(migrateInputJson(fileJson));
          }
        } catch (ex) {
          alert('Failed to parse .JSON file');
        }
      };
      fr.readAsText(f);
    }
  };

  const onImportJson = async () => {
    inputFile.current?.click();
  };

  const onHandleImport = async () => {
    const data = CONFIRMATION.header.import;
    const status = await openConfirmation(data);
    switch (status) {
      case 'confirm':
        loadConfigJson();
        break;
      case 'cancel':
        onImportJson();
        break;
      default:
    }
  };

  const handleNewConfig = async () => {
    const data = CONFIRMATION.header.new;
    const status = await openConfirmation(data);
    if (status === 'confirm') {
      setConfig(configExample);
      navigate('/');
    }
  };

  const firstUpperCase = (value: string | undefined) => first(value)?.toUpperCase() || '';

  const userName = isUndefined(givenName) ? firstUpperCase(email) : `${firstUpperCase(givenName)}`;
  return (
    <AppBar className={classes.container} elevation={1}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt="logo" />
          <Typography variant="h6" className={classes.logoTxt}>
            TerraGo Deployment Manager(vNext)
          </Typography>
        </Box>
        <Box className={classes.centerMenu}>
          {MAIN_MENU.map((menu) => (
            <Button
              key={menu.label}
              onClick={() => goTo(menu.path)}
              variant="text"
              startIcon={<FontAwesomeIcon icon={menu.icon} />}
              classes={{
                label: `${isItemSelected(menu.path) && classes.selectedBtn} ${classes.menuTxt}`,
              }}
            >
              {menu.label}
            </Button>
          ))}
        </Box>

        <Box className={classes.rightMenu}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            classes={{
              root: classes.jsonBtn,
              label: classes.jsonBtntxt,
              endIcon: `${classes.jsonEndIcon} ${Boolean(jsonanchorEl) && classes.jsonEndIconOpen}`,
            }}
            endIcon={<ArrowDropDown />}
            onClick={handleClick}
          >
            JSON
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={jsonanchorEl}
            keepMounted
            open={Boolean(jsonanchorEl)}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            classes={{ paper: classes.menuRoot }}
            className={classes.menuPopover}
            variant="menu"
          >
            <MenuItem onClick={handleClose} classes={{ root: classes.menuItem }}>
              <Box className={classes.menu} onClick={() => goTo('/overview')}>
                Overview
              </Box>
            </MenuItem>
            <MenuItem onClick={handleClose} classes={{ root: classes.menuItem }}>
              <Box className={classes.menu} onClick={handleNewConfig}>
                New
              </Box>
            </MenuItem>
            <MenuItem onClick={handleClose} classes={{ root: classes.menuItem }}>
              <Box className={classes.menu} onClick={onGenerateJson}>
                Export
              </Box>
            </MenuItem>
            <input
              type="file"
              accept="application/json, .json"
              id="file"
              ref={inputFile}
              style={{ display: 'none' }}
              onChange={(e) => loadJson(e)}
            />
            <MenuItem onClick={handleClose} classes={{ root: classes.menuItem }}>
              <Box className={classes.menu} onClick={onHandleImport}>
                Import
              </Box>
            </MenuItem>
          </Menu>
          <Box className={classes.rightMenus}>
            {MENU_BUTTONS.map((menu) => (
              <Tooltip key={menu.key} title={menu.label}>
                <IconButton className={classes.menuButton} onClick={() => goTo(menu.path)}>
                  <FontAwesomeIcon icon={menu.icon} className={classes.icon} />
                </IconButton>
              </Tooltip>
            ))}
          </Box>
          <IconButton
            onClick={(e) => setUserMenuAnchorEl(e.currentTarget)}
            size="small"
            className={classes.rootButton}
          >
            <div className={classes.avatar}>{userName}</div>
          </IconButton>
          <Menu
            anchorEl={userMenuAnchorEl}
            open={Boolean(userMenuAnchorEl)}
            id="account-menu"
            onClose={() => setUserMenuAnchorEl(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            getContentAnchorEl={null}
            classes={{ paper: classes.menuRoot }}
            className={classes.loginmenuPopover}
            variant="menu"
          >
            <div>
              <Avatar className={classes.avator}>{userName}</Avatar>
            </div>
            <div className={classes.givenName}>{capitalize(givenName)}</div>
            <div className={classes.emailName}>
              <>{middleTruncate(email as string, 26)}</>
            </div>
            <div className={classes.logoutbtn} onClick={clearToken}>
              Logout
            </div>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      height: HEADER_HEIGHT,
      padding: 22,
      paddingRight: 35,
      justifyContent: 'center',
      backgroundColor: colors.white,
      flexShrink: 0,
      boxShadow: 'none',
      borderBottom: `1px solid ${colors.black10}`,
    },
    rootButton: {
      position: 'relative',
      left: -7,
    },
    avator: {
      margin: 'auto',
      height: 77,
      width: 77,
      color: theme.palette.primary.main,
      background: colors.transparentBlack,
      fontSize: 36,
      fontWeight: 600,
      marginTop: 22,
    },
    givenName: {
      color: colors.black,
      textAlign: 'center',
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '100%',
      marginTop: 11,
    },
    headerLogoutBtn: {
      height: '50%',
      width: '40px',
      zIndex: 4,
    },
    emailName: {
      color: colors.black70,
      textAlign: 'center',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '100%',
      marginTop: 3,
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingInline: 20,
    },
    logoutbtn: {
      display: 'flex',
      width: 195,
      padding: '12px 0px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
      border: `1px solid ${theme.palette.primary.main}`,
      background: colors.white,
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 400,
      cursor: 'pointer',
      height: 40,
      margin: 'auto',
      position: 'relative',
      top: 23,
      '&:hover': {
        backgroundColor: colors.transparentBlack,
      },
    },
    loginmenuPopover: {
      '& .MuiPaper-elevation8': {
        boxShadow: `0px 2px 10px 3px ${colors.black10}`,
        height: 224,
        width: 230,
        borderRadius: 5,
        background: colors.white,
        textAlign: 'center',
      },
      '& .MuiList-padding': {
        padding: 0,
      },
    },
    menuPopover: {
      '& .MuiPaper-elevation8': {
        boxShadow: `0px 2px 10px 3px ${colors.black10}`,
        width: 181,
      },
      '& .MuiList-padding': {
        paddingBottom: 6,
      },
    },
    logoContainer: {
      display: 'flex',
      width: 230,
      alignItems: 'center',
    },
    logo: {
      width: 41.137,
      height: 36,
    },
    logoTxt: {
      width: 163,
      fontSize: 15,
      lineHeight: '15px',
      fontWeight: 500,
      color: colors.black,
      marginLeft: 15,
    },
    flex: { flexGrow: 1, display: 'flex' },
    toolbar: {
      padding: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    centerMenu: { display: 'flex', gridGap: 45, alignItems: 'center' },
    rightMenu: { display: 'flex', gridGap: 22, alignItems: 'center' },
    rightMenus: { display: 'flex', gridGap: 8 },
    menuIcon: {
      color: colors.black54,
    },
    menuTxt: {
      color: colors.black54,
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 600,
    },
    selectedBtn: { color: theme.palette.primary.main },
    jsonBtn: {
      padding: '8px 9px 8px 35px',
      boxShadow: `0px 2px 6px 0px ${colors.black15}`,
      height: 40,
    },
    jsonBtntxt: {
      fontSize: 15,
      fontWeight: 600,
      color: colors.white,
    },
    jsonEndIcon: {
      width: 24,
      height: 24,
      transform: 'rotate(0deg)',
      transition: 'transform 300ms',
      '& > *:first-child': {
        fontSize: 24,
        fontWeight: 600,
      },
    },
    jsonEndIconOpen: {
      transform: 'rotate(180deg)',
    },
    menuRoot: {
      marginTop: 8,
      borderRadius: 5,
    },
    menuItem: {
      height: '40px !important',
      padding: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    userMenuItem: {
      width: 270,
    },
    menu: {
      fontWeight: 400,
      fontSize: 15,
      fontStyle: 'normal',
      color: colors.black,
      width: '100%',
      padding: '9px 0px',
      marginInline: 7,
      paddingInline: 15,
      borderRadius: 5,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    userMenu: {
      padding: '15px 0px',
      margin: 0,
      paddingLeft: 5,
    },
    icon: { color: colors.black54, fontSize: 22 },
    menuButton: {
      padding: 6,
    },
    avatar: {
      color: theme.palette.primary.main,
      background: colors.transparentBlack,
      cursor: 'pointer',
      height: 26,
      width: 26,
      fontSize: 16,
      fontWeight: 500,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);
