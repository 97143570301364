import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Conjunctions, ConjunctionConst } from '../defaults/defaultConjunctions';
import { Theme, styled } from '@material-ui/core';
import { categoryColors, colors } from 'utils/colors';

interface ToggleSelectorProps {
  option: ConjunctionConst;
  handleOnChange: (conjunction: ConjunctionConst) => void;
  options: Conjunctions;
}

const ButtonGroup = styled(ToggleButtonGroup)(({ theme }: { theme: Theme }) => ({
  '& .MuiToggleButton-root': {
    width: 45,
    height: 25,
    backgroundColor: colors.greyMercury,
    borderWidth: 0.5,
    borderColor: categoryColors.defaultColor,
    '&:first-child': {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    '&:last-child': {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      border: 'none',
      '& .MuiToggleButton-label': {
        color: colors.white,
      },
    },
    '& .MuiToggleButton-label': {
      color: colors.gray80,
    },
  },
}));

const ToggleSelector: React.FC<ToggleSelectorProps> = ({ option, handleOnChange, options }) => (
  <ButtonGroup
    value={option}
    size="small"
    exclusive
    onChange={(_, option) => {
      handleOnChange(option);
    }}
  >
    {options.map((option) => (
      <ToggleButton key={`key-${option.name}`} value={option.name} style={styles.text}>
        {option.label}
      </ToggleButton>
    ))}
  </ButtonGroup>
);
const styles = {
  text: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '100%',
    fontStyle: 'normal',
  },
};
export default ToggleSelector;
