import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { ConfigContext } from '../../context/ConfigContext';
import { TGSelect } from 'views/components/formElements';
import { colors } from 'utils/colors';
import CreateNewDialog from 'common/CreateNewDialog';

interface Props {
  onSubmit: (result: string) => void;
  onClose: () => void;
  configType: 'mobileUIConfig' | 'webUIConfig';
}

export const CreateAggregateUICustomizationDialog: React.FC<Props> = ({
  onSubmit,
  onClose,
  configType,
}) => {
  const classes = useStyles();
  const { config } = useContext(ConfigContext);

  const ifAggrUICustomizationExists = (aggrName: string) =>
    config?.[configType]?.aggregateUICustomizations?.hasOwnProperty(aggrName);
  const getAggregateList = () =>
    config.aggregates
      .map((item) => item.typeName)
      .filter((item) => !ifAggrUICustomizationExists(item));
  const [selected, setSelected] = useState(getAggregateList()[0]);

  return (
    <CreateNewDialog
      title="Add new record UI"
      onClose={onClose}
      onSubmit={() => onSubmit(selected)}
      submitText="Add"
    >
      <TGSelect
        id="Record UI Configuration"
        label="Record UI Configuration"
        options={getAggregateList()}
        onChange={(value) => setSelected(value)}
        value={selected}
        containerStyle={classes.select}
      />
    </CreateNewDialog>
  );
};

const useStyles = makeStyles({
  select: {
    '& .MuiFormLabel-root': {
      fontSize: 15,
      fontWeight: 500,
      color: colors.black,
    },
  },
});
