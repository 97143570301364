const STORE_NAME = 'configEditorSettings';
const Storage = localStorage;

export const StorageKeys = {
  pageHistory: 'pageSelectionHistory',
  properityColumn: 'recordPropertyColumnSelection',
  config: 'config',
};

export const StorageHelper = {
  setByKey(key: string, value: unknown) {
    try {
      const string = JSON.stringify(value);
      Storage.setItem(`${STORE_NAME}:${key}`, string);
    } catch (error) {
      // Error saving data
      console.log(`setByKey error`, error);
    }
  },
  getByKey(key: string) {
    try {
      const value = Storage.getItem(`${STORE_NAME}:${key}`);
      if (value) {
        return JSON.parse(value);
      }
    } catch (error) {
      console.log(`getByKey error`, error);
      // Error retrieving data
    }
    return '';
  },
  removeByKey(key: string) {
    try {
      return Storage.removeItem(`${STORE_NAME}:${key}`);
    } catch (error) {
      console.log(`removeByKey error`, error);
    }
    return '';
  },
};
