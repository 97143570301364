import styled from 'styled-components';
import { colors } from 'utils/colors';

export const NodeFrame = styled.div<{ isSelected?: boolean }>`
  width: max-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  border-radius: 8px;
  background: linear-gradient(225deg, #3a3a3a, #313131);
  padding-bottom: 13px;
  box-shadow: 0px 4px 15px 0px ${colors.black25}
    ${(props) =>
      props.isSelected &&
      `,2px 2px 2px 2px ${colors.borderBlue}, -2px -2px 2px 2px ${colors.borderBlue}`};
`;

export const NodeTitleBox = styled.div`
  width: 100%;
  overflow: visible;
  background-color: #${(props) => props.color || '1e1e1e00'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0px 0px;
`;
export const NodeTitle = styled.div<{ isTitle?: boolean }>`
  width: 100%;
  padding: 9px 26px 10px 5px;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
  text-align: left;
  color: ${colors.white};
  & > input {
    color: ${colors.white};
    background-color: ${(props) => props.isTitle && `${colors.gunmetal}`};
    font-size: 16px;
  }
`;
export const NodeDescriptionBox = styled.div`
  width: 100%;
  overflow: visible;
  background-color: #${(props) => props.color || '1e1e1e00'};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NodeDescription = styled.div<{ isDesc?: boolean; color?: string }>`
  width: 100%;
  max-width: 400px;
  padding: 12px 20px 16px 26px;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 1.2;
  color: ${(props) => props.color};
  & > input {
    color: ${colors.black};
    background-color: ${(props) => props.isDesc && `${colors.black2}`};
    font-size: 12px;
  }
`;
export const NodeCollapseArea = styled.div`
  flex: 1 0 auto;
  cursor: pointer;
  width: 20px;
  color: ${colors.white};
  margin-left: 10px;
  margin-top: 5px;
`;
