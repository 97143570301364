import { FormControl, InputLabel, makeStyles } from '@material-ui/core';
import { TGTextInput } from '.';
import { colors } from 'utils/colors';

interface Props {
  id: string;
  value: string | number;
  label?: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  labelClass?: string;
  inputClass?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
}

export const TGTextInputWithLabel = ({
  id,
  value,
  label,
  onBlur,
  onChange,
  labelClass,
  inputClass,
  placeholder,
  disabled,
  className = '',
}: Props) => {
  const classes = useStyles();
  return (
    <FormControl className={`${classes.formControl} ${className}`}>
      {Boolean(label) && (
        <InputLabel shrink htmlFor={id} className={`${classes.inputLabel} ${labelClass ?? ''}`}>
          {label}
        </InputLabel>
      )}
      <TGTextInput
        defaultValue="Project"
        value={value}
        id={id}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        className={inputClass}
        placeholder={placeholder}
      />
    </FormControl>
  );
};

const useStyles = makeStyles({
  formControl: {
    width: '100%',
  },
  inputLabel: {
    fontSize: 15,
    color: colors.black,
    '&.MuiInputLabel-shrink': {
      transform: 'translate(0, -3px) scale(1)',
    },
  },
});
