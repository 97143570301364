"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileManagementRender = void 0;
const react_1 = require("react");
const FormContexts_1 = require("../Contexts/FormContexts");
/**
 *  This wrapper component adds common file management capabilities to a form control. This includes the addition and removal of files through the onchange handler.
 *  When a new file is added through a media component, The FileManagementRender component will add the file to the FileManager, and put the resultant id into the form control
 * value. Similarly, when a file is removed for a control, the id is removed from the form control value, and the removal is passed on to the FileManager
 * @param controlDefinition The definition of this control. This is primarily used to determing whether the field should be read only
 * @param render The render prop which is used to render the control. This is passed a set of props used to render the form control passed by the consuming application
 * @param onChange  The handler for when a file attachment is changed on the UI
 * @param value The collection of file Ids currently attached to this form value
 */
const FileManagementRender = (props) => {
    const { onChange, value, render } = props;
    const { addFile, removeFile, getFile } = FormContexts_1.useFileManager();
    // if the consumer of those call back to back
    // add/remove if we base our state on value it might not be correct
    // is this the best way to manage the issue not sure
    const synchronizeValue = react_1.useRef(value);
    react_1.useEffect(() => {
        if (value && !synchronizeValue.current) {
            synchronizeValue.current = value;
        }
    }, [value, synchronizeValue]);
    const inputAddFile = react_1.useCallback((file, id) => {
        const finalId = addFile(file, id);
        synchronizeValue.current = synchronizeValue.current || [];
        synchronizeValue.current = [...synchronizeValue.current, finalId];
        onChange([...synchronizeValue.current]);
        return finalId;
    }, [onChange, addFile]);
    const inputRemoveFile = (id) => {
        removeFile(id);
        synchronizeValue.current = synchronizeValue.current || [];
        synchronizeValue.current = synchronizeValue.current.filter((oldId) => oldId !== id);
        onChange([...synchronizeValue.current]);
    };
    return render({ value, getFile, addFile: inputAddFile, removeFile: inputRemoveFile });
};
exports.FileManagementRender = FileManagementRender;
exports.default = exports.FileManagementRender;
