import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginScreen from 'components/Login/LoginScreen';
import {
  AuthConnector,
  AuthState,
  convertUserAttributesToAuthState,
  useAuthState,
} from '@terragotech/gen5-shared-components';
// import { useUserPreferences } from '../hooks/useUserPreferences';

export interface IAppLoginScreenContainerProps {
  onLoginSuccess: ({
    token,
    permissionStatus,
    username,
    name,
    givenName,
    familyName,
    email,
  }: AuthState) => void;
}

export interface IAppLoginScreenContainerState {
  username: string;
  password: string;
  errorMessage: string;
}

/**
 *  LoginScreenContainer - Container for Login Screen that handles Authentication and state
 *
 *  Contains Function 'handleSubmit' that submit username and password to Authconnector.
 *    If authenticated, redirects.
 *
 *  Contains username and password state, passes down hooks for both to obtain values from Login Screen.
 *
 *  @param onLoginSuccess
 */

const CREDENTIAL_ERROR_MESSAGE = 'Oops, you have entered an incorrect username and/or password.';
const NO_SERVER_ACCESS_ERROR_MESSAGE =
  'You do not have access to this server. Contact an admin for help.';
const INVALID_CODE_ERROR_MESSAGE = 'That code is invalid. Please try again or resend your code.';
const IAppLoginScreenContainer = () => {

  const authState = useAuthState();
  const { loginSuccess: onLoginSuccess } = authState;
  //hooks
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mfaCode, setMfaCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [codeNeeded, setCodeNeeded] = useState(false);
  const [emailNeeded, setEmailNeeded] = useState(false);
  const [user, setUser] = useState();
  const [newPasswordNeeded, setNewPasswordNeeded] = useState(false);
  // const { setPreferenceForRecordType } = useUserPreferences();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };
  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfaCode(event.currentTarget.value);
  };

  const attemptLogin = async (): Promise<void> => {
    const mfa = await mfaLogin();
    if (!mfa && (await AuthConnector.isUserLoggedIn())) {
      try {
        if (await AuthConnector.isDomainAllowed(window.location)) {
          await completeLogin();
        } else {
          await AuthConnector.logout();
          setErrorMessage(NO_SERVER_ACCESS_ERROR_MESSAGE);
        }
      } catch (err) {
        // todo what message here? Unknown error?
        setErrorMessage(CREDENTIAL_ERROR_MESSAGE);
      }
    }
  };

  const mfaLogin = async (): Promise<boolean> => {
    try {
      const response = await AuthConnector.login(email, password);
      const challenge = response?.challengeName;
      if (challenge === 'SMS_MFA') {
        setUser(response);
        setCodeNeeded(true);
        setErrorMessage('');
        return true;
      } else if (challenge === 'NEW_PASSWORD_REQUIRED') {
        setUser(response);
        setNewPasswordNeeded(true);
        return true;
      }
    } catch (err) {
      setErrorMessage(CREDENTIAL_ERROR_MESSAGE);
    }
    return false;
  };

  const submitAuthCode = async (): Promise<void> => {
    try {
      // sendAuthCode assume we are logged in if not failure
      await AuthConnector.sendAuthCode(user, mfaCode);
      await completeLogin();
    } catch (err) {
      setErrorMessage(INVALID_CODE_ERROR_MESSAGE);
    }
  };

  const completeNewPassword = async (password: string): Promise<any> => {
    try {
      setNewPasswordNeeded(false);
      const response = await AuthConnector.completeNewPassword(user, password);
      const challenge = response?.challengeName;
      if (challenge === 'SMS_MFA') {
        setUser(response);
        setCodeNeeded(true);
        setErrorMessage('');
        return true;
      } else if (
        (await AuthConnector.isUserLoggedIn()) &&
        (await AuthConnector.isDomainAllowed(window.location))
      ) {
        await completeLogin();
      }
    } catch (err) {
      setErrorMessage(err as string);
    }
  };

  const completeLogin = async () => {
    const userAttributes = await AuthConnector.getSessionIdPayload();
    // setPreferenceForRecordType({
    //   key: 'auth',
    //   recordType: 'login_timestamp',
    //   value: {
    //     value: _.toString(Date.now()),
    //   },
    // });
    onLoginSuccess(convertUserAttributesToAuthState(userAttributes));
    if (navigate.length > 2) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const selectEmailLogin = () => {
    setEmailNeeded(true);
  };

  return (
    <LoginScreen
      errorMessage={errorMessage}
      email={email}
      password={password}
      onEmailChange={handleEmailChange}
      onPasswordChange={handlePasswordChange}
      attemptLogin={attemptLogin}
      code={mfaCode}
      onCodeChange={handleCodeChange}
      codeNeeded={codeNeeded}
      emailNeeded={emailNeeded}
      submitAuthCode={submitAuthCode}
      newPasswordNeeded={newPasswordNeeded}
      completeNewPassword={completeNewPassword}
      selectEmailLogin={selectEmailLogin}
    />
  );
};

export default IAppLoginScreenContainer;
