import {
  AttributeBasedFilterWithID,
  AttributeBasedFilterSimpleFiltersWithID,
} from '../QueryBuilder';
import {
  AttributeBasedFilter,
  AttributeBasedFilterSimpleFilters,
} from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';

export const isConditionGroup = (
  conditionOrGroup: AttributeBasedFilter | AttributeBasedFilterSimpleFilters
): conditionOrGroup is AttributeBasedFilter => {
  return (
    conditionOrGroup.hasOwnProperty('conjunction') && conditionOrGroup.hasOwnProperty('condition')
  );
};

export const isConditionGroupWithId = (
  conditionOrGroup: AttributeBasedFilterWithID | AttributeBasedFilterSimpleFiltersWithID
): conditionOrGroup is AttributeBasedFilterWithID => {
  return (
    conditionOrGroup.hasOwnProperty('conjunction') &&
    conditionOrGroup.hasOwnProperty('condition') &&
    conditionOrGroup.hasOwnProperty('id')
  );
};
