import { useContext } from 'react';
import { ConfigContext } from '../ConfigContext';
import { generateFunction } from '../../utils/jsonPartsGenerators';
import { errorMsg } from '../../components/SnackbarUtilsConfigurator';
import { FunctionDefinition } from '@terragotech/gen5-datamapping-lib';
import { cloneDeep } from 'lodash';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { JSONSchema6 } from 'json-schema';
// import { promisify } from 'util';

export const useFunctionAPI = () => {
  const {
    getFunctions,
    setFunctions,
    getFunction,
    setFunction,
    setFunctionMap,
    setFunctionInternalDescription,
  } = useContext(ConfigContext);

  const promisify = <T extends Function>(
    child: T
  ): Promise<{ error: Error | null; data: T | null }> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ error: null, data: child() });
      } catch (e) {
        if (e instanceof Error) {
          errorMsg(e.message);
        }
        reject({ error: e as Error, data: null });
      }
    });
  };

  const addFunction = (aggrName: string) =>
    promisify(() => {
      const old = getFunctions();
      old[aggrName] = generateFunction();
      setFunctions(old);
    });

  const addFunctionVersion = (name: string, versionIndex: number) =>
    promisify(() => {
      const func = getFunction(name);
      const versions = func.versions;
      if (versions) {
        const lastVersion = Math.max(
          ...versions.map((version: { versionNumber: number }) => version.versionNumber)
        );
        const newVersion = cloneDeep(versions[versionIndex]);
        newVersion.versionNumber = lastVersion + 1;
        versions.push(newVersion);
        func.versions = versions;
        setFunction(name, func);
      }
    });

  const removeFunctionVersion = (name: string, versionIndex: number) =>
    promisify(() => {
      const func = getFunction(name);
      const versions = func.versions;
      if (versions) {
        versions.splice(versionIndex, 1);
        func.versions = versions;
        setFunction(name, func);
      }
    });

  const addComment = (name: string, comment: string) =>
    promisify(() => {
      setFunctionInternalDescription(name, comment);
    });

  const resetFunctionInputSchema = (name: string, versionIndex: number) =>
    promisify(() => {
      const func = getFunction(name);
      if (func?.versions[versionIndex]) {
        func.versions[versionIndex].input = {};
        setFunction(name, func);
      }
    });

  const resetFunctionOutputSchema = (name: string, versionIndex: number) =>
    promisify(() => {
      const func = getFunction(name);
      if (func?.versions[versionIndex]) {
        func.versions[versionIndex].output = {};
        setFunction(name, func);
      }
    });

  const resetFunctionAggregateMap = (name: string, versionIndex: number) =>
    promisify(() => {
      const func = getFunction(name);
      if (func?.versions[versionIndex]) {
        func.versions[versionIndex].aggregateMap = {
          outputDefinition: {
            outputNode: 'OUTPUT',
          },
        };
        setFunction(name, func);
      }
    });

  const deleteFunction = (name: string) =>
    promisify(() => {
      const old = getFunctions();
      delete old[name];
      setFunctions(old);
    });

  const updateFunctionMap = (name: string, versionIndex: number, aggregateMap: NodeMapDefinition) =>
    promisify(() => {
      setFunctionMap(name, versionIndex, aggregateMap);
    });

  const updateInputSchema = (name: string, versionIndex: number, schema: JSONSchema6) =>
    promisify(() => {
      const func = getFunction(name);
      if (func?.versions[versionIndex]) {
        func.versions[versionIndex].input = schema;
        setFunction(name, func);
      }
    });

  const updateOutputSchema = (name: string, versionIndex: number, schema: JSONSchema6) =>
    promisify(() => {
      const func = getFunction(name);
      if (func?.versions[versionIndex]) {
        func.versions[versionIndex].output = schema;
        setFunction(name, func);
      }
    });

  const replaceFunction = (aggrIndex: number, func: FunctionDefinition) =>
    promisify(() => {
      const functions = getFunctions();
      functions[aggrIndex] = func;
      setFunctions(functions);
    });

  return {
    addFunction,
    replaceFunction,
    deleteFunction,
    addFunctionVersion,
    removeFunctionVersion,
    resetFunctionAggregateMap,
    resetFunctionInputSchema,
    resetFunctionOutputSchema,
    updateFunctionMap,
    updateInputSchema,
    updateOutputSchema,
    addComment,
  };
};
