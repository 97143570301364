import { styled } from '@material-ui/core';
import { TextInputTG } from 'views/components/formElements';

export const TextInput = styled(TextInputTG)({
  marginBottom: 26,
  '& .MuiOutlinedInput-root': {
    marginTop: 23,
  },
  '& .MuiFormLabel-root': {
    fontSize: 15,
    fontWeight: 500,
  },
});
