import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { StorageHelper, StorageKeys } from 'utils/StorageManagement';

type HistoryType = {
  [key: string]: string;
};

interface Props {
  pageSelectionHistory: HistoryType;
  setPageSelectionHistory: Dispatch<SetStateAction<HistoryType>>;
  storeData: (value: unknown) => void;
}

const HistoryContext = createContext<Props>({
  pageSelectionHistory: {},
  setPageSelectionHistory: () => {},
  storeData: () => null,
});

export const HistoryContextProvider = ({ children }: { children: ReactNode }) => {
  const [pageSelectionHistory, setPageSelectionHistory] = useState<HistoryType>({});

  const storeData = (data: unknown) => {
    StorageHelper.setByKey(StorageKeys.pageHistory, data);
  };

  const loadInitialData = useCallback(() => {
    const data = StorageHelper.getByKey(StorageKeys.pageHistory);
    if (data) {
      const pageHistoryStoredType = typeof data;
      if (pageHistoryStoredType === 'string') {
        // corrupted data. So clearing stored data
        storeData({});
        setPageSelectionHistory({});
      } else {
        setPageSelectionHistory(data);
      }
    }
  }, []);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  return (
    <HistoryContext.Provider value={{ pageSelectionHistory, setPageSelectionHistory, storeData }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const usePageHistory = () => {
  return useContext(HistoryContext);
};
