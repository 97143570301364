import React, { useMemo, useContext, useCallback } from 'react';
import { EditableTable, MaterialTableRef } from '../EditableTable';
import {
  getCardDefinitionOtherAttributesColumns,
  getAggregatePropertiesList,
} from '../../utils/jsonPartsGenerators';
import { CardDefinitionOtherAttribute } from '../../utils/types';
import { cloneDeep } from 'lodash';
import { ConfigContext } from '../../context/ConfigContext';
import { useParams } from 'react-router-dom';
import { errorMsg, successMsg } from '../SnackbarUtilsConfigurator';
import _ from 'lodash';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { CELL_STYLE } from '../../utils/Utils';

type OtherAttributeID = CardDefinitionOtherAttribute & { tableData: { id: number } };

interface CardDefinitionOtherAttributesProps {
  otherAttributesTableRef: React.RefObject<MaterialTableRef>;
  otherAttributes: CardDefinitionOtherAttribute[];
  setOtherAttributes: (otherAttributes: CardDefinitionOtherAttribute[]) => void;
  style?: string;
  showDivider?: boolean;
  showLinkUnlink?: boolean;
}

export const CardDefinitionOtherAttributes: React.FC<CardDefinitionOtherAttributesProps> = ({
  otherAttributesTableRef,
  otherAttributes,
  setOtherAttributes,
  style,
  showDivider,
  showLinkUnlink,
}) => {
  const { config } = useContext(ConfigContext);
  const { aggrUICustomization: aggrUIName } = useParams() as { aggrUICustomization: string };
  const aggregateProps = useMemo(() => getAggregatePropertiesList(config, aggrUIName), [
    config,
    aggrUIName,
  ]);

  const otherAttributeColumns = useMemo(
    () =>
      getCardDefinitionOtherAttributesColumns(
        aggregateProps,
        config.aggregates[getAggregateIndex(config, aggrUIName)]?.properties
      ),
    [aggregateProps, config, aggrUIName]
  );

  const getOtherAttributesData = useMemo(() => cloneDeep(otherAttributes), [otherAttributes]);

  const isAttributeInvalid = useCallback((row: object, oldRow?: object) => {
    if (!(row as OtherAttributeID).itemKey) {
      errorMsg('Property "Item Key" is required');
      return true;
    }
    if (!(row as OtherAttributeID).itemLabel) {
      errorMsg('Property "Item Label" is required');
      return true;
    }
    return false;
  }, []);

  const addAttribute = useCallback(
    (
      row: object | CardDefinitionOtherAttribute,
      resolve: (data: any) => void,
      reject: () => void
    ) => {
      const newOtherAttributes = cloneDeep(otherAttributes);
      if (isAttributeInvalid(row)) return reject();
      let cdef: CardDefinitionOtherAttribute = { ...(row as CardDefinitionOtherAttribute) };
      if (cdef.itemKey) {
        const attrType = _.find(aggregateProps, { name: cdef.itemKey });
        cdef = { ...(row as CardDefinitionOtherAttribute), type: attrType?.type };
        newOtherAttributes.push(cdef);
      }
      setOtherAttributes(newOtherAttributes);
      resolve(null);
      successMsg('"other Attribues" has been successfully added');
    },
    [otherAttributes, setOtherAttributes, isAttributeInvalid, aggregateProps]
  );

  const updateAttribute = useCallback(
    (newRow: object, oldRow: object, resolve: (data: any) => void, reject: () => void) => {
      const newOtherAttributes = cloneDeep(otherAttributes);
      if (isAttributeInvalid(newRow, oldRow)) return reject();
      let cdef: CardDefinitionOtherAttribute = { ...(newRow as CardDefinitionOtherAttribute) };
      if (cdef.itemKey) {
        const attrType = _.find(aggregateProps, { name: cdef.itemKey });
        cdef = { ...(newRow as CardDefinitionOtherAttribute), type: attrType?.type };
      }
      newOtherAttributes[(oldRow as OtherAttributeID).tableData.id] = cdef;
      setOtherAttributes(newOtherAttributes);
      resolve(null);
      successMsg(`"Other Attributes" item has been successfully updated`);
    },
    [otherAttributes, setOtherAttributes, isAttributeInvalid, aggregateProps]
  );

  const deleteAttribute = useCallback(
    (rowToDel: object) => {
      const newOtherAttributes = cloneDeep(otherAttributes);
      newOtherAttributes.splice((rowToDel as OtherAttributeID).tableData.id, 1);
      setOtherAttributes(newOtherAttributes);
      successMsg(`"Other Attributes" item has been successfully deleted`);
    },
    [otherAttributes, setOtherAttributes]
  );

  const handleReorder = useCallback(
    (newData: unknown) => {
      setOtherAttributes(newData as CardDefinitionOtherAttribute[]);
    },
    [setOtherAttributes]
  );

  const columns = _.chain(otherAttributeColumns)
    .filter((f) => f.field !== 'type')
    .map((o) => ({
      ...o,
      cellStyle: CELL_STYLE,
    }))
    .value();

  return (
    <div style={styles.container}>
      <EditableTable
        title="Attributes"
        columns={columns}
        data={getOtherAttributesData}
        toolbarStyle={otherAttributesTableToolbarStyle}
        onAdd={addAttribute}
        onUpdate={updateAttribute}
        onDelete={deleteAttribute}
        onReOrder={handleReorder}
        options={tableOptions}
        tableRef={otherAttributesTableRef}
        style={style}
        showDivider={showDivider}
        showLinkUnlink={showLinkUnlink}
      />
    </div>
  );
};
const styles = {
  container: { paddingTop: 29, paddingBottom: 18 },
};
const otherAttributesTableToolbarStyle = { position: 'absolute', right: -5, zIndex: 100 } as const;

const tableOptions = {
  paging: false,
  search: false,
  draggable: false,
};
