import { AggregateConfig, Gen5Config } from '@terragotech/gen5-config-lib';
import cloneDeep from 'lodash/cloneDeep';

type SourceType = 'STATE' | 'FORM';

export const useMapperRefChanger = () => {
  let localConfig: Gen5Config | undefined;

  const isAnyReference = (
    aggrIndex: number,
    config: Gen5Config,
    searchedName: string,
    sourceType: SourceType
  ) => {
    const searchedString = `{"sourceObject":"${sourceType}","sourcePath":"$.${searchedName}"}`;
    return JSON.stringify(config?.aggregates?.[aggrIndex]).includes(searchedString);
  };

  const renamePropertyReferences = (
    aggrIndex: number,
    config: Gen5Config,
    oldPropName: string,
    newPropName: string
  ) => {
    localConfig = cloneDeep(config);
    getAggregate(aggrIndex, (mapping: AggregateConfig) =>
      regexRename(oldPropName, newPropName, mapping, 'STATE')
    );
    //TODO: Due to the recent changes, this will need to be modified to affenct all object builder and object input nodes
    //   that refer to the given schema.
    /*
    getDerivedPropertyMapping(aggrIndex, (mapping: Mapping) =>
      outputRename(oldPropName, newPropName, mapping)
    );
    getAggregateMaps(aggrIndex, (mapping: Mapping) =>
      outputRename(oldPropName, newPropName, mapping)
    );
    */
    return localConfig;
  };

  const removePropertyReferences = (aggrIndex: number, config: Gen5Config, propName: string) => {
    localConfig = cloneDeep(config);
    getAggregate(aggrIndex, (mapping: AggregateConfig) => regexRemove(propName, mapping, 'STATE'));
    //TODO: Due to the recent changes, this will need to be modified to affenct all object builder and object input nodes
    //   that refer to the given schema.
    /*
    getDerivedPropertyMapping(aggrIndex, (mapping: Mapping) => outputRemove(propName, mapping));
    getAggregateMaps(aggrIndex, (mapping: Mapping) => outputRemove(propName, mapping));
*/
    return localConfig;
  };

  const removeFormReferences = <T,>(form: T, name: string, group?: string) => {
    let localForm = cloneDeep(form);
    const fullName = group ? `${group}.${name}` : name;
    localForm = regexRemove(fullName, localForm, 'FORM');
    //TODO: Due to the recent changes, this will need to be modified to affenct all object builder and object input nodes
    //   that refer to the given schema.
    /*
    if (localForm.initialDataMap)
      localForm.initialDataMap = outputRemove(name, localForm.initialDataMap, group);
    if (localForm.errorMaps) localForm.errorMaps.map((mapper) => outputRemove(name, mapper, group));
    if (localForm.warningMaps)
      localForm.warningMaps.map((mapper) => outputRemove(name, mapper, group));
      */
    return localForm;
  };

  const renameFormReferences = <T,>(
    form: T,
    oldName: string,
    newName: string,
    group?: string
  ) => {
    let localForm = cloneDeep(form);
    const fullOldName = group ? `${group}.${oldName}` : oldName;
    const fullNewName = group ? `${group}.${newName}` : newName;
    localForm = regexRename(fullOldName, fullNewName, localForm, 'FORM');
    //TODO: Due to the recent changes, this will need to be modified to affenct all object builder and object input nodes
    //   that refer to the given schema.
    /*
    if (localForm.initialDataMap)
      localForm.initialDataMap = outputRename(oldName, newName, localForm.initialDataMap, group);
    if (localForm.errorMaps)
      localForm.errorMaps.map((mapper) => outputRename(oldName, newName, mapper, group));
    if (localForm.warningMaps)
      localForm.warningMaps.map((mapper) => outputRename(oldName, newName, mapper, group));
      */
    return localForm;
  };

  const moveFormReferences = <T,>(
    form: T,
    name: string,
    fromGroup?: string,
    targetGroup?: string
  ) => {
    let localForm = cloneDeep(form);
    const fromName = fromGroup ? `${fromGroup}.${name}` : name;
    const targetName = targetGroup ? `${targetGroup}.${name}` : name;

    localForm = regexRename(fromName, targetName, localForm, 'FORM');
    //TODO: Due to the recent changes, this will need to be modified to affenct all object builder and object input nodes
    //   that refer to the given schema.
    /*
    if (localForm.initialDataMap)
      localForm.initialDataMap = outputMove(name, localForm.initialDataMap, fromGroup, targetGroup);
    if (localForm.errorMaps)
      localForm.errorMaps.map((mapper) => outputMove(name, mapper, fromGroup, targetGroup));
    if (localForm.warningMaps)
      localForm.warningMaps.map((mapper) => outputMove(name, mapper, fromGroup, targetGroup));
      */
    return localForm;
  };

  const regexRename = <T,>(oldName: string, newName: string, obj: T, sourceType: SourceType): T => {
    const oldString = `{"sourceObject":"${sourceType}","sourcePath":"\\$\\.${oldName}"}`;
    const newString = `{"sourceObject":"${sourceType}","sourcePath":"$.${newName}"}`;
    const re = new RegExp(oldString, 'g');
    return JSON.parse(JSON.stringify(obj).replace(re, newString));
  };

  const regexRemove = <T,>(oldName: string, obj: T, sourceType: SourceType): T => {
    const oldString = `,"[a-zA-Z]*":{"sourceObject":"${sourceType}","sourcePath":"\\$\\.${oldName}"}`;
    const re = new RegExp(oldString, 'g');
    return JSON.parse(JSON.stringify(obj).replace(re, '').replace(/,,/g, ','));
  };

  //TODO: Due to the recent changes, this will need to be modified to affenct all object builder and object input nodes
  //   that refer to the given schema.
  /*
  const outputRename = (oldName: string, newName: string, mapping: Mapping, group?: string) => {
    if (group && mapping?.output?.[group]) {
      mapping.output[group] = renameProp(oldName, newName, mapping.output[group] as AttributeMap);
    }
    if (mapping.output.hasOwnProperty(oldName)) {
      mapping.output = renameProp(oldName, newName, mapping.output);
    }
    return mapping;
  };

  const outputRemove = (name: string, mapping: Mapping, group?: string) => {
    if (group && mapping?.output?.[group]) {
      mapping.output[group] = removeProp(name, mapping.output[group] as AttributeMap);
    }
    if (mapping.output.hasOwnProperty(name)) {
      mapping.output = removeProp(name, mapping.output);
    }
    return mapping;
  };

  const outputMove = (name: string, mapping: Mapping, fromGroup?: string, targetGroup?: string) => {
    let nodeValue: NodeDataReference;
    if (fromGroup) {
      nodeValue = (mapping.output[fromGroup] as AttributeMap)?.[name] as NodeDataReference;
    } else {
      nodeValue = mapping.output[name] as NodeDataReference;
    }
    if (nodeValue && fromGroup && targetGroup) {
      mapping.output[fromGroup] = removeProp(name, mapping.output[fromGroup] as AttributeMap);
      if (!mapping.output[targetGroup]) {
        mapping.output[targetGroup] = {};
      }
      (mapping.output[targetGroup] as AttributeMap)[name] = nodeValue;
    } else if (nodeValue && fromGroup) {
      mapping.output[fromGroup] = removeProp(name, mapping.output[fromGroup] as AttributeMap);
      mapping.output[name] = nodeValue;
    } else if (nodeValue && targetGroup) {
      mapping.output = removeProp(name, mapping.output);
      if (!mapping.output[targetGroup]) {
        mapping.output[targetGroup] = {};
      }
      (mapping.output[targetGroup] as AttributeMap)[name] = nodeValue;
    }
    return mapping;
  };
*/
  const getAggregate = (aggrIndex: number, func: (mapping: AggregateConfig) => AggregateConfig) => {
    if (localConfig?.aggregates?.[aggrIndex]) {
      localConfig.aggregates[aggrIndex] = func(localConfig.aggregates[aggrIndex]);
    }
  };
  /*
  const getAggregateMaps = (aggrIndex: number, func: (mapping: Mapping) => Mapping) => {
    if (localConfig?.aggregates?.[aggrIndex]?.events) {
      const events = localConfig.aggregates[aggrIndex].events;
      events &&
        Object.entries(events).forEach(([_, event]) => {
          event.versions.forEach((version) => {
            version.aggregateMap = func(version.aggregateMap);
          });
        });
    }
  };

  const getDerivedPropertyMapping = (aggrIndex: number, func: (mapping: Mapping) => Mapping) => {
    const aggregate = localConfig?.aggregates?.[aggrIndex];
    if (aggregate?.derivedPropertyMapping) {
      aggregate.derivedPropertyMapping = func(aggregate.derivedPropertyMapping);
    }
  };

  const renameProp = (oldProp: string, newProp: string, { [oldProp]: old, ...others }) => ({
    [newProp]: old,
    ...others,
  });

  const removeProp = (oldProp: string, { [oldProp]: old, ...others }) => ({
    ...others,
  });
*/
  return {
    isAnyReference,
    renamePropertyReferences,
    removePropertyReferences,
    renameFormReferences,
    removeFormReferences,
    moveFormReferences,
  };
};
