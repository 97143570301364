import { FunctionComponent } from 'react';
import { V2TextTemplateComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label, Placeholder } from './Common';
import { FormBadges } from './FormBadge';

const Placeholders = styled.div``;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const TextAreaControl: FunctionComponent<NestedFormControlProps<V2TextTemplateComponent>> = (
  props
) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />
      <Placeholders>
        <Placeholder>{controlDefinition.placeholder}</Placeholder>
        <Placeholder>Second line of text would go here</Placeholder>
      </Placeholders>
      <FormBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default TextAreaControl;
