import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@material-ui/core';
import DataMapperDialog from './DataMapperDialog';
import { useStyles } from './useStyles';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { MapScenarioType, NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { LocalSchemaDefinition } from 'utils/useSchemaLookup';

interface Props {
  onToggleMapper: (value: boolean) => void;
  isActive: boolean;
  clearMapper: () => void;
  openDataMap: boolean;
  dataMap: NodeMapDefinition | undefined;
  setDataMap: (newMap?: NodeMapDefinition | undefined) => void;
  localSchemaDefinition: LocalSchemaDefinition | undefined;
  title?: string;
  mapScenario: keyof MapScenarioType;
  containerStyle?: string;
  noMargin?: boolean;
}

const MapperItem = ({
  onToggleMapper,
  isActive,
  clearMapper,
  openDataMap,
  dataMap,
  setDataMap,
  localSchemaDefinition,
  title,
  mapScenario,
  containerStyle,
  noMargin,
}: Props) => {
  const classes = useStyles();
  return (
    <Box className={`${noMargin ? '' : classes.itemContainer} ${containerStyle}`}>
      {title !== undefined && <Typography className={classes.label}>{title}</Typography>}
      <Box className={`${classes.mapButtons} ${noMargin ? classes.mt0 : ''}`}>
        <Button onClick={() => onToggleMapper(true)} className={classes.mapperBtn}>
          Mapper
        </Button>
        {isActive && (
          <Button onClick={clearMapper} className={classes.deleteBtn}>
            <FontAwesomeIcon icon={faTrashCan} className={classes.deleteIcon} />
          </Button>
        )}
      </Box>
      <DataMapperDialog
        mapScenario={mapScenario}
        localSchemaDefinitions={localSchemaDefinition}
        onClose={() => onToggleMapper(false)}
        open={openDataMap}
        datamap={dataMap}
        setDatamap={setDataMap}
      />
    </Box>
  );
};

export default MapperItem;
