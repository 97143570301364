import React, { FC } from 'react';
import WorkflowPage from './WorkflowPage';
import {
    ConfigContext,
    MapBoundsProvider,
    WebUIConfig,
} from '@terragotech/gen5-shared-components';
import { useConfig } from '../../context/ConfigContext';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getConfigContextValue } from '../../utils/config';
import { WorkflowPageContainerProps } from './WorkflowPage.types';

const WorkflowPageContainer: FC<WorkflowPageContainerProps> = ({
                                                                   command,
                                                                   aggregateType,
                                                                   targetAggregateId,
                                                                   title,
                                                                   creationAction,
                                                                   onCancel,
                                                                   onDonePress,
                                                               }) => {
    const { config } = useConfig();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ConfigContext.Provider value={getConfigContextValue(config) as WebUIConfig}>
                <MapBoundsProvider>
                    <WorkflowPage
                        config={config}
                        command={command}
                        targetAggregateId={targetAggregateId}
                        creationAction={creationAction}
                        aggregateType={aggregateType}
                        openWorkflow
                        title={title}
                        onCancelPress={onCancel}
                        onDonePress={onDonePress}
                    />
                </MapBoundsProvider>
            </ConfigContext.Provider>
        </MuiPickersUtilsProvider>
    );
};

export default WorkflowPageContainer;