import styled from 'styled-components';
import * as React from 'react';

const Screen = styled.div`
  /* width: 375px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eeeeee;
`;

const FormFrame: React.FC<{ children: React.ReactElement }> = (props) => {
  return (
    <Screen>
      {/* <Header /> */}
      {props.children}
    </Screen>
  );
};
export default FormFrame;
