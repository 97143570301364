import React from 'react';
import { Checkbox, makeStyles, Typography, Box, Grid } from '@material-ui/core';
import _ from 'lodash';
import { colors } from 'utils/colors';
import TGSwitch from './FormElements/Switch';
import { TextInputTG } from 'views/components/formElements';
import { TitleToolTip } from 'pages/aggregates/components/TitleToolTip';
import { CheckboxInput } from './FormElements';
import FormLegend from './FormLegend';

interface RepeatableProps {
  enabled?: boolean;
  min: number;
  max: number;
  addButtonText?: string;
  deleteConfirmationMessage?: string;
  allowReordering?: boolean;
  allowInitialDataDelete?: boolean;
  allowAdding?: boolean;
}
interface ComponentProps {
  repeats: RepeatableProps;
  setRepeats: React.Dispatch<React.SetStateAction<RepeatableProps>>;
  disabledSwitch?: boolean;
}

export const defaultRepeatableProps: RepeatableProps = {
  enabled: false,
  min: 1,
  max: 1,
  addButtonText: 'Add Field',
  deleteConfirmationMessage: 'Are you sure you want to delete this?',
  allowReordering: true,
  allowInitialDataDelete: true,
  allowAdding: true,
};

/*
//todo: in order to give backward compatibility we need to change the definition like below inside the formrenderer library
export interface V2RepeatableTemplateComponent extends V2FormComponent {
  repeats?: {
    enabled?: boolean;
    min: number;
    max: number;
    addButtonText?: string;
    deleteConfirmationMessage?: string;
    allowReordering?: boolean;
    allowInitialDataDelete?: boolean;
    allowAdding?: boolean;
  };
}*/
const RepeatableComponent = (props: ComponentProps) => {
  const { repeats, setRepeats, disabledSwitch } = props;
  const classes = useStyles();

  const accesses = [
    {
      title: 'Allow deleting of initial data',
      tooltip:
        'Allow users to delete initial data, if the minimum number of instances has not yet ben met.',
      value: repeats.allowInitialDataDelete,
      actionKey: 'allowInitialDataDelete',
    },
    {
      title: 'Allow user to add instances',
      tooltip:
        'Allow users to add additional instances on the workflow, up to the "Maximum" set above',
      value: repeats.allowAdding,
      actionKey: 'allowAdding',
    },
  ];

  return (
    <FormLegend
      title="Show multiple instances of this field"
      tooltip={
        'Configure how many instances of this field will show up in the workflow. Use this command\'s "Initial Data Map" to load this data into the instances configured below if desired'
      }
      disabled={disabledSwitch}
      legendContainer={classes.container}
      rightContent={
        <TGSwitch
          checked={Boolean(repeats?.enabled)}
          onChange={(v) => setRepeats({ ...repeats, enabled: v })}
          disabled={disabledSwitch}
        />
      }
      body={
        repeats.enabled ? (
          <Box className={classes.repeatContainer}>
            <Box className={`${classes.itemContainer} ${classes.mb9}`}>
              <TitleToolTip
                title="Minimum"
                tooltipText={'The required # of instances'}
                titleStyle={classes.title}
                titleContainer={`${classes.titleContainer} ${classes.mb10}`}
              />
              <TextInputTG
                id="Minimum"
                value={repeats.min}
                onChange={(value) => setRepeats({ ...repeats, min: _.toNumber(value) })}
                fullWidth={true}
                type="number"
                placeholder="Enter minimum"
                className={classes.zeroMargin}
              />
            </Box>
            <Box className={classes.itemContainer}>
              <Box className={classes.titleRow}>
                <TitleToolTip
                  title="Maximum"
                  tooltipText={'The allowed # of instances'}
                  titleStyle={classes.title}
                />

                <Box className={classes.inlineBox}>
                  <Checkbox
                    checked={Boolean(repeats.max === -1)}
                    className={classes.checkbox}
                    onChange={(v, checked) => setRepeats({ ...repeats, max: checked ? -1 : 1 })}
                    color="primary"
                  />

                  <Typography className={classes.limitText}>unlimited</Typography>
                </Box>
              </Box>
              <TextInputTG
                id="Maximum"
                value={repeats.max !== -1 ? repeats.max : 'unlimited'}
                fullWidth
                type={repeats.max !== -1 ? 'number' : 'text'}
                placeholder="Enter maximum"
                className={classes.zeroMargin}
                disabled={repeats.max === -1}
                onChange={
                  repeats.max !== -1
                    ? (value) => setRepeats({ ...repeats, max: _.toNumber(value) })
                    : undefined
                }
              />
            </Box>
            <Box className={classes.itemContainer}>
              <TitleToolTip
                title={`"Add" button Label`}
                tooltipText={
                  'This is the button the user sees in the workflow that repeats this field when selected, adding additional instances up to the maximum specified above.'
                }
                titleStyle={classes.title}
                titleContainer={`${classes.titleContainer} ${classes.mb10}`}
              />
              <TextInputTG
                id={`"Add" button Label`}
                value={repeats.addButtonText}
                placeholder="Enter description"
                onChange={(value) =>
                  setRepeats({
                    ...repeats,
                    addButtonText: value || defaultRepeatableProps.addButtonText,
                  })
                }
                type="text"
              />
            </Box>
            <Box className={classes.itemContainer}>
              <TitleToolTip
                title={'Delete confirmation message'}
                tooltipText={'Delete confirmation message'}
                titleStyle={classes.title}
                titleContainer={classes.titleContainer}
              />
              <TextInputTG
                id="outlined-multiline-static"
                multiline
                minRows={5}
                value={repeats.deleteConfirmationMessage}
                className={classes.textInput}
                inputRoot={classes.textArea}
                onChange={(value) =>
                  setRepeats({
                    ...repeats,
                    deleteConfirmationMessage:
                      value || defaultRepeatableProps.deleteConfirmationMessage,
                  })
                }
              />
            </Box>
            <Box>
              <Typography className={`${classes.title} ${classes.mb8}`}>Access</Typography>
              <Grid container>
                {accesses.map((access) => {
                  return (
                    <Grid key={access.title} item xs={6} className={classes.checkBoxContainer}>
                      <CheckboxInput
                        checked={access.value}
                        checkBoxContainer={classes.checkbox}
                        onChange={(checked) => {
                          setRepeats({ ...repeats, [access.actionKey as string]: checked });
                        }}
                      />
                      <TitleToolTip
                        title={access.title}
                        tooltipText={access.tooltip}
                        titleStyle={classes.labelText}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box className={classes.empty}>
            <Typography className={classes.emptyMessage}>
              Multiple Instances have not set up
            </Typography>
          </Box>
        )
      }
    />
  );
};

const useStyles = makeStyles({
  container: {
    marginTop: 12,
  },
  title: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black,
    lineHeight: '100%',
  },
  repeatContainer: {
    padding: '23px 18px',
  },
  itemContainer: {
    width: '100%',
    position: 'relative',
    marginBottom: 19,
  },
  mb8: {
    marginBottom: 8,
  },
  mb9: {
    marginBottom: 9,
  },
  mb10: {
    marginBottom: 10,
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    justifyContent: 'flex-start !important',
  },
  mainContainer: {
    display: 'grid',
    gridTemplateColumns: '70% auto',
  },
  inlineBox: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  limitText: {
    fontSize: 15,
    color: colors.black,
    paddingRight: 5,
  },
  textInput: { width: '100%', marginLeft: 0, marginTop: 10 },
  textArea: {
    padding: '11px 0',
  },
  zeroMargin: { margin: 0 },
  empty: {
    padding: '17px 24px',
  },
  emptyMessage: {
    color: colors.black54,
    fontSize: 15,
    fontWeight: 400,
  },
  labelText: {
    fontSize: 14,
    fontWeight: 400,
    color: colors.black,
  },
  acesses: {
    display: 'flex',
  },
  checkBoxContainer: {
    display: 'flex',
  },
  checkbox: {
    '& .MuiCheckbox-root': {
      padding: '7px 9px',
    },
    
  },
});

export default RepeatableComponent;
