import { ReactNode } from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'utils/colors';

interface Props {
  title: string;
  children: ReactNode;
  onClose: () => void;
  onSubmit: () => void;
  cancelText?: string;
  submitText?: string;
}

const CreateNewDialog = ({
  title,
  children,
  onClose,
  onSubmit,
  cancelText = 'Cancel',
  submitText = 'Create',
}: Props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.header}>
        <DialogTitle>{title}</DialogTitle>
        <IconButton onClick={onClose} className={classes.closeBtn}>
          <FontAwesomeIcon icon={faClose} />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent className={classes.body}>{children}</DialogContent>
      <DialogActions className={classes.footer}>
        <Button
          variant="outlined"
          color="primary"
          className={`${classes.button} ${classes.cancelButton}`}
          onClick={onClose}
        >
          {cancelText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`${classes.button} ${classes.submitButton}`}
          onClick={onSubmit}
        >
          {submitText}
        </Button>
      </DialogActions>
    </>
  );
};

const useStyles = makeStyles({
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 35px',
  },

  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 20px 17px 32px',
    height: 57,
    '& .MuiDialogTitle-root': {
      padding: 0,
    },
    '& .MuiDialogTitle-root .MuiTypography-h6': {
      color: colors.black,
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '100%',
    },
  },
  closeBtn: {
    position: 'absolute',
    right: 20,
    '& .MuiIconButton-root': {
      padding: 0,
    },
    height: 32,
    width: 32,
    color: colors.black54,
  },
  footer: {
    boxShadow: `0px -2px 4px 0px ${colors.black5}`,
    padding: '17.5px 20px',
  },
  button: {
    fontSize: 16,
    fontWeight: 500,
    height: 42,
  },
  cancelButton: {
    width: 134,
  },
  submitButton: {
    marginLeft: '15px !important',
    width: 144,
  },
});

export default CreateNewDialog;
