import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { RECORD_PAGE_HEADER_HEIGHT } from 'utils/Utils';
import { colors } from 'utils/colors';

const useCommonStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerText: {
      '& .MuiTypography-body1': {
        fontSize: 16,
        color: colors.black,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%',
      },
    },
    formHelperText: {
      '& .MuiFormHelperText-root': {
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%',
      },
    },
    container: {
      backgroundColor: colors.white,
      height: '100%',
      width: '100%',
      overflow: 'auto',
    },
    commandEventContainer: {
      overflow: 'hidden',
    },
    containerroot: {
      height: '100dvh',
    },
    innerContainer: {
      height: 'auto',
      padding: '32px 50px',
      paddingTop: 0,
    },
    actionsInnerContainer: {
      height: 'auto',
      padding: '34px 50px',
      paddingBottom: 0,
      paddingTop: 0,
    },
    body: {
      marginTop: 2,
      width: '100%',
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
    },
    commandEventBody: {
      height: `calc(100% - ${RECORD_PAGE_HEADER_HEIGHT}px)`,
    },
    tabContainer: {
      marginTop: 2,
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    tabs: {
      padding: '0 12px',
      minHeight: 40,
    },
    leftPanel: {
      width: 390,
      borderRight: `1px solid ${colors.black10}`,
      height: '100%',
      overflow: 'auto',
    },
    dropable: {
      height: '100%',
      overflow: 'auto',
    },
    panelContainer: {
      border: `1px solid ${colors.black10}`,
      borderRadius: 4,
      marginTop: 15,
      padding: 0,
      overflow: 'hidden',
      height: '100%',
      backgroundColor: colors.greySaturated,
    },
    fullHeight: {
      height: '100%',
    },
    eventPanel: {
      height: '100%',
      overflow: 'auto',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 18,
    },
    leftPanelRoot: {
      width: 390,
      borderRight: `1px solid ${colors.black10}`,
    },
    rootContainer: {
      flexDirection: 'column',
      alignItems: 'baseline !important',
      justifyContent: 'space-between',
      display: 'block !important',
    },
    dividers: { position: 'sticky' as const, top: 128, zIndex: 999 },
    leftContent: {
      display: 'flex',
      alignItems: 'center',
    },
    rightContent: {
      display: 'flex',
    },
    title: {
      fontWeight: 500,
      color: colors.black,
      marginRight: 10,
    },
    cancelBtn: {
      width: 118,
      height: 40,
      color: colors.black54,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      marginRight: 12,
      textTransform: 'none',
      fontSize: 16,
      fontWeight: 500,
    },
    saveBtn: {
      width: 111,
      height: 40,
      fontSize: 16,
      fontWeight: 500,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      textTransform: 'none',
    },
    titleBarContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'sticky',
      padding: '32px 0px 15px 0px',
      top: 0,
      backgroundColor: colors.white,
      zIndex: 999,
    },
    header: {
      color: colors.black,
      fontWeight: 500,
      fontSize: 24,
    },
    saveBtnRoot: {
      borderRadius: 5,
      border: `1px solid ${colors.greySeaShell}`,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      width: 111,
      height: 40,
    },
    saveBtnText: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '100%',
    },
    simulatorBtnRoot: {
      borderRadius: 5,
      border: 'none',
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    },
    simulatorBtnText: {
      color: colors.gray,
    },
    titleRow: { display: 'flex', alignItems: 'center' },
    titleMenuContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 10px',
    },
    menuRoot: {},
    menuItem: {
      width: 170,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    button: {
      width: '100%',
    },
    menu: {
      fontWeight: 400,
      fontSize: 15,
      fontStyle: 'normal',
      color: colors.black,
      textAlign: 'left',
    },
    buttonIcon: {
      marginRight: 10,
      height: 21,
      width: 21,
    },
    buttonContainer: {
      display: 'flex',
      gap: 8,
    },
    text: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '100%',
    },
    addButton: {
      width: 110,
      height: 40,
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    buttons: {
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.blueBorder,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      height: 40,
    },
    label: { textTransform: 'none' },
    settingsDivider: {
      margin: '50px 0',
    },
    firstSettingsContainer: {
      marginTop: 40,
    },
  })
);

export default useCommonStyles;
