import React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { useState, useCallback } from 'react';
import { TransformNodeWidget } from '../CommonWidgets/TransformNodeComponents';
import { DataMapperNodeModel } from './DataMapperNodeModel';
import {
  NestedInboundProperty,
  NestedOutboundProperty,
} from '../CommonWidgets/NestedPortComponent';
import ConfigPropertySection from '../CommonWidgets/ConfigPropertyComponents/ConfigPropertySection';
import SchemaDefinedPortModel from '../../DiagramPorts/SchemaDefinedPort';
import { EventMapDiagramModel } from '../../EventMapDiagramModel';
import { AllNodeMap } from '@terragotech/gen5-datamapping-lib';
import { CONFIRMATION, getCategoryColor } from 'utils/Utils';
import { useConfirmDialog } from 'context/ConfirmContext';

export interface OutputStateWidgetProps {
  node: DataMapperNodeModel;
  engine: DiagramEngine;
}

const BasicNodeWidget: React.FunctionComponent<OutputStateWidgetProps> = ({ node, engine }) => {
  const [configMode, setConfigMode] = useState(false);
  const [name, setName] = useState(node.name);
  const [description, setDescription] = useState(node.description);
  const [collapsed, setCollapsed] = useState(
    (engine.getModel() as EventMapDiagramModel).getLinks().length > 0 && !node.getNewlyCreated()
  );
  const allOutPorts = node.getOutputPortMap();
  const inPortMap = node.getInputPortMap();
  //const outPortMap = node.getOutputPortMap();
  // grab a snapshot of the config
  const configOptions = node.getConfigOptions(); //node.getConfigs());

  const [configValues, setConfigValues] = useState(node.getConfigValues()); //node.getConfigs());
  const { openConfirmation } = useConfirmDialog();

  const handleConfigValueChange = useCallback(
    (key: string, val: unknown) => {
      node.setConfigValue(key, val);
      node.refresh();

      //now we need to update the ports just in case they've changed
      setConfigValues((curVals) => ({ ...curVals, [key]: val }));
    },
    [node]
  );

  const completeEdit = useCallback(() => {
    handleConfigValueChange('name', name);
    node.setName(name);
    node.setDescription(description);
    setConfigMode(false);
    node.setLocked(false);
    node.setConfigs({ ...configValues, name }); // apply configs to node
    //now we need to see if any of the fixedValues were set on the ports
    //TODO: this isn't a good plan, because it overwrites collections, but required for cancel capability
    /**
     * It looks to me like this is a relic of when there were separate edit/view modes. I am commenting it out just on the off chance I am missing something.
     * It currently adds no functionality that I can see, and the comment above seems to indicate it was here for when editing was cancelled.
     * Assuming we can confirm commenting this out did not break anything, this can be deleted.
     * -Kyle 1/17/22
     */
    //node.getInPorts().forEach((inPort) => {
    //  node.updateInputFixedValue(inPort as SchemaDefinedPortModel);
    //});
  }, [node, setConfigMode, name, configValues, handleConfigValueChange, description]);

  const cancelEdit = useCallback(() => {
    setName(node.name);
    setDescription(node.description);
    setConfigMode(false);
    node.setLocked(false);
    setConfigValues(node.getConfigs()); // revert configs
  }, [setName, setConfigMode, node, setDescription]);
  // handlers for collection properties
  const handleCollectionRemove = useCallback(
    (portToRemove?: SchemaDefinedPortModel) => {
      if (portToRemove) {
        //Then remove the actual port from the node
        node.removeCollectionEntry(portToRemove.getName());
        node.refresh();
      }
      // force a re-render
      engine.repaintCanvas();
    },
    [node, engine]
  );
  const handleCollectionAdd = useCallback(
    (parentPort: SchemaDefinedPortModel) => {
      node.addCollectionEntry(parentPort);
      // force a re-render
      engine.repaintCanvas();
    },
    [node, engine]
  );

  const results = React.useMemo(() => {
    const categorizedOutput: {
      [index: string]: { [key: string]: { value: string; label: string } };
    } = {}; //Object.values(AllNodeMap).reduce(

    Object.keys(AllNodeMap)
      .filter((key) => (AllNodeMap as any)[key].nodeDefinition.defaultTitle.toLowerCase())
      .forEach((key) => {
        const { primaryCategory, NODE_TYPE, defaultTitle } = (AllNodeMap as any)[
          key
        ].nodeDefinition;
        categorizedOutput[primaryCategory] = {
          ...categorizedOutput[primaryCategory],
          [key]: { value: NODE_TYPE, label: defaultTitle },
        };
      });
    return categorizedOutput;
  }, []);
  const categoryColor = React.useMemo(() => {
    const categories = Object.keys(results);
    const category = categories.find(
      (key) =>
        Object.keys(results[key]).findIndex(
          (itemKey) => results[key][itemKey].value === node.type
        ) >= 0
    );
    return getCategoryColor(category as string);
  }, [results, node.type]);

  const onRemoveMapping = async () => {
    const status = await openConfirmation(CONFIRMATION.mapperRemove);
    if (status === 'confirm') {
      node.remove();
      engine.repaintCanvas();
    }
  };

  return (
    <div>
      <TransformNodeWidget
        title={name}
        description={description}
        onTitleChange={setName}
        onDescriptionChange={setDescription}
        configMode={configMode}
        onConfigureClicked={() => {
          setConfigMode(true);
          node.setLocked(true);
        }}
        onOkConfigClicked={completeEdit}
        onCancelConfigClicked={cancelEdit}
        isSelected={node.isSelected()}
        isRemovable={true}
        isTitleEditable={true}
        isCollapsible={true}
        isCollapsed={collapsed}
        categoryColor={categoryColor}
        onCollapseClicked={() => setCollapsed((val) => !val)}
        onRemoveClicked={onRemoveMapping}
      >
        <ConfigPropertySection
          configSchema={configOptions}
          configValues={configValues}
          configMode={true}
          onConfigValueChange={handleConfigValueChange}
        />
        <NestedInboundProperty
          configMode={true}
          onRemove={(node: SchemaDefinedPortModel) => {
            handleCollectionRemove(node);
          }}
          onAdd={handleCollectionAdd}
          engine={engine}
          portMap={inPortMap}
          hideDisconnected={collapsed}
          categoryColor={categoryColor}
        />
        <NestedOutboundProperty
          configMode={true}
          onRemove={() => handleCollectionRemove()}
          engine={engine}
          portMap={allOutPorts}
          hideDisconnected={collapsed}
          collapsed={collapsed}
          categoryColor={categoryColor}
        />
      </TransformNodeWidget>
    </div>
  );
};
export default BasicNodeWidget;
