import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { RadioInput } from '../FormElements';
import { CommandType } from '../../utils/types';
import { errorMsg } from '../SnackbarUtilsConfigurator';
import CreateNewDialog from 'common/CreateNewDialog';
import { TextInputTG } from 'views/components/formElements';
import { colors } from 'utils/colors';

interface Props {
  onSubmit: (result: { commandName: string; type: CommandType }) => void;
  onClose: () => void;
}

export const CreateNewCommandForm: React.FC<Props> = ({ onSubmit, onClose }) => {
  const classes = useStyles();
  const [commandName, setCommandName] = useState<string | undefined>(undefined);
  const [type, setType] = useState<CommandType>('BUTTON');
  const handleOnSubmit = () => {
    if (!commandName) return errorMsg('Command name is required');
    onSubmit({ commandName, type });
  };

  return (
    <CreateNewDialog title={'Create new command'} onClose={onClose} onSubmit={handleOnSubmit}>
      <TextInputTG
        id="commandName"
        label="Command Name"
        value={commandName}
        onChange={(value) => setCommandName(value)}
        labelRoot={classes.label}
      />
      <RadioInput
        title="Command Type"
        value={type}
        onChange={(value) => setType(value as 'FORM' | 'BUTTON' | 'IMPORT')}
        options={commandTypeOptions}
        containerStyle={styles.radioContainer}
        labelStyle={classes.label}
        radioButtonStyle={classes.radioButton}
      />
    </CreateNewDialog>
  );
};

const commandTypeOptions = [
  { value: 'BUTTON', label: 'Button' },
  { value: 'FORM', label: 'Form' },
  { value: 'IMPORT', label: 'Import' },
];

const styles = {
  radioContainer: {
    marginTop: 23,
  },
};

const useStyles = makeStyles({
  label: {
    fontSize: '15px !important',
    fontWeight: 500,
    lineHeight: '100%',
    color: colors.black,
  },
  radioButton: {
    width: 24,
    height: 24,
    margin: '6px 9px',
    '& + .MuiTypography-root': {
      fontSize: '14px !important',
      fontWeight: 400,
      color: colors.black,
    },
  },
});
