import React, { useState, useCallback, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { V2TextHeaderComponent } from '@terragotech/form-renderer';
import { useFormSchemas } from './hooks/useFormSchemas';
import { DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import _ from 'lodash';
import { useStyles } from './useStyles';
import { FormEditProps } from 'utils/types';
import { TextInput } from './common';
import MapperItem from './MapperItem';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';

interface TextHeaderEditFormProps extends FormEditProps {
  component: TextHeaderWithName;
}

export type TextHeaderWithName = V2TextHeaderComponent & {
  name: string;
  index?: number;
  droppableId: string;
};

export const TextHeaderEditForm: React.FC<TextHeaderEditFormProps> = ({
  existingNameError,
  handleNameChange,
  component,
  getEditedDef,
  setFormDefinition,
}) => {
  const classes = useStyles();
  const [oldName, setOldName] = useState(component.name);
  const [name, setName] = useState(component.name || '');
  const [label, setLabel] = useState(component.label || '');
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const formSchemas = useFormSchemas();
  const [type, setType] = useState(component.type);
  const { openConfirmation } = useConfirmDialog();

  const setInitialData = useCallback(() => {
    setName(component.name || '');
    setOldName(component.name || '');
    setLabel(component.label || '');
    setType(component.type);
    setConditionalMap(component.conditionalMap || undefined);
  }, [component]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const getFormValues = useCallback(() => {
    return {
      name,
      label,
      type: type,
      ...(conditionalMap && { conditionalMap }),
    };
  }, [name, label, type, conditionalMap]);

  useEffect(() => {
    if (getEditedDef && setFormDefinition && name.length > 0 && !existingNameError) {
      const editedDef = getEditedDef({
        editedData: getFormValues(),
        oldName,
        newName: name,
        droppableId: component.droppableId,
      });
      if (editedDef) {
        setFormDefinition(editedDef);
        if (oldName !== name) {
          setOldName(name);
        }
      }
    }
    // If add those all dependency, it'll call recursively
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormValues, name]);

  const handleClearConditionalMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setConditionalMap(undefined);
    }
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  return (
    <>
      <Typography className={classes.header}>{name}</Typography>
      <TextInput
        autoFocus
        id={name}
        error={existingNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
        label="Name"
        value={name}
        onChange={(value) => handleNameChange && handleNameChange(value || '', setName)}
      />
      <TextInput
        autoFocus
        id={label}
        label="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
      />
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: doesConditionalHaveValue(),
          clearMapper: handleClearConditionalMapper,
          openDataMap: conditionalOpen,
          dataMap: conditionalMap,
          setDataMap: setConditionalMap,
          localSchemaDefinition: formSchemas,
          title: 'Conditional Map',
          mapScenario: 'FORM_FIELD_CONDITIONAL',
        }}
      />
    </>
  );
};
