import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import {  makeStyles } from '@material-ui/core';
import { ConfigContext } from '../../../../context/ConfigContext';
import { JSONSchema6 } from 'json-schema';
import { colors } from 'utils/colors';
import { useOutletContext } from 'react-router-dom';
import SchemaEditor from 'components/SchemaEditor';

export interface CSVEvent {
  type: string;
  functionId: string;
  eventId: string;
  functionType: string;
  data: {
    [key: string]: string | null;
  };
  source: string;
  metadata: object;
}
interface props {
  aggregate: string;
  version: number;
  handleOuputSchema: (schema: JSONSchema6) => void;
  onSave: () => void;
  schemaRef: { current: {} | JSONSchema6 };
}
export const FunctionOutputSchemaEditor: React.FC = () => {
  const {
    aggregate,
    version,
    handleOuputSchema: onChange,
    onSave,
    schemaRef,
  } = useOutletContext() as props;
  const classes = useStyles();
  const { getFunction } = useContext(ConfigContext);
  const schemaData = getFunction(aggregate)?.versions[+version]?.output;

  return (
    <Box className={classes.tabContainer}>
      <SchemaEditor {...{ onChange, schemaRef, schemaValue: schemaData, onSave }} />
    </Box>
  );
};
const useStyles = makeStyles({
  tabContainer: {
    height: '100%',
    overflow: 'auto',
    background: colors.greySaturated,
    border: `1px solid ${colors.black10}`,
  },
});
