import React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';

const PropertyCollectionFrame = styled.div`
  color: ${colors.black};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  background-color: ${colors.darkWashedRed};
  padding-right: 8px;
`;
const PropertyCollectionBorder = styled.div`
  border: 1px solid ${colors.black4};
  padding-top: 5px;
  padding-bottom: 3px;
  width: 98%;
`;
const PropertyCollectionDisplayTitle = styled.span`
  color: ${colors.white};
  background-color: ${colors.darkGray};
  font-size: 12px;
  padding: 2px;
  border-radius: 4px;
`;
const PropertyCollectionTitle = styled.span`
  color: ${colors.black};
  font-size: 12px;
  background-color: ${colors.white};
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
  position: relative;
  top: 3px;
`;
const PropertyCollectionTitleLayout = styled.div`
  position: relative;
  left: 16px;
  top: 6px;
  width: 100%;
`;
const AddButton = styled.a`
  color: ${colors.semiTransparentBlack2};
  cursor: pointer;
  font-size: 14px;
`;
const Span = styled.span``;
interface PropertyCollectionFrameProps {
  configMode?: boolean;
  label: string;
  onAddClick?: () => void;
  collectionLength: number;
  hideLabel?: boolean;
  hide?: boolean;
  children?: React.ReactNode;
}
export const PropertyCollection: React.FunctionComponent<PropertyCollectionFrameProps> = (
  props
) => {
  const { children, configMode, label, onAddClick, hideLabel, hide } = props;

  return (
    <PropertyCollectionFrame>
      {configMode ? (
        (!hide && (
          <>
            <PropertyCollectionTitleLayout>
              <PropertyCollectionTitle>
                {label} <Span>{onAddClick && <AddButton onClick={onAddClick}>+</AddButton>}</Span>
              </PropertyCollectionTitle>
            </PropertyCollectionTitleLayout>
            <PropertyCollectionBorder>{children}</PropertyCollectionBorder>
          </>
        )) ||
        children
      ) : (
        <>
          {!hideLabel && <PropertyCollectionDisplayTitle>{label}</PropertyCollectionDisplayTitle>}
          {children}
        </>
      )}
    </PropertyCollectionFrame>
  );
};
