import { useCallback, useEffect, useState } from 'react';
import { useAuthContext } from '@terragotech/gen5-shared-components';
import { AppContainer } from '../views/components/appContainer/AppContainer';
import { Outlet, useNavigate } from 'react-router-dom';

const ProtectedRoute = () => {
  const { token: authToken, ready, loadExistingAuth } = useAuthContext();
  const [, setToken] = useState<string | null>(null);
  const navigate = useNavigate();

  const getToken = useCallback(async () => {
    const tokenKey = await authToken!();
    setToken(tokenKey);
  }, [authToken]);

  useEffect(() => {
    if (!authToken) {
      loadExistingAuth();
    } else {
      getToken();
    }
  }, [authToken, loadExistingAuth, getToken]);
  if (ready && !authToken) {
    navigate('/login');
  }

  return (
    <AppContainer>
      <Outlet />
    </AppContainer>
  );
};

export default ProtectedRoute;
