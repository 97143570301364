import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import LeftMenu from './LeftMenu';

export const Records: React.FC = () => (
  <FlexContainer>
    <LeftMenu />
    <RightMenu>
      <Outlet />
    </RightMenu>
  </FlexContainer>
);

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

const RightMenu = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
