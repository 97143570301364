import { Box, Typography } from '@material-ui/core';
import { useStyles } from './style';

interface Props {
  title: string;
  description: string;
  containerStyle?: string;
}

const HeaderInfo = ({ title, description, containerStyle }: Props) => {
  const classes = useStyles();
  return (
    <Box className={`${classes.headerContainer} ${containerStyle || ''}`}>
      <Typography className={classes.title}>{title}</Typography>
      {description?.length > 0 && <Typography className={classes.disc}>{description}</Typography>}
    </Box>
  );
};

export default HeaderInfo;
