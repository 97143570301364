import { ChangeEvent } from 'react';
import { Box, createStyles, makeStyles, TextField, TextFieldProps, Theme } from '@material-ui/core';
import { compact, isUndefined } from 'lodash';
import { colors } from 'utils/colors';

export interface TextInputTGProps extends Omit<TextFieldProps, 'onChange' | 'onBlur'> {
  label?: string;
  value: unknown;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  containerStyle?: string;
  inputStyle?: string;
  inputRoot?: string;
  labelRoot?: string;
  placeholder?: string;
}
export const TextInputTG = ({
  label,
  value,
  onChange,
  onBlur,
  containerStyle,
  inputStyle,
  inputRoot,
  labelRoot,
  InputProps,
  variant,
  placeholder,
  ...props
}: TextInputTGProps) => {
  const classes = useStyles();
  const inputPropsClasses = {
    root: compact([
      classes.inputRoot,
      (isUndefined(label) || label?.length === 0) && classes.noLabel,
      inputRoot,
    ]).join(' '),
    input: compact([classes.inputField, inputStyle]).join(' '),
  };

  return (
    <Box key={label} className={compact([classes.inputFieldContainer, containerStyle]).join(' ')}>
      <TextField
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          onChange && onChange(event?.target.value)
        }
        variant={'outlined' || variant}
        fullWidth
        label={label}
        InputLabelProps={{
          classes: { root: compact([classes.labelRoot, labelRoot]).join(' ') },
          shrink: true,
        }}
        InputProps={{
          classes: inputPropsClasses,
          notched: false,
          ...InputProps,
        }}
        onBlur={(e) => onBlur && onBlur(e.target.value ? e.target.value : '')}
        placeholder={placeholder || 'Enter Text'}
        autoComplete="off"
        {...props}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputLabel: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
    },
    inputFieldContainer: {
      width: '100%',
    },
    noLabel: {
      marginTop: '0px !important',
    },
    inputRoot: {
      marginTop: 25,
    },
    inputField: {
      padding: '0px 14px',
      height: 38,
      fontSize: 16,
      borderRadius: 5,
      backgroundColor: theme.palette.common.white,
      color: colors.black,
      width: '100%',
      fontFamily: 'Inter Variable,"Roboto","Helvetica","Arial",sans-serif',
      '&.Mui-disabled': {
        color: colors.black54,
        background: colors.greyBackground,
      },
      '&::placeholder': {
        color: colors.black54,
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '100%',
      },
    },
    labelRoot: {
      transform: 'translate(0, 0)',
      fontSize: 15,
      fontWeight: 400,
      color: colors.black,
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, 0)',
        color: colors.black,
      },
    },
  })
);
