import React, { useState, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { CheckboxInput } from '../../components/FormElements';
import { TextInputTG as TextInput } from 'views/components/formElements';
import { V2MediaComponent } from '@terragotech/page-renderer';
import { JSONSchema6, JSONSchema6Definition } from 'json-schema';
import { isEqual } from 'lodash';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { usePageSchemas } from './hooks/usePageSchemas';
import { PageContext } from './contexts/PageContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import _ from 'lodash';
import usePageRedirect from './hooks/usePageRedirect';
import { useCustomPageStyles } from './commonStyles';
import ModalFrame from './ModalFrame';
import MapperItem from 'components/FormDialog/MapperItem';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION, getPresentValue } from 'utils/Utils';

export type TextTemplateWithName = V2MediaComponent & {
  name: string;
  droppableId: string;
};

interface MediaEditFormProps {
  onSubmit: (result: TextTemplateWithName) => void;
  onClose: () => void;
  component: TextTemplateWithName & { height?: string; width?: string };
}

export const expandPath = (
  parts: string[],
  properties: JSONSchema6
): Array<{ [x: string]: JSONSchema6Definition | JSONSchema6Definition[] | undefined }> => {
  return parts.map((part, idx) => {
    let target = properties as JSONSchema6;
    let name = '';
    for (let i = 0; i < idx; i++) {
      target = _.get(target, parts[i]);
      name += parts[i].replace(/\[\d+\]/, '') + '.';
    }
    target = target ? _.get(target, part) : undefined;
    name += part.replace(/\[\d+\]/, '');
    if (target.type === 'array') {
      return { [name]: target.items };
    }
    return { [name]: target };
  });
};

export const MediaEditForm: React.FC<MediaEditFormProps> = ({ onSubmit, onClose, component }) => {
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [height, setHeight] = useState(component.height);
  const [width, setWidth] = useState(component.width);
  const [repeats, setRepeats] = useState(component.repeats);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const classes = useCustomPageStyles();
  const pageSchemas = usePageSchemas();
  const { isForceLeaveConfirmed } = usePageRedirect();
  const [existingNameError, setExistingNameError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const { openConfirmation } = useConfirmDialog();

  const nameHelperTxt =
    'Duplicate name error. The name must be unique accross all workflow elements';

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'page'
      ? []
      : expandPath(parts, pageSchemas.FORM.schema.properties as JSONSchema6);

  let pageValue = { value: { type: 'string' } };
  for (let i = 0; i < t.length; i++) {
    pageValue = { ...pageValue, ...t[i] };
  }

  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: pageValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...pageSchemas,
  };

  const handleClearDisplayMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDisplayOptions(undefined);
    }
  };

  const getValue = (value: unknown, key: string) => getPresentValue(component, value, key);

  const getFormValues = () => ({
    type: component.type,
    name,
    label,
    ...getValue(repeats, 'repeats'),
    ...(displayOptions && { displayOptions }),
    ...(width && { width }),
    ...(height && { height }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());

  const handleClose = async () =>
    (!isFormDirty() || (isFormDirty() && (await isForceLeaveConfirmed({ handleSubmit })))) &&
    onClose();

  const handleSubmit = async () => {
    if (checkDuplicatePageName(name, component, pageDefinition.elements)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  return (
    <ModalFrame
      {...{ name: component.name, type: component.type, classes, handleClose, handleSubmit }}
    >
      <TextInput
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError}
        helperText={existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={styles.textInputContainer}
        fullWidth={false}
        labelRoot={classes.Root}
      />
      <TextInput
        id="Label"
        label="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={styles.textInputContainer}
        fullWidth={false}
        labelRoot={classes.Root}
      />
      {/* Disabling for now, until Repeatable rendering is implemented. */}
      <Typography className={classes.text}>Additional Options</Typography>
      <CheckboxInput title="Repeatable" checked={repeats} onChange={(value) => setRepeats(value)} />
      {component.type === 'image' && (
        <>
          <TextInput
            id="Height"
            label="Height"
            value={height}
            onChange={(value) => setHeight(value || '')}
            style={styles.textInputContainer}
            fullWidth={false}
            labelRoot={classes.Root}
          />
          <TextInput
            id="Width"
            label="Width"
            value={width}
            onChange={(value) => setWidth(value || '')}
            style={styles.textInputContainer}
            fullWidth={false}
            labelRoot={classes.Root}
          />
        </>
      )}
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: displayOptions !== undefined,
          clearMapper: handleClearDisplayMapper,
          openDataMap: conditionalOpen,
          dataMap: displayOptions,
          setDataMap: setDisplayOptions,
          localSchemaDefinition: errorWarningSchemas,
          title: 'Display Options',
          mapScenario: 'DISPLAY_OPTIONS',
          containerStyle: classes.mapperContainer,
        }}
      />
    </ModalFrame>
  );
};
const styles = {
  textInputContainer: {
    margin: '10px 0',
    width: '100%',
  },
};
