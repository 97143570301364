import React, { useMemo } from 'react';
import { Button, Box } from '@material-ui/core';
import styled from 'styled-components';
import { EventMapDiagram, useDataMapDiagram } from '../../../../map-editor/src';
import { FullScreen } from '../../../../components/FullScreen';
import { useConfig } from '../../../../context/ConfigContext';
import { successMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { useBlocker, useParams } from 'react-router-dom';
import { getAggregateIndex } from '../../../../utils/navigationUtils';
import { propertiesToSchema } from '../../../../pages/aggregates/utils/PropertiesToSchemaConverter';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';
import { useSchemaLookup } from '../../../../utils/useSchemaLookup';
import { useFormDialog } from '../../../../components/FormDialog/FormDialogService';
import { MapperShortCutInfoDialog } from '../../../../components/FormDialog/MapperShortCutInfoDialog';
import { colors } from 'utils/colors';
import { HEADER_HEIGHT } from 'utils/Utils';
import useRouteBlocker from 'common/useBlocker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKeyboard, faSave } from '@fortawesome/pro-light-svg-icons';

export const ComputedMapping: React.FC = () => {
  const { config, getAggregateProperties } = useConfig();
  const AggregateAPI = useAggregateAPI();
  const formDialog = useFormDialog();

  const { aggregate: aggrName } = useParams() as {
    aggregate: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const localSchemas = useMemo(() => {
    return {
      STATE: {
        schema: propertiesToSchema(getAggregateProperties(aggrIndex) || {}),
        schemaLabel: 'Properties',
      },
    };
  }, [aggrIndex, getAggregateProperties]);
  const schemaLookup = useSchemaLookup({
    currentAggregateType: aggrName,
    localSchemas: localSchemas,
  });

  const handleShortCutInfo = () => {
    formDialog<typeof MapperShortCutInfoDialog>((props) => <MapperShortCutInfoDialog {...props} />);
  };

  const handleSaveRequest = async () => {
    const thing = model?.getMapDefinition();
    if (thing) {
      const { error } = await AggregateAPI.updateDerivedPropertyMapping(aggrIndex, thing);
      if (error) return;
      successMsg('Derived property map has been saved');
    }
  };

  const { model, engine, checkModelHasChanged, hasSetPosition, setInitialDataMap } = useDataMapDiagram(
    {
      schemaLookup: schemaLookup,
      dataMap: config.aggregates[aggrIndex].derivedPropertyMapping,
    }
  );

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && checkModelHasChanged()
  );

  useRouteBlocker({ blocker, onSave: handleSaveRequest });

  return (
    <DerivedPropertyMappingContainer>
      <FullScreen zoomButton={styles.zoomButton}>
        <Button
          color="primary"
          onClick={handleSaveRequest}
          style={{ ...styles.button, ...styles.saveBtn }}
          startIcon={<FontAwesomeIcon icon={faSave} />}
        >
          Save
        </Button>

        <Button
          style={{ ...styles.button, ...styles.shortcutBtn }}
          onClick={handleShortCutInfo}
          startIcon={<FontAwesomeIcon icon={faKeyboard} />}
        >
          Shortcuts
        </Button>
        <EventMapDiagram
          model={model}
          engine={engine}
          hasSetPosition={hasSetPosition}
          mapScenario="DERIVED_PROPERTY_MAPPING"
          setInitialDataMap={setInitialDataMap}
        />
      </FullScreen>
    </DerivedPropertyMappingContainer>
  );
};

const DerivedPropertyMappingContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const styles = {
  button: {
    position: 'absolute' as const,
    backgroundColor: colors.white,
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    zIndex: 10,
    height: 40,
    top: 31,
    fontSize: 16,
    fontWeight: 500,
  },
  saveBtn: {
    right: 20,
    width: 111,
  },
  shortcutBtn: {
    color: colors.black54,
    right: 141,
    width: 141,
  },
  zoomButton: {
    top: 27,
    left: 41,
  },
};
