import * as React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';

const BASE_HEIGHT = 24;
export interface NodeProps {
  connected: boolean;
  fixedValue: boolean;
  nodeSide?: 'left' | 'right';
  depth?: number;
  color?: string;
}
const PortHolder = styled.div<NodeProps>`
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  &:hover div {
    background: ${colors.maniacGreen};
    background-attachment: fixed;
  }

  div {
    background: ${(props) => {
      if (props.connected) {
        if (props.color) {
          return `${props.color};`;
        }
        return `${colors.greyBorder};`;
      } else if (props.fixedValue || props.fixedValue === null) {
        if (props.color) {
          return `${props.color};`;
        }
        return `${colors.greyBorder};`;
      } else {
        return `${colors.greyBorder};`;
      }
    }};
  }
`;
const Stem = styled.div<NodeProps>`
  width: ${(props) => {
    return 4 + (props.depth || 0) * 8;
  }}px;
  height: ${BASE_HEIGHT / 4}px;
  margin-top: ${BASE_HEIGHT / 8}px;
`;
export const ConnectionPort = styled.div<NodeProps>`
  width: ${BASE_HEIGHT / 2}px;
  height: ${BASE_HEIGHT / 2}px;
  overflow: visible;
  margin-left: ${(props) => (props.nodeSide === 'left' ? -2 : 0)}px;
  margin-right: ${(props) => (props.nodeSide === 'right' ? -2 : 0)}px;
  border-radius: 8px 8px 8px 8px;
`;

export const InLinkPort: React.FunctionComponent<{
  //TODO: Allow color to be determined by the type
  connected: boolean;
  fixedValue: boolean;
  depth?: number;
  categoryColor?: string;
}> = (props) => {
  const { connected, fixedValue, depth, categoryColor } = props;
  return (
    <PortHolder nodeSide="left" color={categoryColor} connected={connected} fixedValue={fixedValue}>
      <Stem depth={depth || 0} connected={connected} fixedValue={fixedValue} />
      <ConnectionPort nodeSide="left" connected={connected} fixedValue={fixedValue} />
    </PortHolder>
  );
};
export const OutLinkPort: React.FunctionComponent<{
  connected: boolean;
  depth?: number;
  categoryColor?: string;
}> = (props) => {
  const { connected, depth, categoryColor } = props;
  return (
    <PortHolder nodeSide="right" color={categoryColor} connected={connected} fixedValue={false}>
      <ConnectionPort nodeSide="right" connected={connected} fixedValue={false} />
      <Stem depth={depth || 0} connected={connected} fixedValue={false} />
    </PortHolder>
  );
};
