import { FunctionComponent } from 'react';
import { V2TextTemplateComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label, Placeholder } from './Common';
import IsoIcon from '@material-ui/icons/Iso';
import { FormBadges } from './FormBadge';

const PlaceHolderFrame = styled.div`
  display: flex;
`;
const IconContainer = styled.div`
  margin-left: 18px;
  padding-top: 3px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const styles = {
  placeholder: {
    marginLeft: 10,
  },
};
const TextInputControl: FunctionComponent<NestedFormControlProps<V2TextTemplateComponent>> = (
  props
) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />
      <PlaceHolderFrame>
        {controlDefinition.type === 'computed' && (
          <IconContainer>
            <IsoIcon fontSize="small" />
          </IconContainer>
        )}
        <Placeholder style={controlDefinition.type === 'computed' ? styles.placeholder : {}}>
          {controlDefinition.placeholder}
        </Placeholder>
      </PlaceHolderFrame>
      <FormBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default TextInputControl;
