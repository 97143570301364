import { FunctionComponent } from 'react';
import { V2LocationComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label, Placeholder } from './Common';
import { FormBadges } from './FormBadge';

const BodyFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftFrame = styled.div``;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const LocationInputControl: FunctionComponent<NestedFormControlProps<V2LocationComponent>> = (
  props
) => {
  const { controlDefinition, pasted } = props;
  return (
    <StandardInputFrame>
      <BodyFrame>
        <LeftFrame>
          <Label
            text={controlDefinition.label}
            conditional={Boolean(controlDefinition.conditionalMap)}
          />
          <Placeholder>{controlDefinition.placeholder}</Placeholder>
          <FormBadges controlDefinition={controlDefinition} />
        </LeftFrame>
      </BodyFrame>

      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default LocationInputControl;
