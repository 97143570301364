import * as React from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { JSONSchema6 } from 'json-schema';
import { TinyDropdown, TinyDropdownProps } from '../../../InputControls/TinyDropdown';
import { TinyTextInput } from '../../../InputControls/TinyTextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'utils/colors';

export interface ConfigPropertyEditorProps {
  propertySchema: JSONSchema6;
  propertyValue: any;
  onChange: (val: unknown) => void;
  portValue?: string;
  onRemove?: () => void;
  showLabel: boolean;
}
export const isKeyValueSchema = (schema: JSONSchema6): boolean => {
  //TODO: once we're doing collections properly , we should remove the outer layer here
  if (schema.type === 'array' && Array.isArray(schema.items)) {
    if (schema.items.length === 2) {
      return (
        (schema.items[0] as JSONSchema6).type === 'string' &&
        (schema.items[1] as JSONSchema6).type === 'string'
      );
    }
  }
  return false;
};
const ConfigPropertyEditor: React.FunctionComponent<ConfigPropertyEditorProps> = (props) => {
  const { propertySchema, propertyValue, onChange, portValue, onRemove, showLabel } = props;
  const label = propertySchema.description;
  //TODO: Need to account for collections here...
  const editControl: React.ReactElement = useMemo(() => {
    // evaluate the schema to figure out if there are enumerable options
    const enums = propertySchema.enum;
    if (enums) {
      const options: TinyDropdownProps['options'] = enums.map((value) => ({
        optionLabel: String(value),
        optionValue: String(value),
      }));
      return (
        <TinyDropdown
          value={String(propertyValue)}
          onChange={(val) => onChange(val as string)}
          style={styles.tinyWidth}
          options={options}
          def={propertySchema.default as string}
        />
      );
    }
    const oneOf = propertySchema.oneOf;
    if (oneOf) {
      const options: TinyDropdownProps['options'] = oneOf.map((value) => ({
        optionLabel: String((value as JSONSchema6).title),
        optionValue: String((value as JSONSchema6).const),
      }));
      return (
        <TinyDropdown
          value={String(propertyValue)}
          onChange={(val) => onChange(val as string)}
          style={{ width: 'unset' }}
          options={options}
          def={propertySchema.default as string}
        />
      );
    }
    // Check for the rare key value case
    if (isKeyValueSchema(propertySchema)) {
      return (
        <>
          <ConfigPropertyEditor
            showLabel={showLabel}
            onChange={(val) => onChange([val, propertyValue[1]])}
            onRemove={onRemove}
            propertySchema={(propertySchema.items as any)[0]}
            propertyValue={propertyValue[0]}
          />
          <ConfigPropertyEditor
            showLabel={showLabel}
            onChange={(val) => onChange([propertyValue[0], val])}
            onRemove={onRemove}
            propertySchema={(propertySchema.items as any)[1]}
            propertyValue={propertyValue[1]}
          />
        </>
      );
    }
    switch (propertySchema.type) {
      case 'string':
        return (
          <TinyTextInput
            value={propertyValue ? String(propertyValue) : (propertyValue as null | undefined)}
            onChange={(val) => onChange(val as string)}
            style={styles.input}
            containerStyle={styles.container}
            placeholder={portValue}
            undefinable
          />
        );
        break;
      case 'number':
      case 'integer':
        return (
          <TinyTextInput
            value={
              propertyValue !== null && !isNaN(propertyValue as number)
                ? String(propertyValue)
                : (propertyValue as null | undefined)
            }
            onChange={(val) =>
              onChange(val === undefined || val === null ? val : Number.parseFloat(val || ''))
            }
            style={styles.input}
            containerStyle={styles.container}
            placeholder={portValue}
            undefinable
            type={'number'}
          />
        );
      default:
        return <></>;
    }
  }, [onChange, portValue, propertySchema, propertyValue, onRemove, showLabel]);
  return (
    <EditableRow>
      {showLabel && label && (
        <div style={styles.label}>
          {`${label}${propertyValue === undefined && !portValue ? ':' : ''} `}
        </div>
      )}
      {editControl}
      {onRemove && !isKeyValueSchema(propertySchema) && (
        <RemoveButton onClick={onRemove}>
          <FontAwesomeIcon icon={faTrash} />
        </RemoveButton>
      )}
    </EditableRow>
  );
};
export default ConfigPropertyEditor;
const EditableRow = styled.div`
  font-size: 12px;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const RemoveButton = styled.a`
  margin-left: 4px;
  cursor: pointer;
  color: ${colors.black40};
`;
const styles = {
  label: {
    color: colors.black,
    lineHeight: '100%',
  },
  input: {
    border: 'none',
    borderRadius: '0px 2.3px 2.3px 0px',
  },
  container: {
    marginLeft: 6,
  },
  tinyWidth: { width: 'unset' },
};
