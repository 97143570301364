import { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { ConfigContext } from '../../../context/ConfigContext';
import {
  ADD_MODAL_WIDTH,
  CONFIRMATION,
  getReOrderedItems,
  getUrlFirstParam,
  goTo,
  ROOT_RECORDS_ROUTE,
} from '../../../utils/Utils';
import { errorMsg, successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import DraggableListItem from './components/common/DraggableListItem';
import RecordsHeader from './components/common/RecordsHeader';
import { Events as EventProps } from '../../../utils/types';
import { SingleTextInputForm } from '../../../components/FormDialog/SingleTextInputForm';
import { useFormDialog } from '../../../components/FormDialog/FormDialogService';
import useCommonStyles from '../../useCommonStyles';
import _ from 'lodash';
import { EmptyCommand } from './EmptyCommand';
import { useConfirmDialog } from 'context/ConfirmContext';

const Events = () => {
  const commonClasses = useCommonStyles();
  const { config, getAggregate, setEvents, getEvents, getEventVersions } = useContext(
    ConfigContext
  );
  const AggregateAPI = useAggregateAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const formDialog = useFormDialog();
  const { aggregate } = useParams() as { aggregate: string };
  const aggrIndex = getAggregateIndex(config, aggregate);
  const aggregateInfo = getAggregate(aggrIndex);
  const { openConfirmation } = useConfirmDialog();

  const pathName = location.pathname;
  const pattern = `${ROOT_RECORDS_ROUTE}${aggregate}/events/`;
  const selectedEvent = getUrlFirstParam(pathName, pattern) || '';
  const handleAddEvent = async (aggrIndex: number) => {
    const eventName = await formDialog<typeof SingleTextInputForm>(
      (props) => <SingleTextInputForm title="Create new event" {...props} />,
      true,
      true,
      ADD_MODAL_WIDTH
    );
    if (!eventName) return errorMsg('Event name is required');
    if (getEvents(aggrIndex)?.hasOwnProperty(eventName))
      return errorMsg(`An event named "${eventName}" already exists`);
    const { error } = await AggregateAPI.addNewEvent(aggrIndex, eventName);
    if (error) return;
    successMsg(`New event "${eventName}" has been successfully created`);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reOrderedEvents = getReOrderedItems(
      source.index,
      destination?.index,
      aggregateInfo.events
    );
    setEvents(aggrIndex, reOrderedEvents as EventProps);
  };
  const aggrName = aggregateInfo.typeName;
  const handleRemoveEvent = async (eventName: string) => {
    const { error } = await AggregateAPI.removeEvent(aggrIndex, eventName);
    if (error) return;
    navigate(`${ROOT_RECORDS_ROUTE}${aggregate}/events`);
    successMsg(`An event "${eventName}" of the "${aggrName}" has been successfully deleted.`);
  };
  const isEmptyEvents = _.isEmpty(_.keys(aggregateInfo.events));

  const onHandleDelete = async (name: string) => {
    const props = CONFIRMATION.event({ name, aggrName });
    const status = await openConfirmation(props);
    if (status === 'confirm') {
      handleRemoveEvent(name);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box className={[commonClasses.container, commonClasses.commandEventContainer].join(' ')}>
        <Box className={[commonClasses.innerContainer, commonClasses.fullHeight].join(' ')}>
          <RecordsHeader title="Events" onAdd={() => handleAddEvent(aggrIndex)} />
          <Grid
            container
            className={[commonClasses.body, commonClasses.commandEventBody].join(' ')}
          >
            <Grid
              item
              className={isEmptyEvents ? commonClasses.leftPanelRoot : commonClasses.leftPanel}
            >
              {isEmptyEvents && (
                <>
                  <EmptyCommand
                    title="Events Not Added."
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, se"
                  />
                </>
              )}
              <Droppable droppableId="menu">
                {(provided) => (
                  <Box
                    // @ts-ignore
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={commonClasses.dropable}
                  >
                    {_.keys(aggregateInfo.events).map((eventName, index) => {
                      const versions = getEventVersions(aggrIndex, eventName);
                      const versionsCount = versions?.length || 0;
                      const maxVersionIndex = versionsCount > 0 ? versionsCount - 1 : 0;
                      const maxVersion = versions ? versions[maxVersionIndex]?.versionNumber : 0;
                      return (
                        <DraggableListItem
                          name={eventName}
                          index={index}
                          selectedItem={selectedEvent}
                          onSelectItem={() =>
                            goTo(navigate, `${ROOT_RECORDS_ROUTE}${aggregate}/events/${eventName}`)
                          }
                          onDelete={onHandleDelete}
                          maxVersion={maxVersion}
                          goToConfigure={(e) => {
                            e.stopPropagation();
                            goTo(
                              navigate,
                              `${ROOT_RECORDS_ROUTE}${aggregate}/events/${eventName}/version/${maxVersionIndex}/eventSchema`
                            );
                          }}
                        />
                      );
                    })}
                  </Box>
                )}
              </Droppable>
            </Grid>
            <Outlet /> {/* Route mapping */}
          </Grid>
        </Box>
      </Box>
    </DragDropContext>
  );
};
export default Events;
