import React, { useState, useContext } from 'react';
import {
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Paper,
  makeStyles,
  Checkbox,
} from '@material-ui/core';
import { ConfigContext } from '../../context/ConfigContext';
import { convertCommandRefToString } from '../../utils/jsonPartsGenerators';
import _ from 'lodash';
import { blue } from '@material-ui/core/colors';
import { ActionButton } from '../../utils/types';

interface ActionButtonPicker {
  onSubmit: (result: ActionButton[]) => void;
  onClose: () => void;
  additionalActions?: string[];
  scanOnlyAggregateName?: string;
  excludeActionsButtons?: ActionButton[];
  webOrMobile: 'web' | 'mobile';
}

export const ActionButtonPicker: React.FC<ActionButtonPicker> = ({
  onSubmit,
  onClose,
  additionalActions,
  scanOnlyAggregateName,
  excludeActionsButtons,
  webOrMobile,
}) => {
  const classes = useStyles();
  const { config } = useContext(ConfigContext);
  const [selected, setSelected] = useState<ActionButton[]>([]);

  const getMobileActionButtons = () => {
    const mobileAggrUICust = config.mobileUIConfig.aggregateUICustomizations;
    const webAggrUICust = config.webUIConfig.aggregateUICustomizations;
    let actionButtonList: ActionButton[] = [];
    if (mobileAggrUICust)
      Object.entries(mobileAggrUICust).forEach(([aggrName, item]) => {
        if (!scanOnlyAggregateName || scanOnlyAggregateName === aggrName) {
          actionButtonList = [
            ...actionButtonList,
            ...item.editorWorkflows,
            ...item.editorActions,
            ...item.multiSelectActions,
          ];
        }
      });
    if (webAggrUICust)
      Object.entries(webAggrUICust).forEach(([aggrName, item]) => {
        if (!scanOnlyAggregateName || scanOnlyAggregateName === aggrName) {
          actionButtonList = [
            ...actionButtonList,
            ...item.editorWorkflows,
            ...item.tableActions,
            ...(item.multiSelectActions ? [...item.multiSelectActions] : []),
          ];
        }
      });
    let filteredActionButtonList = actionButtonList.filter(
      (v, i, a) => a.findIndex((t) => _.isEqual(t, v)) === i
    );
    if (additionalActions)
      filteredActionButtonList = filteredActionButtonList.filter(
        (item) => typeof item.action === 'object' || additionalActions.includes(item.action)
      );
    if (excludeActionsButtons) {
      filteredActionButtonList = filteredActionButtonList.filter(
        (item) => !excludeActionsButtons.some((excludeItem) => _.isEqual(item, excludeItem))
      );
    }
    if (webOrMobile) {
      if (webOrMobile === 'mobile') {
        filteredActionButtonList = filteredActionButtonList.filter(
          (button) =>
            !webAdditionalActionOptions.some((mobileAction) => button.action === mobileAction)
        );
      } else {
        filteredActionButtonList = filteredActionButtonList.filter(
          (button) =>
            !mobileAdditionalActionOptions.some((mobileAction) => button.action === mobileAction)
        );
      }
    }

    return filteredActionButtonList;
  };

  const isSelected = (actionButton: ActionButton) => selected.indexOf(actionButton) !== -1;

  const handleClick = (_: React.MouseEvent<unknown>, actionButton: ActionButton) => {
    const selectedIndex = selected.indexOf(actionButton);
    let newSelected: ActionButton[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, actionButton);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getMobileActionButtons().length !== 0 ? (
              getMobileActionButtons().map((row: ActionButton) => {
                const isItemSelected = isSelected(row);
                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    tabIndex={-1}
                    key={convertCommandRefToString(row.action)}
                    selected={isItemSelected}
                    className={classes.tableRow}
                  >
                    <TableCell padding="checkbox" style={{ paddingLeft: 15 }}>
                      <Checkbox checked={isItemSelected} color="primary" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.label}
                    </TableCell>
                    <TableCell>
                      <i className={`fa-solid ${row.icon}`} style={{ fontSize: 24 }} />
                    </TableCell>
                    <TableCell>{convertCommandRefToString(row.action)}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div style={{ fontSize: 14, textAlign: 'center' }}>
                    <p>No buttons to display</p>
                  </div>
                </td>
              </tr>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogActions style={{ marginRight: 10 }}>
        <Button color="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          focusRipple={false}
          color="primary"
          onClick={() => onSubmit(selected || [])}
          autoFocus
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
};

const mobileAdditionalActionOptions = ['directions', 'streetView', 'assign', 'updateLocation'];
const webAdditionalActionOptions = [
  'csvExport',
  'delete',
  'directions',
  'streetView',
  'printDetailSheet',
];

const useStyles = makeStyles({
  table: {
    minWidth: 800,
  },
  tableRow: {
    '&.Mui-selected': {
      backgroundColor: blue[50],
    },
    '&.Mui-selected:hover': {
      backgroundColor: blue[100],
    },
    cursor: 'pointer',
  },
});
