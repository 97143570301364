import React, { useContext } from 'react';
import { makeStyles, createStyles, Theme, Box } from '@material-ui/core';
import styled from 'styled-components';
import { ConfigContext } from '../../../../context/ConfigContext';
import { useParams, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { getAggregateIndex } from '../../../../utils/navigationUtils';
import { useAggregateAPI } from '../../../../context/fakeAPIHooks/useAggregateAPI';
import { useFunctionAPI } from '../../../../context/fakeAPIHooks/useFunctionAPI';
import { SHARED_ROUTE } from '../../../../utils/Utils';
import { colors } from 'utils/colors';
import TGEditor from 'views/components/TGEditor';

interface props {
  aggregate: string;
}
export const AggrComments: React.FC = () => {
  const { aggregate } = useOutletContext() as props;
  let internalDescription = '';
  const { config, getFunction, getAggregate } = useContext(ConfigContext);
  const location = useLocation();
  const navigate = useNavigate();
  const isAggrEvent = location.pathname.includes('events');
  const isAggrCommand = location.pathname.includes('commands');
  const isFunction = location.pathname.includes(SHARED_ROUTE.replace('/', ''));
  const { name } = useParams() as {
    name: string;
  };

  const reDirectToAggregates = () => {
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    navigate(location.pathname.substring(0, lastSlashIndex));
  };

  const aggrIndex = getAggregateIndex(config, aggregate);
  const classes = useStyles();
  const aggregateInfo = getAggregate(aggrIndex);
  if (isAggrEvent) {
    if (aggregateInfo.events?.[name])
      internalDescription = aggregateInfo.events?.[name].$internalDescription || '';
    else reDirectToAggregates();
  } else if (isAggrCommand) {
    if (aggregateInfo.commands?.[name])
      internalDescription = aggregateInfo.commands?.[name].$internalDescription || '';
    else reDirectToAggregates();
  } else if (isFunction) {
    const func = getFunction(aggregate);
    if (func) internalDescription = func.$internalDescription || '';
    else reDirectToAggregates();
  }
  const AggregateAPI = useAggregateAPI();
  const FunctionAPI = useFunctionAPI();

  const toggleCommentMode = async (isEditMode: boolean, comment: string) => {
    if (isEditMode) {
      if (isAggrEvent) {
        const { error } = await AggregateAPI.addEventComment(aggrIndex, name, comment);
        if (error) return;
      } else if (isAggrCommand) {
        const { error } = await AggregateAPI.addCommandComment(aggrIndex, name, comment);
        if (error) return;
      } else if (isFunction) {
        const { error } = await FunctionAPI.addComment(aggregate, comment);
        if (error) return;
      }
    }
  };

  return (
    <Box className={classes.tabContainer}>
      <AggrCommentsContainer>
        <TGEditor
          {...{
            description: internalDescription,
            onToggle: toggleCommentMode,
            title: 'Description',
            name,
          }}
        />
      </AggrCommentsContainer>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContainer: {
      height: '100%',
    },
    internalTxt: {
      lineHeight: '100%',
    },
    editDivider: {
      borderBottom: `1.5px solid ${colors.CadetBlue}`,
      marginTop: 2,
    },
  })
);
const AggrCommentsContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  padding: 25px 34px;
  height: 100%;
  background: ${colors.greySaturated};
  border: 1px solid ${colors.black10};
  box-shadow: none;
`;
