import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { useDataMapDiagram } from 'map-editor/src';
import { propertiesToSchema } from 'pages/aggregates/utils/PropertiesToSchemaConverter';
import { convertV2FormTemplateToJsonSchema } from 'pages/aggregates/utils/V2FormTemplateToJsonSchema';
import { useEffect, useMemo } from 'react';
import { metadataSchema } from 'utils/jsonPartsGenerators';
import { useSchemaLookup } from 'utils/useSchemaLookup';
import DataMapper from '../../common/DataMapper';
import { V2FormTemplate } from '@terragotech/form-renderer';
import { UseEventMapDiagramOutput } from 'map-editor/src/Hooks/useDataMapDiagram';
import { PropertyCollection } from '@terragotech/gen5-config-lib';

export const INITIAL_DATA_MAPPING_SCENARIO = 'INITIAL_DATA_MAPPING';

interface Props {
  properties: PropertyCollection;
  formDefinition: V2FormTemplate;
  mapperRef: { current: UseEventMapDiagramOutput | null };
}

const InitialDataMap = ({ formDefinition, properties, mapperRef }: Props) => {
  const localSchemas = useMemo(() => {
    return {
      STATE: {
        schema: propertiesToSchema(properties),
        schemaLabel: 'Properties',
      },
      FORM: {
        schema: convertV2FormTemplateToJsonSchema(formDefinition),
        schemaLabel: 'Form State',
      },
      METADATA: {
        schema: metadataSchema,
        schemaLabel: 'Metadata',
      },
    };
  }, [properties, formDefinition]);

  const initialDataMapSchemaLookup = useSchemaLookup({
    localSchemas: localSchemas,
  });

  const initialDataMapDiagramOutput = useDataMapDiagram({
    dataMap:
      formDefinition.initialDataMap || mapScenarios[INITIAL_DATA_MAPPING_SCENARIO].defaultNodeMap,
    schemaLookup: initialDataMapSchemaLookup,
    scenario: INITIAL_DATA_MAPPING_SCENARIO,
  });

  mapperRef.current = initialDataMapDiagramOutput;
  useEffect(() => {
    return () => {
      mapperRef.current = initialDataMapDiagramOutput;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataMapper
      eventMapDiagramOutput={initialDataMapDiagramOutput}
      mapScenario={INITIAL_DATA_MAPPING_SCENARIO}
    />
  );
};

export default InitialDataMap;
