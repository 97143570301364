import { useConfig } from 'context/ConfigContext';
import { groupBy, keys, size } from 'lodash';
import { useMemo } from 'react';

export const NO_CATEGORY_NAME_PLACEHOLDER = 'Ad-Hoc';

export const usePageGroup = () => {
  const { config, getCustomPages } = useConfig();

  const customPages = getCustomPages();

  const hasCustomPages = size(keys(customPages)) !== 0;

  const pageGroupList = useMemo(() => {
    const groupNames = [NO_CATEGORY_NAME_PLACEHOLDER, ...config?.aggregates.map((a) => a.typeName)];
    const groups = groupBy(
      Object.entries(customPages),
      ([_, page]) => page.aggregateType ?? NO_CATEGORY_NAME_PLACEHOLDER
    );

    return groupNames.reduce((acc, groupName) => {
      acc.push({
        groupName,
        pageNames: groups[groupName]?.map(([pageName, _]) => pageName) ?? [],
      });
      return acc;
    }, [] as Array<{ groupName: string; pageNames: string[] }>);
  }, [config?.aggregates, customPages]);

  return { pageGroupList, hasCustomPages, customPages };
};
