import React, { useState } from 'react';
import { IconButton, makeStyles, Button, Box, Typography, Tooltip } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import {
  MapScenarioType,
  NodeMapDefinition,
  mapScenarios,
} from '@terragotech/gen5-datamapping-lib';
import { cloneDeep } from 'lodash';
import { successMsg } from '../SnackbarUtilsConfigurator';
import DataMapperDialog from '../FormDialog/DataMapperDialog';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';

interface Props {
  title: string;
  data: NodeMapDefinition[];
  setData: (data: NodeMapDefinition[] | undefined) => void;
  style?: React.CSSProperties;
  mapScenario: keyof MapScenarioType;
  localSchemas?: LocalSchemaDefinition;
  containerStyle?: string;
}

export const MapperList = ({
  title,
  data,
  setData,
  mapScenario,
  localSchemas,
  containerStyle,
}: Props) => {
  const classes = useStyles();
  const [openedIndex, setOpenedIndex] = useState<number | undefined>(undefined);
  const handleSaveMapperData = (dataItem: NodeMapDefinition | undefined) => {
    if (dataItem) {
      const newData = cloneDeep(data);
      newData[openedIndex || 0] = dataItem;
      setData(newData);
      successMsg('The mapping has been successfully saved.');
    }
  };
  const { openConfirmation } = useConfirmDialog();

  const handleOpenMapper = (index: number) => {
    setOpenedIndex(index);
  };

  const handleDeleteMappingItem = async (index: number) => {
    const status = await openConfirmation(CONFIRMATION.mapperList);
    if (status === 'confirm') {
      const newData = cloneDeep(data);
      newData.splice(index, 1);
      setData(newData.length !== 0 ? newData : undefined);
      successMsg('New mapping has been successfully deleted');
    }
  };

  const handleAddNewMappingItem = () => {
    const newData = cloneDeep(data);
    newData.push({ ...mapScenarios[mapScenario].defaultNodeMap });
    setData(newData);
    successMsg('New mapping has been successfully added');
  };

  const defaultWarningMapping = {
    nodes: {
      FORM: { type: 'FORM', config: {}, inputs: {} },
      FORM_WARNING: {
        type: 'FORM-WARNING',
        config: {},
        inputs: {
          warningMessage: {
            sourceObject: 'newNode5',
            sourcePath: '$.output',
          },
        },
      },
      newNode1: { type: 'METADATA', config: {} },
      newNode2: {
        type: 'DISTANCE-CALC',
        config: { distanceUnit: 'feet' },
        inputs: {
          secondLatitude: {
            sourceObject: 'newNode3',
            sourcePath: '$.lat',
          },
          secondLongitude: {
            sourceObject: 'newNode3',
            sourcePath: '$.lon',
          },
          firstLatitude: {
            sourceObject: 'newNode1',
            sourcePath: '$.latitude',
          },
          firstLongitude: {
            sourceObject: 'newNode1',
            sourcePath: '$.longitude',
          },
        },
      },
      newNode3: { type: 'STATE', config: {} },
      newNode4: {
        type: 'NUMBER-GREATER-THAN',
        config: {},
        inputs: {
          compareNumber: '75',
          baseNumber: {
            sourceObject: 'newNode2',
            sourcePath: '$.output',
          },
        },
      },
      newNode5: {
        type: 'CONDITIONAL-MAPPER',
        config: {},
        inputs: {
          booleanSource: {
            sourceObject: 'newNode4',
            sourcePath: '$.isGreater',
          },
          trueSource:
            'You are more than 75 Feet from the selected feature. Please verify your location.',
        },
      },
    },
    outputDefinition: { outputNode: 'FORM_WARNING' },
  } as const;

  const addNewDefaultMappingItem = () => {
    const newData = cloneDeep(data);
    newData.push(defaultWarningMapping);
    setData(newData);
    successMsg('New mapping has been successfully added');
  };
  const styles = {
    emptyContainer: {
      fontSize: 14,
      color: colors.black54,
      padding: '1px 23px',
    },
    tableCell: {
      width: 49,
    },
  };
  return (
    <Box className={`${classes.container} ${containerStyle ?? ''}`}>
      <Box className={classes.header}>
        <Typography className={classes.headerText}>{title}</Typography>
        <Box>
          <IconButton onClick={handleAddNewMappingItem} className={classes.iconButton}>
            <FontAwesomeIcon icon={faPlus} className={classes.icon} />
          </IconButton>
          {title === 'Warning Mapping' && (
            <Tooltip title="Proximity Mapping">
              <IconButton
                onClick={addNewDefaultMappingItem}
                className={`${classes.iconButton} ${classes.mapperIcon}`}
              >
                <MyLocationIcon className={classes.addIcon} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      {data.length === 0 ? (
        <p style={styles.emptyContainer}>Mapping list is empty</p>
      ) : (
        <Box className={classes.table}>
          {data.map((_, index) => (
            <Box
              key={index}
              className={`${classes.row} ${data.length - 1 !== index && classes.border}`}
            >
              <Button
                color="primary"
                onClick={() => handleOpenMapper(index)}
                className={classes.mapperButton}
              >
                Mapper
              </Button>
              <IconButton
                className={classes.iconButton}
                component="span"
                onClick={() => handleDeleteMappingItem(index)}
              >
                <FontAwesomeIcon icon={faMinus} className={classes.icon} />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}

      <DataMapperDialog
        onClose={() => {
          setOpenedIndex(undefined);
        }}
        open={typeof openedIndex === 'number'}
        datamap={data[openedIndex || 0] as NodeMapDefinition}
        setDatamap={(dataItem) => handleSaveMapperData(dataItem)}
        mapScenario={mapScenario}
        localSchemaDefinitions={localSchemas}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    margin: '10px 0',
    overflow: 'hidden',
  },
  header: {
    backgroundColor: colors.black5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 23,
    paddingRight: 7,
    height: 45,
  },
  headerText: {
    fontSize: 15,
    fontWeight: 400,
    color: colors.black,
  },
  table: {
    backgroundColor: colors.grayBackground,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 7px 3px 16px',
    backgroundColor: colors.white,
    height: 55,
  },
  icon: {
    color: colors.black54,
    fontSize: 21,
  },
  border: {
    borderBottom: `1px solid ${colors.black10}`,
  },
  addIcon: {
    fontSize: 21,
    color: colors.black54,
  },
  mapperButton: {
    fontSize: 15,
    fontWeight: 500,
  },
  iconButton: {
    color: colors.black54,
  },
  mapperIcon: {
    marginLeft: 5,
  },
});
