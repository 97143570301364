import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '@terragotech/gen5-shared-components'
import React from 'react';

export enum TGLoginButtonType {
  primary = 'primary',
  secondary = 'secondary',
  small = 'small',
}

interface TGLoginButtonProps {
  type?: TGLoginButtonType;
  onClick?: (arg0: any) => void;
  children?: React.ReactNode;
}

const TGLoginButton: React.FC<TGLoginButtonProps> = (props: any) => {
  const { type } = props;
  const classes = useStyles();
  if (type === 'primary') {
    return (
      <Button variant="contained" color="primary" type="submit" className={classes.primaryButton} {...props}>
        {props.children}
      </Button>
    );
  }
  if (type === 'secondary') {
    return (
      <Button variant="contained" color="primary" type="submit" className={classes.secondaryButton} {...props}>
        {props.children}
      </Button>
    );
  }
  if (type === 'small') {
    return (
      <Button variant="contained" size="small" className={classes.smallButton} {...props}>
        {props.children}
      </Button>
    );
  }
  return <Button {...props}>{props.children}</Button>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      border: `${theme.palette.primary.main} 2px solid`,
      boxShadow:
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
      color: colors.white,
      padding: '6px 16px',
      '&:hover': { opacity: 0.9 },
    },
    secondaryButton: {
      backgroundColor: `${colors.white} !important`,
      '&:hover': { opacity: 0.9 },
      border: `${theme.palette.primary.main} 2px solid`,
      boxShadow:
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
      color: theme.palette.primary.main,
      padding: '6px 16px',
    },
    smallButton: {
      boxShadow: '0px 0.662791px 1.98837px rgba(0, 0, 0, 0.4)',
      color: colors.black,
      '&:hover': { opacity: 0.9 },
      marginLeft: 20,
    },
  })
);

export default TGLoginButton;
