import React from 'react';
import { TGSelect } from 'views/components/formElements';

type Options = Array<{ name: string }>;

interface OptionSelector<T> {
  option: T;
  handleOnChange: (key: T) => void;
  options: Options;
  style?: React.CSSProperties;
}

const OptionSelector = <T extends string>({
  handleOnChange,
  options,
  option,
  style,
}: OptionSelector<T>) => {
  const getOptions = () => options.map((option) => option.name);
  return (
    <TGSelect
      id="option_Selector"
      value={option}
      onChange={(value) => handleOnChange(value as T)}
      options={getOptions()}
      style={{ ...styles.root, ...style }}
      containerCss={styles.container}
    />
  );
};
const styles = {
  root: {
    marginRight: 10,
    background: 'white',
    width: 120,
  },
  container: {
    margin: 0,
  },
};
export default OptionSelector;
