import { ChangeEvent, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import { colors } from '../../../../../utils/colors';
import { COMMAND_TYPE_OPTIONS, CONFIRMATION } from '../../../../../utils/Utils';
import { TextInputTG, TGSelect } from 'views/components/formElements';
import { map } from 'lodash';
import { useConfirmDialog } from 'context/ConfirmContext';

export type Option = { key: string; value: string };

export interface CommandTypeOption {
  selected: boolean;
  type?: 'select' | 'text' | 'number';
  label: string;
  value?: Option[] | string;
  style?: string;
}

export type CommandType = 'BUTTON' | 'FORM' | 'IMPORT';
export type CreateCommand = 'aggregate' | 'nonaggregate';

interface CommandTypeConfigurationProps {
  commandType: CommandType;
  setCommandType: (val: CommandType) => void;
  isImportIntegration: boolean;
  setIsImportIntegration: (checked: boolean) => void;
  fileType: string;
  onChangeTextFileType: (val: string) => void;
  commandTypeOptions: CommandTypeOption[];
  onChangeOption: (checked: boolean, index: number) => void;
  createCommandType: string;
  setCreateCommandType: (val: CreateCommand) => void;
  onChangeText: (value: string, key: string) => void;
  emptyIsNull: boolean;
  setEmptyIsNull: (val: boolean) => void;
  resetFormDefinition: () => void;
}

const CommandTypeConfiguration = ({
  commandType,
  setCommandType,
  isImportIntegration,
  setIsImportIntegration,
  fileType,
  onChangeTextFileType,
  onChangeOption,
  createCommandType,
  setCreateCommandType,
  onChangeText,
  commandTypeOptions,
  emptyIsNull,
  setEmptyIsNull,
  resetFormDefinition,
}: CommandTypeConfigurationProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);
  const { openConfirmation } = useConfirmDialog();

  const onCommandTypeChange = async (value: string) => {
    const key = COMMAND_TYPE_OPTIONS.find((o) => o.value === value)?.key as CommandType;
    setCommandType(key);
  };
  const onChangeCmdIntegration = async (checked: boolean) => {
    setIsImportIntegration(checked);
  };

  const onCommandNameChange = (options: Option[], value: string) => {
    const key = options.find((o) => o.value === value)?.key as CreateCommand;
    setCreateCommandType(key);
  };

  const handleChange = (event: ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  const handleConfirmation = async (
    action: 'import' | 'integration' | '',
    value: boolean | string
  ) => {
    const props = CONFIRMATION.commandType({ action });
    const dialogContainerStyle = action === 'import' ? { width: 550, padding: 20 } : undefined;
    const status = await openConfirmation({ ...props, dialogContainerStyle });
    if (status === 'confirm') {
      switch (action) {
        case 'import': {
          onCommandTypeChange(value as string);
          resetFormDefinition();
          break;
        }
        case 'integration': {
          onChangeCmdIntegration(value as boolean);
          break;
        }
        default:
      }
    }
  };

  return (
    <Accordion className={classes.commandTypeContainer} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<KeyboardArrowRightOutlined className={classes.expandIcon} />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
        className={classes.accordSummary}
      >
        <Box className={classes.accordHead} onClick={(e) => e.stopPropagation()}>
          <Typography className={classes.commandTypeTxt}>Command Type</Typography>
          <TGSelect
            id="commandType"
            options={map(COMMAND_TYPE_OPTIONS, 'value')}
            containerStyle={classes.selectContainer}
            value={COMMAND_TYPE_OPTIONS.find((o) => o.key === commandType)?.value || ''}
            onChange={(value) => {
              if (value === 'Import') {
                handleConfirmation('import', value);
              } else {
                onCommandTypeChange(value);
              }
            }}
          />
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.accordDetail}>
        {commandType === 'IMPORT' ? (
          <Box className={classes.fileTypeContainer}>
            {!isImportIntegration && (
              <Box className={classes.intgrationCheckbox}>
                <Checkbox
                  checked={emptyIsNull}
                  className={classes.checkBox}
                  onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                    setEmptyIsNull(checked);
                  }}
                  color="primary"
                />
                <Typography className={classes.commandLabel}>Treat Empty Cells as Null</Typography>
              </Box>
            )}
            <Box className={[classes.intgrationCheckbox, classes.firstElement].join(' ')}>
              <Checkbox
                checked={isImportIntegration}
                className={classes.checkBox}
                onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                  if (checked) {
                    handleConfirmation('integration', checked);
                  } else {
                    onChangeCmdIntegration(checked);
                  }
                }}
                color="primary"
              />
              <Typography className={classes.commandLabel}>Integration Import</Typography>
            </Box>
            {isImportIntegration && (
              <Box className={classes.fileTypeBox}>
                <Typography className={classes.fileTypeLabel}>
                  Comma-separated allowed file types
                </Typography>
                <TextInputTG
                  value={fileType as string}
                  inputStyle={classes.fileTypeInput}
                  disabled={!isImportIntegration}
                  onChange={onChangeTextFileType}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Grid container>
            {commandTypeOptions.map((commandOption, index) => {
              return (
                <Grid
                  key={commandOption.label}
                  item
                  sm={6}
                  className={classes.commandOptionContainer}
                >
                  <Box className={classes.rootContainer}>
                    <Checkbox
                      checked={commandOption.selected}
                      className={classes.checkBox}
                      onChange={(e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
                        onChangeOption(checked, index);
                      }}
                      color="primary"
                    />
                    <Typography className={classes.commandLabel}>{commandOption.label}</Typography>
                  </Box>
                  {commandOption.type === 'select' && commandOption.selected && (
                    <TGSelect
                      id="aggregate"
                      options={map(commandOption.value as Option[], 'value')}
                      containerStyle={classes.agregateSelectContainer}
                      value={
                        (commandOption?.value as Option[])?.find((o) => o.key === createCommandType)
                          ?.value || ''
                      }
                      onChange={(value) =>
                        onCommandNameChange(commandOption.value as Option[], value)
                      }
                      disabled={!commandOption.selected}
                    />
                  )}
                  {(commandOption.type === 'text' || commandOption.type === 'number') &&
                    commandOption.selected && (
                      <TextInputTG
                        type={commandOption.type}
                        value={commandOption.value as string}
                        disabled={!commandOption.selected}
                        inputStyle={commandOption.style}
                        containerStyle={commandOption.style}
                        onChange={(value) => {
                          onChangeText(value, commandOption.label);
                        }}
                      />
                    )}
                </Grid>
              );
            })}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectContainer: {
      pointerEvents: 'auto',
      '&.MuiFormControl-root': {
        width: 142,
      },
      '& .MuiSelect-root': {
        height: 30,
        fontSize: 15,
      },
    },
    firstElement: {
      marginTop: 10,
    },
    rootContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    agregateSelectContainer: {
      '&.MuiFormControl-root': {
        width: 200,
        position: 'relative',
        left: 38,
        top: 3,
      },
      '& .MuiSelect-root': {
        height: 30,
        fontSize: 14,
      },
    },
    inputStyle: {
      border: `1px solid ${colors.black25}`,
      fontSize: 15,
    },
    menuStyle: {
      fontSize: 15,
    },
    expandIcon: {
      pointerEvents: 'auto',
    },
    commandTypeContainer: {
      boxShadow: 'none',
      backgroundColor: colors.gray99,
      borderRadius: 5,
      padding: '5px 3px',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
      '&.Mui-expanded': {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    accordSummary: {
      flexDirection: 'row-reverse',
      alignItems: 'center',
      marginBottom: -1,
      padding: 10,
      paddingLeft: 2,
      minHeight: 45,
      height: 0,
      '&.Mui-expanded': {
        minHeight: 45,
      },
      '& .MuiAccordionSummary-expandIcon': {
        padding: 8,
        marginRight: 0,
        '&.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      },
      pointerEvents: 'none',
    },
    accordDetail: {
      padding: '11px 32px 17px 31px',
    },
    accordHead: {
      display: 'flex',
      alignItems: 'center',
    },
    commandTypeTxt: {
      fontSize: 15,
      color: colors.black,
      marginRight: 9,
      fontWeight: 500,
    },
    commandOptionContainer: {
      margin: '5px 0',
    },
    commandLabel: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
      marginRight: 10,
      flexShrink: 0,
    },
    intgrationCheckbox: {
      display: 'flex',
      alignItems: 'center',
    },
    fileTypeContainer: {
      margin: '5px 0',
    },
    fileTypeBox: {
      marginLeft: 35,
    },
    fileTypeLabel: {
      color: colors.black54,
      fontSize: 13,
      marginTop: 14,
      marginBottom: 5,
    },
    fileTypeInput: {
      fontSize: 14,
      width: 440,
    },
    checkBox: {
      width: 28,
      height: 28,
      marginRight: 8,
    },
  })
);

export default CommandTypeConfiguration;
