import { configType } from 'utils/Utils';
import { ActionButtonEditor } from './ActionButtonEditor';

export const getTitle = (type: ActionButtonEditor) => {
  switch (type) {
    case 'editorWorkflows':
      return 'Editor Workflows';
    case 'editorActions':
      return 'Editor Actions';
    case 'multiSelectActions':
      return 'Multiselect Actions';
    case 'tableActions':
      return 'Table Actions';
    default:
      return '';
  }
};

export const getAdditionalElements = (type: ActionButtonEditor, config: string) => {
  const mobileAdditionalActionOptions = ['directions', 'streetView', 'assign', 'updateLocation'];
  const webAdditionalActionOptions = [
    'csvExport',
    'delete',
    'directions',
    'streetView',
    'pdfExport',
  ];
  switch (type) {
    case 'editorActions':
    case 'tableActions':
    case 'multiSelectActions':
      if (config === configType.ALL.type || config === configType.WEB.type) {
        return webAdditionalActionOptions;
      }
      return mobileAdditionalActionOptions;
    case 'editorWorkflows':
    default:
      return [];
  }
};
