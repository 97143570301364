import { Box, alpha, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import 'utils/antDesignCss.css';
require('json-schema-editor-visual/dist/main.css');
import schemaEditor from 'json-schema-editor-visual/dist/main.js';
import { JSONSchema6 } from 'json-schema';
import useRouteBlocker from 'common/useBlocker';
import { useBlocker } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import { eventEditorSchema } from 'pages/aggregates/utils/eventEditorSchema';
import { useCallback } from 'react';

interface Props {
  onChange: (schema: JSONSchema6) => void;
  schemaRef: { current: {} | JSONSchema6 };
  schemaValue: JSONSchema6;
  onSave: () => void;
}

const SchemaEditor = ({ onChange, schemaRef, schemaValue, onSave }: Props) => {
  const classes = useStyles();
  let actualSchema: JSONSchema6 = {};
  const option = { data: eventEditorSchema };
  const Editor = schemaEditor(option);

  const clearSchema = () => {
    schemaRef.current = {};
  };

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    const isBlocked =
      currentLocation.pathname !== nextLocation.pathname && !isEqual(actualSchema, schemaValue);
    if (!isBlocked) {
      clearSchema();
    }
    return isBlocked;
  });

  useRouteBlocker({ blocker, onSave, onDiscard: clearSchema });

  const onUpdate = useCallback(
    (schema: JSONSchema6) => {
      if (blocker.state !== 'blocked') {
        schemaRef.current = schema;
        onChange(schema);
      }
    },
    [blocker.state, schemaRef, onChange]
  );

  const schemaFinalData = !isEmpty(schemaRef.current) ? schemaRef.current : schemaValue;
  const schemaData = JSON.stringify(schemaFinalData);

  return (
    <Box className={classes.schemaEditorContainer}>
      <Editor
        onChange={(schema: string) => {
          let parsedSchema: JSONSchema6 = JSON.parse(schema);
          actualSchema = parsedSchema;
          onUpdate(parsedSchema);
        }}
        data={schemaData}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      [`& .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
        .ant-select-dropdown-menu-item-selected,
        .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
        .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover`]: {
        backgroundColor: alpha(theme.palette.primary.light, 0.2),
      },
      '& .ant-select-dropdown-menu-item-selected': {
        color: colors.black54,
      },
      '& .ant-input:hover, .ant-input:active, .ant-input:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.light, 0.2)}`,
      },
      '& .col-item-mock .ant-input, .col-item-desc .ant-input': {
        borderRight: 'none',
      },
      '& .ant-btn:hover': {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '& .ant-btn.ant-btn-primary': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: theme.palette.primary.dark,
          borderColor: theme.palette.primary.main,
          color: colors.white,
        },
      },
    },
  },
  schemaEditorContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    flex: 1,
    '& .json-schema-react-editor': {
      padding: '40px 0 40px 25px',

      '& .wrapper': {
        paddingLeft: 0,
      },
      '& .ant-col.object-style:first-child': {
        paddingTop: 0,
        marginTop: 0,
      },
      '& .ant-col-8': {
        width: '25.3333333%',
      },
      '& .ant-col-3': {
        width: '16.5%',
      },
      '& .ant-col-3.col-item-setting': {
        width: '9.5%',
      },
      '& .ant-col-5': {
        width: '24.3333%',
      },
      [`& .ant-select-selection:hover,
        .ant-select-selection:focus,
        .ant-select-selection:active,
        .ant-select-auto-complete.ant-select .ant-input:hover,
        .ant-input:hover,
        .ant-input:focus,
        .ant-checkbox-checked::after,
        `]: {
        borderColor: theme.palette.primary.main,
      },
      [`& .ant-input:focus,
        .ant-select-selection:focus`]: {
        boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.light, 0.2)}`,
      },
      '& .ant-checkbox-inner': {
        borderColor: colors.black54,
      },
      [`& .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner`]: {
        borderColor: theme.palette.primary.main,
      },
      '& .ant-checkbox-checked .ant-checkbox-inner': {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
      '& .ant-input-wrapper.ant-input-group .ant-input-group-addon': {
        padding: '0 10px 0 20px',
      },
    },
  },
}));

export default SchemaEditor;
