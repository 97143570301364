import { FC, useEffect, useRef, useState } from 'react';
import { Box, makeStyles, styled as muiStyled } from '@material-ui/core';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { TextInputTG } from './TextInputTG';

interface ColorPickerProps {
  title?: string;
  value: string;
  onChange?: (value: string) => void;
  classes?: {
    input?: string;
    label?: string;
    container?: string;
    picker?: string;
    palette?: string;
  };
  disabled?: boolean;
  summary?: string;
}

const Container = muiStyled(Box)({
  alignItems: 'center',
  position: 'relative',
});
const Input = styled.input`
  position: absolute;
  top: -8px;
  left: 2px;
  width: 0px;
  height: 0px;
  z-index: 1;
`;

export const TGColorPicker: FC<ColorPickerProps> = ({
  title,
  value,
  onChange,
  classes,
  disabled,
}) => {
  const clx = useStyles();
  const [color, setColor] = useState(value);
  const colorPicker = useRef<HTMLInputElement | null>(null);

  const onBlur = () => {};

  const onChangeColor = (value: string) => {
    if (onChange) {
      setColor(value);
      onChange(value);
    }
  };

  useEffect(() => {
    setColor(value);
  }, [value]);

  const onPalletClick = () => {
    if (colorPicker?.current !== null) {
      colorPicker?.current.click();
    }
  };

  return (
    <Container className={classes?.container}>
      <Box className={clx.formGroup}>
        <TextInputTG
          id={'colorPicker'}
          value={color}
          label={title as string}
          containerStyle={classes?.input}
          labelRoot={classes?.label}
          onBlur={onBlur}
          onChange={onChangeColor}
        />
        <Box className={clx.inputContainer}>
          <Box className={`${clx.button} ${classes?.picker}`} onClick={onPalletClick}>
            <Box
              className={`${clx.colorPallette} ${classes?.palette}`}
              style={{ backgroundColor: color }}
            />
          </Box>
          <Input
            ref={colorPicker}
            className={classes?.input}
            type="color"
            value={color}
            onChange={(e) => onChangeColor(e.target.value)}
            onBlur={onChange && (() => onChange(color))}
            disabled={disabled}
          />
        </Box>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles({
  inputContainer: {
    position: 'relative',
  },
  button: {
    width: 70,
    height: 36,
    borderRight: `1px solid ${colors.black15}`,
    backgroundColor: colors.lotion,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 11,
    top: -37,
    left: 1,
    cursor: 'pointer',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  colorPallette: {
    backgroundColor: colors.black,
    width: '75%',
    height: '70%',
    borderRadius: 5,
  },
  formGroup: {
    position: 'relative',
    '& .MuiInputBase-input': {
      paddingLeft: 80,
    },
  },
});
