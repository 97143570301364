import { ChangeEvent, useState } from 'react';
import { Box, Typography, Checkbox } from '@material-ui/core';
import TabPanel from '../../TabPanel';
import HeaderInfo from './HeaderInfo';
import { useStyles } from './style';
import {
  AttributeBasedFilterWithID,
  QueryBuilder,
} from 'pages/aggregates/components/QueryEditor/QueryBuilder';
import { propertiesObjsToArray } from '../../../../../../pages/aggregates/utils/propertiesObjsToArray';
import { AggregateConfig } from '@terragotech/gen5-config-lib';
import cloneDeep from 'lodash/cloneDeep';
import { AttributeBasedFilter } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { QueryItemWithID } from '../../../../../../components/FormDialog/QueryFilterEditorDialog';
import { AggregateInfoValueProp } from '../../../Settings';

interface Props {
  tabValue: number;
  aggrInfo: Partial<AggregateConfig>;
  aggregateInfoOnChange: (key: string, value: AggregateInfoValueProp) => void;
}

export const PermissionTab = ({ tabValue, aggrInfo, aggregateInfoOnChange }: Props) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const createRowData = (label: string) => {
    return { label };
  };
  const tabs = ['Row Level Permission', 'Public Access'].map(createRowData);

  const onChangeTab = (value: number) => {
    setActiveTab(value);
  };

  const removeIDs = (item: QueryItemWithID) => {
    //@ts-ignore
    delete item.id;
    if (item.type === 'GroupFilter') {
      item.condition.map((item) => removeIDs(item as QueryItemWithID));
    }
    return item;
  };

  const handleQueryChange = (query: AttributeBasedFilterWithID, isRLP: boolean) =>
    aggregateInfoOnChange(
      isRLP ? 'attributeBasedAccessRule' : 'publicAccessRule',
      removeIDs(cloneDeep(query)) as AttributeBasedFilter
    );

  const tabViewClass = (index: number) =>
    `${classes.innerTabPanel} ${activeTab === index && classes.activTab}`;
  const publicStyle = { marginBottom: aggrInfo.isPublic ? 30 : 0 };
  return (
    <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
      <Box className={classes.container}>
        <Box className={classes.tabBar}>
          {tabs.map((tab, index) => (
            <Box
              key={tab.label}
              className={`${classes.tabBtn} ${activeTab === index && classes.activeTab}`}
              onClick={() => onChangeTab(index)}
            >
              <Typography
                className={`${classes.tabBtnTxt} ${activeTab === index && classes.tabActiveTxt}`}
              >
                {tab.label}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.body}>
          <Box className={tabViewClass(0)}>
            <HeaderInfo
              {...{
                title: 'Row Level Permission',
                description: '',
              }}
            />
            <QueryBuilder
              keys={propertiesObjsToArray(aggrInfo.properties)}
              onQueryChange={(query) => handleQueryChange(query, true)}
              query={aggrInfo.attributeBasedAccessRule}
              panelTitle="RLP"
            />
          </Box>
          <Box className={tabViewClass(1)}>
            <Box className={classes.gridContainer} style={publicStyle}>
              <Checkbox
                checked={aggrInfo.isPublic}
                color="primary"
                className={classes.checkbox}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  aggregateInfoOnChange('isPublic', event?.target?.checked || undefined)
                }
              />
              <HeaderInfo
                {...{
                  title: 'Public Access Rules',
                  description: '',
                  containerStyle: `${classes.pt3} ${!aggrInfo.isPublic ? classes.bottom0 : ''}`,
                }}
              />
            </Box>
            <Box className={classes.fullWidth}>
              {aggrInfo.isPublic && (
                <QueryBuilder
                  keys={propertiesObjsToArray(aggrInfo.properties)}
                  onQueryChange={(query) => handleQueryChange(query, false)}
                  query={aggrInfo.publicAccessRule}
                  panelTitle="PAR 1"
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </TabPanel>
  );
};
