import React, { ReactNode } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import {
  AttributeBasedFilter,
  AttributeBasedFilterSimpleFilters,
} from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import ConditionGroup from './ConditionGroup';
import { defaultTranslations, Translations } from './defaults/defaultTranslations';
import { defaultOperators, Operators } from './defaults/defaultOperators';
import { defaultConjunctions, Conjunctions } from './defaults/defaultConjunctions';
import { defaultControlElements, ControlElements } from './defaults/defaultControlElements';
import { findCondition } from './utils/findCondition';
import { generateValidQuery } from './utils/generateValidQuery';
import { createConditionGroup } from './utils/createConditionGroup';
import { isConditionGroupWithId } from './utils/isConditionGroup';
import { QueryBuilderProperty } from './controls/OptionEditor';

export type Key = { name: string; type: string; relation?: string };

interface QueryBuilderProps {
  query: AttributeBasedFilter | undefined;
  keys: Key[];
  onQueryChange: (query: AttributeBasedFilterWithID) => void;
  operators?: Operators;
  conjunctions?: Conjunctions;
  translations?: Translations;
  controlElements?: ControlElements;
  showNotToggle?: boolean;
  allowValue?: boolean;
  panelTitle: string;
  panelTitleStyle?: string;
  aggregateType?: ReactNode | null;
  onRemove?: () => void;
  pannelHeaderStyle?: string;
}

export type AttributeBasedFilterWithID = {
  id: string;
  type: 'GroupFilter';
  conjunction: 'AND' | 'OR';
  relation?: string;
  condition: Array<AttributeBasedFilterWithID | AttributeBasedFilterSimpleFiltersWithID>;
  not?: boolean;
};

export type AttributeBasedFilterSimpleFiltersWithID = AttributeBasedFilterSimpleFilters & {
  id: string;
};

export interface QueryBuilderSchema {
  keys: Key[];
  controls: ControlElements;
  operators: Operators;
  conjunctions: Conjunctions;
  onGroupAdd: (group: AttributeBasedFilterWithID, parentId: string) => void;
  onGroupRemove: (groupId: string, parentId: string | null) => void;
  onConditionAdd: (condition: AttributeBasedFilterSimpleFiltersWithID, parentId: string) => void;
  onConditionRemove: (conditionId: string, parentId: string) => void;
  onPropChange: (property: string, option: QueryBuilderProperty, conditionId: string) => void;
  showNotToggle: boolean | undefined;
}

export const QueryBuilder: React.FC<QueryBuilderProps> = ({
  query,
  conjunctions,
  onQueryChange,
  keys,
  operators,
  showNotToggle,
  controlElements,
  translations,
  allowValue,
  panelTitle,
  panelTitleStyle,
  aggregateType,
  onRemove,
  pannelHeaderStyle,
}) => {
  const root = generateValidQuery(
    query ? query : createConditionGroup()
  ) as AttributeBasedFilterWithID;

  const onGroupAdd = (group: AttributeBasedFilterWithID, parentId: string) => {
    const rootCopy = cloneDeep(root);
    const parent = findCondition(parentId, rootCopy);
    if (parent && isConditionGroupWithId(parent)) {
      parent.condition.push(group);
      onQueryChange(rootCopy);
    }
  };

  const onGroupRemove = (groupId: string, parentId: string | null) => {
    const rootCopy = cloneDeep(root);
    const parent = findCondition(parentId, rootCopy);
    if (parent && isConditionGroupWithId(parent)) {
      const index = parent.condition.findIndex((x: { id: string }) => x.id === groupId);
      parent.condition.splice(index, 1);
      onQueryChange(rootCopy);
    }
  };

  const onConditionAdd = (condition: AttributeBasedFilterSimpleFiltersWithID, parentId: string) => {
    const rootCopy = cloneDeep(root);
    const parent = findCondition(parentId, rootCopy);
    if (parent && isConditionGroupWithId(parent)) {
      parent.condition.push({
        ...condition,
      });
      onQueryChange(rootCopy);
    }
  };

  const onConditionRemove = (conditionId: string, parentId: string) => {
    const rootCopy = cloneDeep(root);
    const parent = findCondition(parentId, rootCopy);
    if (parent && isConditionGroupWithId(parent)) {
      const index = parent.condition.findIndex((x: { id: string }) => x.id === conditionId);
      parent.condition.splice(index, 1);
      onQueryChange(rootCopy);
    }
  };

  const onPropChange = (property: string, value: QueryBuilderProperty, conditionId: string) => {
    const rootCopy = cloneDeep(root);
    const condition = findCondition(conditionId, rootCopy);
    if (property === 'key' && condition) {
      condition.relation = keys.find((key) => key.name === value)?.relation;
    }
    if (condition) {
      Object.assign(condition, { [property]: value });
    }
    onQueryChange(rootCopy);
  };

  const schema: QueryBuilderSchema = {
    keys,
    controls: { ...defaultControlElements, ...controlElements },
    operators: operators || defaultOperators,
    conjunctions: conjunctions || defaultConjunctions,
    onGroupAdd,
    onGroupRemove,
    onConditionRemove,
    onConditionAdd,
    onPropChange,
    showNotToggle,
  };

  return (
    <ConditionGroup
      pannelHeaderStyle={pannelHeaderStyle}
      id={root.id}
      parentId={null}
      conjunction={root.conjunction}
      condition={root.condition}
      translations={{ ...defaultTranslations, ...translations }}
      schema={schema}
      not={root.not}
      allowValue={allowValue}
      panelTitle={panelTitle}
      panelTitleStyle={panelTitleStyle}
      aggregateType={aggregateType}
      onRemove={onRemove}
    />
  );
};
