/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react';
import { PortWidget, DiagramEngine, PortModel } from '@projectstorm/react-diagrams';
import styled from 'styled-components';
import { InLinkPort, OutLinkPort } from './Ports';
import { ConfigProperty } from './ConfigPropertyComponents/ConfigPropertyComponents';
import SchemaDefinedPortModel from '../../DiagramPorts/SchemaDefinedPort';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { colors } from 'utils/colors';

export const BASE_HEIGHT = 24;
export const PropertyListFrame = styled.div`
  color: #000000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  background-color: rgba(200, 30, 30, 0);
`;
export const PropertyFrame = styled.div`
  width: 100%;
  height: ${BASE_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  color: #fff;
`;
const InLinkFrame = styled.div`
  height: ${BASE_HEIGHT}px;
  overflow: visible;
  display: flex;
  align-items: center;
`;
const OutLinkFrame = styled.div`
  min-width: ${BASE_HEIGHT}px;
  height: ${BASE_HEIGHT}px;
  overflow: visible;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;
const TypeFrame = styled.div`
  margin-left: 8px;
  min-width: ${BASE_HEIGHT}px;
  height: ${BASE_HEIGHT}px;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: ${BASE_HEIGHT / 2}px;
`;
const NameFrame = styled.div`
  flex: auto 1 0;
  height: ${BASE_HEIGHT}px;
  overflow: visible;
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-size: ${BASE_HEIGHT / 2}px;
`;
export const Indentation = styled.div<{ indent: number }>`
  width: ${(props) => props.indent * 10}px;
  display: flex;
`;
const style = {
  label: {
    color: colors.black,
  },
  portWidget: {
    display: 'flex',
    position: 'absolute',
    right: '-8px',
  },
  portWidgetFlex: {
    display: 'flex',
  },
};
interface PropertyProps<PORTMODEL extends PortModel> {
  port: PORTMODEL | null;
  engine: DiagramEngine;
  type: string;
  portName?: string;
  hideDisconnected?: boolean;
  configMode?: boolean;
  onRemove?: () => void;
  readOnly?: boolean;
  collapsed?: boolean;
  categoryColor?: string;
}

export const InboundProperty: React.FunctionComponent<PropertyProps<SchemaDefinedPortModel>> = ({
  portName,
  type,
  hideDisconnected,
  engine,
  port,
  onRemove,
  collapsed,
  categoryColor,
}) => {
  // We're using useState here to ensure this is only evaluated once
  const [isFixedValue, setIsFixedValue] = useState(port?.getFixedValue() !== undefined);
  const [, setFixedValue] = useState(port?.getFixedValue());
  const [localCollapsed, setLocalCollapsed] = useState(collapsed);

  useEffect(() => {
    if (localCollapsed !== collapsed) {
      setLocalCollapsed(collapsed);
    }
  }, [localCollapsed, collapsed]);

  port.updateFixedValue = (val: string): void => {
    if (port) {
      const links = port.getLinks();
      if (links) {
        Object.values(links).forEach((link) => {
          link.remove();
        });
        engine.repaintCanvas();
      }
      // This is needed right now even though it isn't used. It essentially tells react to re-render since react-diagram's paradigm is to mutate data

      setFixedValue(val);
      port.setFixedValue(val);
      if (val !== undefined) {
        setIsFixedValue(true);
      } else {
        setIsFixedValue(false);
      }
    }
  };
  const updateFixedValue = useCallback(
    (val: string) => {
      port.updateFixedValue(val);
    },
    [port]
  );
  const connectedPort = port?.getSingleConnectedPort();
  useEffect(() => {
    if (isFixedValue && connectedPort) {
      setIsFixedValue(false);
    }
  }, [connectedPort, isFixedValue]);

  let portNameLabel = portName;
  if (port) {
    if (isFixedValue) {
      portNameLabel = `${portNameLabel} = `;
    } else if (connectedPort) {
      portNameLabel = `${portNameLabel} => `;
    }
  }
  let portValue = '';
  if (port) {
    if (isFixedValue) {
      portValue = `${port.getFixedValue()}`;
    } else if (connectedPort) {
      portValue = `${port.getSingleConnectedPort()?.getOptions().label}`;
    }
  }
  const isHidden = hideDisconnected && !isFixedValue && !connectedPort;
  // The collapsed check here will force a re-render, which forces the node to recalculate its position, which prevents the links from being drawn incorrectly.
  return (isHidden && port) || localCollapsed !== collapsed ? (
    <PortWidget engine={engine} port={port} />
  ) : (
    <PropertyFrame style={style.label}>
      <>
        <InLinkFrame>
          {port && (
            <PortWidget style={style.portWidgetFlex} engine={engine} port={port}>
              <InLinkPort
                connected={!!connectedPort}
                fixedValue={isFixedValue}
                depth={port.getDepth()}
                categoryColor={categoryColor}
              />
            </PortWidget>
          )}
        </InLinkFrame>
        <TypeFrame>{type}</TypeFrame>
        {!connectedPort && port?.isFixedValueAllowed() ? (
          <>
            <NameFrame>{portNameLabel}</NameFrame>
            <ConfigProperty
              showLabel={false}
              isEditing={true}
              value={port ? (port.getFixedValue() as string) : ''}
              onChange={updateFixedValue}
              portValue={port.getFixedValue() === undefined ? 'undefined' : 'empty'}
              onRemove={onRemove}
              schemaType={port.getSchema()}
            />
          </>
        ) : (
          <NameFrame>
            {portNameLabel}
            {portValue}
            {onRemove && (
              <RemoveButton onClick={onRemove}>
                <FontAwesomeIcon icon={faTrash} />
              </RemoveButton>
            )}
          </NameFrame>
        )}
      </>
    </PropertyFrame>
  );
};

export const OutboundProperty: React.FunctionComponent<PropertyProps<SchemaDefinedPortModel>> = (
  props
) => {
  const {
    portName,
    type,
    engine,
    port,
    hideDisconnected,
    readOnly,
    collapsed,
    categoryColor,
  } = props;
  const connectedPort = port?.getSingleConnectedPort();
  const [localCollapsed, setLocalCollapsed] = useState(collapsed);

  useEffect(() => {
    if (localCollapsed !== collapsed) {
      setLocalCollapsed(collapsed);
    }
  }, [localCollapsed, collapsed]);

  // Even if we're hidden, we have to reender a dom node with the port for react-diagram
  // The collapsed check here will force a re-render, which forces the node to recalculate its position, which prevents the links from being drawn incorrectly.
  return (hideDisconnected && port && !connectedPort) || localCollapsed !== collapsed ? (
    <PortWidget engine={engine} port={port} />
  ) : (
    <PropertyFrame style={style.label}>
      <Indentation indent={port.getDepth()} />
      <NameFrame>{portName}</NameFrame>
      <TypeFrame>{type}</TypeFrame>
      <OutLinkFrame>
        {port && !readOnly && (
          <PortWidget
            style={style.portWidget}
            engine={engine}
            port={port}
            categoryColor={categoryColor}
          >
            <OutLinkPort
              categoryColor={categoryColor}
              connected={Object.keys(port.links).length > 0}
              depth={0}
            />
          </PortWidget>
        )}
      </OutLinkFrame>
    </PropertyFrame>
  );
};

const RemoveButton = styled.a`
  margin-left: 4px;
  cursor: pointer;
`;
