import * as React from 'react';
import UnSavedChanges from 'common/UnSavedChanges';

interface PromiseProps {
  resolve: <T>(result: T) => void;
  reject: () => void;
}

interface Props {
  openRedirect: () => Promise<unknown>;
}

const RedirectServiceContext = React.createContext<Props>({ openRedirect: Promise.reject });

export const useRedirect = () => React.useContext(RedirectServiceContext);

export const RedirectServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<PromiseProps>();

  const openRedirect = (): Promise<unknown> => {
    setShowModal(true);

    return new Promise((resolve: <T>(result: T) => void, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleAction = (action: 'save' | 'cancel' | 'discard') => {
    awaitingPromiseRef.current?.resolve(action);
    setShowModal(false);
  };

  const message = 'The form has not been saved. Are you sure you want to leave this page?';

  return (
    <>
      <RedirectServiceContext.Provider value={{ openRedirect }}>
        {children}
      </RedirectServiceContext.Provider>
      <UnSavedChanges {...{ showModal, handleAction, message }} />
    </>
  );
};
