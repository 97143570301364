import React, { ReactNode } from 'react';
import { colors } from 'utils/colors';

const Placeholder = ({ children }: { children: ReactNode }) => {
  return <div style={styles.placeholderText}>{children}</div>;
};

export default Placeholder;

const styles = {
  placeholderText: {
    color: colors.black30,
    fontSize: 14,
    fontWeight: 400,
  },
};
