import { useCallback, useContext, useRef } from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { MaterialTableRef } from 'components/EditableTable';
import { ActionButtonRow, AggrPropertyRow, FabAction } from 'utils/types';
import { chain, map } from 'lodash';
import { errorMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { PropertyEditableTable } from 'components/PageDialog/PropertyEditableTable';
import { MobileActionButton } from '@terragotech/gen5-config-lib/dist/MobileUIConfig';
import {
  commandActionButtonActionToRef,
  convertCommandRefToString,
  getAggregateList,
  getFabActionColumns,
} from 'utils/jsonPartsGenerators';
import { ConfigContext } from 'context/ConfigContext';
import { CELL_STYLE } from 'utils/Utils';
import { CommandReference } from '@terragotech/gen5-config-lib';

const FAB_TABLE_HEIGHT = 226;

type FabActionRow = MobileActionButton & { aggregateName?: string };
type FabActionRowWithID = FabActionRow & { tableData: { id: number } };
type FabActionProp = {
  aggregateName: string | undefined;
  button: ActionButtonRow & {
    action: CommandReference;
  };
};

type CustomPageActionsParams = {
  fabActions: FabAction[];
  setFabActions: (fabActions: FabAction[]) => void;
}

export default function CustomPageActions(params: CustomPageActionsParams) {
  const { fabActions, setFabActions } = params;
  const tableRef = useRef<MaterialTableRef>(null);
  const { config } = useContext(ConfigContext);

  const getFabActions = useCallback(() =>
    (fabActions as FabAction[]).map((item) => ({
      aggregateName: item.aggregateName,
      ...item.button,
      action: convertCommandRefToString(item.button?.action),
    })), [fabActions]);

  const FabActions = getFabActions();

  const convertRowToFabAction = ({ aggregateName, ...rest }: FabActionRow) => ({
    aggregateName,
    button: commandActionButtonActionToRef({ ...rest } as ActionButtonRow),
  });

  const moveRowFabAction = useCallback(async (reorderedRows: FabActionProp[]) => {
    setFabActions(reorderedRows);
    successMsg('Actions have been reordered.');
  }, [setFabActions]);

  const reOrderData = (newData: unknown) => {
    const newActions = map(newData as FabActionRow[], (data) => {
      return convertRowToFabAction(data);
    });
    moveRowFabAction(newActions);
  };

  const isButtonInvalid = (row: object) => {
    if (!(row as FabActionRow).aggregateName) {
      errorMsg('Aggregate name must be defined');
      return true;
    }
    if (!(row as FabActionRow).action) {
      errorMsg('Action must be defined');
      return true;
    }
    return false;
  };

  const addFabAction = useCallback(async (
    row: object,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(row)) return reject();
    const fabAction = convertRowToFabAction(row as FabActionRow);
    setFabActions([...fabActions, fabAction]);
    successMsg('Command action has been successfully added');
    resolve(null);
  }, [fabActions, setFabActions]);

  const updateFabAction = useCallback(async (
    newRow: object,
    oldRow: object,
    resolve: (data: Object | null) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(newRow)) return reject();
    const fabAction = convertRowToFabAction(newRow as FabActionRow);
    const fabActionIndex = (oldRow as FabActionRowWithID).tableData.id;
    const newActions = [...fabActions];
    newActions[fabActionIndex] = fabAction;
    setFabActions(newActions);
    successMsg('Editor action has been successfully updated');
    resolve(null);
  }, [fabActions, setFabActions]);

  const deleteFabAction = useCallback(async (rowToDel: object) => {
    const fabActionIndex = (rowToDel as FabActionRowWithID).tableData.id;
    const newActions = [...fabActions];
    newActions.splice(fabActionIndex, 1);
    setFabActions(newActions);
    successMsg('Editor action has been successfully deleted');
  }, [fabActions, setFabActions]);

  const getFabActionEditorColumns = getFabActionColumns(getAggregateList(config), false);
  const classes = useStyles();
  const tableHeaderStyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: colors.greyBackground,
  };

  const columns = chain(getFabActionEditorColumns)
    .map((o) => ({
      ...o,
      cellStyle: CELL_STYLE,
    }))
    .value();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <PropertyEditableTable
          hideExport
          hideFilter
          options={{
            maxBodyHeight: `calc(100vh - ${FAB_TABLE_HEIGHT}px)`,
            headerStyle: tableHeaderStyle,
            search: false,
            sorting: false,
            draggable: false,
            paging: false,
            exportButton: false,
            exportAllData: false,
            showTitle: false,
            toolbar: false,
          }}
          enableBlurBackground
          columns={columns}
          data={FabActions}
          onAdd={addFabAction}
          fileType="propertyList"
          onUpdate={updateFabAction}
          onDelete={deleteFabAction}
          tableRef={tableRef}
          reOrderData={reOrderData}
          headerStyle={classes.tableHeaderStyle}
        />
      </Box>
    </Box>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContainer: {
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.greyBorder,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    mainContainer: {
      height: '100%',
      width: '100%',
    },
    icon: {
      height: 20,
      width: 20,
    },
    tableHeaderStyle: {
      padding: '0px !important',
      backgroundColor: 'unset',
      zIndex: 998,
    },
  })
);
