import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';
import { MaterialTableRef } from 'components/EditableTable';
import { ValidOptions } from 'pages/aggregates/components/ValidOptionsCustomInput';
import { ActionButtonRow, AggrPropertyRow, FabAction, UIConfigType } from 'utils/types';
import { chain, cloneDeep, get, map } from 'lodash';
import { errorMsg, successMsg } from 'components/SnackbarUtilsConfigurator';
import { PropertyEditableTable } from 'components/PageDialog/PropertyEditableTable';
import { MobileActionButton } from '@terragotech/gen5-config-lib/dist/MobileUIConfig';
import { useUICustomizationAPI } from 'context/fakeAPIHooks/useUICustomizationAPI';
import {
  commandActionButtonActionToRef,
  convertCommandRefToString,
  getAggregateList,
  getFabActionColumns,
} from 'utils/jsonPartsGenerators';
import { ConfigContext } from 'context/ConfigContext';
import { CELL_STYLE, UI_ACTIONS_LEFT_MENU } from 'utils/Utils';
import { useParams } from 'react-router-dom';
import { CommandReference } from '@terragotech/gen5-config-lib';

const FAB_TABLE_HEIGHT = 226;

export interface OtherProps {
  tableData?: number;
  validOptions: ValidOptions | string;
}
export type FabActionRow = MobileActionButton & { aggregateName?: string };
type FabActionRowWithID = FabActionRow & { tableData: { id: number } };
type FabActionProp = {
  aggregateName: string | undefined;
  button: ActionButtonRow & {
    action: CommandReference;
  };
};

export default function ActionsContainer() {
  const UICustomizationAPI = useUICustomizationAPI();
  const { currentActionPage: KEY } = useParams() as {
    currentActionPage: string;
  };
  const tableRef = useRef<MaterialTableRef>(null);
  const { config, configDetails } = useContext(ConfigContext);
  const UIAction = UI_ACTIONS_LEFT_MENU.find((o) => o.keyValue === KEY);
  const [title, setTitle] = useState(UIAction?.title || '');
  const [type, setType] = useState(KEY === 'fabActions' ? 'mobile' : 'webImport');
  const UIConfig =
    type === 'webImport' ? 'webUIConfig' : (configDetails[KEY]?.config as UIConfigType);
  const UIConfigPath =
    type === 'webImport'
      ? 'webUIConfig.importActions'
      : `${configDetails?.fabActions?.config}.${KEY}`;

  const updateCurretnAction = useCallback(() => {
    if (UIAction) {
      setTitle(UIAction.title);
      setType(KEY === 'fabActions' ? 'mobile' : 'webImport');
    }
  }, [UIAction, KEY]);

  useEffect(() => {
    updateCurretnAction();
  }, [KEY, updateCurretnAction]);

  const updateFunction =
    type === 'webImport'
      ? UICustomizationAPI.updateImportAction
      : UICustomizationAPI.updateFabAction;
  const addFunction =
    type === 'webImport' ? UICustomizationAPI.addImportAction : UICustomizationAPI.addFabAction;
  const deleteFunction =
    type === 'webImport'
      ? UICustomizationAPI.deleteImportAction
      : UICustomizationAPI.deleteFabAction;

  const fabActions = cloneDeep(get(config, UIConfigPath) || []);

  const getFabActions = () =>
    (fabActions as FabAction[]).map((item) => ({
      aggregateName: item.aggregateName,
      ...item.button,
      action: convertCommandRefToString(item.button?.action),
    }));

  const FabActions = getFabActions();

  const convertRowToFabAction = ({ aggregateName, ...rest }: FabActionRow) => ({
    aggregateName,
    button: commandActionButtonActionToRef({ ...rest } as ActionButtonRow),
  });

  const moveRowFabAction = async (row: FabActionProp[]) => {
    const { error } = await UICustomizationAPI.reOrderRow(
      UIConfig,
      UIAction?.keyValue as 'fabActions' | 'importActions',
      row
    );
    if (error) return;
    successMsg('Actions have been reordered.');
  };

  const reOrderData = (newData: unknown) => {
    const newActions = map(newData as FabActionRow[], (data) => {
      return convertRowToFabAction(data);
    });
    moveRowFabAction(newActions);
  };

  const isButtonInvalid = (row: object) => {
    if (!(row as FabActionRow).aggregateName) {
      errorMsg('Aggregate name must be defined');
      return true;
    }
    if (!(row as FabActionRow).action) {
      errorMsg('Action must be defined');
      return true;
    }
    return false;
  };

  const addFabAction = async (
    row: object,
    resolve: (data: AggrPropertyRow | null) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(row)) return reject();
    const fabAction = convertRowToFabAction(row as FabActionRow);
    const { error } = await addFunction(UIConfig, fabAction);
    if (error) reject();
    successMsg('Command action has been successfully added');
    resolve(null);
  };

  const updateFabAction = async (
    newRow: object,
    oldRow: object,
    resolve: (data: Object | null) => void,
    reject: () => void
  ) => {
    if (isButtonInvalid(newRow)) return reject();
    const fabAction = convertRowToFabAction(newRow as FabActionRow);
    const fabActionIndex = (oldRow as FabActionRowWithID).tableData.id;
    const { error } = await updateFunction(UIConfig, fabActionIndex, fabAction);
    if (error) reject();
    successMsg('Editor action has been successfully updated');
    resolve(null);
  };

  const deleteFabAction = async (rowToDel: object) => {
    const fabActionIndex = (rowToDel as FabActionRowWithID).tableData.id;
    const { error } = await deleteFunction(UIConfig, fabActionIndex);
    if (error) return;
    successMsg('Editor action has been successfully deleted');
  };
  const getFabActionEditorColumns = getFabActionColumns(
    getAggregateList(config),
    type === 'webImport'
  );
  const classes = useStyles();
  const tableHeaderStyle = {
    position: 'sticky',
    top: 0,
    backgroundColor: colors.greyBackground,
  };

  const columns = chain(getFabActionEditorColumns)
    .map((o) => ({
      ...o,
      cellStyle: CELL_STYLE,
    }))
    .value();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.innerContainer}>
        <PropertyEditableTable
          title={title}
          hideExport
          hideFilter
          options={{
            maxBodyHeight: `calc(100vh - ${FAB_TABLE_HEIGHT}px)`,
            headerStyle: tableHeaderStyle,
            search: false,
            sorting: false,
            draggable: false,
            paging: false,
            exportButton: false,
            exportAllData: false,
            showTitle: false,
            toolbar: false,
          }}
          enableBlurBackground
          columns={columns}
          data={FabActions}
          onAdd={addFabAction}
          fileType="propertyList"
          onUpdate={updateFabAction}
          onDelete={deleteFabAction}
          tableRef={tableRef}
          configKey={UIAction?.isLink ? KEY : ''}
          reOrderData={reOrderData}
        />
      </Box>
    </Box>
  );
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    innerContainer: {
      padding: '0px 50px',
      backgroundColor: colors.white,
      width: '100%',
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: colors.white,
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: colors.greyBorder,
      borderRadius: 4,
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
    },
    mainContainer: {
      height: '100%',
      backgroundColor: colors.white,
      width: '100%',
    },
    icon: {
      height: 20,
      width: 20,
    },
  })
);
