import { Gen5Config } from '@terragotech/gen5-config-lib';
import { AggrPropertyRow } from '../../../utils/types';

export const migrateInputJson = (fileJson: Gen5Config): Gen5Config => {
  const aggregates = fileJson.aggregates;
  for (const [index, aggregate] of aggregates.entries()) {
    let properties = aggregate.properties;
    for (const [key, value] of Object.entries(properties as object)) {
      properties = {
        ...properties,
        [key]: migrateGeoGraphyUiType(value as AggrPropertyRow),
      };
    }
    aggregates[index].properties = properties;
  }
  fileJson.aggregates = aggregates;
  return fileJson;
};

export const migrateGeoGraphyUiType = (value: AggrPropertyRow): AggrPropertyRow => {
  let propertyValue = value;
  const { type, uiType } = propertyValue;
  if (type && type === 'Geography' && (!uiType || uiType !== 'JSON')) {
    propertyValue = { ...propertyValue, uiType: 'JSON' };
  }
  return propertyValue;
};
