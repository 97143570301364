import * as React from 'react';
import { Dialog } from '@material-ui/core';
import { colors } from 'utils/colors';

interface PromiseProps {
  resolve: <T>(result: T) => void;
  reject: () => void;
}

export type FormComponentType = React.FC<{
  onClose: () => void;
  onSubmit: <T>(result: T) => void;
}>;

function a<T>(
  formComponent: FormComponentType,
  disableClosingByClickingOutside?: boolean,
  autoHeight?: boolean,
  minWidth?: number
): Promise<
  T extends React.FC<infer E>
    ? E extends { onSubmit: (...args: any[]) => void }
      ? Parameters<E['onSubmit']>[0]
      : unknown
    : unknown
> {
  return Promise.reject();
}

const FormDialogServiceContext = React.createContext<typeof a>(Promise.reject);

export const useFormDialog = () => React.useContext(FormDialogServiceContext);

export const FormDialogServiceProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [Form, setForm] = React.useState<FormComponentType | null>(null);
  const [isAutoHeight, setIsAutoHeight] = React.useState<boolean>(false);
  const [minWidth, setMinWidth] = React.useState<number>(500);
  const [
    disableClosingByClickingOutside,
    setDisableClosingByClickingOutside,
  ] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<PromiseProps>();

  const openFormDialog = (
    formComponent: FormComponentType | undefined,
    disableClosingByClickingOutside?: boolean,
    autoHeight?: boolean,
    width?: number
  ) => {
    if (disableClosingByClickingOutside) setDisableClosingByClickingOutside(true);
    formComponent && setForm(() => formComponent);
    autoHeight && setIsAutoHeight(autoHeight);
    width && setMinWidth(width);
    return new Promise((resolve: <T>(result: T) => void, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const setDefault = () => {
    setForm(null);
    setIsAutoHeight(false);
    setMinWidth(500);
  };

  const handleClose = () => {
    awaitingPromiseRef.current?.reject();
    setDisableClosingByClickingOutside(false);
    setDefault();
  };

  const handleSubmit = <T,>(result: T) => {
    awaitingPromiseRef.current?.resolve(result);
    setDefault();
  };

  return (
    <>
      <FormDialogServiceContext.Provider value={openFormDialog as typeof a}>
        {children}
      </FormDialogServiceContext.Provider>
      <Dialog
        open={Boolean(Form)}
        onClose={disableClosingByClickingOutside ? undefined : handleClose}
        maxWidth="xl"
        PaperProps={{
          style: { ...styles.dialogStyle, minWidth, ...(isAutoHeight ? styles.autoHeight : {}) },
        }}
      >
        {Form && <Form onClose={handleClose} onSubmit={handleSubmit} />}
      </Dialog>
    </>
  );
};
const styles = {
  dialogStyle: {
    minWidth: 500,
    maxHeight: '50%',
    boxShadow: `0px 4px 10px 6px ${colors.black10}`,
    borderRadius: 5,
  },
  autoHeight: {
    maxHeight: '90%',
  },
};
