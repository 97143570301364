import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import Lock from '@material-ui/icons/Lock';
import React from 'react';
import logoIcon from '../../images/streetlightsLogo.svg';
import LoginForm from './LoginForm';
import TempPasswordDialog from './TempPasswordModal';
import { colors } from '@terragotech/gen5-shared-components'

interface LoginScreenProps {
  email: string;
  password: string;
  code: string;
  errorMessage?: string;
  onEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onCodeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  attemptLogin: () => void;
  codeNeeded: boolean;
  emailNeeded: boolean;
  submitAuthCode: () => void;
  newPasswordNeeded: boolean;
  completeNewPassword: (password: string) => void;
  selectEmailLogin: () => void;
}

/**
 * Login Screen
 *
 * @remarks Login Screen is a form for manipulating and submitting state in LoginScreenContainer.
 *
 * props.attemptLogin called for email user authentication handling in container.
 * props.submitAuthCode called for code user authentication handling in container.
 * props.selectEmailLogin called for switching to email context in container.
 *
 * @param email - string
 * @param password - string
 * @param code - string
 * @param errorMessage - string
 * @param loginFailed - boolean
 * @param onEmailChange - handleEmailChange (function)
 * @param onCodeChange - handleCodeChange (function)
 * @param onPasswordChange - handlePasswordChange (function)
 * @param attemptLogin - attemptLogin (function)
 * @param codeNeeded - boolean
 * @param emailNeeded - boolean
 * @param submitAuthCode -submitAuthCode (function)
 * @param newPasswordNeeded - boolean
 * @param completeNewPassword - completeNewPassword (function)
 * @param selectEmailLogin - selectEmailLogin (function)
 *
 */

const LoginScreen: React.FunctionComponent<LoginScreenProps> = (props) => {
  const {
    email,
    password,
    code,
    onEmailChange,
    onCodeChange,
    onPasswordChange,
    attemptLogin,
    codeNeeded,
    emailNeeded,
    errorMessage,
    submitAuthCode,
    newPasswordNeeded,
    completeNewPassword,
    selectEmailLogin,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.loginScreenWrapper}>
      <div className={classes.headerBackground}>
        <Typography className={classes.headerTitle}>
          Config Editor
        </Typography>
      </div>
      <div className={classes.loginBoxContainer}>
        <div className={classes.loginBox}>
          <div className={classes.loginHeader}>
            <div className={classes.loginTitle}>
              <Lock className={classes.lockIcon} />
              <Typography variant="h3" className={classes.header}>
                Login
              </Typography>
            </div>
          </div>
          <div className={classes.logoDiv}>
            <img
              src={logoIcon}
              className={classes.logo}
              alt="logoIcon"
            />
          </div>
          {newPasswordNeeded && (
            <TempPasswordDialog title={'Reset your password'} onOkPress={completeNewPassword} />
          )}
          <LoginForm
            attemptLogin={attemptLogin}
            code={code}
            codeNeeded={codeNeeded}
            emailNeeded={emailNeeded}
            errorMessage={errorMessage}
            onCodeChange={onCodeChange}
            onPasswordChange={onPasswordChange}
            onEmailChange={onEmailChange}
            password={password}
            secondButtonCB={attemptLogin}
            submitAuthCode={submitAuthCode}
            email={email}
            selectEmailLogin={selectEmailLogin}
          />
        </div>
      </div>
    </div>
  );
};

const transitionTime = '0.25s';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginScreenWrapper: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
    },
    headerBackground: {
      background: theme.palette.secondary.main,
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
      height: '25vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    headerTitle: {
      paddingTop: 53,
      fontSize: 20,
      textAlign: 'center',
      textTransform: 'uppercase',
      color: colors.white,
    },
    loginBoxContainer: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    loginBox: {
      width: 360,
      boxShadow: '0 1px 10px 1px rgba(0, 0, 0, 0.2)',
      borderRadius: 5,
      top: 100,
      backgroundColor: colors.white,
      position: 'absolute',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    loginHeader: {
      backgroundColor: theme.palette.primary.main,
      height: 68,
      color: colors.white,
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
      width: '100%',
    },
    loginTitle: {
      padding: '27px 45px',
      display: 'inline-flex',
    },
    lockIcon: {
      height: 16,
      width: 16,
    },
    header: {
      color: colors.white,
      fontWeight: 500,
      paddingLeft: 5,
      fontSize: '16px',
      textTransform: 'uppercase',
    },
    logoDiv: {
      top: 22,
      right: 43,
      position: 'absolute',
      width: 94,
      height: 94,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: '50%',
      border: `2px solid ${theme.palette.primary.main}`,
      transition: transitionTime,
    },
    logo: {
      borderRadius: '50%',
      height: 82,
      width: 82,
    },
  })
);

export default LoginScreen;
