import { FunctionComponent } from 'react';
import { SimpleOptionType, V2SingleSelectComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label } from './Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleDot } from '@fortawesome/pro-regular-svg-icons';
import { Box, styled as MuiStyled, Theme } from '@material-ui/core';
import { colors } from 'utils/colors';
import { FormBadges } from './FormBadge';

const RadioFrame = MuiStyled(Box)({
  marginLeft: 18,
  marginRight: 15,
  marginBottom: 2,
});

const RadioButtonIcon = MuiStyled(FontAwesomeIcon)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
}));

const RadioOption = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: ${colors.black50};
`;
const RadioRow = styled.div`
  display: flex;
  margin-top: 2px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const RadioButton: FunctionComponent<NestedFormControlProps<V2SingleSelectComponent>> = (props) => {
  const { controlDefinition, pasted } = props;

  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />
      {controlDefinition.options.type === SimpleOptionType &&
        controlDefinition.options.items.map((option, key) => (
          <RadioRow key={option.value}>
            <RadioFrame>
              <RadioButtonIcon icon={key === 0 ? faCircleDot : faCircle} />
            </RadioFrame>

            <RadioOption>{option.label}</RadioOption>
          </RadioRow>
        ))}
      <FormBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default RadioButton;
