import { CommandAction, MobileActionBuiltIns } from '@terragotech/gen5-config-lib';
import { ActionButtonType } from '../components/ActionButtonEditor/ActionButtonEditor';
import { UIConfigType, ActionButtons } from '../utils/types';
import { ExtendedGen5Config } from './ConfigContext';
import { actions } from 'utils/Utils';

export const extractActionButtons = (
  config: ExtendedGen5Config,
  uiConfigType: UIConfigType,
  aggrUIName: string,
  actionButtonType: ActionButtonType
) => {
  if (uiConfigType === 'mobileUIConfig') {
    if (actionButtonType === 'editorWorkflows')
      return config.mobileUIConfig.aggregateUICustomizations?.[aggrUIName]?.editorWorkflows;
    if (actionButtonType === 'multiSelectActions')
      return config.mobileUIConfig.aggregateUICustomizations?.[aggrUIName]?.multiSelectActions;
    if (actionButtonType === 'editorActions')
      return config.mobileUIConfig.aggregateUICustomizations?.[aggrUIName]?.editorActions;
  }
  if (uiConfigType === 'webUIConfig') {
    if (actionButtonType === 'editorWorkflows')
      return config.webUIConfig.aggregateUICustomizations?.[aggrUIName]?.editorWorkflows;
    if (actionButtonType === 'multiSelectActions')
      return config.webUIConfig.aggregateUICustomizations?.[aggrUIName]?.multiSelectActions;
    if (actionButtonType === 'editorActions')
      return config.webUIConfig.aggregateUICustomizations?.[aggrUIName]?.tableActions;
  }
};

const setExtractedEditorActions = (
  isMergedConfig: boolean,
  uiConfigType: UIConfigType,
  mobileAggrUI: ExtendedGen5Config['mobileUIConfig'],
  webAggrUI: ExtendedGen5Config['webUIConfig'],
  aggrUIName: string,
  actionButton: ActionButtons
) => {
  if (isMergedConfig && mobileAggrUI && webAggrUI) {
    mobileAggrUI[aggrUIName].editorActions = actionButton as Array<
      CommandAction<MobileActionBuiltIns>
    >;
    webAggrUI[aggrUIName].tableActions = actionButton;
  } else {
    if (uiConfigType === 'mobileUIConfig') {
      mobileAggrUI[aggrUIName].editorActions = actionButton as Array<
        CommandAction<MobileActionBuiltIns>
      >;
    } else {
      webAggrUI[aggrUIName].tableActions = actionButton;
    }
  }
};

export const setExtractedActionButtons = (
  config: ExtendedGen5Config,
  uiConfigType: UIConfigType,
  aggrUIName: string,
  actionButtonType: ActionButtonType,
  isMergedConfig: boolean,
  actionButton: ActionButtons
) => {
  const mobileAggrUI = config.mobileUIConfig
    .aggregateUICustomizations as ExtendedGen5Config['mobileUIConfig'];
  const webAggrUI = config.webUIConfig
    .aggregateUICustomizations as ExtendedGen5Config['webUIConfig'];
  if (actionButtonType === actions.EDITOR_ACTIONS) {
    setExtractedEditorActions(
      isMergedConfig,
      uiConfigType,
      mobileAggrUI,
      webAggrUI,
      aggrUIName,
      actionButton
    );
  } else {
    if (isMergedConfig && mobileAggrUI && webAggrUI && mobileAggrUI[aggrUIName]) {
      mobileAggrUI[aggrUIName][actionButtonType] = actionButton as Array<
        CommandAction<MobileActionBuiltIns>
      >;
      webAggrUI[aggrUIName][actionButtonType] = actionButton;
    }
    if (uiConfigType === 'mobileUIConfig' && mobileAggrUI && mobileAggrUI[aggrUIName]) {
      mobileAggrUI[aggrUIName][actionButtonType] = actionButton as Array<
        CommandAction<MobileActionBuiltIns>
      >;
    }
    if (uiConfigType === 'webUIConfig' && webAggrUI && webAggrUI[aggrUIName] && !isMergedConfig) {
      webAggrUI[aggrUIName][actionButtonType] = actionButton as Array<
        CommandAction<MobileActionBuiltIns>
      >;
    }
  }
  return config;
};
