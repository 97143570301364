import { DiagramModel } from '@projectstorm/react-diagrams';
import { NodeMapDefinition, OutputDefinition } from '@terragotech/gen5-datamapping-lib';
import { DataMapperNodeModel } from './DiagramNodes/DataMapperNode/DataMapperNodeModel';
//import { TransformNodeModel } from './DiagramNodes/TransformNodes/TransformNodeModel';
/*import {
  NODE_TYPE as OUTPUT_STATE_NODE_TYPE,
  OutputStateModel,
} from './DiagramNodes/OutputState/OutputStateModel';*/

export class EventMapDiagramModel extends DiagramModel {
  private outputDefinition: OutputDefinition;
  constructor(options: { outputDefinition: OutputDefinition }) {
    super();
    this.outputDefinition = options.outputDefinition;
  }
  getMapDefinition(): NodeMapDefinition {
    const nodeMap: NodeMapDefinition = {
      nodes: {},
      outputDefinition: this.outputDefinition,
    };
    (this.getNodes() as DataMapperNodeModel[]).forEach((node) => {
      //TODO: not quite right here
      //@ts-ignore
      nodeMap.nodes[node.getID()] = node.getNodeDefinition(node.getPosition().x,node.getPosition().y);
    });
    return nodeMap;
  }

  getUniqueName(): string {
    const nodes = this.getNodes();
    const noteIds = nodes.map((node) => node.getID());
    let i = 1;
    let tempName = `newNode${i}`;
    while (noteIds.includes(tempName)) {
      tempName = `newNode${i}`;
      i++;
    }
    return tempName;
  }
}
