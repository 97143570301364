import * as React from 'react';
import styled from 'styled-components';
import { DiagramEngine } from '@projectstorm/react-diagrams';
//import { transformNodeFactories } from '../DiagramNodes/TransformNodes';

export const DROP_LAYER_DATA_TAG = 'EVENT_MAPPER';
const Wrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
`;

export interface DroppableNode {
  type: string;
}

export interface DropLayerProps {
  diagramEngine: DiagramEngine;
  onDroppedNode: () => void;
  children: React.ReactNode;
}
const DropLayer: React.FunctionComponent<DropLayerProps> = (props) => {
  const { onDroppedNode } = props;
  const dropHandler = React.useCallback(
    (event: React.DragEvent) => {
      const dropData = event.dataTransfer.getData(DROP_LAYER_DATA_TAG);
      if (dropData) {
        // dropData will need to be of a certain type
        // determine the node type based on what was dropped
        // Create a new node of that type
        /*if (transformNodeFactories[dropData]) {
          // grab a unique name here
          const temp = (diagramEngine.getModel() as EventMapDiagramModel).getUniqueName();
          const newModel = transformNodeFactories[dropData].generateModel({
            initialConfig: { id: temp },
          });
          // set the position of that node based on the mouse
          const point = diagramEngine.getRelativeMousePoint(event);
          newModel.setPosition(point);
          diagramEngine.getModel().addNode(newModel);
        }*/
        // 2022-09-13: Removed diagramEngine dependency, since this is currently commented out. If restoring this section, also re-add dependency.
        onDroppedNode();
      }
    },
    [onDroppedNode]
  );
  return (
    //@ts-ignore
    <Wrapper
      onDrop={dropHandler}
      onDragOver={(event: any) => {
        event.preventDefault();
      }}
    >
      {props.children}
    </Wrapper>
  );
};
export default DropLayer;
