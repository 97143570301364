import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { MapScenarioType, NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { propertiesToSchema } from '../pages/aggregates/utils/PropertiesToSchemaConverter';
import { PropertyCollection } from '@terragotech/gen5-config-lib';
import { metadataSchema } from '../utils/jsonPartsGenerators';
import { LocalSchemaDefinition } from '../utils/useSchemaLookup';
import { JSONSchema6 } from 'json-schema';
import { colors } from 'utils/colors';
import MapperItem from './FormDialog/MapperItem';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';

interface TableEmbeddedMapperProps {
  value: NodeMapDefinition | undefined;
  onChange: (value: NodeMapDefinition | undefined) => void;
  properties?: PropertyCollection;
  mapScenario?: keyof MapScenarioType;
  form?: JSONSchema6;
}

export const TableEmbeddedMapper: React.FC<TableEmbeddedMapperProps> = ({
  value,
  onChange,
  properties,
  mapScenario,
  form,
}) => {
  const [mapperOpen, setMapperOpen] = useState(false);
  const { openConfirmation } = useConfirmDialog();
  const localSchemaDefinitions: LocalSchemaDefinition = {
    METADATA: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
  };
  if (properties) {
    localSchemaDefinitions['STATE'] = {
      schema: propertiesToSchema(properties),
      schemaLabel: 'Properties',
    };
  }
  if (form) {
    localSchemaDefinitions['FORM'] = {
      schema: form,
      schemaLabel: 'Form',
    };
  }
  const styles = {
    mapperBtn: {
      width: '138px',
      height: '36px',
      borderRadius: '5px',
      background: colors.black5,
      boxShadow: `0px 2px 4px 0px ${colors.black15}`,
    },
    deleteBtn: {
      minWidth: 0,
      padding: 0,
      marginLeft: 15,
    },
    deleteIcon: {
      width: '18px',
      height: '20px',
      color: colors.black54,
    },
    root: {
      margin: '10px 0px',
    },
  };

  const clearMapper = async () => {
    const resp = await openConfirmation(CONFIRMATION.commonClear);
    if (resp === 'confirm') {
      onChange(undefined);
    }
  };

  return (
    <Box style={styles.root}>
      <MapperItem
        {...{
          onToggleMapper: setMapperOpen,
          isActive: value !== undefined,
          clearMapper,
          openDataMap: mapperOpen,
          dataMap: value,
          setDataMap: (data: NodeMapDefinition | undefined) => onChange(data),
          localSchemaDefinition: localSchemaDefinitions,
          mapScenario: mapScenario || (properties ? 'BUTTON_STATE' : 'FAB_BUTTON_STATE'),
          noMargin: true,
        }}
      />
    </Box>
  );
};
