import ConfirmDialog from 'common/ConfirmModal';
import { createContext, useContext, useRef, useState } from 'react';

interface ConfirmProps {
  title?: string;
  description?: string;
  confirmationText?: string;
  cancellationText?: string;
  dialogContainerStyle?: React.CSSProperties;
}

type ResolveType = 'confirm' | 'cancel';

interface Props {
  openConfirmation: (props: ConfirmProps) => Promise<ResolveType>;
}

interface PromiseProps {
  resolve: (value: ResolveType) => void;
  reject: () => void;
}

const ConfirmContext = createContext<Props>({ openConfirmation: Promise.reject });

export const useConfirmDialog = () => useContext(ConfirmContext);

export const ConfirmServiceProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const awaitingPromiseRef = useRef<PromiseProps>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [confirmationText, setConfirmationText] = useState<string>();
  const [cancellationText, setCancellationText] = useState<string>();
  const [dialogContainerStyle, setDialogContainerStyle] = useState<React.CSSProperties>();

  const openConfirmation = (props: ConfirmProps): Promise<ResolveType> => {
    setTitle(props?.title);
    setDescription(props?.description);
    setConfirmationText(props?.confirmationText);
    setCancellationText(props.cancellationText);
    setShowConfirmDialog(true);
    setDialogContainerStyle(props?.dialogContainerStyle);
    return new Promise((resolve: (value: ResolveType) => void, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleAction = (action: ResolveType) => {
    awaitingPromiseRef.current?.resolve(action);
    setTitle(undefined);
    setDescription(undefined);
    setConfirmationText(undefined);
    setCancellationText(undefined);
    setShowConfirmDialog(false);
    setDialogContainerStyle(undefined);
  };

  return (
    <ConfirmContext.Provider value={{ openConfirmation }}>
      {children}
      <ConfirmDialog
        showModal={showConfirmDialog}
        title={title}
        confirmationText={confirmationText}
        description={description}
        cancellationText={cancellationText}
        handleAction={handleAction}
        dialogContainerStyle={dialogContainerStyle}
      />
    </ConfirmContext.Provider>
  );
};
