"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPathPrefixFromPath = exports.formattedPath = exports.getTruePath = void 0;
const getTruePath = (sourcePath, controlPath) => {
    let cleanSource = sourcePath;
    // when a conditional depend on a field in the same group
    // the sourComponent will start with '~.'
    // example '~.abc' and controlPath 'xyzGroup.xyzField, expected result => xyzGroup.abc (sibling field)
    // else it is not part of the group but a parent or root and the sourcePath should be the correct one
    if (sourcePath.startsWith('~.')) {
        cleanSource = sourcePath.replace('~.', '');
        return exports.formattedPath(cleanSource, exports.getPathPrefixFromPath(controlPath));
    }
    return sourcePath;
};
exports.getTruePath = getTruePath;
const formattedPath = (sourcePath, pathPrefix) => {
    return pathPrefix ? `${pathPrefix}.${sourcePath}` : sourcePath;
};
exports.formattedPath = formattedPath;
const getPathPrefixFromPath = (controlPath) => {
    if (!controlPath)
        return '';
    const indexStart = controlPath.lastIndexOf('.');
    return controlPath.substring(0, indexStart);
};
exports.getPathPrefixFromPath = getPathPrefixFromPath;
