import styled from 'styled-components';
import { V2MediaComponent } from '@terragotech/form-renderer';
import { FunctionComponent } from 'react';
import { NestedFormControlProps } from '../FormTypes';
import { Label, StandardInputFrame } from './Common';
import { Theme, Typography, styled as MuiStyled } from '@material-ui/core';
import { colors } from 'utils/colors';
import { FormBadges } from './FormBadge';

const AddMedia = MuiStyled(Typography)(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '100%',
  marginLeft: 18,
}));

const StatusText = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${colors.black54};
  margin-right: 12px;
`;
const StatusLabel = styled(StatusText as any)`
  font-weight: bold;
  margin-right: 4px;
`;
const StatusSection = styled.div`
  display: flex;
  margin-left: 18px;
  margin-top: 2px;
  margin-bottom: 4px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;
const MediaUpload: FunctionComponent<NestedFormControlProps<V2MediaComponent>> = (props) => {
  const { controlDefinition, pasted } = props;
  //TODO: Update this to use the icons
  let typeLabel = '';
  switch (controlDefinition.type) {
    case 'audioupload':
      typeLabel = 'audio';
      break;
    case 'imageupload':
      typeLabel = 'image';
      break;
    case 'videoupload':
      typeLabel = 'video';
      break;
  }
  const repeatMin = controlDefinition.min || 0;
  const repeatMax = controlDefinition.max || 'Unlimited';
  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.conditionalMap)}
      />
      <StatusSection>
        <StatusText>0 added</StatusText>

        <StatusLabel>Required:</StatusLabel>

        <StatusText>{repeatMin}</StatusText>

        <StatusLabel>Allowed:</StatusLabel>

        <StatusText>{repeatMax}</StatusText>
      </StatusSection>
      <AddMedia>Add {typeLabel}</AddMedia>
      <FormBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default MediaUpload;
