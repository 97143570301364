import React, { useContext } from 'react';
import { ConfigContext } from '../../../context/ConfigContext';
import { useParams } from 'react-router-dom';
import { RelationalPropertyType } from '@terragotech/gen5-config-lib';
import { AggrPropertyRow } from '../../../utils/types';
import { TGSelect } from 'views/components/formElements';

interface ForeignColumnInputProps {
  row: AggrPropertyRow;
  onChange: (value: string) => void;
}

export const ForeignColumnInput: React.FC<ForeignColumnInputProps> = ({ row, onChange }) => {
  const { config } = useContext(ConfigContext);
  const { aggregate } = useParams() as { aggregate: string };

  const isTypeAggregate = () => config.aggregates.some((aggr) => aggr.typeName === row.type);

  const isRelationOneToMany = () => row.relation === 'ONE-TO-MANY';

  const getFilteredProperties = () => {
    return Object.fromEntries(
      Object.entries(
        config.aggregates.find((aggr) => aggr.typeName === row.type)?.properties || {}
      ).filter(
        ([_, v]) => v.type === aggregate && (v as RelationalPropertyType).relation === 'ONE-TO-ONE'
      )
    );
  };

  return isTypeAggregate() && isRelationOneToMany() ? (
    <TGSelect
      id="mutiple-select-label"
      variant="standard"
      options={['', ...Object.keys(getFilteredProperties())]}
      value={row.foreignColumn || ''}
      onChange={(value) => onChange(value as string)}
      type="foreignColumns"
      style={styles.mt9}
    />
  ) : (
    <div />
  );
};

const styles = {
  mt9: {
    marginTop: 9,
  },
};
