import { useDataMapDiagram } from 'map-editor/src';
import DataMapper from '../../common/DataMapper';
import { NodeMapDefinition, mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { useSchemaLookup } from 'utils/useSchemaLookup';
import { useEffect, useMemo } from 'react';
import { getLocalSchemaDefinitions } from 'utils/Utils';
import { V2FormTemplate } from '@terragotech/form-renderer';
import { UseEventMapDiagramOutput } from 'map-editor/src/Hooks/useDataMapDiagram';

export const COMMAND_MULTI_EVENT_MAPPING_SCENARIO = 'COMMAND_MULTI_EVENT_MAPPING';

interface Props {
  mapping: NodeMapDefinition | undefined;
  commandId: string;
  aggregate: string;
  formDefinition: V2FormTemplate;
  mapperRef: { current: UseEventMapDiagramOutput | null };
}

const CommandToEventMapper = ({
  mapping,
  commandId,
  aggregate,
  formDefinition,
  mapperRef,
}: Props) => {
  const localSchemasWithDefinition = useMemo(() => getLocalSchemaDefinitions(formDefinition), [
    formDefinition,
  ]);
  const commandToEventMappingSchemaLookup = useSchemaLookup({
    localSchemas: localSchemasWithDefinition,
    commandId,
    currentAggregateType: aggregate,
  });

  const commandToEventMappingDiagramOutput = useDataMapDiagram({
    dataMap: mapping || mapScenarios[COMMAND_MULTI_EVENT_MAPPING_SCENARIO].defaultNodeMap,
    schemaLookup: commandToEventMappingSchemaLookup,
    scenario: COMMAND_MULTI_EVENT_MAPPING_SCENARIO,
  });

  mapperRef.current = commandToEventMappingDiagramOutput;
  useEffect(() => {
    return () => {
      mapperRef.current = commandToEventMappingDiagramOutput;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataMapper
      eventMapDiagramOutput={commandToEventMappingDiagramOutput}
      mapScenario={COMMAND_MULTI_EVENT_MAPPING_SCENARIO}
    />
  );
};

export default CommandToEventMapper;
