import {
  Box,
  Button,
  Dialog,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { colors } from 'utils/colors';

interface Props {
  showModal: boolean;
  handleAction: (value: 'confirm' | 'cancel') => void;
  description?: string;
  title?: string;
  confirmationText?: string;
  cancellationText?: string;
  dialogContainerStyle?: React.CSSProperties;
}

const ConfirmModal = ({
  showModal,
  handleAction,
  description = 'The mapping will be cleared.',
  title = 'Are you sure?',
  confirmationText = 'Delete',
  cancellationText = 'Cancel',
  dialogContainerStyle,
}: Props) => {
  const classes = useStyles();

  const handleSubmit = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleAction('confirm');
  };

  const onCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    handleAction('cancel');
  };
  return (
    <Dialog
      open={showModal}
      onClose={undefined}
      transitionDuration={{exit: 0, enter: 100}}
      maxWidth="xl"
      PaperProps={{ style: dialogContainerStyle || styles.dialogStyle }}
      BackdropComponent={() => (
        <Box className={classes.backdrop} onClick={(e) => e.stopPropagation()} />
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <Typography className={classes.header}>{title}</Typography>
      <Typography className={classes.label}>{description}</Typography>
      <Box className={classes.footerRow}>
        <Button variant="contained" onClick={onCancel} className={classes.cancel} disableElevation>
          {cancellationText}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          className={classes.saveButton}
        >
          {confirmationText}
        </Button>
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: 18,
      color: colors.black,
      fontWeight: 600,
      paddingBottom: 12,
    },
    content: {
      paddingTop: 0,
      paddingBottom: 20,
      overflow: 'hidden',
    },
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
      paddingBottom: 26,
      lineHeight: 'normal',
    },
    footerRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 9,
      height: 32,
    },
    cancel: {
      background: colors.lightgray,
      padding: '7px 20px',
      fontSize: 13,
      fontStyle: 'normal',
      fontWeight: 500,
      borderRadius: 2,
    },
    saveButton: {
      color: colors.white,
      padding: '7px 20px',
      fontSize: 13,
      fontStyle: 'normal',
      fontWeight: 500,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      '&:hover': {
        color: colors.white,
        backgroundColor: theme.palette.primary.main,
      },
    },
    discardButton: {
      fontSize: 13,
      color: colors.white,
      fontWeight: 500,
      borderRadius: 2,
      height: 32,
    },
    backdrop: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      backgroundColor: colors.black35,
    },
  })
);

const styles = {
  dialogStyle: {
    width: 520,
    padding: '20px',
  },
};

export default ConfirmModal;
