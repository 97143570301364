import React, { useContext, useEffect, useMemo } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../records/components/TabPanel';
import { Box } from '@material-ui/core';
import {
  HEADER_HEIGHT,
  ROOT_UI_ROUTE,
  UI_ACTIONS_LEFT_MENU,
  UI_MENU,
  UI_MENU_ACTIONS,
  UI_MENU_CUSTOM_PAGES,
  UI_MENU_RECORDS,
  UI_MENU_SETTINGS,
  UI_SETTINGS_LEFT_MENU,
  getUrlFirstParam,
} from 'utils/Utils';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LeftMenu from './components/LeftMenu';
import { CustomPagesList } from '../customPages/components/CustomPagesMenu/CustomPagesList';
import TreeViewLeftMenu from './components/TreeViewLeftMenu';
import { colors } from 'utils/colors';
import { get, chain, size } from 'lodash';
import { ConfigContext } from 'context/ConfigContext';
import { usePageGroup } from 'common/usePageGroup';

const TABS_HEIGHT = 47;

const UI_TAB_PANELS = [
  {
    key: 'settings',
    sidePanel: LeftMenu,
  },
  {
    key: 'recordsUI',
    sidePanel: TreeViewLeftMenu,
  },
  {
    key: 'actions',
    sidePanel: LeftMenu,
  },
  {
    key: 'customPages',
    sidePanel: CustomPagesList,
  },
];

const UIPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const selectedUI = getUrlFirstParam(location.pathname, ROOT_UI_ROUTE);
  const { config } = useContext(ConfigContext);
  const mobileConfig = config.mobileUIConfig.aggregateUICustomizations;

  const firstRecord = useMemo(() => {
    const records = chain(mobileConfig)
      .entries()
      .map(([key, value]) => ({ key, ...value }))
      .value();
    return get(records, '[0].key', '');
  }, [mobileConfig]);

  const { pageGroupList } = usePageGroup();

  const firstCustomPage = chain(pageGroupList)
    .filter((o) => size(o.pageNames) > 0)
    .first()
    .value();

  const goTo = (path: string) => navigate(path);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const menu = UI_MENU[newValue];
    let path = menu.path;
    switch (menu.key) {
      case UI_MENU_SETTINGS:
        path = get(UI_SETTINGS_LEFT_MENU, '[0].path', menu.path);
        break;
      case UI_MENU_RECORDS:
        path = `${menu.path}/${firstRecord}`;
        break;
      case UI_MENU_ACTIONS:
        path = get(UI_ACTIONS_LEFT_MENU, '[0].path', menu.path);
        break;
      case UI_MENU_CUSTOM_PAGES:
        path = firstCustomPage?.groupName
          ? `${menu.path}/${firstCustomPage.groupName}/${get(firstCustomPage, 'pageNames.[0]', '')}`
          : menu.path;
        break;
      default:
    }
    goTo(path);
  };

  useEffect(() => {
    if (selectedUI) {
      const index = UI_MENU.findIndex((o) => o.key === selectedUI);
      setValue(index);
    }
  }, [selectedUI]);

  return (
    <Box className={classes.root}>
      <Paper className={classes.tabs}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          className={classes.tabRoot}
        >
          {UI_MENU.map((uiMenu) => (
            <Tab key={uiMenu.label} label={uiMenu.label} className={classes.tab} />
          ))}
        </Tabs>
      </Paper>
      {UI_TAB_PANELS.map((tapPanel, index) => {
        const SidePanel = tapPanel.sidePanel;
        return (
          <TabPanel key={tapPanel.key} className={classes.tabPanel} value={value} index={index}>
            <SidePanel keyValue={tapPanel.key} title={UI_MENU[index].label} />
            <Outlet />
          </TabPanel>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    tabs: {
      borderRadius: 0,
    },
    tabRoot: {
      minHeight: 45,
      borderBottom: `1px solid ${colors.black10}`,
    },
    tab: {
      textTransform: 'none',
      fontSize: 14,
      minHeight: 45,
      fontWeight: 400,
      boxShadow: 'none',
      [theme.breakpoints.up('sm')]: {
        minWidth: 110,
      },
    },
    tabPanel: {
      display: 'flex',
      height: `calc(100vh - ${HEADER_HEIGHT + TABS_HEIGHT}px)`,
      backgroundColor: colors.white,
    },
  })
);

export default UIPage;
