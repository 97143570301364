import { ReactNode } from 'react';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from '@material-ui/core';

interface Props {
  name: string;
  type: string;
  classes: { [key: string]: string };
  handleClose: () => void;
  handleSubmit: () => void;
  children: ReactNode;
}

const ModalFrame = ({ name, type, classes, handleClose, handleSubmit, children }: Props) => {
  return (
    <>
      <Box className={classes.header}>
        <DialogTitle>{`${name} (${type})`}</DialogTitle>
        <IconButton onClick={handleClose} className={classes.closeBtn}>
          <FontAwesomeIcon icon={faClose} />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />
      <DialogContent style={styles.body}>{children}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant="outlined" color="primary" onClick={handleClose} className={classes.button}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          className={classes.button}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 31px',
    paddingRight: 35,
  },
};

export default ModalFrame;
