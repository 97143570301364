import { IconDefinition, faDisplayCode, faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Typography,
  Divider,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import useCommonStyles from 'views/useCommonStyles';
import ConfigMenu from './ConfigMenu';
import { cloneDeep } from 'lodash';
import { useConfig } from 'context/ConfigContext';
import { CONFIRMATION, actions, configType, CARD_DEFINITION_KEY, MOBILE_ONLY_CARD_DEFINITION_KEYS } from 'utils/Utils';
import { faLinkSimple, faLinkSimpleSlash } from '@fortawesome/pro-regular-svg-icons';
import { colors } from 'utils/colors';
import _ from 'lodash';
import { useConfirmDialog } from 'context/ConfirmContext';

interface Props {
  title: string;
  icon?: IconDefinition;
  buttonText?: string;
  configKey?: string;
  onSave?: () => void;
  simulate?: boolean;
  openModals?: (val: boolean) => void;
  setHeader?: (val: string) => void;
  enableAddButton?: boolean;
  enableSaveButton?: boolean;
  style?: string;
  showDivider?: boolean;
  showLinkUnlink?: boolean;
  headerStyle?: string;
  titleStyle?: string;
}

const PageHeader = ({
  title,
  icon,
  buttonText,
  configKey,
  onSave,
  simulate,
  openModals,
  setHeader,
  enableAddButton = false,
  enableSaveButton = false,
  style,
  showDivider = false,
  showLinkUnlink = false,
  headerStyle = '',
  titleStyle = '',
}: Props) => {
  const classes = useCommonStyles();
  const {
    config,
    setConfig,
    configDetails,
    setConfigDetails,
    handleConfigTypeChange,
  } = useConfig();

  const handleLinkandUnlink = () => {
    const configCopy = cloneDeep(config);
    const webConfigg = configCopy.webUIConfig.aggregateUICustomizations;
    const mobileConfig = configCopy.mobileUIConfig.aggregateUICustomizations;
    if (configKey && webConfigg && mobileConfig) {
      const [commandName, nodeName] = configKey?.split('.');

      if (nodeName === actions.EDITOR_ACTIONS) {
        mobileConfig[commandName].editorActions = webConfigg[commandName].tableActions;
      } else {
        let webNode = webConfigg[commandName][nodeName];
        if (nodeName === CARD_DEFINITION_KEY) {
          for (const mobileOnlyCardDefKey of MOBILE_ONLY_CARD_DEFINITION_KEYS) {
            const mobileOnlyCardDefKeyData = mobileConfig[commandName][nodeName][mobileOnlyCardDefKey];
            if (mobileOnlyCardDefKeyData) {
              webNode = {...webNode, [mobileOnlyCardDefKey]: mobileOnlyCardDefKeyData}
            }
          }
        }
        mobileConfig[commandName][nodeName] = webNode;
      }
      if (configDetails[configKey]?.type === configType.ALL.type) {
        setConfigDetails(configKey, configType.WEB);
      } else {
        setConfigDetails(configKey, configType.ALL);
      }
      setConfig(configCopy);
    }
  };
  const tempDescription = configKey as string;
  const onLinkClick = () => {
    if (configDetails[tempDescription] !== configType.ALL) {
      handleConfigTypeChange(tempDescription, configType.ALL, configDetails[tempDescription]?.type);
      return;
    }
    handleConfigTypeChange(tempDescription, configType.WEB, configDetails[tempDescription]?.type);
  };
  const componentStyles = useStyles();
  const { openConfirmation } = useConfirmDialog();
  const linkAndUnlinkHandler = _.includes(['symbolLegend', 'fabActions'], configKey)
    ? undefined
    : handleLinkandUnlink;

  const isAllType = configDetails[tempDescription]?.type === configType.ALL.type;
  const confirmationObj = CONFIRMATION.linking[isAllType ? 'unlink' : 'link'];

  const handleLinking = async () => {
    const action = linkAndUnlinkHandler ? linkAndUnlinkHandler : onLinkClick;
    const resp = await openConfirmation(confirmationObj);
    if (resp === 'confirm') {
      action();
    }
  };

  return (
    <>
      <Box className={style || [classes.titleBarContainer, headerStyle].join(' ')}>
        <Box className={classes.titleRow}>
          <Typography variant="h2" className={[classes.header, titleStyle].join(' ')}>
            {title}
          </Typography>
          {configKey && <ConfigMenu definitionName={configKey} />}
        </Box>
        <ButtonContainer>
          {!showLinkUnlink && (
            <Button onClick={handleLinking} className={componentStyles.buttonStyles}>
              <FontAwesomeIcon
                icon={isAllType ? faLinkSimpleSlash : faLinkSimple}
                className={componentStyles.titleIcon}
              />
              <Typography className={componentStyles.iconStyle}>{confirmationObj.title}</Typography>
            </Button>
          )}
          {simulate && (
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={faDisplayCode} />}
              classes={{
                root: classes.simulatorBtnRoot,
                label: classes.simulatorBtnText,
              }}
              onClick={onSave ? onSave : undefined}
            >
              Simulate
            </Button>
          )}
          {enableAddButton && (
            <Box className={classes.buttonContainer}>
              <Button
                disableElevation
                variant="outlined"
                color="primary"
                className={classes.addButton}
                onClick={() => {
                  openModals && openModals(true);
                  setHeader && setHeader('Add');
                }}
                startIcon={<FontAwesomeIcon icon={faAdd} />}
              >
                Add
              </Button>
            </Box>
          )}
          {!enableSaveButton && (
            <Button
              variant="outlined"
              startIcon={<FontAwesomeIcon icon={icon ? icon : faFloppyDisk} />}
              classes={{
                root: classes.saveBtnRoot,
                label: classes.saveBtnText,
              }}
              onClick={onSave ? onSave : undefined}
            >
              {buttonText}
            </Button>
          )}
        </ButtonContainer>
      </Box>
      {showDivider && <Divider style={styles.dividers} />}
    </>
  );
};

export default PageHeader;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;
const styles = {
  dividers: { position: 'sticky' as const, top: 87, zIndex: 999 },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleIcon: {
      width: 25,
      height: 20,
      color: colors.black54,
    },
    buttonStyles: {
      position: 'absolute',
      right: 120,
      borderRadius: 5,
      border: `1px solid ${colors.greySeaShell}`,
      background: colors.white,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
      height: 40,
      padding: '3px 16px 2px 16px',
    },
    iconStyle: {
      color: colors.black54,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '100%',
      marginLeft: 6,
    },
  })
);
