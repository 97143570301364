import { useEffect, useState } from 'react';
import { Box, Theme, Typography, createStyles, makeStyles, Divider } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { colors } from 'utils/colors';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { GeneralTab } from './components/settings/general/GeneralTab';
import { PermissionTab } from './components/settings/permission/PermissionTab';
import useCommonStyles from '../../useCommonStyles';
import PageHeader from 'views/components/PageHeader';
import { getPrimaryLocationType, mainTabs, ROOT_RECORDS_ROUTE } from '../../../utils/Utils';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { AggregateConfig, LocationProperty } from '@terragotech/gen5-config-lib';
import { useConfig } from '../../../context/ConfigContext';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { errorMsg, successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import { AttributeBasedFilter } from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';
import { useRedirect } from 'context/RedirectModalContext';
import useRouteBlocker from 'common/useBlocker';
import { useUICustomizationAPI } from 'context/fakeAPIHooks/useUICustomizationAPI';
import { UIConfigType } from 'utils/types';
import { usePageHistory } from 'context/HistoryContext';

export type LocationTypeProp = 'latitude' | 'longitude' | 'geography';
export type AggregateInfoValueProp = boolean | undefined | string | AttributeBasedFilter;
const Settings = () => {
  const PERMISSION_TAB = 1;
  const commonClasses = useCommonStyles();
  const { openRedirect } = useRedirect();
  const classes = useStyles();
  const { config } = useConfig();
  const navigate = useNavigate();
  const AggregateAPI = useAggregateAPI();
  const [activeTab, setActiveTab] = useState(0);
  const { aggregate } = useParams() as { aggregate: string };
  const [aggrName, setAggrName] = useState(aggregate);
  const aggrIndex = getAggregateIndex(config, aggrName);
  const [aggrInfo, setAggrInfo] = useState<Partial<AggregateConfig>>(config.aggregates[aggrIndex]);
  const [primaryLocationType, setPrimaryLocationType] = useState<'GEOGRAPHY' | 'LATLON'>(
    getPrimaryLocationType(aggrInfo.primaryLocationProperty)
  );
  const UICustomizationAPI = useUICustomizationAPI();
  const isDirty = !isEqual(config.aggregates[aggrIndex], aggrInfo);
  const { pageSelectionHistory, setPageSelectionHistory, storeData } = usePageHistory();

  useEffect(() => {
    setAggrName(aggregate);
  }, [aggregate]);

  const [showMeasurement, setShowMeasurement] = useState<boolean | undefined>(
    typeof config.aggregates[aggrIndex].showMeasurementOnMap === 'undefined' ||
      config.aggregates[aggrIndex].showMeasurementOnMap === null
      ? true
      : config.aggregates[aggrIndex].showMeasurementOnMap
  );

  useEffect(() => {
    setPrimaryLocationType(getPrimaryLocationType(aggrInfo.primaryLocationProperty));
  }, [aggrInfo.primaryLocationProperty]);

  useEffect(() => {
    setAggrInfo(config.aggregates[aggrIndex]);
    setShowMeasurement(
      typeof config.aggregates[aggrIndex].showMeasurementOnMap === 'undefined' ||
        config.aggregates[aggrIndex].showMeasurementOnMap === null
        ? true
        : config.aggregates[aggrIndex].showMeasurementOnMap
    );
  }, [aggrIndex, config]);

  const handleChange = async (event: any, newValue: number) => {
    let canMove = false;
    if (isDirty) {
      const resp = (await openRedirect()) as 'save' | 'discard' | 'cancel';
      switch (resp) {
        case 'save': {
          await save();
          canMove = true;
          break;
        }
        case 'discard': {
          setAggrInfo(config.aggregates[aggrIndex]);
          canMove = true;
          break;
        }
      }
    }
    if (canMove || !isDirty) {
      setActiveTab(newValue);
    }
  };

  const handlePrimaryLocationChange = (value: string, type: LocationTypeProp) => {
    const aggrInfoCopy = cloneDeep(aggrInfo);
    if (type === 'geography') aggrInfoCopy.primaryLocationProperty = value;
    else {
      if (
        !aggrInfoCopy.primaryLocationProperty ||
        typeof aggrInfoCopy.primaryLocationProperty !== 'object'
      )
        (aggrInfoCopy.primaryLocationProperty as Partial<LocationProperty>) = {};
      (aggrInfoCopy.primaryLocationProperty as Partial<LocationProperty>)[type] = value
        ? value
        : undefined;
    }
    setAggrInfo(aggrInfoCopy);
  };

  const handleMeasurementChange = (value: boolean) => {
    const aggrInfoCopy = cloneDeep(aggrInfo);
    aggrInfoCopy.showMeasurementOnMap = value;
    setShowMeasurement(value);
    setAggrInfo(aggrInfoCopy);
  };

  const aggregateInfoOnChange = (key: string, value: AggregateInfoValueProp) =>
    setAggrInfo({ ...aggrInfo, [key]: value });

  const save = async () => {
    if (!aggrInfo.typeName) return errorMsg(`Type Name is required`);
    if (!aggrInfo.singularName) return errorMsg(`Singular Name is required`);
    if (!aggrInfo.pluralName) return errorMsg(`Plural name is required`);
    if (!aggrInfo.labelProperty) return errorMsg(`Label Property is required`);
    if (!aggrInfo.objectStore?.type) return errorMsg(`Object Store Type is required`);
    if (!aggrInfo.objectStore?.schema) return errorMsg(`Object Store Schema is required`);
    if (!aggrInfo.objectStore?.table) return errorMsg(`Object Store Table is required`);
    if (!aggrInfo.objectStore?.reducerName)
      return errorMsg(`Object Store Reducer Name is required`);
    if (
      config?.aggregates.some(
        (e: { typeName: string }) => e.typeName.toUpperCase() === aggrInfo?.typeName?.toUpperCase()
      ) &&
      aggrName !== aggrInfo.typeName
    )
      return errorMsg(`An aggregate named "${aggrInfo.typeName}" already exists`);
    if (
      primaryLocationType === 'LATLON' &&
      aggrInfo.primaryLocationProperty &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.latitude &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.longitude
    )
      return errorMsg('Location property for both latitude and longitude are not specified.');
    if (
      primaryLocationType === 'LATLON' &&
      aggrInfo.primaryLocationProperty &&
      (aggrInfo.primaryLocationProperty as LocationProperty)?.latitude &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.longitude
    )
      return errorMsg(
        'Location type longitude is not specified. Set either both longitude and latitude or remove latitude property'
      );
    if (
      primaryLocationType === 'LATLON' &&
      aggrInfo.primaryLocationProperty &&
      (aggrInfo.primaryLocationProperty as LocationProperty)?.longitude &&
      !(aggrInfo.primaryLocationProperty as LocationProperty)?.latitude
    )
      return errorMsg(
        'Location type latitude is not specified. Set either both longitude and latitude or remove longitude property'
      );
    if (
      primaryLocationType === 'GEOGRAPHY' &&
      aggrInfo.primaryLocationProperty &&
      ((aggrInfo.primaryLocationProperty as LocationProperty)?.longitude ||
        (aggrInfo.primaryLocationProperty as LocationProperty)?.latitude ||
        aggrInfo?.primaryLocationProperty === '')
    ) {
      return errorMsg('Geography aggregate property is not specified. Specify and try again.');
    }
    if (
      aggrInfo.primaryLocationProperty === '' ||
      (typeof aggrInfo.primaryLocationProperty === 'object' &&
        !aggrInfo.primaryLocationProperty.latitude &&
        !aggrInfo.primaryLocationProperty.longitude)
    ) {
      const aggrInfoCopy = cloneDeep(aggrInfo);
      aggrInfo.primaryLocationProperty = undefined;
      setAggrInfo(aggrInfoCopy);
    }
    setAggrName(aggrInfo.typeName);
    const { error } = await AggregateAPI.replaceAggregate(aggrIndex, aggrInfo as AggregateConfig);
    if (error) return;

    if (config.aggregates[aggrIndex].typeName !== aggrInfo.typeName) {
      const uiConfigTypes: UIConfigType[] = ['webUIConfig', 'mobileUIConfig'];
      await Promise.all(
        uiConfigTypes.map(async (configType) => {
          return await UICustomizationAPI.updateAggregateUICustomization(
            configType,
            aggregate,
            aggrInfo.typeName!
          );
        })
      );
      const data = { ...pageSelectionHistory };
      if (!isEmpty(data)) {
        const uiPath = data?.ui;
        const pattern = new RegExp(`\/ui\/recordsUI\/${aggregate}(\/.+)?`);
        const isMatch = uiPath.match(pattern);
        if (isMatch !== null) {
          data.ui = uiPath.replace(pattern, `/ui/recordsUI/${aggrInfo.typeName}$1`);
          storeData(data);
          setPageSelectionHistory(data);
        }
      }

      navigate(`${ROOT_RECORDS_ROUTE}${aggrInfo.typeName}`, { replace: true });
    }
    successMsg('The aggregate has been saved');
  };
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (currentLocation.pathname !== nextLocation.pathname) {
      return isDirty;
    }
    return false;
  });

  useRouteBlocker({ blocker, onSave: save });

  return (
    <Box className={`${commonClasses.container} ${classes.container}`}>
      <Box
        className={`${commonClasses.innerContainer} ${
          activeTab !== PERMISSION_TAB ? classes.inner : classes.permissionTab
        }`}
      >
        <Box className={classes.headerContainer}>
          <PageHeader
            title={'Settings'}
            icon={faSave}
            buttonText="Save"
            onSave={save}
            showLinkUnlink
          />
          <Tabs
            value={activeTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="tabs"
          >
            {mainTabs.map((tab, index) => (
              <Tab
                key={tab.label}
                label={
                  <Typography
                    variant="h3"
                    className={`${classes.tabText} ${
                      index === activeTab ? classes.activeTab : ''
                    } `}
                  >
                    {tab.label}
                  </Typography>
                }
              />
            ))}
          </Tabs>
          <Divider />
        </Box>
        <GeneralTab
          tabValue={activeTab}
          aggrInfo={aggrInfo}
          showMeasurement={showMeasurement}
          primaryLocationType={primaryLocationType}
          setPrimaryLocationType={setPrimaryLocationType}
          handlePrimaryLocationChange={handlePrimaryLocationChange}
          handleMeasurementChange={handleMeasurementChange}
          aggregateInfoOnChange={aggregateInfoOnChange}
        />
        <PermissionTab
          tabValue={activeTab}
          aggrInfo={aggrInfo}
          aggregateInfoOnChange={aggregateInfoOnChange}
        />
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleBarContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    container: {
      overflow: 'hidden',
    },
    inner: {
      height: '100%',
      overflow: 'auto',
    },
    permissionTab: {
      height: '100%',
    },
    headerContainer: {
      position: 'sticky',
      top: 0,
      zIndex: 10,
      backgroundColor: colors.white,
    },
    header: {
      color: colors.black,
      fontWeight: 500,
    },
    saveBtnRoot: {
      borderRadius: 5,
      border: `1px solid ${colors.greySeaShell}`,
      boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    },
    saveBtnText: {
      color: theme.palette.primary.main,
    },
    tabText: {
      textTransform: 'none',
      fontWeight: 400,
      color: colors.black54,
    },
    activeTab: {
      color: theme.palette.primary.main,
    },
  })
);

export default Settings;
