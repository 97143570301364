import React from 'react';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { HEADER_HEIGHT } from 'utils/Utils';
import FunctionsList from './FunctionsList';
import { colors } from 'utils/colors';
export const SharedUtilities: React.FC = () => {
  return (
    <FlexContainer>
      <FunctionsList />
      <RightMenu>
        <Outlet />
      </RightMenu>
    </FlexContainer>
  );
};

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - ${HEADER_HEIGHT}px);
`;

const RightMenu = styled.div`
  flex: 1;
  padding: 34px 51px 20px 51px;
  background-color: ${colors.white};
  height: 100%;
  overflow-y: auto;
  padding-top: 0px;
`;
