import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { Outlet } from 'react-router-dom';
import SymbolValueContextProvider from '../settings/SymbolContext';

const UISettings = () => {
  const classes = useStyles();
  return (
    <SymbolValueContextProvider>
      <Box className={classes.container}>
        <Outlet />
      </Box>
    </SymbolValueContextProvider>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      height: '100%',
    },
  })
);

export default UISettings;
