import { FunctionComponent } from 'react';
import { SimpleOptionType, V2SingleSelectComponent } from '@terragotech/form-renderer';
import styled from 'styled-components';
import { NestedFormControlProps } from '../FormTypes';
import { StandardInputFrame, Label, Placeholder } from './Common';
import { FormBadges } from './FormBadge';

const BodyFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LeftFrame = styled.div``;
const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;
const SelectControl: FunctionComponent<NestedFormControlProps<V2SingleSelectComponent>> = (
  props
) => {
  const { controlDefinition, pasted } = props;
  const placeholder =
    (controlDefinition.options.type === SimpleOptionType &&
      controlDefinition.options?.items?.[0]?.label) ||
    controlDefinition.placeholder;

  return (
    <StandardInputFrame>
      <BodyFrame>
        <LeftFrame>
          <Label
            text={controlDefinition.label}
            conditional={Boolean(controlDefinition.conditionalMap)}
          />
          <Placeholder>{placeholder}</Placeholder>
        </LeftFrame>
      </BodyFrame>
      <FormBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default SelectControl;
