import React, { useContext } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { ConfigContext } from '../.././../../context/ConfigContext';
import { JSONSchema6 } from 'json-schema';
import { useOutletContext } from 'react-router-dom';
import SchemaEditor from 'components/SchemaEditor';
import { colors } from 'utils/colors';

export interface CSVEvent {
  type: string;
  functionId: string;
  eventId: string;
  functionType: string;
  data: {
    [key: string]: string | null;
  };
  source: string;
  metadata: object;
}
interface props {
  aggregate: string;
  version: number;
  handleInputSchema: (schema: JSONSchema6) => void;
  onSave: () => void;
  schemaRef: { current: {} | JSONSchema6 };
}

export const FunctionInputSchemaEditor: React.FC = () => {
  const {
    aggregate,
    version,
    handleInputSchema: onChange,
    onSave,
    schemaRef,
  } = useOutletContext() as props;
  const { getFunction } = useContext(ConfigContext);
  const classes = useStyles();

  const schemaData = getFunction(aggregate)?.versions[+version]?.input;

  return (
    <Box className={classes.tabContainer}>
      <SchemaEditor {...{ onChange, schemaRef, schemaValue: schemaData, onSave }} />
    </Box>
  );
};
const useStyles = makeStyles({
  tabContainer: {
    height: '100%',
    overflow: 'auto',
    background: colors.greySaturated,
    border: `1px solid ${colors.black10}`,
  },
});
