import { useContext } from 'react';
import { ConfigContext } from '../ConfigContext';
import { errorMsg } from '../../components/SnackbarUtilsConfigurator';
import {
  UIConfigType,
  FabAction,
  ActionButton,
  FabActions,
} from '../../utils/types';
import { getAggregateIndex } from '../../utils/navigationUtils';
import { defaultAggregateUICustomization } from '../../utils/jsonPartsGenerators';
import { ActionButtonType } from '../../components/ActionButtonEditor/ActionButtonEditor';
import { MobileCardDefinition, WebCardDefinition } from "@terragotech/gen5-config-lib";

export const useUICustomizationAPI = () => {
  const {
    config,
    getConfig,
    setUICustomization,
    getUICustomizations,
    setUICustomizations,
    getFabActions,
    setFabActions,
    getImportActions,
    setImportActions,
    getActionButtons,
    setActionButtons,
    setCardDefinition,
  } = useContext(ConfigContext);

  const promisify = <T extends Function>(
    child: T
  ): Promise<{ error: Error | null; data: T | null }> => {
    return new Promise((resolve, reject) => {
      try {
        resolve({ error: null, data: child() });
      } catch (e) {
        if (e instanceof Error) {
          errorMsg(e.message);
        }
        reject({ error: e as Error, data: null });
      }
    });
  };

  const addNewAggregateUICustomization = (uiConfigType: UIConfigType, aggrUIName: string) =>
    promisify(() => {
      const labelProperty = getConfig().aggregates[getAggregateIndex(config, aggrUIName)]
        .labelProperty;
      const aggregateUICustomization = defaultAggregateUICustomization(uiConfigType, labelProperty);
      setUICustomization(uiConfigType, aggrUIName, aggregateUICustomization);
    });

  const deleteAggregateUICustomization = (uiConfigType: UIConfigType, aggrUIName: string) =>
    promisify(() => {
      const aggrUICustomizations = getUICustomizations(uiConfigType);
      if (aggrUICustomizations) {
        delete aggrUICustomizations[aggrUIName];
        setUICustomizations(uiConfigType, aggrUICustomizations);
      }
    });


  const updateAggregateUICustomization = (uiConfigType: UIConfigType, oldAggrName: string, newAggrName: string) =>
    promisify(() => {
      const aggrUICustomizations = getUICustomizations(uiConfigType);
      if (aggrUICustomizations) {
        const data = aggrUICustomizations[oldAggrName];
        delete aggrUICustomizations[oldAggrName]
        aggrUICustomizations[newAggrName] = data;
        setUICustomizations(uiConfigType, aggrUICustomizations);
      }
    });

  const addFabAction = (uiConfigType: UIConfigType, fabAction: FabAction) =>
    promisify(() => {
      const fabActions = getFabActions(uiConfigType) || [];
      fabActions.push(fabAction);
      setFabActions(uiConfigType, fabActions);
    });

  const addImportAction = (uiConfigType: UIConfigType, fabAction: FabAction) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType) || [];
      fabActions.push(fabAction);
      setImportActions(uiConfigType, fabActions);
    });

  const updateImportAction = (
    uiConfigType: UIConfigType,
    fabActionIndex: number,
    fabAction: FabAction
  ) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType);
      fabActions[fabActionIndex] = fabAction;
      setImportActions(uiConfigType, fabActions);
    });

  const deleteImportAction = (uiConfigType: UIConfigType, fabActionIndex: number) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType);
      fabActions.splice(fabActionIndex, 1);
      setImportActions(uiConfigType, fabActions);
    });

  const moveImportAction = (
    uiConfigType: UIConfigType,
    fabActionIndex: number,
    direction: 'UP' | 'DOWN'
  ) =>
    promisify(() => {
      const fabActions = getImportActions(uiConfigType);
      if (direction === 'UP') {
        if (fabActionIndex === 0) throw new Error('Editor action is already at the top');
        fabActions.splice(
          fabActionIndex - 1,
          2,
          fabActions[fabActionIndex],
          fabActions[fabActionIndex - 1]
        );
      }
      if (direction === 'DOWN') {
        if (fabActionIndex === fabActions.length - 1)
          throw new Error('Editor action is already at the bottom');
        fabActions.splice(
          fabActionIndex,
          2,
          fabActions[fabActionIndex + 1],
          fabActions[fabActionIndex]
        );
      }
      setImportActions(uiConfigType, fabActions);
    });

  const updateFabAction = (
    uiConfigType: UIConfigType,
    fabActionIndex: number,
    fabAction: FabAction
  ) =>
    promisify(() => {
      const fabActions = getFabActions(uiConfigType);
      fabActions[fabActionIndex] = fabAction;
      setFabActions(uiConfigType, fabActions);
    });

  const deleteFabAction = (uiConfigType: UIConfigType, fabActionIndex: number) =>
    promisify(() => {
      const fabActions = getFabActions(uiConfigType);
      fabActions.splice(fabActionIndex, 1);
      setFabActions(uiConfigType, fabActions);
    });

  const reOrderRow = (
    uiConfigType: UIConfigType,
    keyValue: 'fabActions' | 'importActions',
    rowData: FabActions
  ) =>
    promisify(() => {
      const setActions = keyValue === 'fabActions' ? setFabActions : setImportActions;
      setActions(uiConfigType, rowData);
    });

  const addActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    isMergedConfig: boolean,
    actionButton: ActionButton
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType) || [];
      if (actionButtons) {
        actionButtons.push(actionButton);
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
      }
    });

  const addActionButtons = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    newActionButtons: ActionButton[]
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        actionButtons.push(...newActionButtons);
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons);
      }
    });

    const reOrderActionButton = (
        uiConfigType: UIConfigType,
        aggrUIName: string,
        actionButtonType: ActionButtonType,
        isMergedConfig: boolean,
        actionButtons: ActionButton[]
    ) =>
        promisify(() => {
            setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
        });

  const updateActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    actionButtonIndex: number,
    fabAction: ActionButton,
    isMergedConfig: boolean = false,
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        actionButtons[actionButtonIndex] = fabAction;
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
      }
    });

  const deleteActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    actionButtonIndex: number,
    isMergedConfig: boolean = false,
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        actionButtons.splice(actionButtonIndex, 1);
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons, isMergedConfig);
      }
    });

  const moveActionButton = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    actionButtonIndex: number,
    direction: 'UP' | 'DOWN'
  ) =>
    promisify(() => {
      const actionButtons = getActionButtons(uiConfigType, aggrUIName, actionButtonType);
      if (actionButtons) {
        if (direction === 'UP') {
          if (actionButtonIndex === 0) throw new Error('Action button is already at the top');
          actionButtons.splice(
            actionButtonIndex - 1,
            2,
            actionButtons[actionButtonIndex],
            actionButtons[actionButtonIndex - 1]
          );
        }
        if (direction === 'DOWN') {
          if (actionButtonIndex === actionButtons.length - 1)
            throw new Error('Action button is already at the bottom');
          actionButtons.splice(
            actionButtonIndex,
            2,
            actionButtons[actionButtonIndex + 1],
            actionButtons[actionButtonIndex]
          );
        }
        setActionButtons(uiConfigType, aggrUIName, actionButtonType, actionButtons);
      }
    });

  const updateCardDefinition = (
    uiConfigType: UIConfigType,
    aggrUIName: string,
    webCardDefinition: WebCardDefinition,
    mobileCardDefinition: MobileCardDefinition,
  ) =>
    promisify(() => {
      setCardDefinition(uiConfigType, aggrUIName, webCardDefinition, mobileCardDefinition);
    });

  return {
    addNewAggregateUICustomization,
    deleteAggregateUICustomization,
    addFabAction,
    addImportAction,
    addActionButtons,
    updateFabAction,
    deleteFabAction,
    updateImportAction,
    deleteImportAction,
    moveImportAction,
    addActionButton,
    updateActionButton,
    deleteActionButton,
    moveActionButton,
    updateCardDefinition,
    reOrderRow,
    reOrderActionButton,
    updateAggregateUICustomization,
  };
};
