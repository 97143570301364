import React, { useState, useContext, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { CheckboxInput } from '../FormElements';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { JSONSchema6 } from 'json-schema';
import { LocalSchemaDefinition } from '../../utils/useSchemaLookup';
import { useFormSchemas } from './hooks/useFormSchemas';
import { ConfigContext } from '../../context/ConfigContext';
import { V2MapAggregateSelectorComponent } from '@terragotech/form-renderer';
import { DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import _ from 'lodash';
import { expandPath } from './TextInputEditForm';
import { getPropertyObjectFromFormComponent } from '../../pages/aggregates/utils/V2FormTemplateToJsonSchema';
import { useStyles } from './useStyles';
import { TGSelect } from 'views/components/formElements';
import { FormEditProps } from 'utils/types';
import MapperItem from './MapperItem';
import { Mapper } from './MapperDefinition';
import FormValidation from './FormValidation';
import { TextInput } from './common';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';

export type AggregateSelectorTemplateWithName = V2MapAggregateSelectorComponent & {
  name: string;
  droppableId: string;
  index?: number;
};

interface MapAggregateSelectorProps extends FormEditProps {
  component: AggregateSelectorTemplateWithName;
  autosuggest?: boolean;
}

export const MapAggregateSelector: React.FC<MapAggregateSelectorProps> = ({
  existingNameError,
  handleNameChange,
  component,
  getEditedDef,
  setFormDefinition,
}) => {
  const classes = useStyles();
  const [oldName, setOldName] = useState(component.name);
  const { getAggregates } = useContext(ConfigContext);
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState(component.label);
  const [selectableAggregateTypes, setSelectableAggregateTypes] = useState<string[]>(
    component.selectableAggregateTypes || []
  );
  const [droppableId, setDroppableId] = useState(component.droppableId);
  const [type, setType] = useState(component.type);
  const [required, setRequired] = useState(component.required);
  const [placeholder, setPlaceholder] = useState(component.placeholder);
  const [description, setDescription] = useState(component.description);
  const [info, setInfo] = useState(component.info);
  const [readOnly, setReadOnly] = useState(component.readOnly);
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const [errorMap, setErrorMap] = useState<NodeMapDefinition[] | undefined>(
    component.errorMap || undefined
  );
  const [warningMap, setWarningMap] = useState<NodeMapDefinition[] | undefined>(
    component.warningMap || undefined
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const formSchemas = useFormSchemas();
  const { openConfirmation } = useConfirmDialog();

  const setInitialData = useCallback(() => {
    setName(component.name || '');
    setOldName(component.name || '');
    setLabel(component.label || '');
    setType(component.type);
    setDroppableId(component.droppableId);
    setSelectableAggregateTypes(component.selectableAggregateTypes || []);
    setConditionalMap(component.conditionalMap || undefined);
    setRequired(component.required);
    setPlaceholder(component.placeholder || '');
    setDescription(component.description);
    setInfo(component.info);
    setReadOnly(component.readOnly);
    setRepeats(
      component.repeats
        ? { ...defaultRepeatableProps, ...component.repeats }
        : defaultRepeatableProps
    );
    setErrorMap(component.errorMap || undefined);
    setWarningMap(component.warningMap || undefined);
  }, [component]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const getFormValues = useCallback(() => {
    return {
      type: type,
      name,
      label,
      ...(selectableAggregateTypes.length > 0 && { selectableAggregateTypes }),
      ...(placeholder && { placeholder }),
      ...(required !== undefined && { required }),
      ...(description && { description }),
      ...(info && { info }),
      ...(readOnly !== undefined && { readOnly }),
      repeats,
      ...(conditionalMap && { conditionalMap }),
      ...(errorMap && { errorMap }),
      ...(warningMap && { warningMap }),
      droppableId: droppableId,
    };
  }, [
    droppableId,
    type,
    name,
    label,
    selectableAggregateTypes,
    placeholder,
    required,
    description,
    info,
    readOnly,
    repeats,
    conditionalMap,
    errorMap,
    warningMap,
  ]);

  useEffect(() => {
    if (getEditedDef && setFormDefinition && name.length > 0 && !existingNameError) {
      const editedDef = getEditedDef({
        editedData: getFormValues(),
        oldName,
        newName: name,
        droppableId: component.droppableId,
      });
      if (editedDef) {
        setFormDefinition(editedDef);
        if (oldName !== name) {
          setOldName(name);
        }
      }
    }
    // If add those all dependency, it'll call recursively
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, getFormValues]);

  const getSelectableAggregateOptions = getAggregates().map((item) => item.typeName);

  const parts = (component.droppableId || '').split('.');
  const t =
    !component.droppableId || component.droppableId === 'form'
      ? []
      : expandPath(parts, formSchemas.FORM.schema.properties as JSONSchema6);

  let formValue = { value: getPropertyObjectFromFormComponent(component) };
  for (let i = 0; i < t.length; i++) {
    formValue = { ...formValue, ...t[i] };
  }
  const errorWarningSchemas: LocalSchemaDefinition = {
    FORMVALUE: {
      schema: {
        type: 'object',
        properties: formValue,
      } as JSONSchema6,
      schemaLabel: 'Current Field Value',
    },
    ...formSchemas,
  };
  const handleClearConditionalMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setConditionalMap(undefined);
    }
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  const mapperList: Mapper[] = [
    {
      title: 'Error Mapping',
      data: errorMap || [],
      localSchemas: errorWarningSchemas,
      setData: (data) => setErrorMap(data),
      mapScenario: 'FIELD_LEVEL_ERROR',
      containerStyle: classes.firstMapper,
    },
    {
      title: 'Warning Mapping',
      data: warningMap || [],
      localSchemas: errorWarningSchemas,
      setData: (data) => setWarningMap(data),
      mapScenario: 'FIELD_LEVEL_WARNING',
      containerStyle: classes.secondMapper,
    },
  ];

  return (
    <>
      <Typography className={classes.header}>{name}</Typography>
      <TextInput
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
        value={name}
        onChange={(value) => handleNameChange && handleNameChange(value || '', setName)}
        placeholder="Enter name"
      />
      <TextInput
        id="Label"
        label="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        placeholder="Enter label"
      />
      <TGSelect
        id="Options type"
        label="Selectable Aggregate Types"
        options={getSelectableAggregateOptions}
        value={(selectableAggregateTypes || []) as string[]}
        onChange={(value) => setSelectableAggregateTypes(value as string[])}
        containerStyle={classes.selectContainer}
        multiple
      />
      <TextInput
        id="Placeholder"
        label="Placeholder"
        value={placeholder}
        onChange={(value) => setPlaceholder(value)}
        placeholder="Enter placeholder"
      />
      <TextInput
        id="Description"
        label="Description"
        value={description}
        placeholder="Enter description"
        onChange={(value) => setDescription(value)}
      />
      <TextInput
        id="Info"
        label="Info"
        value={info}
        placeholder="Enter info"
        onChange={(value) => setInfo(value)}
      />
      <Box className={`${classes.itemContainer} ${classes.bottom20}`}>
        <Typography className={classes.label}>Additional Options</Typography>
        <Box className={classes.checkBoxContainer}>
          <CheckboxInput
            title="Required"
            checked={required}
            onChange={(value) => setRequired(value)}
          />
          <CheckboxInput
            title="Read-only"
            checked={readOnly}
            onChange={(value) => setReadOnly(value)}
          />
        </Box>
      </Box>
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: doesConditionalHaveValue(),
          clearMapper: handleClearConditionalMapper,
          openDataMap: conditionalOpen,
          dataMap: conditionalMap,
          setDataMap: setConditionalMap,
          localSchemaDefinition: errorWarningSchemas,
          title: 'Conditional Map',
          mapScenario: 'FORM_FIELD_CONDITIONAL',
        }}
      />
      <Box className={`${classes.itemContainer} ${classes.bottom20}`}>
        <Typography className={classes.label}>Repeating Instances</Typography>
        <RepeatableComponent repeats={repeats} setRepeats={setRepeats} />
      </Box>
      <FormValidation mapperList={mapperList} />
    </>
  );
};
