import { createStyles, makeStyles, Theme, Typography, Link } from '@material-ui/core';
import React, { useState } from 'react';
import LoginFields, { LoginField } from './LoginFields';
import TGLoginButton, { TGLoginButtonType } from './TGLoginButton';
import { colors } from '@terragotech/gen5-shared-components'
import ForgotPasswordFlow from './ForgotPassword/ForgotPasswordFlow';

interface Props {
  fields: LoginField[];
  errorMessage?: string;
}

const LoginEmailView: React.FC<Props> = (props: any) => {
  const { errorMessage, fields } = props;
  const classes = useStyles();
  const [showPasswordReset, setShowPasswordReset] = useState(false);

  return (
    <>
      <LoginFields fields={fields} errorMessage={errorMessage} />
      {errorMessage && (
        <Typography variant="h3" className={classes.errorText}>
          {errorMessage}
        </Typography>
      )}
      <Link
        className={classes.forgotText}
        onClick={(ev: React.MouseEvent) => {
          ev.preventDefault();
          setShowPasswordReset(true);
        }}
        href=""
      >
        FORGOT PASSWORD?
      </Link>
      <TGLoginButton type={TGLoginButtonType.primary}>LOGIN</TGLoginButton>
      {showPasswordReset && (
        <ForgotPasswordFlow
          title={'Reset your password'}
          onClose={() => setShowPasswordReset(false)}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorText: {
      color: colors.danger,
      marginBottom: 15,
    },
    forgotText: {
      fontSize: '13px',
      color: theme.palette.primary.main,
      marginBottom: 20,
    },
  })
);

export default LoginEmailView;
