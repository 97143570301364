import { LocalSchemaDefinition } from '../../../utils/useSchemaLookup';
import { metadataSchema } from '../../../utils/jsonPartsGenerators';
import { useForm } from '../contexts/FormContext';
import { useCurrentAggregate } from '../contexts/AggregateContext';

export const useFormSchemas = (
  additionalSchemas?: LocalSchemaDefinition
): LocalSchemaDefinition => {
  const { aggregateSchema } = useCurrentAggregate();
  const { formSchema } = useForm();

  return {
    ...additionalSchemas,
    METADATA: {
      schema: metadataSchema,
      schemaLabel: 'Metadata',
    },
    STATE: {
      schema: aggregateSchema,
      schemaLabel: 'Properties',
    },
    FORM: {
      schema: formSchema,
      schemaLabel: 'FORM',
    },
  };
};
