import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

const TAB_PANEL_HEIGHT = 65;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabPanel: {
      padding: '40px 0px',
      height: `calc(100% - ${TAB_PANEL_HEIGHT}px)`,
    },
    container: {
      height: '100%',
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
      backgroundColor: colors.greySaturated,
      overflow: 'hidden',
      paddingBottom: 10,
    },
    tabBar: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: colors.black5,
      height: 47,
    },
    tabBtn: {
      padding: '13px 22px',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
    activeTab: {
      backgroundColor: colors.gray99,
    },
    tabBtnTxt: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black54,
    },
    tabActiveTxt: {
      color: colors.black,
    },
    body: {
      padding: '40px 25px',
      height: '100%',
      overflow: 'auto',
    },
    innerTabPanel: {
      display: 'none',
    },
    activTab: {
      display: 'block',
    },
    title: {
      fontSize: 18,
      fontWeight: 500,
      color: colors.black,
      lineHeight: '100%',
    },
    disc: {
      fontSize: 14,
      color: colors.black85,
      fontWeight: 400,
      lineHeight: '100%',
      marginTop: 7,
    },
    pannelContainer: {
      marginTop: 30,
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
    },
    panelHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.black5,
      height: 46,
      padding: '0 20px',
    },
    leftContent: {
      display: 'flex',
    },
    rightContent: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    panelTxt: {
      fontSize: 15,
      fontWeight: 500,
      color: colors.black,
    },
    groupBtn: {
      marginLeft: 16,
      '& .MuiButton-root': {
        width: 45,
        height: 25,
      },
    },
    unSelected: {
      color: colors.gray80,
      backgroundColor: colors.greyMercury,
    },
    panelBody: {
      backgroundColor: colors.white,
    },
    table: {
      minWidth: 650,
    },
    formControl: {
      width: '100%',
    },
    headerCell: {
      height: 15,
      paddingTop: 12,
      paddingBottom: 7,
    },
    headerCellTxt: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
    },
    trashIcon: {
      fontSize: 20,
    },
    input: {
      fontSize: 15,
      fontWeight: 400,
    },
    gridContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 13,
    },
    g1: {
      width: 24,
    },
    headerContainer: {
      marginBottom: 30,
    },
    bottom0: {
      marginBottom: 0,
    },
    checkbox: {
      width: 24,
      height: 24,
    },
    fullWidth: {
      marginLeft: 37,
    },
    pt3: {
      paddingTop: 0,
      marginBottom: 0,
    },
  })
);
