import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '../resources/images/icon_delete.png';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import React from 'react';
import { colors } from 'utils/colors';
import LinkButton from 'views/pages/ui/components/LinkButton';
import _ from 'lodash';

type Color = 'orange' | 'purple' | 'blue' | 'green' | number;

interface AggregateNodeProps {
  children?: React.ReactNode;
  color?: Color;
  label: string;
  count?: number;
  nodeId: string;
  headerName?: string;
  onAdd?: () => void;
  onDelete?: () => void;
  onRemove?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onLabelClick?: () => void;
  onToggleLink?: () => void;
  isLinked?: boolean;
  isParent?: boolean;
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelInfo?: number;
  labelText: string;
  commentName: string;
  onAdd?: () => void;
  onDelete?: () => void;
  onRemove?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  onLabelClick?: () => void;
  onToggleLink?: () => void;
  isLinked?: boolean;
  isParent?: boolean;
};

export const TreeViewNode: React.FC<AggregateNodeProps> = ({
  children,
  label,
  count,
  nodeId,
  onAdd,
  onDelete,
  onRemove,
  onMoveUp,
  onMoveDown,
  color,
  onLabelClick,
  onToggleLink,
  isLinked,
  isParent,
  headerName,
}) => {
  const colors = ['#e3742f', '#a250f5', '#1a73e8', '#3c8039'];
  const bgColors = ['#fcefe3', '#f3e8fd', '#e8f0fe', '#e6f4ea'];
  const getColor = () => {
    switch (color) {
      case 'orange':
        return '#e3742f';
      case 'purple':
        return '#a250f5';
      case 'blue':
        return '#1a73e8';
      case 'green':
        return '#3c8039';
      default:
        if (typeof color === 'number') return colors[color % 4];
        return '#e3742f';
    }
  };
  const getBgColor = () => {
    switch (color) {
      case 'orange':
        return '#fcefe3';
      case 'purple':
        return '#f3e8fd';
      case 'blue':
        return '#e8f0fe';
      case 'green':
        return '#e6f4ea';
      default:
        if (typeof color === 'number') return bgColors[color % 4];
        return '#fcefe3';
    }
  };
  return (
    <StyledTreeItem
      nodeId={nodeId}
      onAdd={onAdd}
      onDelete={onDelete}
      onRemove={onRemove}
      onMoveUp={onMoveUp}
      onMoveDown={onMoveDown}
      onLabelClick={onLabelClick}
      onToggleLink={onToggleLink}
      labelText={label}
      commentName={headerName as string}
      labelInfo={count}
      color={getColor()}
      bgColor={getBgColor()}
      isLinked={isLinked}
      isParent={isParent}
    >
      {children}
    </StyledTreeItem>
  );
};

declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

const StyledTreeItem = (props: StyledTreeItemProps) => {
  const {
    labelText,
    commentName,
    labelInfo,
    color,
    bgColor,
    nodeId,
    onDelete,
    onRemove,
    onAdd,
    onMoveUp,
    onMoveDown,
    onLabelClick,
    onToggleLink,
    ...other
  } = props;

  const classes = useTreeItemStyles();

  const getLabelPadding = (text: string) => {
    switch (text) {
      case 'Settings':
        return '12';
      case 'Commands':
        return '12';
      case 'Events':
        return '12';
      default:
        return '0';
    }
  };
  const isParent = _.isEqual(labelText, commentName);
  const AggregateLabel = [labelText];
  const replaceAggregateLabel: { [key: string]: string } = {
    'Card Definition': 'Card View',
    'Map Label Properties': 'Map Label',
    'Editor Actions': 'Editor/Table Actions',
  };
  const Labels = AggregateLabel.map((item) => replaceAggregateLabel[item] || item);
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <Typography className={classes.labelText} variant={isParent ? 'h2' : 'body1'}>
            {Labels}
          </Typography>
          <Typography variant="caption" color="inherit" style={styles.labelText}>
            {labelInfo}
          </Typography>

          {onMoveUp && (
            <IconButton
              style={styles.button}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onMoveUp();
              }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          )}

          {onMoveDown && (
            <IconButton
              style={styles.button}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onMoveDown();
              }}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          )}

          {onAdd && (
            <IconButton
              style={styles.button}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onAdd();
              }}
            >
              <AddIcon />
            </IconButton>
          )}

          {onRemove && (
            <IconButton
              style={styles.button}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onRemove();
              }}
            >
              <ClearIcon />
            </IconButton>
          )}

          {props.isLinked !== undefined && <LinkButton isLinked={props.isLinked} />}

          {onDelete && (
            <IconButton
              style={styles.button}
              component="span"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <img src={DeleteIcon} alt="Delete Asset" />
            </IconButton>
          )}
        </div>
      }
      style={{
        marginLeft: getLabelPadding(labelText),
        borderRadius: 5,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      className={isParent ? classes.parentItem : ''}
      nodeId={nodeId}
      {...other}
      onLabelClick={onLabelClick}
    />
  );
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        display: 'block',
      },
      '& > $content $label': {
        backgroundColor: 'transparent',
        padding: '0',
        color: colors.black54,
      },
      '&:hover > $content': {
        backgroundColor: theme.palette.primary.light,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: theme.palette.primary.light,
        borderRadius: 5,
      },

      '& .MuiTypography-h2': {
        color: colors.black,
        fontSize: 15,
        fontWeight: 400,
      },
      [`&.Mui-selected > .MuiTreeItem-content .MuiTypography-h2,
      &:hover > .MuiTreeItem-content .MuiTypography-h2,
      &:hover > $content $label,
      &$selected > $content $label`]: {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent !important',
      },
      '& .MuiTreeItem-iconContainer': {
        marginRight: 8,
        '& svg': {
          fontSize: 24,
          color: colors.black54,
        },
      },
    },
    content: {
      display: 'flex',
      color: theme.palette.text.primary,
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 0,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: '.5rem 0 .5rem 0',
      height: 40,
    },
    parentItem: {
      margin: '10px 0',
    },
    labelText: {
      flexGrow: 1,
      wordBreak: 'break-word',
      fontSize: 14,
      color: 'inherit',
    },
  })
);
const styles = {
  button: {
    color: colors.darkBlue,
    padding: 0,
    zIndex: 1,
  },
  labelText: { marginRight: 5, marginLeft: 5 },
};
