import {
  AbstractReactFactory,
  GenerateWidgetEvent,
  GenerateModelEvent,
} from '@projectstorm/react-canvas-core';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import { AccessorMap, NodeKeys, SchemaLookup } from '@terragotech/gen5-datamapping-lib';
import { BaseNode } from '@terragotech/gen5-datamapping-lib';
import React from 'react';
import { DataMapperNodeModel } from './DataMapperNodeModel';
import BasicNodeWidget from './DataMapperNodeWidget';

export interface NodeExternalLookups {
  accessorMap?: AccessorMap;
  schemaLookup?: SchemaLookup;
}
/**
 * This function takes a node definition from the data mapping lib
 * @param nodeDef The datamapping node bas class used to build the react diagrams node factory class
 */
export const createBasicNodeFactoryClass = ({
  nodeDef,
  lookups,
}: {
  nodeDef: typeof BaseNode;
  lookups?: NodeExternalLookups;
}): AbstractReactFactory => {
  const TransFormNodeFactoryClass = class extends AbstractReactFactory<
    DataMapperNodeModel,
    DiagramEngine
  > {
    constructor() {
      super(nodeDef.nodeDefinition.NODE_TYPE);
    }

    /**
     * This generates a model class instance to be used to work with the node's data
     * @param event
     */
    generateModel(event: GenerateModelEvent) {
      //const defaults = { mappings: [], name: nodeDef.nodeDefinition.defaultTitle }; //nodeDef.defaultTitle };

      return new DataMapperNodeModel({
        //...defaults,
        id: event.initialConfig.id,
        name: event.initialConfig.name || nodeDef.nodeDefinition.defaultTitle,
        type: nodeDef.nodeDefinition.NODE_TYPE,
        description: event.initialConfig.description,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore Not sure how to fix this one yet.
        nodeConfiguration: {
          inputs: event.initialConfig?.inputs,
          config: event.initialConfig?.config,
          //@ts-ignore
          type: nodeDef.nodeDefinition.NODE_TYPE as NodeKeys,
        },
        lookups,
        newlyCreated: event.initialConfig.newlyCreated,
      });
    }
    /**
     * This function is used to generate a react component from the model
     * @param event event from react diagrams
     */
    generateReactWidget(event: GenerateWidgetEvent<DataMapperNodeModel>): JSX.Element {
      return <BasicNodeWidget node={event.model} engine={this.engine} />;
    }
  };
  return new TransFormNodeFactoryClass();
};
