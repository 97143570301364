import { Breadcrumbs, createStyles, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import { colors } from '../../../../../utils/colors';
import { goTo } from '../../../../../utils/Utils';
import { useNavigate } from 'react-router-dom';

interface TGBreadCrumbsProp {
  title: string;
  backPath: string;
  selectedItem: string;
}
const TGBreadCrumbs = ({ title, backPath, selectedItem }: TGBreadCrumbsProp) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.breadCrumb}>
      <Link
        underline="hover"
        color="primary"
        onClick={() => goTo(navigate, backPath)}
        className={classes.link}
      >
        {title}
      </Link>
      <Typography className={`${classes.linkText} ${classes.black}`}>{selectedItem}</Typography>
    </Breadcrumbs>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadCrumb: {
      '& .MuiBreadcrumbs-separator': {
        margin: '0 4px',
        color: theme.palette.primary.main,
      },
    },
    link: {
      fontSize: 15,
      fontWeight: 500,
      '&:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
    linkText: {
      fontSize: 15,
      fontWeight: 500,
    },
    black: {
      color: colors.black,
    },
  })
);
export default TGBreadCrumbs;
