import React, { useState, useContext } from 'react';
import { V2StreetViewComponent } from '@terragotech/page-renderer';
import { isEqual } from 'lodash';
import { usePageSchemas } from './hooks/usePageSchemas';
import { PageContext } from './contexts/PageContext';
import { checkDuplicatePageName } from '../../pages/aggregates/utils/pageUtils';
import usePageRedirect from './hooks/usePageRedirect';
import { TextInputTG as TextInput } from 'views/components/formElements';
import { useCustomPageStyles } from './commonStyles';
import ModalFrame from './ModalFrame';
import MapperItem from 'components/FormDialog/MapperItem';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION, getPresentValue } from 'utils/Utils';

export type StreetViewComponentDefWithName = V2StreetViewComponent & {
  name: string;
};

interface StreetViewEditFormFormProps {
  onSubmit: (result: StreetViewComponentDefWithName) => void;
  onClose: () => void;
  component: StreetViewComponentDefWithName;
}

export const StreetViewEditForm: React.FC<StreetViewEditFormFormProps> = ({
  onSubmit,
  onClose,
  component,
}) => {
  const [name, setName] = useState(component.name);
  const [label, setLabel] = useState<string>(component.label);
  const [displayOptions, setDisplayOptions] = useState(component.displayOptions || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [height, setHeight] = useState(component.height);
  const classes = useCustomPageStyles();
  const pageSchemas = usePageSchemas();
  const { isForceLeaveConfirmed } = usePageRedirect();
  const [existingNameError, setExistingNameError] = useState(false);
  const { pageDefinition } = useContext(PageContext);
  const { openConfirmation } = useConfirmDialog();
  const nameHelperTxt =
    'Duplicate name error. The name must be unique accross all workflow elements';

  const handleClearDisplayMapper = async () => {
    const status = await openConfirmation(CONFIRMATION.commonClear);
    if (status === 'confirm') {
      setDisplayOptions(undefined);
    }
  };
  const getValue = (value: unknown, key: string) => getPresentValue(component, value, key);

  const getFormValues = () => ({
    type: component.type,
    name: name || '',
    label: label || '',
    ...getValue(height, 'height'),
    ...(displayOptions && { displayOptions }),
    droppableId: component.droppableId,
  });

  const isFormDirty = () => !isEqual(component, getFormValues());

  const handleClose = async () =>
    (!isFormDirty() || (isFormDirty() && (await isForceLeaveConfirmed({ handleSubmit })))) &&
    onClose();

  const handleSubmit = async () => {
    if (checkDuplicatePageName(name, component, pageDefinition.elements)) {
      setExistingNameError(true);
    } else onSubmit(getFormValues());
  };

  return (
    <ModalFrame
      {...{ name: component.name, type: component.type, classes, handleClose, handleSubmit }}
    >
      <TextInput
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError}
        helperText={existingNameError ? nameHelperTxt : ''}
        value={name}
        onChange={(value) => setName(value || '')}
        style={styles.textInput}
        labelRoot={classes.Root}
      />
      <TextInput
        id="Label"
        label="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        style={styles.textInput}
        labelRoot={classes.Root}
      />
      <TextInput
        id="Height"
        label="Height"
        value={height}
        onChange={(value) => setHeight(value || '')}
        style={styles.textInput}
        labelRoot={classes.Root}
      />
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: displayOptions !== undefined,
          clearMapper: handleClearDisplayMapper,
          openDataMap: conditionalOpen,
          dataMap: displayOptions,
          setDataMap: setDisplayOptions,
          localSchemaDefinition: pageSchemas,
          title: 'Conditional Map',
          mapScenario: 'DISPLAY_OPTIONS',
          containerStyle: classes.mapperContainer,
        }}
      />
    </ModalFrame>
  );
};

const styles = {
  textInput: {
    margin: '10px 0',
    width: '100%',
  },
};
