{
  /*Color names are referenced from https://www.schemecolor.com/sample */
}
export const colors = {
  black87: 'rgba(0, 0, 0, 0.87)',
  black78: 'rgba(0, 0, 0, 0.24)',
  lightGray10: 'rgba(217, 217, 217, 1)',
  dimGray10: 'rgba(148, 148, 148, 1)',
  gray80: 'rgba(129, 125, 125, 0.80)',
  black59: '#00000059',
  black85: 'rgba(0, 0, 0, 0.85)',
  black60: 'rgba(0, 0, 0, 0.60)',
  black54: 'rgba(0, 0, 0, 0.54)',
  black50: 'rgba(0, 0, 0, 0.50)',
  black75: 'rgba(0, 0, 0, 0.75)',
  black70: 'rgba(0, 0, 0, 0.70)',
  black45: 'rgba(0, 0, 0, 0.45)',
  black40: 'rgba(0, 0, 0, 0.4)',
  black30: 'rgba(0, 0, 0, 0.3)',
  black35: 'rgba(0, 0, 0, 0.35)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black20: 'rgba(0, 0, 0, 0.2)',
  black10: 'rgba(0, 0, 0, 0.10)',
  black5: 'rgba(0, 0, 0, 0.05)',
  black3: 'rgba(0, 0, 0, 0.03)',
  black2: 'rgba(0, 0, 0, 0.02)',
  black1: 'rgba(0, 0, 0, 0.01)',
  blueShadow: 'rgba(69, 81, 87, 0.2)',
  paleWhite10: 'rgba(251, 251, 251, 1)',
  white10: 'rgba(255, 255, 255, 1)',
  white1: 'rgba(255, 255, 255, 0.01)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white20: 'rgba(255, 255, 255, 0.2)',
  dividerBorder: 'rgb(220, 220, 220)',
  groupDivider: 'rgb(250, 250, 250)',
  grayBorder: 'rgba(181, 181, 181, 1)',
  philippineGray10: 'rgba(140, 140, 140, 1)',
  selectedBg: 'rgba(83, 136, 216, 0.05)',
  iconBlue: 'rgba(83, 136, 216, 1)',
  semiTransparentBlack2: '#0000008A',
  black: '#000000',
  CadetBlue: '#5c9a76',
  white: '#FFFFFF',
  azureishWhite: '#D6E6FF',
  paleWhite: '#FBFBFBFB',
  black57: '#00000057',
  black4: '#00000040',
  lotion: '#FAFAFA',
  lavenderBlush: '#FFF3F3',
  jellyBean: '#D85353',
  greySeaShell: '#F1F1F1',
  greySaturated: '#FDFDFD',
  gray10: '#F2F2F2',
  gray99: '#FCFCFC',
  greyMercury: '#E6E6E6',
  greyText: '#777777',
  greyBorder: '#D7D7D7',
  greyBackground: '#F7F7F7',
  eclipse: '#3a3a3a',
  green10: '#47815a',
  ChateauGreen: '#4AA163',
  red: '#D74E4E',
  success: '#3B953E',
  sandGray: '#313131',
  classicGreen: '#3aaa3a',
  maniacGreen: '#009100',
  grayPlaceholder: '#CCCCCC',
  danger: '#EB5757',
  grayLine: '#EEEEEE',
  info: '#58C8FD',
  potterBlue: '#3a3aaa',
  backgroundLight: '#F8F8F8',
  blueMagenta: '#1D1D1F',
  grayDescription: '#8C8C8C',
  warning: '#EEA345',
  title: '#AAAAAA',
  whiteSmoke: '#f5f5f5',
  gray88: '#e0e0e0',
  gray74: '#bdbdbd',
  gray62: '#9e9e9e',
  silverSonic: '#757575',
  grayDim: '#616161',
  gray92: '#ebebeb',
  gray26: '#424242',
  gray13: '#212121',
  grayd9: '#D9D9D9',
  gray: '#808080',
  charcoal: '#333333',
  blueBorder: '#5388D8',
  linkBackground: '#D6E6FF',
  activeBtn: '#F5F9FF',
  hoverMenu: '#E3EEFF',
  cyanBlue: '#ced4da',
  hoverBlue: '#EFF3FF',
  darkBlue: '#1e88e5',
  grayBackground: '#fbfbfb',
  magnolia: '#F4FAFE',
  gray11: '#1c1c1c',
  oldSilver: '#848484',
  darkSilver: '#6F6F6F',
  lightBlueText: 'rgb(66, 165, 245)',
  electricBlue: '#6CF3E9',
  grayLight: '#5A5A5A',
  silverChalice: '#adaaaa',
  doveGray: '#656565',
  delGreen: '#4aa163',
  redLight: '#f94e4e',
  transparentBlack: 'rgba(0, 0, 0, 0.04)',
  chineseSilver: '#CBCBCB',
  lightgray: '#EFEFEF',
  borderBlue: '#00C4FF',
  lineGray: '#CECECE',
  dodgerBlue: '#1890ff',
  dimGray: 'rgb(96, 96, 96)',
  gunmetal: '#383838',
  yellowishBrown: '#847C3A',
  darkGray: 'rgba(51, 51, 51)',
  darkWashedRed: 'rgba(200, 30, 30, 0)',
  alto: '#DFDFDF',
};

export const categoryColors = {
  comparision: '#EC9516',
  collection: '#517FB0',
  conversion: '#B69A07',
  dateOrTime: '#61A79F',
  geography: '#846058',
  inputData: '#6E4DB5',
  logic: '#968C2F',
  dataLookup: '#C88366',
  math: '#75B227',
  text: '#757575',
  photo: '#EF7D7D',
  defaultColor: '#949494',
};
export const BadgeColors = {
  salmonPink: '#FF7373',
  salmonPink15: 'rgba(255, 115, 115, 0.15)',
  skyBlue: '#4FC3CA',
  skyBlue15: 'rgba(79, 195, 202, 0.15)',
  cornflowerBlue: '#5E7ADF',
  cornflowerBlue15: 'rgba(94, 122, 223, 0.15)',
  steelBlue: '#67B4E1',
  steelBlue15: 'rgba(103, 180, 225, 0.15)',
  peach: '#FFA869',
  peach15: 'rgba(255, 177, 120, 0.15)',
  slateGray: '#60777C',
  slateGray15: 'rgba(96, 119, 124, 0.15)',
  sageGreen: '#70A485',
  sageGreen15: 'rgba(112, 164, 133, 0.15)',
};
