import React, { useContext, useEffect } from 'react';
import { ConfigContext } from '../../../context/ConfigContext';
import { FabActionRow } from '../../MobileUI/subpages/FabActions';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import {
  getCommandReferenceArr,
  commandRefObjToList,
  convertCommandRefToString,
} from '../../../utils/jsonPartsGenerators';
import { FormControl } from '@material-ui/core';
import { TGSelect } from 'views/components/formElements';

interface FabActionColumnInputProps {
  row: FabActionRow;
  onChange: (value: string) => void;
  forImport?: boolean;
}

export const FabActionColumnInput: React.FC<FabActionColumnInputProps> = ({
  row,
  onChange,
  forImport,
}) => {
  const { config } = useContext(ConfigContext);
  useEffect(() => {
    if (selectedAggrNotContainSelectedAction()) onChange('');
    // eslint-disable-next-line
  }, [row.aggregateName]);

  const getSelectedAggregateIndex = () => getAggregateIndex(config, row.aggregateName);

  const getSelectedAggregateCommands = () =>
    commandRefObjToList(
      getCommandReferenceArr(config, !forImport, getSelectedAggregateIndex(), forImport)
    );

  const selectedAggrNotContainSelectedAction = () =>
    !Object.keys(getSelectedAggregateCommands()).includes(convertCommandRefToString(row.action));

  const styles = {
    selectBtn: {
      height: 38,
    },
    formContainer: {
      minWidth: 120,
      marginTop: 10,
    },
  };

  return (
    <FormControl variant="outlined" style={styles.formContainer}>
      <TGSelect
        id="Action"
        value={convertCommandRefToString(row.action) || ''}
        options={Object.keys(getSelectedAggregateCommands()).map((versions) => versions)}
        onChange={(value) => onChange(value as string)}
        type="Action"
      />
    </FormControl>
  );
};
