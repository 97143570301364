import { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import _ from 'lodash';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { ConfigContext } from '../../../context/ConfigContext';
import {
  getReOrderedItems,
  goTo,
  getUrlFirstParam,
  ROOT_RECORDS_ROUTE,
  CONFIRMATION, ADD_MODAL_WIDTH,
} from '../../../utils/Utils';
import { errorMsg, successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import DraggableListItem from './components/common/DraggableListItem';
import RecordsHeader from './components/common/RecordsHeader';
import { Commands as CommandsType } from '../../../utils/types';
import { useFormDialog } from 'components/FormDialog/FormDialogService';
import { CreateNewCommandForm } from 'components/FormDialog/CreateNewCommandForm';
import useCommonStyles from 'views/useCommonStyles';
import { EmptyCommand } from './EmptyCommand';
import { useConfirmDialog } from 'context/ConfirmContext';

const Commands = () => {
  const commonClasses = useCommonStyles();
  const { config, getAggregate, setCommands, getCommands, getCommandVersions } = useContext(
    ConfigContext
  );
  const AggregateAPI = useAggregateAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const { aggregate } = useParams() as { aggregate: string };
  const aggrIndex = getAggregateIndex(config, aggregate);
  const aggregateInfo = getAggregate(aggrIndex);
  const formDialog = useFormDialog();
  const pathName = location.pathname;
  const pattern = `${ROOT_RECORDS_ROUTE}${aggregate}/commands/`;
  const selectedCommand = getUrlFirstParam(pathName, pattern) || '';
  const { openConfirmation } = useConfirmDialog();

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const reOrderedCommands = getReOrderedItems(
      source.index,
      destination?.index,
      aggregateInfo.commands
    );
    setCommands(aggrIndex, reOrderedCommands as CommandsType);
  };

  const handleRemoveCommand = async (commandName: string) => {
    const { error } = await AggregateAPI.removeCommand(aggrIndex, commandName);
    if (error) return;
    navigate(`${ROOT_RECORDS_ROUTE}${aggregate}/commands`);
    successMsg(`Command "${commandName}" has been successfully deleted.`);
  };

  const handleAddCommand = async () => {
    const { commandName, type } = await formDialog<typeof CreateNewCommandForm>(
      (props) => <CreateNewCommandForm {...props} />,
      true,
      true,
       ADD_MODAL_WIDTH
    );
    if (getCommands(aggrIndex)?.hasOwnProperty(commandName))
      return errorMsg(`Command named "${commandName}" already exists`);
    const { error } = await AggregateAPI.addNewCommand(aggrIndex, commandName, type);
    if (error) return;
    successMsg(`New command "${commandName}" has been successfully created`);
  };
  const commands = _.keys(aggregateInfo.commands);

  const onHandleDelete = async (name: string) => {
    const props = CONFIRMATION.command({ name });
    const status = await openConfirmation(props);
    if (status === 'confirm') {
      handleRemoveCommand(name);
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box className={[commonClasses.container, commonClasses.commandEventContainer].join(' ')}>
        <Box className={[commonClasses.innerContainer, commonClasses.fullHeight].join(' ')}>
          <RecordsHeader title="Commands" onAdd={handleAddCommand} />
          <Grid
            container
            className={[commonClasses.body, commonClasses.commandEventBody].join(' ')}
          >
            <Box
              className={
                _.isEmpty(commands) ? commonClasses.leftPanelRoot : commonClasses.leftPanel
              }
            >
              {_.isEmpty(commands) && (
                <>
                  <EmptyCommand
                    title="Commands Not Added."
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, se"
                  />
                </>
              )}
              <Droppable droppableId="menu">
                {(provided) => (
                  <Box
                    //@ts-ignore
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={commonClasses.dropable}
                  >
                    {commands.map((commandName, index) => {
                      const versions = getCommandVersions(aggrIndex, commandName);
                      const versionsCount = versions?.length || 0;
                      const maxVersionIndex = versionsCount > 0 ? versionsCount - 1 : 0;
                      const maxVersion = versions ? versions[maxVersionIndex]?.version : 0;
                      const isImportCommand = versions
                        ? versions[maxVersionIndex]?.isImportCommand
                        : false;
                      const maxVersionCommandType = versions
                        ? isImportCommand
                          ? 'Import'
                          : _.capitalize(versions[maxVersionIndex]?.commandType)
                        : '';
                      return (
                        <DraggableListItem
                          name={commandName}
                          index={index}
                          key={commandName}
                          onSelectItem={() =>
                            goTo(
                              navigate,
                              `${ROOT_RECORDS_ROUTE}${aggregate}/commands/${commandName}`
                            )
                          }
                          selectedItem={selectedCommand}
                          onDelete={onHandleDelete}
                          maxVersion={maxVersion}
                          maxVersionCommandType={maxVersionCommandType}
                          goToConfigure={(e) => {
                            e.stopPropagation();
                            goTo(
                              navigate,
                              `${ROOT_RECORDS_ROUTE}${aggregate}/commands/${commandName}/version/${maxVersionIndex}`
                            );
                          }}
                        />
                      );
                    })}
                  </Box>
                )}
              </Droppable>
            </Box>
            <Outlet />
          </Grid>
        </Box>
      </Box>
    </DragDropContext>
  );
};
export default Commands;
