import React from 'react';
import styled from 'styled-components';
import requiredButton from './img/Danger.png';
import { colors } from 'utils/colors';

interface LabelProps {
  text: string;
  required?: boolean;
  info?: boolean;
  conditional?: boolean;
  isPageEditor?: boolean;
}
export const Label: React.FC<LabelProps> = ({ text, required, isPageEditor }) => (
  <LabelHolder>
    <LabelText isPageEditor={isPageEditor}>{text}</LabelText>
    {required && (
      <BadgeHolder>
        <img src={requiredButton} alt="required" />
      </BadgeHolder>
    )}
  </LabelHolder>
);

export const StandardInputFrame = styled.div`
  position: relative;
  padding-top: 14px;
  padding-bottom: 15px;
`;
export const LabelText = styled.div<{ isPageEditor?: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  text-align: left;
  margin-left: ${(props) => (props.isPageEditor ? '0px' : '15px')};
  color: ${colors.black};
`;
export const LabelHolder = styled.div`
  display: flex;
  margin-bottom: 2px;
`;
export const BadgeHolder = styled.div`
  margin-right: 8px;
  margin-top: -2px;
`;
export const LabelSubText = styled.div<{ isGroup?: boolean; isDisplayOptionsEnabled?: boolean }>`
  font-size: 15px;
  font-weight: 400;
  margin-top: ${(props) =>
    props.isDisplayOptionsEnabled ? (props.isGroup ? '-5px' : '2px') : '8px'};
  color: ${colors.black50};
`;
export const ShrinkInfo = styled.span`
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: ${colors.black50};
  margin-top: 8px;
`;
