import { Box, SvgIcon, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as Sheets } from 'images/sheets.svg';
import { ReactComponent as CircleBang } from 'images/circleBang.svg';
import { colors } from 'utils/colors';

interface EmptyCommandProps {
  title: string;
  description: string;
}
export function EmptyCommand(props: EmptyCommandProps) {
  const classes = useStyles();
  const { title, description } = props;
  return (
    <Box className={classes.emptyFomContainer}>
      <Box className={classes.emptyIcon}>
        <SvgIcon component={Sheets} />
        <SvgIcon component={CircleBang} className={classes.absoluteBang} />
      </Box>
      <Typography className={classes.emptyTxtTitle}>{title}</Typography>
      <Typography className={classes.emptyTxtDescription}>{description}</Typography>
    </Box>
  );
}
const useStyles = makeStyles({
  emptyFomContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 52px',
    height: '100%',
  },
  emptyTxtTitle: {
    fontSize: 20,
    color: colors.black50,
    fontWeight: 500,
  },
  emptyTxtDescription: {
    fontSize: 16,
    color: colors.black50,
    fontWeight: 400,
    textAlign: 'center',
  },
  emptyIcon: {
    position: 'relative',
    width: 72,
    height: 72,
  },
  absoluteBang: {
    position: 'absolute',
    right: 0,
    bottom: 7,
  },
  column: { display: 'flex', flexDirection: 'column' },
});
