import styled from 'styled-components';
import * as React from 'react';
import { colors } from 'utils/colors';

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.grayLine};
`;

const PageFrame: React.FC<{ children: React.ReactElement }> = (props) => {
  return <Screen>{props.children}</Screen>;
};
export default PageFrame;
