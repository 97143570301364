import { Key, AttributeBasedFilterSimpleFiltersWithID } from '../QueryBuilder';
import { Operators, defaultOperators, OperatorName } from '../defaults/defaultOperators';

const getDefaultOptions = (operator: OperatorName) => {
  switch (operator) {
    case 'EQUAL':
      return '';
    case 'INCLUDE':
      return [''];
    case 'NULL':
    case 'NOTNULL':
    default:
      return undefined;
  }
};

export const createCondition = (
  keys: Key[],
  operators?: Operators
): AttributeBasedFilterSimpleFiltersWithID => {
  const key = keys[0]?.name;
  const operator = (operators || defaultOperators)?.[0].name;
  const options = getDefaultOptions(operator);

  return {
    id: '',
    type: 'SimpleFilter',
    key,
    options,
    operator,
    relation: keys[0]?.relation,
  } as AttributeBasedFilterSimpleFiltersWithID;
};
