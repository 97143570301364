import {
  Theme,
  Typography,
  createStyles,
  makeStyles,
  IconButton,
  Grid,
  Box,
  useTheme,
} from '@material-ui/core';
import { faArrowRight, faBars, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'utils/colors';
import { Draggable } from 'react-beautiful-dnd';
import { MouseEvent } from 'react';

interface DraggableListItemProps {
  name: string;
  index: number;
  selectedItem: string;
  onSelectItem: (val: string) => void;
  onDelete: (val: string) => void;
  goToConfigure: (e: MouseEvent) => void;
  maxVersionCommandType?: string;
  maxVersion?: number;
}
const DraggableListItem = ({
  name,
  index,
  selectedItem,
  onSelectItem,
  onDelete,
  goToConfigure,
  maxVersion,
  maxVersionCommandType,
}: DraggableListItemProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const configBtnStyle = { color: theme.palette.primary.main };
  return (
    <Draggable key={name} draggableId={name} index={index}>
      {(dragProvider) => (
        <Grid
          container
          key={name}
          className={`${classes.menuItemContainer} ${name === selectedItem && classes.activeMenu}`}
          ref={dragProvider.innerRef}
          {...dragProvider.draggableProps}
          style={dragProvider.draggableProps.style}
          onClick={() => onSelectItem(name)}
        >
          <Grid item sm={11} className={classes.leftActions}>
            <IconButton {...dragProvider.dragHandleProps} className={classes.menuIcon}>
              <FontAwesomeIcon icon={faBars} className={classes.barIcon} />
            </IconButton>
            <Box className={classes.centerContent}>
              <Typography className={classes.commandText}>{name}</Typography>
              {maxVersion && (
                <div key={index}>
                  <Typography className={classes.commandType}>
                    {maxVersionCommandType
                      ? `Version ${maxVersion}, ${maxVersionCommandType}`
                      : `Version ${maxVersion}`}
                  </Typography>
                </div>
              )}
              <div onClick={goToConfigure} className={classes.configButton}>
                <Typography className={classes.buttonText} style={configBtnStyle}>
                  Configure
                  <FontAwesomeIcon className={classes.rightArrowIcon} icon={faArrowRight} />
                </Typography>
              </div>
            </Box>
          </Grid>
          <Grid item sm={1} className={classes.rightActions}>
            <IconButton className={classes.trashButton}>
              <FontAwesomeIcon icon={faTrashCan} size="sm" onClick={() => onDelete(name)} />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Draggable>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItemContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '20px 12px',
      borderBottom: `1px solid ${colors.black10}`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    activeMenu: {
      backgroundColor: theme.palette.primary.light,
    },
    leftActions: {
      display: 'flex',
      alignItems: 'flex-start',
      height: '100%',
    },
    rightActions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    menuIcon: {
      marginTop: -6,
      width: 30,
      height: 30,
    },
    barIcon: {
      color: colors.black54,
      fontSize: 15,
    },
    centerContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px 0px',
      marginLeft: 10,
    },
    commandText: {
      fontSize: 16,
      fontWeight: 400,
      color: colors.black,
      lineHeight: '100%',
      wordBreak: 'break-all',
    },
    configButton: {
      width: 102,
    },
    trashButton: {
      marginLeft: 2,
      marginTop: -9,
      fontSize: 20,
    },
    commandType: {
      color: colors.black,
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '100%',
    },
    rightArrowIcon: {
      height: 16,
      width: 16,
    },
    buttonText: {
      fontSize: 15,
      fontWeight: 500,
      display: 'flex' as const,
      gap: 4,
      alignItems: 'center',
      lineHeight: '100%',
    },
  })
);

export default DraggableListItem;
