import { createStyles, makeStyles, Typography } from '@material-ui/core';
import TGLoginButton, { TGLoginButtonType } from './TGLoginButton';

interface LegalDisclaimerViewProps {
  legalDisclaimerText?: string | null;
  onDisclaimerAccepted: () => void;
}

const LegalDisclaimerView = (props: LegalDisclaimerViewProps) => {
  const { legalDisclaimerText, onDisclaimerAccepted } = props;
  const classes = useStyles();

  return (
    <div className={classes.legalContainer} style={{ marginTop: '5px', width: '287px' }}>
      <Typography className={classes.title}>Legal Disclaimer</Typography>
      <div className={classes.legalContainer}>
        <div className={classes.legalText}>{legalDisclaimerText}</div>
        <div style={{ marginTop: '25px' }}>
          <TGLoginButton type={TGLoginButtonType.primary} onClick={onDisclaimerAccepted}>
            <>AGREE & CONTINUE</>
          </TGLoginButton>
        </div>
      </div>
    </div>
  );
};
const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '24px',
      width: '287px',
    },
    legalContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    legalText: {
      marginTop: '15px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      whiteSpace: 'pre-wrap',
      minHeight: '50px',
      maxHeight: '360px',
      width: '287px',
      overflowY: 'auto',
    },
  })
);

export default LegalDisclaimerView;
