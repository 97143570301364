import { isConditionGroup } from './isConditionGroup';
import {
  AttributeBasedFilterSimpleFiltersWithID,
  AttributeBasedFilterWithID,
} from '../QueryBuilder';
import {
  AttributeBasedFilterSimpleFilters,
  AttributeBasedFilter,
} from '@terragotech/gen5-config-lib/dist/AttributeBasedFilter';

export const generateValidQuery = (
  query: AttributeBasedFilter | AttributeBasedFilterSimpleFilters,
  index = 0,
  level = 0
): AttributeBasedFilterWithID | AttributeBasedFilterSimpleFiltersWithID => {
  if (isConditionGroup(query)) {
    return {
      id: `g-${level}${index}`,
      condition: query.condition.map((condition, index) =>
        generateValidQuery(condition, index, level + 1)
      ),
      conjunction: query.conjunction,
      type: 'GroupFilter',
      // not: !!query.not,
    };
  }
  return {
    id: `r-${level}${index}`,
    ...query,
  };
};
