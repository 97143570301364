import { V2FormTemplate, V2FormComponentDef } from '@terragotech/form-renderer';

interface FormComponents {
  [name: string]: V2FormComponentDef;
}

export const DUPLICATE_NAME_ERROR_MESSAGE
  = 'Duplicate name error. The name must be unique accross all workflow or group elements.';

export const cleanFormTemplate = (template: V2FormTemplate): V2FormTemplate => {
  //Given a form template, recusively remove any components that aren't used(based on the order attribute)
  const keptComponents: Record<string, V2FormComponentDef> = {};
  // cycle through order and add any component found there to the keptComponents
  template.order.forEach((key) => {
    const component = template.components[key];
    if (component.type === 'group') {
      const newComponent = { ...component };
      newComponent.template = cleanFormTemplate(component.template);
      keptComponents[key] = newComponent;
    } else {
      keptComponents[key] = { ...component };
    }
  });
  // if the component is a group type, run its template through this

  return {
    order: template.order,
    ...(template.errorMaps && { errorMaps: template.errorMaps }),
    ...(template.warningMaps && { warningMaps: template.warningMaps }),
    ...(template.initialDataMap && { initialDataMap: template.initialDataMap }),
    components: keptComponents,
  };
};

const checkComponentNamesFromGroup = (
  name: string,
  component: { name: string, droppableId?: string },
  formcomponents: FormComponents
) => {
  let duplicateNameExistsInGroup = false;
  let componentNamesFromGroup: string[] = [];
  Object.entries(formcomponents).forEach(([fcName, fc]) => {
    if (fc.type === 'group' && fcName === component.droppableId) {
      componentNamesFromGroup = componentNamesFromGroup.concat(
        Object.keys(fc.template.components)
      );
    }
  });

  const nameExists = componentNamesFromGroup.some((componentName) => name === componentName);
  if (nameExists && component.name !== name) {
    duplicateNameExistsInGroup = true;
  }
  return duplicateNameExistsInGroup;
};

export const checkDuplicateFormName = (
  name: string,
  component: { name: string, droppableId?: string },
  formcomponents: FormComponents
) => {
  let duplicateNameExists = false;
  const components = Object.keys(formcomponents);
  const nameExists = components.some((componentName) => name === componentName);
  const duplicateNameExistsInGroup = checkComponentNamesFromGroup(name, component, formcomponents);
  if ((nameExists && component.name !== name) || duplicateNameExistsInGroup) {
    duplicateNameExists = true;
  }
  return duplicateNameExists;
};
