import { alpha, createTheme } from '@material-ui/core/styles';
import { colors } from './colors';
import '@fontsource-variable/inter';

export const theme = (primaryColor: string, secondaryColor: string) =>
  createTheme({
    palette: {
      primary: {
        main: primaryColor,
        light: alpha(primaryColor, 0.05),
      },
      secondary: {
        main: secondaryColor,
      },
      grey: {
        50: colors.backgroundLight,
        100: colors.whiteSmoke,
        200: colors.grayLine,
        300: colors.gray88,
        400: colors.gray74,
        500: colors.gray62,
        600: colors.silverSonic,
        700: colors.grayDim,
        800: colors.gray26,
        900: colors.gray13,
      },
      error: {
        main: colors.danger,
        contrastText: colors.white,
      },
      success: {
        main: colors.success,
        contrastText: colors.white,
      },
    },
    typography: {
      h1: {
        fontSize: 24,
      },
      h2: {
        fontSize: 20,
      },
      h3: {
        fontSize: 16,
      },
      h4: {
        fontSize: 14,
        lineHeight: 'normal',
      },
      h5: {
        fontSize: 12,
      },
      fontFamily: ['"Inter Variable"', '"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(
        ','
      ),
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          body: {
            color: colors.black,
          },
          '::-webkit-scrollbar': {
            height: 5,
            width: 5,
          },
          '::selection': {
            color: colors.white,
            backgroundColor: primaryColor,
          },
        },
      },
      MuiListItem: {
        root: {
          '&:hover': {
            color: 'unset',
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: 5,
          textTransform: 'none',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: colors.black10,
        },
      },
      MuiTypography: {
        h3: {
          color: 'unset',
        },
      },
      MuiListItemText: {
        root: {
          padding: '0 5px',
        },
      },
      MuiInputAdornment: {
        positionStart: {
          marginRight: '4px',
        },
      },
      MuiSelect: {
        select: {
          '&:focus': {
            backgroundColor: 'none',
          },
        },
      },
      MuiAppBar: {
        colorDefault: {
          backgroundColor: colors.white,
        },
      },
      MuiFormLabel: {
        root: {
          '& .MuiFormLabel-asterisk': {
            display: 'none',
          },
        },
      },
      MuiMenuItem: {
        root: {
          '&:hover, &.Mui-focusVisible': {
            backgroundColor: alpha(primaryColor, 0.2),
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: alpha(primaryColor, 0.2),
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          '&.Mui-focused, .Mui-focusVisible': {
            backgroundColor: colors.gray99,
          },
        },
      },
      MuiFormControlLabel: {
        root: {
          width: 'fit-content',
          marginRight: 0,
        },
      },
    },
  });
