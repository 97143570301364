import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { V2GroupComponentDef } from '@terragotech/form-renderer';
import { useFormSchemas } from './hooks/useFormSchemas';
import { DUPLICATE_NAME_ERROR_MESSAGE } from '../../pages/aggregates/utils/formUtils';
import RepeatableComponent, { defaultRepeatableProps } from '../RepeatableComponent';
import _ from 'lodash';
import { JSONSchema6Definition } from 'json-schema';
import { useStyles } from './useStyles';
import { FormEditProps } from 'utils/types';
import MapperItem from './MapperItem';
import { TextInput } from './common';
import { useConfirmDialog } from 'context/ConfirmContext';
import { CONFIRMATION } from 'utils/Utils';

export type GroupComponentDefWithName = V2GroupComponentDef & {
  name: string;
  droppableId: string;
};

interface GroupEditFormFormProps extends FormEditProps {
  onBlur?: (name: string, setName: (name: string) => void) => void;
  component: GroupComponentDefWithName;
  isImportCommand?: boolean;
}

export const GroupEditForm: React.FC<GroupEditFormFormProps> = ({
  existingNameError,
  handleNameChange,
  component,
  isImportCommand,
  getEditedDef,
  setFormDefinition,
}) => {
  const classes = useStyles();
  const [name, setName] = useState(component.name);
  const [oldName, setOldName] = useState(component.name);
  const [type, setType] = useState(component.type);
  const [template, setTemplate] = useState(component.template);
  const [label, setLabel] = useState<string>(component.label);
  const [description, setDescription] = useState(component.description);
  const [info, setInfo] = useState(component.info);
  const [repeats, setRepeats] = useState(
    component.repeats ? { ...defaultRepeatableProps, ...component.repeats } : defaultRepeatableProps
  );
  const [conditionalMap, setConditionalMap] = useState(component.conditionalMap || undefined);
  const [conditionalOpen, setConditionalOpen] = useState(false);
  const [computedMap, setComputedMap] = useState(() => {
    const map = component.computedMap || undefined;
    if (map && map.nodes && map.nodes['OUTPUT']) {
      map.nodes['OUTPUT'].config = { objectSchema: 'COMPUTED' };
    }
    return map;
  });
  const [computedOpen, setComputedOpen] = useState(false);

  const formSchemas = useFormSchemas();
  const { openConfirmation } = useConfirmDialog();

  const setInitialData = useCallback(() => {
    setName(component.name);
    setOldName(component.name);
    setLabel(component.label);
    setType(component.type);
    setDescription(component.description || '');
    setInfo(component.info || '');
    setTemplate(component.template);
    setRepeats(
      component.repeats
        ? { ...defaultRepeatableProps, ...component.repeats }
        : defaultRepeatableProps
    );
    setConditionalMap(component.conditionalMap || undefined);
    setComputedMap(() => {
      const map = component.computedMap || undefined;
      if (map && map.nodes && map.nodes['OUTPUT']) {
        map.nodes['OUTPUT'].config = { objectSchema: 'COMPUTED' };
      }
      return map;
    });
  }, [component]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const getFormValues = useCallback(() => {
    return {
      template: template,
      type: type,
      name: name || '',
      label: label || '',
      ...(description && { description }),
      ...(info && { info }),
      ...(conditionalMap && { conditionalMap }),
      ...(computedMap && { computedMap }),
      repeats,
      ...(conditionalMap && { conditionalMap }),
    };
  }, [type, template, name, label, computedMap, conditionalMap, description, repeats, info]);

  useEffect(() => {
    if (getEditedDef && setFormDefinition && name.length > 0 && !existingNameError) {
      const edited = getFormValues();
      const editedDef = getEditedDef({
        editedData: edited,
        oldName,
        newName: name,
        droppableId: component.droppableId,
      });
      if (editedDef) {
        setFormDefinition(editedDef);
        if (oldName !== name) {
          setOldName(name);
        }
      }
    }
    // If add those all dependency, it'll call recursively
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFormValues, name]);

  const getConfirmation = () => openConfirmation(CONFIRMATION.commonClear);

  const handleClearConditionalMapper = async () => {
    const status = await getConfirmation();
    if (status === 'confirm') {
      setConditionalMap(undefined);
    }
  };
  const handleClearComputedMapper = async () => {
    const status = await getConfirmation();
    if (status === 'confirm') {
      setComputedMap(undefined);
    }
  };

  const doesConditionalHaveValue = useCallback(() => {
    return !_.isEmpty(conditionalMap);
  }, [conditionalMap]);

  const doesComputedHaveValue = useCallback(() => {
    return !_.isEmpty(computedMap);
  }, [computedMap]);

  return (
    <>
      <Typography className={classes.header}>{name}</Typography>
      <TextInput
        autoFocus
        id="Name"
        label="Name"
        error={existingNameError}
        helperText={existingNameError ? DUPLICATE_NAME_ERROR_MESSAGE : ''}
        value={name}
        onChange={(value) => handleNameChange && handleNameChange(value || '', setName)}
        placeholder="Enter name"
      />
      <TextInput
        id="Label"
        label="Label"
        value={label}
        onChange={(value) => setLabel(value || '')}
        placeholder="Enter label"
      />
      <TextInput
        id="Description"
        label="Description"
        value={description}
        onChange={(value) => setDescription(value)}
        placeholder="Enter description"
      />
      <TextInput
        id="Info"
        label="Info"
        value={info}
        onChange={(value) => setInfo(value)}
        placeholder="Enter info"
      />
      <MapperItem
        {...{
          onToggleMapper: setConditionalOpen,
          isActive: doesConditionalHaveValue(),
          clearMapper: handleClearConditionalMapper,
          openDataMap: conditionalOpen,
          dataMap: conditionalMap,
          setDataMap: setConditionalMap,
          localSchemaDefinition: formSchemas,
          title: 'Conditional Map',
          mapScenario: 'FORM_FIELD_CONDITIONAL',
        }}
      />
      {!isImportCommand && (
        <MapperItem
          {...{
            onToggleMapper: setComputedOpen,
            isActive: doesComputedHaveValue(),
            clearMapper: handleClearComputedMapper,
            openDataMap: computedOpen,
            dataMap: computedMap,
            setDataMap: setComputedMap,
            localSchemaDefinition: {
              ...formSchemas,
              COMPUTED: {
                schemaLabel: 'Result',
                schema: {
                  type: 'object',
                  properties: {
                    result: (formSchemas.FORM.schema?.properties || {})[
                      component.name
                    ] as JSONSchema6Definition,
                  },
                },
              },
            },
            title: 'Calculation',
            mapScenario: 'COMPUTED_MAPPING',
          }}
        />
      )}
      {!isImportCommand && (
        <Box className={`${classes.itemContainer} ${classes.bottom0}`}>
          <Typography className={classes.label}>Repeating Instances</Typography>
          <RepeatableComponent repeats={repeats} setRepeats={setRepeats} />
        </Box>
      )}
    </>
  );
};
