export const fontawesomeIconList = [
  'fa-solid fa-diamond-turn-right',
  'fa-solid fa-address-book',
  'fa-solid fa-address-card',
  'fa-solid fa-circle-half-stroke',
  'fa-solid fa-align-center',
  'fa-solid fa-align-justify',
  'fa-solid fa-align-left',
  'fa-solid fa-align-right',
  'fa-solid fa-hand-dots',
  'fa-solid fa-truck-medical',
  'fa-solid fa-hands-asl-interpreting',
  'fa-solid fa-anchor',
  'fa-solid fa-angles-down',
  'fa-solid fa-angles-left',
  'fa-solid fa-angles-right',
  'fa-solid fa-angles-up',
  'fa-solid fa-angle-down',
  'fa-solid fa-angle-left',
  'fa-solid fa-angle-right',
  'fa-solid fa-angle-up',
  'fa-solid fa-box-archive',
  'fa-solid fa-circle-down',
  'fa-solid fa-circle-left',
  'fa-solid fa-circle-right',
  'fa-solid fa-circle-up',
  'fa-solid fa-circle-arrow-down',
  'fa-solid fa-circle-arrow-left',
  'fa-solid fa-circle-arrow-right',
  'fa-solid fa-circle-arrow-up',
  'fa-solid fa-arrow-down',
  'fa-solid fa-arrow-left',
  'fa-solid fa-arrow-right',
  'fa-solid fa-arrow-up',
  'fa-solid fa-up-down-left-right',
  'fa-solid fa-left-right',
  'fa-solid fa-up-down',
  'fa-solid fa-ear-listen',
  'fa-solid fa-asterisk',
  'fa-solid fa-at',
  'fa-solid fa-audio-description',
  'fa-solid fa-backward',
  'fa-solid fa-scale-balanced',
  'fa-solid fa-ban',
  'fa-solid fa-bandage',
  'fa-solid fa-barcode',
  'fa-solid fa-bars',
  'fa-solid fa-baseball',
  'fa-solid fa-basketball',
  'fa-solid fa-bath',
  'fa-solid fa-battery-empty',
  'fa-solid fa-battery-full',
  'fa-solid fa-battery-half',
  'fa-solid fa-battery-quarter',
  'fa-solid fa-battery-three-quarters',
  'fa-solid fa-bed',
  'fa-solid fa-beer-mug-empty',
  'fa-solid fa-bell',
  'fa-solid fa-bell-slash',
  'fa-solid fa-bicycle',
  'fa-solid fa-binoculars',
  'fa-solid fa-cake-candles',
  'fa-solid fa-person-walking-with-cane',
  'fa-solid fa-bold',
  'fa-solid fa-bolt',
  'fa-solid fa-bomb',
  'fa-solid fa-book',
  'fa-solid fa-bookmark',
  'fa-solid fa-bowling-ball',
  'fa-solid fa-box',
  'fa-solid fa-box-open',
  'fa-solid fa-boxes-stacked',
  'fa-solid fa-braille',
  'fa-solid fa-briefcase',
  'fa-solid fa-briefcase-medical',
  'fa-solid fa-bug',
  'fa-solid fa-building',
  'fa-solid fa-bullhorn',
  'fa-solid fa-bullseye',
  'fa-solid fa-fire-flame-simple',
  'fa-solid fa-bus',
  'fa-solid fa-calculator',
  'fa-solid fa-calendar',
  'fa-solid fa-calendar-days',
  'fa-solid fa-calendar-check',
  'fa-solid fa-calendar-minus',
  'fa-solid fa-calendar-plus',
  'fa-solid fa-calendar-xmark',
  'fa-solid fa-camera',
  'fa-solid fa-camera-retro',
  'fa-solid fa-capsules',
  'fa-solid fa-car',
  'fa-solid fa-caret-down',
  'fa-solid fa-caret-left',
  'fa-solid fa-caret-right',
  'fa-solid fa-square-caret-down',
  'fa-solid fa-square-caret-left',
  'fa-solid fa-square-caret-right',
  'fa-solid fa-square-caret-up',
  'fa-solid fa-caret-up',
  'fa-solid fa-cart-arrow-down',
  'fa-solid fa-cart-plus',
  'fa-solid fa-certificate',
  'fa-solid fa-chart-area',
  'fa-solid fa-chart-bar',
  'fa-solid fa-chart-line',
  'fa-solid fa-chart-pie',
  'fa-solid fa-check',
  'fa-solid fa-circle-check',
  'fa-solid fa-square-check',
  'fa-solid fa-chess',
  'fa-solid fa-chess-bishop',
  'fa-solid fa-chess-board',
  'fa-solid fa-chess-king',
  'fa-solid fa-chess-knight',
  'fa-solid fa-chess-pawn',
  'fa-solid fa-chess-queen',
  'fa-solid fa-chess-rook',
  'fa-solid fa-circle-chevron-down',
  'fa-solid fa-circle-chevron-left',
  'fa-solid fa-circle-chevron-right',
  'fa-solid fa-circle-chevron-up',
  'fa-solid fa-chevron-down',
  'fa-solid fa-chevron-left',
  'fa-solid fa-chevron-right',
  'fa-solid fa-chevron-up',
  'fa-solid fa-child',
  'fa-solid fa-circle',
  'fa-solid fa-circle-notch',
  'fa-solid fa-clipboard',
  'fa-solid fa-clipboard-check',
  'fa-solid fa-clipboard-list',
  'fa-solid fa-clock',
  'fa-solid fa-clone',
  'fa-solid fa-closed-captioning',
  'fa-solid fa-cloud',
  'fa-solid fa-cloud-arrow-down',
  'fa-solid fa-cloud-arrow-up',
  'fa-solid fa-code',
  'fa-solid fa-code-branch',
  'fa-solid fa-mug-saucer',
  'fa-solid fa-gear',
  'fa-solid fa-gears',
  'fa-solid fa-table-columns',
  'fa-solid fa-comment',
  'fa-solid fa-message',
  'fa-solid fa-comment-dots',
  'fa-solid fa-comment-slash',
  'fa-solid fa-comments',
  'fa-solid fa-compass',
  'fa-solid fa-compress',
  'fa-solid fa-copy',
  'fa-solid fa-copyright',
  'fa-solid fa-couch',
  'fa-solid fa-credit-card',
  'fa-solid fa-crop',
  'fa-solid fa-crosshairs',
  'fa-solid fa-cube',
  'fa-solid fa-cubes',
  'fa-solid fa-scissors',
  'fa-solid fa-database',
  'fa-solid fa-ear-deaf',
  'fa-solid fa-desktop',
  'fa-solid fa-person-dots-from-line',
  'fa-solid fa-dna',
  'fa-solid fa-dollar-sign',
  'fa-solid fa-dolly',
  'fa-solid fa-cart-flatbed',
  'fa-solid fa-circle-dollar-to-slot',
  'fa-solid fa-circle-dot',
  'fa-solid fa-dove',
  'fa-solid fa-download',
  'fa-solid fa-pen-to-square',
  'fa-solid fa-eject',
  'fa-solid fa-ellipsis',
  'fa-solid fa-ellipsis-vertical',
  'fa-solid fa-envelope',
  'fa-solid fa-envelope-open',
  'fa-solid fa-square-envelope',
  'fa-solid fa-eraser',
  'fa-solid fa-euro-sign',
  'fa-solid fa-right-left',
  'fa-solid fa-exclamation',
  'fa-solid fa-circle-exclamation',
  'fa-solid fa-triangle-exclamation',
  'fa-solid fa-expand',
  'fa-solid fa-maximize',
  'fa-solid fa-up-right-from-square',
  'fa-solid fa-square-up-right',
  'fa-solid fa-eye',
  'fa-solid fa-eye-dropper',
  'fa-solid fa-eye-slash',
  'fa-solid fa-backward-fast',
  'fa-solid fa-forward-fast',
  'fa-solid fa-fax',
  'fa-solid fa-person-dress-simple',
  'fa-solid fa-jet-fighter',
  'fa-solid fa-file',
  'fa-solid fa-file-lines',
  'fa-solid fa-file-zipper',
  'fa-solid fa-file-audio',
  'fa-solid fa-file-code',
  'fa-solid fa-file-excel',
  'fa-solid fa-file-image',
  'fa-solid fa-file-medical',
  'fa-solid fa-file-waveform',
  'fa-solid fa-file-pdf',
  'fa-solid fa-file-powerpoint',
  'fa-solid fa-file-video',
  'fa-solid fa-file-word',
  'fa-solid fa-film',
  'fa-solid fa-filter',
  'fa-solid fa-fire',
  'fa-solid fa-fire-extinguisher',
  'fa-solid fa-kit-medical',
  'fa-solid fa-flag',
  'fa-solid fa-flag-checkered',
  'fa-solid fa-flask',
  'fa-solid fa-folder',
  'fa-solid fa-folder-open',
  'fa-solid fa-font',
  'fa-solid fa-football',
  'fa-solid fa-forward',
  'fa-solid fa-face-frown',
  'fa-solid fa-futbol',
  'fa-solid fa-gamepad',
  'fa-solid fa-gavel',
  'fa-solid fa-gem',
  'fa-solid fa-genderless',
  'fa-solid fa-gift',
  'fa-solid fa-martini-glass',
  'fa-solid fa-globe',
  'fa-solid fa-golf-ball-tee',
  'fa-solid fa-graduation-cap',
  'fa-solid fa-square-h',
  'fa-solid fa-hand-holding',
  'fa-solid fa-hand-holding-heart',
  'fa-solid fa-hand-holding-dollar',
  'fa-solid fa-hand-lizard',
  'fa-solid fa-hand',
  'fa-solid fa-hand-peace',
  'fa-solid fa-hand-point-down',
  'fa-solid fa-hand-point-left',
  'fa-solid fa-hand-point-right',
  'fa-solid fa-hand-point-up',
  'fa-solid fa-hand-pointer',
  'fa-solid fa-hand-back-fist',
  'fa-solid fa-hand-scissors',
  'fa-solid fa-hand-spock',
  'fa-solid fa-hands-holding',
  'fa-solid fa-handshake-angle',
  'fa-solid fa-handshake',
  'fa-solid fa-hashtag',
  'fa-solid fa-hard-drive',
  'fa-solid fa-heading',
  'fa-solid fa-headphones',
  'fa-solid fa-heart',
  'fa-solid fa-heart-pulse',
  'fa-solid fa-clock-rotate-left',
  'fa-solid fa-hockey-puck',
  'fa-solid fa-house',
  'fa-solid fa-hospital',
  'fa-solid fa-hospitals',
  'fa-solid fa-circle-h',
  'fa-solid fa-hourglass-empty',
  'fa-solid fa-hourglass-end',
  'fa-solid fa-hourglass',
  'fa-solid fa-hourglass-start',
  'fa-solid fa-i-cursor',
  'fa-solid fa-id-badge',
  'fa-solid fa-id-card',
  'fa-solid fa-id-card-clip',
  'fa-solid fa-image',
  'fa-solid fa-images',
  'fa-solid fa-inbox',
  'fa-solid fa-indent',
  'fa-solid fa-industry',
  'fa-solid fa-info',
  'fa-solid fa-circle-info',
  'fa-solid fa-italic',
  'fa-solid fa-key',
  'fa-solid fa-keyboard',
  'fa-solid fa-language',
  'fa-solid fa-laptop',
  'fa-solid fa-leaf',
  'fa-solid fa-lemon',
  'fa-solid fa-turn-down',
  'fa-solid fa-turn-up',
  'fa-solid fa-life-ring',
  'fa-solid fa-lightbulb',
  'fa-solid fa-link',
  'fa-solid fa-lira-sign',
  'fa-solid fa-list',
  'fa-solid fa-rectangle-list',
  'fa-solid fa-list-ol',
  'fa-solid fa-list-ul',
  'fa-solid fa-location-arrow',
  'fa-solid fa-lock',
  'fa-solid fa-lock-open',
  'fa-solid fa-down-long',
  'fa-solid fa-left-long',
  'fa-solid fa-right-long',
  'fa-solid fa-up-long',
  'fa-solid fa-eye-low-vision',
  'fa-solid fa-wand-magic-sparkles',
  'fa-solid fa-magnet',
  'fa-solid fa-person',
  'fa-solid fa-map',
  'fa-solid fa-location-pin',
  'fa-solid fa-location-dot',
  'fa-solid fa-map-pin',
  'fa-solid fa-signs-post',
  'fa-solid fa-mars',
  'fa-solid fa-mars-double',
  'fa-solid fa-mars-stroke',
  'fa-solid fa-mars-stroke-right',
  'fa-solid fa-mars-stroke-up',
  'fa-solid fa-suitcase-medical',
  'fa-solid fa-face-meh',
  'fa-solid fa-mercury',
  'fa-solid fa-microchip',
  'fa-solid fa-microphone',
  'fa-solid fa-microphone-slash',
  'fa-solid fa-minus',
  'fa-solid fa-circle-minus',
  'fa-solid fa-square-minus',
  'fa-solid fa-mobile',
  'fa-solid fa-mobile-screen',
  'fa-solid fa-money-bill-1',
  'fa-solid fa-moon',
  'fa-solid fa-motorcycle',
  'fa-solid fa-arrow-pointer',
  'fa-solid fa-music',
  'fa-solid fa-neuter',
  'fa-solid fa-newspaper',
  'fa-solid fa-notes-medical',
  'fa-solid fa-object-group',
  'fa-solid fa-object-ungroup',
  'fa-solid fa-outdent',
  'fa-solid fa-paint-brush',
  'fa-solid fa-pallet-box',
  'fa-solid fa-paper-plane',
  'fa-solid fa-paperclip',
  'fa-solid fa-parachute-box',
  'fa-solid fa-paragraph',
  'fa-solid fa-paste',
  'fa-solid fa-pause',
  'fa-solid fa-circle-pause',
  'fa-solid fa-paw',
  'fa-solid fa-square-pen',
  'fa-solid fa-pencil',
  'fa-solid fa-people-carry-box',
  'fa-solid fa-percent',
  'fa-solid fa-phone',
  'fa-solid fa-phone-slash',
  'fa-solid fa-square-phone',
  'fa-solid fa-phone-volume',
  'fa-solid fa-piggy-bank',
  'fa-solid fa-pills',
  'fa-solid fa-plane',
  'fa-solid fa-play',
  'fa-solid fa-circle-play',
  'fa-solid fa-plug',
  'fa-solid fa-plus',
  'fa-solid fa-circle-plus',
  'fa-solid fa-square-plus',
  'fa-solid fa-podcast',
  'fa-solid fa-poo',
  'fa-solid fa-sterling-sign',
  'fa-solid fa-power-off',
  'fa-solid fa-prescription-bottle',
  'fa-solid fa-prescription-bottle-medical',
  'fa-solid fa-print',
  'fa-solid fa-bed-pulse',
  'fa-solid fa-puzzle-piece',
  'fa-solid fa-qrcode',
  'fa-solid fa-question',
  'fa-solid fa-circle-question',
  'fa-solid fa-broom-ball',
  'fa-solid fa-quote-left',
  'fa-solid fa-quote-right',
  'fa-solid fa-shuffle',
  'fa-solid fa-recycle',
  'fa-solid fa-arrow-rotate-right',
  'fa-solid fa-rotate-right',
  'fa-solid fa-registered',
  'fa-solid fa-reply',
  'fa-solid fa-reply-all',
  'fa-solid fa-retweet',
  'fa-solid fa-ribbon',
  'fa-solid fa-road',
  'fa-solid fa-rocket',
  'fa-solid fa-rss',
  'fa-solid fa-square-rss',
  'fa-solid fa-ruble-sign',
  'fa-solid fa-indian-rupee-sign',
  'fa-solid fa-floppy-disk',
  'fa-solid fa-magnifying-glass',
  'fa-solid fa-magnifying-glass-minus',
  'fa-solid fa-magnifying-glass-plus',
  'fa-solid fa-seedling',
  'fa-solid fa-server',
  'fa-solid fa-share',
  'fa-solid fa-share-nodes',
  'fa-solid fa-square-share-nodes',
  'fa-solid fa-share-from-square',
  'fa-solid fa-shekel-sign',
  'fa-solid fa-shield-halved',
  'fa-solid fa-ship',
  'fa-solid fa-truck-fast',
  'fa-solid fa-bag-shopping',
  'fa-solid fa-basket-shopping',
  'fa-solid fa-cart-shopping',
  'fa-solid fa-shower',
  'fa-solid fa-sign-hanging',
  'fa-solid fa-right-to-bracket',
  'fa-solid fa-hands',
  'fa-solid fa-right-from-bracket',
  'fa-solid fa-signal',
  'fa-solid fa-sitemap',
  'fa-solid fa-sliders',
  'fa-solid fa-face-smile',
  'fa-solid fa-smoking',
  'fa-solid fa-snowflake',
  'fa-solid fa-sort',
  'fa-solid fa-arrow-down-a-z',
  'fa-solid fa-arrow-up-a-z',
  'fa-solid fa-arrow-down-wide-short',
  'fa-solid fa-arrow-up-wide-short',
  'fa-solid fa-sort-down',
  'fa-solid fa-arrow-down-1-9',
  'fa-solid fa-arrow-up-1-9',
  'fa-solid fa-sort-up',
  'fa-solid fa-shuttle-space',
  'fa-solid fa-spinner',
  'fa-solid fa-square',
  'fa-solid fa-square-full',
  'fa-solid fa-star',
  'fa-solid fa-star-half',
  'fa-solid fa-backward-step',
  'fa-solid fa-forward-step',
  'fa-solid fa-stethoscope',
  'fa-solid fa-note-sticky',
  'fa-solid fa-stop',
  'fa-solid fa-circle-stop',
  'fa-solid fa-stopwatch',
  'fa-solid fa-street-view',
  'fa-solid fa-strikethrough',
  'fa-solid fa-subscript',
  'fa-solid fa-train-subway',
  'fa-solid fa-suitcase',
  'fa-solid fa-sun',
  'fa-solid fa-superscript',
  'fa-solid fa-arrows-rotate',
  'fa-solid fa-rotate',
  'fa-solid fa-syringe',
  'fa-solid fa-table',
  'fa-solid fa-table-tennis-paddle-ball',
  'fa-solid fa-tablet',
  'fa-solid fa-tablet-screen',
  'fa-solid fa-tablets',
  'fa-solid fa-gauge-high',
  'fa-solid fa-tag',
  'fa-solid fa-tags',
  'fa-solid fa-tape',
  'fa-solid fa-list-check',
  'fa-solid fa-taxi',
  'fa-solid fa-terminal',
  'fa-solid fa-text-height',
  'fa-solid fa-text-width',
  'fa-solid fa-table-cells',
  'fa-solid fa-table-cells-large',
  'fa-solid fa-table-list',
  'fa-solid fa-thermometer',
  'fa-solid fa-temperature-empty',
  'fa-solid fa-temperature-full',
  'fa-solid fa-temperature-half',
  'fa-solid fa-temperature-quarter',
  'fa-solid fa-temperature-three-quarters',
  'fa-solid fa-thumbs-down',
  'fa-solid fa-thumbs-up',
  'fa-solid fa-thumbtack',
  'fa-solid fa-ticket',
  'fa-solid fa-xmark',
  'fa-solid fa-circle-xmark',
  'fa-solid fa-droplet',
  'fa-solid fa-toggle-off',
  'fa-solid fa-toggle-on',
  'fa-solid fa-trademark',
  'fa-solid fa-train',
  'fa-solid fa-transgender',
  'fa-solid fa-mars-and-venus',
  'fa-solid fa-trash',
  'fa-solid fa-trash-can',
  'fa-solid fa-tree',
  'fa-solid fa-trophy',
  'fa-solid fa-truck',
  'fa-solid fa-truck-ramp-box',
  'fa-solid fa-truck-moving',
  'fa-solid fa-tty',
  'fa-solid fa-tv',
  'fa-solid fa-umbrella',
  'fa-solid fa-underline',
  'fa-solid fa-arrow-rotate-left',
  'fa-solid fa-rotate-left',
  'fa-solid fa-universal-access',
  'fa-solid fa-building-columns',
  'fa-solid fa-link-slash',
  'fa-solid fa-unlock',
  'fa-solid fa-unlock-keyhole',
  'fa-solid fa-upload',
  'fa-solid fa-user',
  'fa-solid fa-circle-user',
  'fa-solid fa-user-doctor',
  'fa-solid fa-user-plus',
  'fa-solid fa-user-secret',
  'fa-solid fa-user-xmark',
  'fa-solid fa-users',
  'fa-solid fa-spoon',
  'fa-solid fa-utensils',
  'fa-solid fa-venus',
  'fa-solid fa-venus-double',
  'fa-solid fa-venus-mars',
  'fa-solid fa-vial',
  'fa-solid fa-vials',
  'fa-solid fa-video',
  'fa-solid fa-video-slash',
  'fa-solid fa-volleyball',
  'fa-solid fa-volume-low',
  'fa-solid fa-volume-off',
  'fa-solid fa-volume-high',
  'fa-solid fa-warehouse',
  'fa-solid fa-weight-scale',
  'fa-solid fa-wheelchair',
  'fa-solid fa-wifi',
  'fa-solid fa-rectangle-xmark',
  'fa-solid fa-window-maximize',
  'fa-solid fa-window-minimize',
  'fa-solid fa-window-restore',
  'fa-solid fa-wine-glass',
  'fa-solid fa-won-sign',
  'fa-solid fa-wrench',
  'fa-solid fa-x-ray',
  'fa-solid fa-yen-sign',
];
