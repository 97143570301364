import React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';

interface LabelProps {
  text: string;
  info?: boolean;
  conditional?: boolean;
}

export const Label: React.FC<LabelProps> = ({ text }) => (
  <>
    <LabelHolder>
      <LabelText>{text}</LabelText>
    </LabelHolder>
  </>
);

export const Placeholder = styled.div`
  line-height: 100%;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  margin-left: 18px;
  color: ${colors.black50};
  padding-top: 5px;
`;

export const StandardInputFrame = styled.div`
  position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  min-height: 50px;
  box-shadow: 0px 1px 4px 0px ${colors.black15};
`;
export const LabelText = styled.div`
  font-weight: 500;
  font-size: 15px;
  text-align: left;
  margin-left: 18px;
  color: ${colors.black};
`;
export const LabelHolder = styled.div`
  display: flex;
`;
