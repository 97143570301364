import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { errorMsg } from '../SnackbarUtilsConfigurator';
import { TextInputTG } from 'views/components/formElements';
import CreateNewDialog from 'common/CreateNewDialog';

interface Props {
  onSubmit: (result: string) => void;
  onClose: () => void;
  title: string;
}

export const SingleTextInputForm: React.FC<Props> = ({ onSubmit, onClose, title }) => {
  const classes = useStyle();
  const [value, setValue] = useState<string | undefined>(undefined);
  const handleOnSubmit = () => {
    if (!value) return errorMsg('Text input is required');
    onSubmit(value);
  };

  return (
    <CreateNewDialog title={title} onClose={onClose} onSubmit={handleOnSubmit}>
      <TextInputTG
        id="singleTextInput"
        label={'Single Text Input'}
        value={value || ''}
        onChange={setValue}
        labelRoot={classes.label}
      />
    </CreateNewDialog>
  );
};

const useStyle = makeStyles({
  label: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '100%',
  }  
});
