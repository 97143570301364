import React from 'react';
import {
  FormControl,
  Select,
  MenuItem as MuiMenu,
  InputLabel,
  Theme,
  createStyles,
  makeStyles,
  styled,
  alpha,
} from '@material-ui/core';
import { Variant } from '.';
import { isEmpty } from 'lodash';
import Placeholder from 'common/PlaceHolder';
import { colors } from 'utils/colors';

type SelectOption = string | number | string[];

interface SelectInputProps<T> {
  title?: string;
  value: T;
  onChange: (value: T) => void;
  options: object | T[] | T;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  variant?: Variant;
  fullWidth?: boolean;
  containerStyle?: React.CSSProperties;
  containerClass?: string;
  margin?: 'dense' | 'none' | 'normal';
  multiple?: boolean;
  type?: string;
}

const MenuItem = styled(MuiMenu)(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
}));

export const SelectInput = <T extends SelectOption>({
  title,
  value,
  onChange,
  options,
  labelStyle,
  style,
  variant,
  fullWidth = true,
  containerStyle,
  containerClass = '',
  margin,
  multiple,
  type = 'item',
}: SelectInputProps<T>) => {
  const classes = useStyles();
  return (
    <FormControl
      fullWidth={fullWidth}
      variant={variant ? variant : 'outlined'}
      margin={margin || 'dense'}
      style={containerStyle}
      className={containerClass}
    >
      {title && (
        <InputLabel
          htmlFor={`${title}-label`}
          style={{ ...labelStyle, fontSize: 14, backgroundColor: 'white' }}
        >
          {title}
        </InputLabel>
      )}
      <Select
        labelId={`${title}-label`}
        onChange={(e) => onChange(e.target.value as T)}
        label={title}
        inputProps={{
          name: 'label',
          id: `${title}-label`,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        multiple={multiple}
        style={{ ...style }}
        displayEmpty
        renderValue={() =>
          !isEmpty(value) ? <>{value}</> : <Placeholder>{`Select ${type}`}</Placeholder>
        }
        disableUnderline
        className={classes.root}
      >
        {options &&
          ((Array.isArray(options) && options.length === 0) ||
            Object.keys(options).length === 0) && (
            <MenuItem value="" disabled>
              None available
            </MenuItem>
          )}
        {options && Array.isArray(options)
          ? options.map((item: T) => (
              <MenuItem key={item as string | number} value={item} className={classes.menuHeight}>
                {item}
              </MenuItem>
            ))
          : Object.entries(options).map(([propertyKey, value]) => (
              <MenuItem key={propertyKey} value={value} className={classes.menuHeight}>
                {propertyKey}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 38,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      '& .MuiSelect-select': {
        border: `1px solid ${colors.black25}`,
        borderRadius: 5,
      },
      '& .PrivateNotchedOutline-root-378': {
        border: `1px solid ${colors.black10}`,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    menuHeight: { height: 36 },
  })
);
