import React, { useState } from 'react';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Theme,
  alpha,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from 'utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
import { TextInputTG } from 'views/components/formElements';

interface SelectedItem {
  type: string;
}

interface PaletteProp {
  type: string;
  label: string;
  icon: string;
  isImport?: boolean;
}

interface FormPaletteProps {
  isImportCommand?: boolean;
  onSubmit: (value: SelectedItem) => void;
  onClose: () => void;
  isImport?: boolean;
  isImportGroup?: boolean;
}
export const FormPalette: React.FC<FormPaletteProps> = ({
  isImportCommand,
  onSubmit,
  onClose,
  isImport = false,
  isImportGroup = false,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const paletteData = paletteItems.filter((o) =>
    isImport ? o.isImport : isImportGroup ? o.isImportGroup : true
  );
  const [currentPaletteItems, setCurrentPaletteItems] = useState<PaletteProp[]>(paletteData);

  const onChange = (value: string) => {
    setSearch(value);
    let items = [...paletteData];
    if (value.length > 0) {
      items = items.filter(
        (o) => _.toLower(o.label).includes(value) || _.toLower(o.type).includes(value)
      );
    }
    setCurrentPaletteItems(items);
  };

  return (
    <>
      <DialogTitle>Form Palette</DialogTitle>
      <IconButton aria-label="close" onClick={onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      <Box className={classes.formGroup}>
        <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} />
        <TextInputTG
          className={classes.input}
          placeholder="Search"
          value={search}
          onChange={onChange}
        />
      </Box>
      <Divider />
      <DialogContent className={classes.dialog}>
        <Box className={classes.body}>
          {currentPaletteItems.map(
            (item) =>
              (!isImportCommand || item.isImport) && (
                <Button
                  className={classes.paletteItem}
                  color="primary"
                  onClick={() => onSubmit({ type: item.type })}
                >
                  {item.label}
                </Button>
              )
          )}
        </Box>
      </DialogContent>
    </>
  );
};

const paletteItems = [
  { type: 'textArea', label: 'Text Area', icon: '' },
  { type: 'textInput', label: 'Text Input', icon: '', isImportGroup: true },
  { type: 'computed', label: 'Calculated', icon: '' },
  { type: 'numberInput', label: 'Number Input', icon: '', isImportGroup: true },
  { type: 'date', label: 'Date', icon: '' },
  { type: 'time', label: 'Time', icon: '' },
  { type: 'textheader', label: 'Text Header', icon: '' },
  { type: 'radio', label: 'Radio', icon: '' },
  { type: 'barcode', label: 'Barcode', icon: '' },
  { type: 'location', label: 'Location', icon: '' },
  { type: 'polyline', label: 'Create Polyline (Web Only)', icon: '' },
  { type: 'mapAggregateSelector', label: 'Location Aggregate Selector', icon: '' },
  { type: 'select', label: 'Dropdown', icon: '' },
  { type: 'checkbox', label: 'Checkbox', icon: '' },
  { type: 'group', label: 'Group', icon: '', isImport: true },
  { type: 'imageupload', label: 'Image Upload', icon: '' },
  { type: 'fileupload', label: 'File Upload', icon: '' },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    body: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px',
    },
    dialog: {
      padding: '8px 0',
    },
    paletteItem: {
      justifyContent: 'flex-start',
      paddingLeft: 10,
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
      },
      '& .MuiButton-label': {
        fontSize: 15,
        color: colors.black,
        fontWeight: 400,
      },
    },
    formGroup: {
      position: 'relative',
      width: '90%',
      alignSelf: 'center',
    },
    input: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 15,
      height: 35,
      '& .MuiInputBase-input': {
        height: 35,
        backgroundColor: colors.black5,
        borderRadius: 3,
        border: 'none',
        padding: '3px 10px 3px 40px',
      },
    },
    searchIcon: {
      position: 'absolute',
      left: 12,
      top: 8,
      fontSize: 20,
      zIndex: 2,
      color: colors.black30,
    },
    closeIcon: {
      position: 'absolute',
      right: 3,
      top: 4,
    },
  })
);
