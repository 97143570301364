import { Switch, Theme, createStyles, makeStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

interface Props {
  checked: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  className?: string;
}

const TGSwitch = ({ checked, onChange, disabled, className }: Props) => {
  const switchStyles = useIosSwitch();
  return (
    <Switch
      focusVisibleClassName={switchStyles.focusVisible}
      checked={checked}
      className={className}
      onChange={(e, checked) => {
        onChange(checked);
      }}
      disabled={disabled}
      classes={{
        root: switchStyles.root,
        switchBase: switchStyles.switchBase,
        thumb: switchStyles.thumb,
        track: switchStyles.track,
        checked: switchStyles.checked,
      }}
    />
  );
};

const useIosSwitch = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 45.5,
      height: 26,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: theme.palette.primary.main,
        border: `3px solid ${colors.white}`,
      },
    },
    thumb: {
      width: 23,
      height: 23,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: colors.black25,
      opacity: 1,
      transition: theme.transitions.create(['background-color']),
    },
    checked: {},
    focusVisible: {},
  })
);

export default TGSwitch;
