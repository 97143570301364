import React, { FunctionComponent } from 'react';
import { V2NumberTemplateComponent } from '@terragotech/page-renderer';
import styled from 'styled-components';
import { NestedPageControlProps } from '../PageTypes';
import { StandardInputFrame, Label, LabelSubText } from './Common';
import { colors } from 'utils/colors';
import _ from 'lodash';
import { CustomPageBadges } from './CustomPageBadges';

const Placeholder = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  margin-left: 15px;
  margin-right: 15px;
  color: ${colors.title};
  margin-bottom: 12px;
`;

const PastedMark = styled.div`
  position: absolute;
  margin: 5px;
  right: 0;
  bottom: 0;
  font-weight: 700;
  font-size: 12px;
`;

const NumberInputControl: FunctionComponent<NestedPageControlProps<V2NumberTemplateComponent>> = (
  props
) => {
  const { controlDefinition, pasted, isPageEditor } = props;
  const capitalizedControlType = _.upperFirst(controlDefinition.type);
  return (
    <StandardInputFrame>
      <Label
        text={controlDefinition.label}
        conditional={Boolean(controlDefinition.displayOptions)}
        isPageEditor={isPageEditor}
      />
      {isPageEditor && (
        <LabelSubText isDisplayOptionsEnabled={Boolean(controlDefinition.displayOptions)}>
          {capitalizedControlType}
        </LabelSubText>
      )}
      <Placeholder>{controlDefinition.placeholder}</Placeholder>
      <CustomPageBadges controlDefinition={controlDefinition} />
      {pasted && <PastedMark>Pasted</PastedMark>}
    </StandardInputFrame>
  );
};
export default NumberInputControl;
