import {
  AccordionDetails,
  Typography,
  createStyles,
  Theme,
  makeStyles,
  Box,
  withStyles,
  Divider,
} from '@material-ui/core';
import { colors } from 'utils/colors';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { useNavigate } from 'react-router-dom';
import { userguideLeftHeader, userguideSubHeadings } from '../../../utils/Utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

interface subHeading {
  title: string;
  id: string;
}

const UserGuide = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const Accordion = withStyles({
    root: {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      flexDirection: 'row-reverse',
      marginBottom: -1,
      padding: 0,
      minHeight: 45,
      height: 0,
      '&$expanded': {
        minHeight: 45,
      },
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(90deg)',
      },
      '& .MuiIconButton-root': {
        padding: 4,
      },
      '& .MuiIconButton-edgeEnd': {
        marginRight: 0,
      },
    },
    expandIcon: {
      color: colors.title,
      fontSize: 16,
      '&$expanded': {
        transform: 'rotate(90deg)',
      },
    },
    content: {
      fontSize: 15,
      fontWeight: 500,
      marginLeft: 16,
      '&$expanded': {
        margin: '1px 0',
        marginLeft: 16,
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

  const handleHover = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Box className={classes.leftBar}>
          <Box
            className={classes.previousBtn}
            onClick={() => {
              navigate(-1);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className={classes.backIcon} />
            <Typography className={classes.backText}>Previous Page</Typography>
          </Box>

          <Box className={classes.leftMenu}>
            <Typography className={classes.leftBarHeader}>Contents</Typography>
            {userguideLeftHeader.map((header, index) => (
              <Accordion defaultExpanded={index === 0}>
                <AccordionSummary
                  expandIcon={<FontAwesomeIcon icon={faChevronRight} />}
                  className={classes.header}
                >
                  {header.title}
                </AccordionSummary>
                {userguideSubHeadings[header.id].map((subHeading: subHeading) => (
                  <AccordionDetails className={classes.detail}>
                    <Box>
                      <Typography
                        onClick={() => handleHover(subHeading.id)}
                        className={classes.subHeading}
                      >
                        {subHeading.title}
                      </Typography>
                    </Box>
                  </AccordionDetails>
                ))}
              </Accordion>
            ))}
          </Box>
        </Box>
        <Box className={classes.contentContainer}>
          <Typography className={classes.heading}>Config Editor</Typography>
          <Typography className={classes.guide}>User Guide</Typography>
          <Typography className={classes.mainHeader}>Shared Utilities</Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.mainContent}>
            Shared Utilities can be used as pre-defined and reusable sets of logic that have
            customizable inputs and outputs. All functions can be accessed using the "Functions"
            node in any mapping except functions, events, and derived properties. Functions that
            meet specific criteria outlined below can also be utilized as a part of the "Array
            Filter" and "Array Transform" nodes in any mapping except events and derived properties.
          </Typography>
          <Typography id="filter" className={classes.innerHeader}>
            Array Filter
          </Typography>
          <Typography className={classes.content}>
            The "Array Filter" node is used to filter items out of an array in a way defined by the
            function selected as the filter. For a function to be used for an array filter, the
            output must be a single boolean. If the output boolean is true for a given item, the
            item in the array will be present in the output array, whereas if the output boolean for
            the item is false, it will be removed from the output array.
          </Typography>
          <Typography id="reduce" className={classes.innerHeader}>
            Array Reduce
          </Typography>
          <Typography className={classes.content}>
            The "Array Reduce" node is used to condense an array into a single item in a way defined
            by the function selected as the reducer. For a function to be used for an array reduce,
            the output must only have a single parameter which will be the final state of the item.
            In addition, the input must have a parameter named "prev" with the same type as the
            output. This parameter is the running result of the reducer. It will be populated by a
            supplied initial value for the first item, then by the output from the previous item for
            each subsequent item in the array.
          </Typography>
          <Typography id="transform" className={classes.innerHeader}>
            Array Transform
          </Typography>
          <Typography className={classes.content}>
            The "Array Transform" node is used to mutate items of an array in a way defined by the
            function selected as the transformer. For a function to be used for an array transform,
            the output must only have a single parameter which will be the final state of the item
            in the output array, though it can be an object that can contain as many properties as
            you would like.
          </Typography>
          <Typography id="requirement" className={classes.innerHeader}>
            Requirement
          </Typography>
          <Typography className={classes.content}>
            The "Array Reduce", "Array Filter" and "Array Transform" nodes require the same
            signature for their input parameters, as described below, with the "Array Reduce" having
            the additional "prev" parameter as described above:
          </Typography>
          <ul className={`${classes.list} ${classes.doubleTop}`}>
            <li className={classes.listContent}>
              <span className={classes.contentHeader}>Item of the array</span>: You must always
              provide an input parameter that will be the individual item of the array, which will
              iterated over for each item in your array. This parameter can be named anything you
              would like.
            </li>
            <li className={classes.listContent}>
              <span className={classes.contentHeader}>Full array</span>: Optionally, you can include
              the full array to be available to you if necessary for the mapping. The name of this
              parameter does not matter, but it must be of type array and the items within the array
              must be of the same type as the individual item.
            </li>
            <li className={classes.listContent}>
              <span className={classes.contentHeader}>Index</span>: Optionally, if you include the
              full array as a parameter, you can also include the index of the current item within
              the array as a parameter. This must be named "index" and be of type number.
            </li>
            <li className={classes.listContent}>
              <span className={classes.contentHeader}>Additional data</span>: Optionally, you can
              include an input parameter named "data" which can be any type and that will allow you
              to send additional data into the array filter or array transform node separate from
              the array itself.
            </li>
          </ul>
          <Typography className={classes.customPageHeader}>Custom Pages</Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.mainContent}>
            Custom pages can be used as pre-defined and reuseable layouts that have customizable
            rows and columns. All custom pages can be accessed using the "Custom Pages" dropdown in
            any Card Definition. Custom pages override any "Other Atributes" settings on the card.
          </Typography>
          <Typography id="custom_requirement" className={classes.innerHeader}>
            Requirement
          </Typography>
          <Typography className={classes.content}>
            Each custom page must meet certain criteria:
          </Typography>
          <ul className={classes.list}>
            <li className={classes.listContent}>
              <span className={classes.contentHeader}>Aggregate Type</span>: Selecting an aggregate
              type will allow use of the custom page with the Card Definition for that aggregate.
              The aggregate data will also be available in the custom page's node mapping. If no
              aggregate type is selected, the custom page will be available to Card Definition for
              all aggregates, but will not have aggregate data available.
            </li>
            <li className={classes.listContent}>
              <span className={classes.contentHeader}>Elements</span>: Each layout section can
              include one or more elements (attributes) for display. Drag and drop the desired
              element of the type of data you want to display, and select the data point to show
              from the output definition.
            </li>
            <li className={classes.listContent}>
              <span className={classes.contentHeader}>Title</span>: Each element has an optional
              title property, which will add a label to the element data. Each element has a toggle
              to change whether the content is displayed on the same line as the title or below it.
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      backgroundColor: colors.white,
      height: '100%',
    },
    container: {
      width: '100%',
      height: '100%',
      display: 'flex',
      '& ::-webkit-scrollbar': {
        display: 'none',
      },
    },
    previousBtn: {
      display: 'flex',
      color: theme.palette.primary.main,
      paddingLeft: 40,
      paddingTop: 22,
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    backIcon: {
      fontSize: 20,
    },
    backText: {
      fontSize: 16,
      fontWeight: 500,
      marginLeft: 4,
    },
    leftMenu: {
      padding: '0px 97px',
      paddingTop: 48,
    },
    leftBarHeader: {
      fontSize: '18px',
      fontWeight: 500,
      color: colors.black,
    },
    innerHeader: {
      fontSize: 15,
      fontWeight: 600,
      marginTop: 20,
      color: colors.black,
    },
    header: {
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 500,
      color: colors.black,
    },
    mainContent: {
      fontSize: 15,
      lineHeight: '125%',
      marginTop: 15,
      fontWeight: 400,
      color: colors.black,
    },

    contentContainer: {
      height: 'auto',
      width: '68%',
      overflow: 'auto',
      paddingBottom: 40,
      paddingTop: 98,
    },
    heading: {
      fontSize: '16px',
      fontWeight: 400,
      marginBottom: 4,
      color: colors.black,
    },
    content: {
      fontSize: 15,
      lineHeight: '125%',
      fontWeight: 400,
      color: colors.black,
      marginTop: 5,
    },
    guide: {
      fontSize: 30,
      fontWeight: 500,
      marginBottom: 22,
      color: colors.black,
    },
    customPageHeader: {
      fontWeight: 500,
      fontSize: 22,
      marginTop: 64,
      color: colors.black,
    },
    subHeading: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
      },
    },
    detail: {
      padding: '6px 8px',
      paddingLeft: 38,
    },
    mainHeader: {
      fontWeight: 500,
      fontSize: 22,
      color: colors.black,
    },
    leftBar: {
      overflow: 'auto',
    },
    list: {
      marginTop: 20,
    },
    divider: {
      border: `1px solid ${colors.black25}`,
    },
    contentHeader: {
      fontSize: '15px',
      lineHeight: '100%',
      fontWeight: 500,
      color: colors.black,
    },
    listContent: {
      fontSize: 15,
      lineHeight: '125%',
      fontWeight: 400,
      color: colors.black,
      marginTop: 10,
    },
    doubleTop: {
      marginTop: 32,
    },
  })
);
export default UserGuide;
