import { useEffect } from 'react';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ROOT_UI_ROUTE,
  UI_ACTIONS_LEFT_MENU,
  UI_SETTINGS_LEFT_MENU,
  configType,
  getUrlFirstParam,
} from 'utils/Utils';
import { colors } from 'utils/colors';
import useLeftMenuStyles from './useLeftMenuStyles';
import LinkButton from './LinkButton';
import { useConfig } from 'context/ConfigContext';

interface Props {
  keyValue: string;
  title: string;
}

const LeftMenu = ({ keyValue, title }: Props) => {
  const classes = useStyles();
  const commonClasses = useLeftMenuStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { configDetails, getLinkUnLinkStatus, config } = useConfig();
  const goTo = (path: string) => navigate(path);

  const menus = keyValue === 'actions' ? UI_ACTIONS_LEFT_MENU : UI_SETTINGS_LEFT_MENU;
  const currentMenu = getUrlFirstParam(location.pathname, `${ROOT_UI_ROUTE}${keyValue}/`);

  useEffect(() => {
    const webConfig = config.webUIConfig;
    const mobileConfig = config.mobileUIConfig;
    menus.map((menu) => {
      if (menu?.isLink && !configDetails[menu.keyValue]) {
        getLinkUnLinkStatus(webConfig[menu.keyValue], mobileConfig[menu.keyValue], menu.keyValue);
      }
    });
  }, [keyValue, config, getLinkUnLinkStatus, menus, configDetails]);

  return (
    <Box className={commonClasses.container}>
      <Box className={commonClasses.menuContainer}>
        <Typography className={commonClasses.itemTxt}>{title}</Typography>
      </Box>
      <Box className={commonClasses.listBody}>
        <Box className={commonClasses.list}>
          {menus.map((menu) => {
            const isActive = currentMenu === menu.keyValue;
            return (
              <Box
                key={menu.label}
                className={`${classes.menuButton} ${isActive && classes.activeButton}`}
                onClick={() => goTo(menu.path)}
              >
                <Typography className={classes.menuButtonTxt}>{menu.label}</Typography>
                {menu.isLink && (
                  <LinkButton
                    isLinked={configDetails[menu.keyValue]?.type === configType.ALL.type}
                  />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      width: '100%',
      height: 35,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      borderRadius: 5,
      paddingLeft: 12,
      paddingRight: 7,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        '& .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    menuButtonTxt: {
      color: colors.black54,
      fontSize: 14,
    },
    activeButton: {
      backgroundColor: theme.palette.primary.light,
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
  })
);

export default LeftMenu;
