import { defaultConjunctions, Conjunctions } from '../defaults/defaultConjunctions';
import { AttributeBasedFilterWithID } from '../QueryBuilder';

export const createConditionGroup = (
  conjunctions?: Conjunctions | undefined
): AttributeBasedFilterWithID => ({
  id: '',
  type: 'GroupFilter',
  condition: [],
  conjunction: conjunctions?.[0].name || defaultConjunctions[0].name,
  // not: false,
});
