import { V2PageTemplate } from '@terragotech/page-renderer';
import { JSONSchema6 } from 'json-schema';
import React, { createContext, useContext, useMemo } from 'react';
import { convertV2PageTemplateToJsonSchema } from '../../../pages/aggregates/utils/V2PageTemplateToJsonSchema';
import UnSavedChanges from 'common/UnSavedChanges';

interface ProviderProps {
  pageDefinition: V2PageTemplate;
  children: React.ReactElement | React.ReactElement[];
}
type PromiseResult = 'save' | 'cancel' | 'discard';
interface ContextProps {
  pageSchema: JSONSchema6;
  pageDefinition: V2PageTemplate;
  openRedirect: () => Promise<PromiseResult>;
}

interface PromiseProps {
  resolve: (result: PromiseResult) => void;
  reject: () => void;
}
export const PageContext = createContext<ContextProps>({} as ContextProps);

export const PageContextProvider: React.FC<ProviderProps> = ({ children, pageDefinition }) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const awaitingPromiseRef = React.useRef<PromiseProps>();

  const value = useMemo(() => {
    const pageSchema = pageDefinition ? convertV2PageTemplateToJsonSchema(pageDefinition) : {};
    return { pageDefinition, pageSchema };
  }, [pageDefinition]);

  const openRedirect = (): Promise<PromiseResult> => {
    setShowModal(true);

    return new Promise((resolve: (result: PromiseResult) => void, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleAction = (action: PromiseResult) => {
    awaitingPromiseRef.current?.resolve(action);
    setShowModal(false);
  };
  const message = 'The page has not been saved. do you want to redirect?';
  return (
    <PageContext.Provider value={{ ...value, openRedirect }}>
      {children}

      <UnSavedChanges {...{ showModal, handleAction, message }} />
    </PageContext.Provider>
  );
};
export const usePage = (): ContextProps => useContext<ContextProps>(PageContext);
