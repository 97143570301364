import React, { useState } from 'react';
import { ColorPicker, RadioInput } from '../../../components/FormElements';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import {
  Gen5ColorDefinition,
  Gen5ColorMap,
  Gen5ColorAttribute,
} from '@terragotech/gen5-config-lib/dist/Gen5ColorDefinition';
import { faMinus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'utils/colors';
import { TGSelect, TextInputTG } from 'views/components/formElements';
import { Box, Divider, makeStyles } from '@material-ui/core';

export interface CustomColorTableInputProps {
  color: Gen5ColorDefinition | undefined;
  setColor: (value: Gen5ColorDefinition | undefined) => void;
  aggregates: string[] | object;
}
type typeOptions = '' | 'Fixed Color' | 'Color Attribute' | 'Map Color Key';
type Option = {
  value: string;
  label: string;
};

const getType = (color: Gen5ColorDefinition | undefined) => {
  if (!color) return undefined;
  if (typeof color === 'string') return 'Fixed Color';
  if (typeof color === 'object' && color.hasOwnProperty('mapping')) return 'Map Color Key';
  return 'Color Attribute';
};

export const CustomColorTableInput: React.FC<CustomColorTableInputProps> = ({
  color,
  setColor,
  aggregates,
}) => {
  const classes = useStyles();
  const [type, setType] = useState(getType(color));

  const defaultFixedColor = colors.black;

  const defaultColorAttribute = {
    hexColorKey: '',
  };

  const defaultColorMappingItem = {
    value: '',
    hexColor: '',
  };

  const defaultColorMapping = { mapping: [defaultColorMappingItem], colorKey: '' };

  const options = [undefined, 'Fixed Color', 'Color Attribute', 'Map Color Key'];

  const getDefaultColor = (type: typeOptions) => {
    switch (type) {
      case 'Fixed Color':
        return defaultFixedColor;
      case 'Color Attribute':
        return defaultColorAttribute;
      case 'Map Color Key':
        return defaultColorMapping;
      default:
        return undefined;
    }
  };

  const handleAddNewMappingItem = () => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).mapping.push({ ...defaultColorMappingItem });
      setColor(copiedColor);
    }
  };

  const handleDeleteMappingItem = (index: number) => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).mapping.splice(index, 1);
      setColor(copiedColor);
    }
  };

  const handleMapChange = (value: string, index: number, type: 'value' | 'hexColor') => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).mapping[index][type] = value;
      setColor(copiedColor);
    }
  };

  const handleColorKeyChange = (value: string) => {
    if (typeof color === 'object') {
      const copiedColor = { ...color } as Gen5ColorMap;
      (copiedColor as Gen5ColorMap).colorKey = value;
      setColor(copiedColor);
    }
  };

  const arrayOfObjects = options.map((item) => {
    if (item !== undefined) {
      return { value: item, label: item };
    }
  });
  const filteredArray = arrayOfObjects.filter((item) => item !== undefined);
  const colorMappings = (color as Gen5ColorMap)?.mapping;

  return (
    <>
      <>
        <RadioInput
          value={type}
          onChange={(value) => {
            setType(value as typeOptions);
            setColor(getDefaultColor(value as typeOptions));
          }}
          options={(filteredArray as unknown) as Option[]}
          enableColor={false}
          type={type}
          containerStyle={styles.fieldSetContianer}
          component={
            <>
              {type === 'Fixed Color' && (
                <ColorPicker value={color as string} onChange={(value) => setColor(value)} />
              )}
              {type === 'Color Attribute' && (
                <TGSelect
                  {...{
                    id: 'Color Attrigute',
                    value: (color as Gen5ColorAttribute).hexColorKey,
                    options: aggregates,
                    canShowEmpty: true,
                    onChange: (value) => setColor({ hexColorKey: value as string }),
                    type,
                  }}
                />
              )}
            </>
          }
        />
      </>
      {type === 'Map Color Key' && (
        <Box style={styles.mapContainer}>
          <TGSelect
            {...{
              id: 'Map Color Key',
              value: (color as Gen5ColorMap).colorKey || '',
              options: aggregates,
              canShowEmpty: true,
              onChange: handleColorKeyChange,
              type,
            }}
          />
          <div style={styles.container}>
            <div style={styles.innerContainer}>
              <div style={styles.txt}>Value</div>
              <div>
                <IconButton component="span" onClick={handleAddNewMappingItem}>
                  <AddIcon />
                </IconButton>
              </div>
            </div>
            {colorMappings.map((item, index) => (
              <Box key={`${index}`}>
                <div
                  className={`${classes.render} ${
                    colorMappings.length > 1 && classes.colorsContainer
                  }`}
                >
                  <ColorPicker
                    value={item.hexColor}
                    onChange={(value) => handleMapChange(value, index, 'hexColor')}
                    onlyColorEnabled
                  />
                  <TextInputTG
                    value={item.value}
                    onChange={(value) => handleMapChange(value || '', index, 'value')}
                    autoFocus
                    className={classes.colorInput}
                    inputRoot={classes.colorPickerContainer}
                    placeholder={`item ${index}`}
                  />
                  {colorMappings.length > 1 && (
                    <IconButton
                      style={styles.removeIcon}
                      onClick={() => handleDeleteMappingItem(index)}
                    >
                      <FontAwesomeIcon icon={faMinus} className={classes.minusIcon} />
                    </IconButton>
                  )}
                </div>
                {colorMappings.length - 1 !== index && <Divider />}
              </Box>
            ))}
          </div>
        </Box>
      )}
    </>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  input: {
    marginBottom: 0,
  },
  mapContainer: {
    marginLeft: 30,
  },
  fieldSetContianer: {
    width: '100%',
    position: 'relative',
  },
  container: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    marginTop: 10,
  },
  innerContainer: {
    background: colors.grayBackground,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 5,
  },
  colorPick: {
    height: 33,
    border: 'none',
  },
  removeIcon: {
    color: colors.black,
  },
  txt: {
    marginLeft: 22,
    fontSize: 15,
    fontWeight: 400,
  },
  otherAttribute: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    paddingLeft: 5,
    width: '100%',
  },
};

const useStyles = makeStyles({
  colorPickerContainer: {
    height: 36,
  },
  colorInput: {
    width: '97%',
    marginLeft: 7,
  },
  minusIcon: {
    fontSize: 17,
    color: colors.black54,
  },
  render: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
  },
  colorsContainer: {
    paddingRight: 5,
  },
});
