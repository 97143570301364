import { usePage } from '../contexts/PageContext';

const usePageRedirect = () => {
  const { openRedirect } = usePage();
  const isForceLeaveConfirmed = async ({ handleSubmit }: { handleSubmit: () => Promise<unknown> }) => {
    const resp = await openRedirect();
    switch (resp) {
      case 'save':
        await handleSubmit();
        return true;
      case 'discard':
        return true;
      case 'cancel':
        return false;
      default:
        return false;
    }
  };
  return { isForceLeaveConfirmed };
};

export default usePageRedirect;
