import { SimpleOptionType } from "@terragotech/form-renderer";
import { AggregateConfig, CommandVersionDefinition, EventVersionDefinition, Gen5Config, PropertyCollection, WebUIConfig } from "@terragotech/gen5-config-lib";
import { AGGREGATE_DEFAULT_KEYS, MAP_SERVICE_DEFAULT_KEYS, MAP_SERVICE_TYPE } from "@terragotech/gen5-shared-utilities";
import { JSONSchema6 } from "json-schema";
import _ from "lodash";
import { commandActionButtonActionToRef, defaultAggregateUICustomization, generateAggregateByName, generateNewCommandAndVersions, generateNewEventAndVersions } from "./jsonPartsGenerators";
import { getAggregateIndex } from "./navigationUtils";
import { ActionButtonRow, AggregateDefault, CommandVersionDefault, EventVersionDefault, FabAction, WebUIDefault } from "./types";

//#region MapService
const MAP_SERVICE_AGGREGATE_DEFAULT: AggregateDefault = {
  typeName: MAP_SERVICE_DEFAULT_KEYS.typeName,
  singularName: 'Map Service',
  properties: {
    symbolKey: {
      uiType: 'Hidden',
    },
    serviceType: {
      nullable: false,
      label: 'Service Type',
      type: 'String',
    },
    serviceUri: {
      nullable: true,
      label: 'Service URL',
      type: 'String',
    },
    layers: {
      nullable: true,
      label: 'Layers',
      type: 'JSON',
      uiType: 'JSON',
    },
  },
  isReferenceType: true,
};
MAP_SERVICE_AGGREGATE_DEFAULT.events = {
  mapServiceCreatedEvent: {
    currentVersion: 1,
    versions: [
      {
        versionNumber: 1,
        eventSchema: {
          properties: {
            data: {
              type: 'object',
              properties: {
                serviceType: { type: 'string' },
                serviceUri: { type: 'string' },
                serviceTitle: { type: 'string' },
                layerGroup: { type: 'string' },
              },
            }
          }
        },
        aggregateMap: {
          nodes: {
            EVENT: { type: 'EVENT', config: {}, inputs: { data: {} } },
            STATE: { type: 'STATE', config: {}, inputs: {} },
            OUTPUT: {
              type: 'OBJECT-BUILDER',
              config: { objectSchema: 'STATE' },
              inputs: {
                title: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.data.serviceTitle'
                },
                createdDateTime: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.timestamp'
                },
                createdBy: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.metadata.userInfo.userName'
                },
                id: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.aggregateId'
                },
                serviceType: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.data.serviceType'
                },
                serviceUri: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.data.serviceUri'
                },
                layers: {
                  sourceObject: 'EVENT',
                  sourcePath: '$.data.layerGroup'
                },
                symbolKey: 'symbol_nodeonly_000000'
              }
            }
          },
          outputDefinition: { outputNode: 'OUTPUT' }
        }
      },
    ]
  }
};
MAP_SERVICE_AGGREGATE_DEFAULT.commands = {
  createMapService: {
    defaultLabel: `Create ${MAP_SERVICE_AGGREGATE_DEFAULT.singularName ?? MAP_SERVICE_AGGREGATE_DEFAULT.typeName}`,
    versions: [
      {
        version: 1,
        commandType: 'FORM',
        isCreationCommand: true,
        hasNoAggregateIdCommand: false,
        fabOptionEnabled: true,
        cmdToEventsMapping: {
          mappingType: 'ONE-TO-MANY',
          ...(true && { eventName: 'mapServiceCreatedEvent' }), // Not applicable for 'ONE-TO-MANY' mappingType; defaults to ''.
          mapping: {
            nodes: {
              OUTPUT: {
                type: 'OUTPUT-EVENT',
                config: {
                  objectSchema: `${MAP_SERVICE_AGGREGATE_DEFAULT.typeName}::mapServiceCreatedEvent::1`,
                  aggregateType: MAP_SERVICE_AGGREGATE_DEFAULT.typeName
                },
                inputs: {
                  timestamp: {
                    sourceObject: 'newNode1',
                    sourcePath: '$.timestamp'
                  },
                  aggregateId: {
                    sourceObject: 'newNode2',
                    sourcePath: '$.newId'
                  },
                  data: {
                    sourceObject: 'newNode1',
                    sourcePath: '$.data'
                  }
                }
              },
              newNode1: { type: 'COMMAND_DATA', config: {} },
              newNode2: { type: 'ID-GENERATOR', config: {} }
            },
            outputDefinition: {
              outputQuery: { types: ['OUTPUT-EVENT'] }
            }
          },
        },
        formDefinition: {
          order: ['serviceType', 'serviceUri', 'serviceTitle', 'layerGroup'],
          components: {
            serviceType: {
              type: 'select',
              label: 'Service Type',
              required: true,
              repeats: {
                enabled: false,
                min: 1,
                max: 1,
                addButtonText: 'Add Field',
                deleteConfirmationMessage: 'Are you sure you want to delete this?',
                allowReordering: true,
                allowInitialDataDelete: true,
                allowAdding: true
              },
              options: {
                type: SimpleOptionType,
                items: [
                  { value: MAP_SERVICE_TYPE.WMS, label: 'OpenGIS Web Map Service (WMS)' },
                  { value: MAP_SERVICE_TYPE.ArcGIS, label: 'ArcGIS Map Server' }
                ]
              },
              droppableId: 'form'
            },
            serviceUri: {
              type: 'textInput',
              label: 'Map Service URL',
              placeholder: 'https://www.xyzmapservice.com/api/',
              required: true,
              info: 'A list of the available layers and data are provided by the service endpoint. For WMS, you can view this data via its "GetCapabilities" request. E.g. https://www.xyzmapservice.com/api?request=getcapabilities&service=WMS&version=1.3.0',
              repeats: {
                enabled: false,
                min: 1,
                max: 1,
                addButtonText: 'Add Field',
                deleteConfirmationMessage: 'Are you sure you want to delete this?',
                allowReordering: true,
                allowInitialDataDelete: true,
                allowAdding: true
              },
              droppableId: 'form'
            },
            serviceTitle: {
              type: 'textInput',
              label: 'Map Service Title',
              placeholder: 'XYZ Map Service',
              required: true,
              repeats: {
                enabled: false,
                min: 1,
                max: 1,
                addButtonText: 'Add Field',
                deleteConfirmationMessage: 'Are you sure you want to delete this?',
                allowReordering: true,
                allowInitialDataDelete: true,
                allowAdding: true
              },
              droppableId: 'form'
            },
            layerGroup: {
              template: {
                order: ['layerName', 'layerTitle', 'layerLegendUri'],
                components: {
                  layerName: {
                    type: 'textInput',
                    label: 'Name',
                    placeholder: 'layer_name',
                    required: true,
                    info: 'Comma-separated list of layer Names (e.g. abc,xyz) or IDs (e.g. 10,17,25). For WMS, the layer Name of the Layer XML tag returned from the API. For ArcGIS, the unique ID of the layer. See URL field info for more details.',
                    repeats: {
                      enabled: false,
                      min: 1,
                      max: 1,
                      addButtonText: 'Add Field',
                      deleteConfirmationMessage: 'Are you sure you want to delete this?',
                      allowReordering: true,
                      allowInitialDataDelete: true,
                      allowAdding: true
                    },
                    droppableId: 'layerGroup'
                  },
                  layerTitle: {
                    type: 'textInput',
                    label: 'Title',
                    placeholder: 'Layer Name',
                    required: false,
                    info: 'A custom title for the layer to display in the app. For WMS, this may be obtained from the Title tag on the Layer returned from the API. For ArcGIS, this will be the layer name alongside its unique ID. See URL field info for more details.',
                    repeats: {
                      enabled: false,
                      min: 1,
                      max: 1,
                      addButtonText: 'Add Field',
                      deleteConfirmationMessage: 'Are you sure you want to delete this?',
                      allowReordering: true,
                      allowInitialDataDelete: true,
                      allowAdding: true
                    },
                    droppableId: 'layerGroup'
                  },
                  layerLegendUri: {
                    type: 'textInput',
                    label: 'Layer Legend URL',
                    placeholder: 'https://www.xyzmapservice.com/layerLegend.png',
                    required: false,
                    info: 'A link to the image to show for the map layer\'s legend or key. For WMS, this is sometimes provided by the LegendURL tag returned from the API. For ArcGIS, the map server may provide a Legend endpoint instead. See URL field info for more details.',
                    repeats: {
                      enabled: false,
                      min: 1,
                      max: 1,
                      addButtonText: 'Add Field',
                      deleteConfirmationMessage: 'Are you sure you want to delete this?',
                      allowReordering: true,
                      allowInitialDataDelete: true,
                      allowAdding: true
                    },
                    droppableId: 'layerGroup'
                  }
                }
              },
              type: 'group',
              label: 'Layers',
              repeats: {
                enabled: true,
                min: 1,
                max: -1,
                addButtonText: 'Add Layer',
                deleteConfirmationMessage: 'Are you sure you want to delete this layer?',
                allowReordering: true,
                allowInitialDataDelete: true,
                allowAdding: true
              }
            }
          }
        },
      },
    ]
  }
};
const MAP_SERVICE_WEBUI_DEFAULT: WebUIDefault = {
  fabActions: [
    {
      aggregateName: MAP_SERVICE_AGGREGATE_DEFAULT.typeName,
      button: commandActionButtonActionToRef({
        action: `createMapService ver:1`,
        label: `Add ${MAP_SERVICE_AGGREGATE_DEFAULT.singularName ?? MAP_SERVICE_AGGREGATE_DEFAULT.typeName}`,
        icon: 'fa-object-ungroup',
        conditionalMap: {
          nodes: {
            METADATA: { type: 'METADATA', config: {} },
            BUTTON_STATE: {
              type: 'BUTTON-STATE',
              config: {},
              inputs: {
                isHidden: {
                  sourceObject: 'newNode2',
                  sourcePath: '$.output'
                }
              }
            },
            newNode1: {
              type: 'FIXED-REGEX',
              config: {},
              inputs: {
                text: {
                  sourceObject: 'METADATA',
                  sourcePath: '$.userInfo.permissionString'
                },
                regex: `(:?^|,)(${MAP_SERVICE_DEFAULT_KEYS.permissionKey})(:?$|,)`
              }
            },
            newNode2: {
              type: 'NOT',
              config: {},
              inputs: {
                input: {
                  sourceObject: 'newNode1',
                  sourcePath: '$.match'
                }
              }
            }
          },
          outputDefinition: { outputNode: 'BUTTON_STATE' }
        }
      } as ActionButtonRow),
    },
  ],
  aggregateUICustomizations: {
    [MAP_SERVICE_AGGREGATE_DEFAULT.typeName]: {
      hiddenFromMapView: true,
    }
  }
};

export const generateMapServiceAggregateData = (config: Gen5Config) => {
  removeAggregateData(config, 'WebMapServiceSource'); // Remove deprecated aggregate definition.
  return generateAggregateData(config, MAP_SERVICE_AGGREGATE_DEFAULT, MAP_SERVICE_WEBUI_DEFAULT);
};
export const removeMapServiceAggregateData = (config: Gen5Config) => {
  removeAggregateData(config, 'WebMapServiceSource'); // Remove deprecated aggregate definition.
  return removeAggregateData(config, MAP_SERVICE_AGGREGATE_DEFAULT.typeName);
};
//#endregion

const generateAggregateData = (config: Gen5Config, aggregateDefault: AggregateDefault, webUIDefault?: WebUIDefault) => {
  const aggregateIndex = getAggregateIndex(config, aggregateDefault.typeName);

  const aggregate = aggregateIndex !== -1 && config.aggregates[aggregateIndex] || generateAggregateByName(aggregateDefault.typeName);
  aggregate.singularName = aggregateDefault.singularName ?? aggregateDefault.typeName;
  aggregate.pluralName = `${aggregateDefault.singularName ?? aggregateDefault.typeName}s`;
  aggregate.isReferenceType = aggregateDefault.isReferenceType;

  if (aggregateDefault.properties) {
    // Assign default values to standard aggregate properties (this preserves property order and values not specified in the default).
    const standardKeys = Object.keys(aggregate.properties);
    standardKeys.forEach(propertyName => {
      const standardDefault = aggregateDefault.properties![propertyName];
      if (standardDefault)
        _.assign(aggregate.properties[propertyName], standardDefault);
    });
    // Push non-standard aggregate properties.
    const nonStandardProps = standardKeys.reduce((acc, cur, i, a) => {
      const { [cur]: _, ...rest } = acc;
      return rest;
    }, aggregateDefault.properties);
    aggregate.properties = {
      ...aggregate.properties,
      ...nonStandardProps as PropertyCollection,
    };
  }

  generateAggregateEvents(aggregate, aggregateDefault.events);
  generateAggregateCommands(aggregate, aggregateDefault.commands);

  if (aggregateIndex !== -1)
    config.aggregates[aggregateIndex] = aggregate;
  else
    config.aggregates.push(aggregate);

  if (webUIDefault) {
    generateWebUI(config.webUIConfig, webUIDefault, aggregate);
  }

  return config;
};

const generateAggregateEvents = (aggregate: AggregateConfig, eventDefaults: AggregateDefault['events']) => {
  aggregate.events = aggregate.events ?? {};

  Object.entries(eventDefaults ?? {}).forEach(([eventName, eventDefinition]) => {
    const versionDefaults = eventDefinition?.versions as EventVersionDefinition[];
    const newEvent = generateNewEventAndVersions(eventName, versionDefaults.map<EventVersionDefault>(v => ({
      versionNumber: v.versionNumber,
      dataSchema: v.eventSchema?.properties?.data as JSONSchema6,
      aggregateMap: v.aggregateMap,
    })));

    aggregate.events = { ...aggregate.events, [eventName]: newEvent };
  });

  return aggregate;
};

const generateAggregateCommands = (aggregate: AggregateConfig, commandDefaults: AggregateDefault['commands']) => {
  aggregate.commands = aggregate.commands ?? {};

  Object.entries(commandDefaults ?? {}).forEach(([commandName, commandDefinition]) => {
    const versionDefaults = commandDefinition?.versions as CommandVersionDefinition[];
    const newCommand = generateNewCommandAndVersions(commandName, versionDefaults.map<CommandVersionDefault>(v => ({
      commandType: v.commandType,
      version: v.version,
      eventName: v.cmdToEventsMapping?.mappingType === 'ONE-TO-MANY' ? undefined : v.cmdToEventsMapping?.eventName,
      isCreationCommand: v.isCreationCommand,
      cmdToEventsMapping: v.cmdToEventsMapping,
      formDefinition: v.commandType === 'FORM' ? v.formDefinition : undefined,
      hasNoAggregateIdCommand: v.hasNoAggregateIdCommand,
      fabOptionEnabled: v.fabOptionEnabled,
    })), commandDefinition?.defaultLabel);

    aggregate.commands = { ...aggregate.commands, [commandName]: newCommand };
  });

  return aggregate;
};

const generateWebUI = (webUI: WebUIConfig, webUIDefault: WebUIDefault, aggregate?: AggregateConfig | AggregateDefault) => {
  // FAB Actions
  if (webUIDefault.fabActions) {
    const faDefaults = webUIDefault.fabActions as FabAction[];
    faDefaults
      .filter(fa => !aggregate?.typeName || fa.aggregateName === aggregate.typeName)
      .forEach(fa => webUI.fabActions.push(fa));
  }

  // Aggregate Customizations
  if (webUIDefault.aggregateUICustomizations) {
    webUI.aggregateUICustomizations = webUI.aggregateUICustomizations ?? {};

    const acDefaults = webUIDefault.aggregateUICustomizations as WebUIConfig['aggregateUICustomizations'];
    Object.entries(acDefaults ?? {})
      .filter(([acName]) => !aggregate?.typeName || acName === aggregate.typeName)
      .forEach(([acName, acDefinition]) => {
        webUI.aggregateUICustomizations![acName] = {
          ...(webUI.aggregateUICustomizations![acName] ?? defaultAggregateUICustomization('webUIConfig', aggregate?.labelProperty ?? AGGREGATE_DEFAULT_KEYS.labelProperty)),
          ...acDefinition,
        };
      });
  }

  return webUI;
};

const removeAggregateData = (config: Gen5Config, aggregateTypeName: string) => {
  // Aggregate, Events, Commands
  const aggregateIndex = getAggregateIndex(config, aggregateTypeName);
  if (aggregateIndex !== -1) {
    config.aggregates.splice(aggregateIndex, 1);
  }

  // WebUI
  if (config.webUIConfig.fabActions.findIndex(fa => fa.aggregateName === aggregateTypeName) !== -1) {
    config.webUIConfig.fabActions = [...config.webUIConfig.fabActions.filter(fa => fa.aggregateName !== aggregateTypeName)];
  }
  if (config.webUIConfig.aggregateUICustomizations && config.webUIConfig.aggregateUICustomizations[aggregateTypeName]) {
    delete config.webUIConfig.aggregateUICustomizations[aggregateTypeName];
  }

  return config;
};
