import React, { useContext } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { JSONSchema6 } from 'json-schema';
import { ConfigContext } from '../../../context/ConfigContext';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import SchemaEditor from 'components/SchemaEditor';

export interface CSVEvent {
  type: string;
  aggregateId: string;
  eventId: string;
  aggregateType: string;
  data: {
    [key: string]: string | null;
  };
  source: string;
  metadata: object;
  eventSchemaVersion: number;
}

interface Props {
  onChange: (val: JSONSchema6) => void;
  save: () => void;
  schemaRef: { current: {} | JSONSchema6 };
}

export const EventSchemaEditor: React.FC = () => {
  const { config, getEventSchema } = useContext(ConfigContext);
  const props = useOutletContext() as Props;
  const { onChange, schemaRef } = props;
  const { aggregate: aggrName, eventName, eventVersion } = useParams() as {
    aggregate: string;
    eventName: string;
    eventVersion: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const schemaValue = getEventSchema(aggrIndex, eventName, +eventVersion) as JSONSchema6;

  return <SchemaEditor {...{ onChange, schemaRef, schemaValue, onSave: props.save }} />;
};
