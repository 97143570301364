import ToggleSelector from '../controls/ToggleSelector';
import OptionSelector from '../controls/OptionSelector';
import NotToggle from '../controls/NotToggle';
import ActionElement from '../controls/ActionElement';

export type ControlElements = typeof defaultControlElements;

export const defaultControlElements = {
  AddGroupAction: ActionElement,
  RemoveGroupAction: ActionElement,
  AddConditionAction: ActionElement,
  RemoveConditionAction: ActionElement,
  ConjunctionSelector: ToggleSelector,
  KeySelector: OptionSelector,
  OperatorSelector: OptionSelector,
  NotToggle: NotToggle,
};
