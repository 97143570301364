import { isConditionGroup } from './isConditionGroup';
import {
  AttributeBasedFilterWithID,
  AttributeBasedFilterSimpleFiltersWithID,
} from '../QueryBuilder';

export const findCondition = (
  id: string | null,
  parent: AttributeBasedFilterWithID
): AttributeBasedFilterSimpleFiltersWithID | AttributeBasedFilterWithID | undefined => {
  if (parent.id === id) {
    return parent;
  }

  for (const condition of parent.condition) {
    if (condition.id === id) {
      return condition;
    } else if (isConditionGroup(condition)) {
      const subCondition = findCondition(id, condition);
      if (subCondition) {
        return subCondition;
      }
    }
  }
};
