import React from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
  makeStyles,
  createStyles,
  Box,
} from '@material-ui/core';
import { size } from 'lodash';

type Option = {
  value: string;
  label: string;
};

interface RadioInputProps {
  value: string | undefined;
  onChange: (value: string) => void;
  options?: Option[];
  title?: string;
  containerStyle?: React.CSSProperties;
  enableColor?: boolean;
  component?: JSX.Element;
  type?: string;
  labelStyle?: string;
  radioButtonStyle?: string;
  radioFormStyle?: string;
}

export const RadioInput: React.FC<RadioInputProps> = ({
  value,
  onChange,
  options,
  title,
  containerStyle,
  enableColor,
  component,
  type,
  labelStyle = '',
  radioButtonStyle = '',
  radioFormStyle = '',
}) => {
  const classes = useStyles();
  return (
    <FormControl
      component="fieldset"
      style={containerStyle}
      className={enableColor ? classes.root : classes.customRow}>
        {size(title) > 0 && (
          <FormLabel component="legend" style={styles.title} className={labelStyle}>
            {title}
          </FormLabel>
        )}
        <RadioGroup
          row
          name="type"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={!enableColor ? classes.customRow : classes.root}
        >
          {options !== undefined &&
            options.map(({ value: val, label }: Option, index: number) => {
              const hasShowComponent =
                component !== undefined && index === 0 && type === 'Fixed Color';
              const hasShowComponent2 =
                component !== undefined && index === 1 && type === 'Color Attribute';
              return (
                <>
                  <FormControlLabel
                    key={val}
                    value={val}
                    control={<Radio color="primary" className={radioButtonStyle} />}
                    label={label}
                    style={styles.text}
                    className={radioFormStyle}
                  />
                  {component && (
                    <Box className={classes.componentContainer}>
                      {hasShowComponent && component}
                      {hasShowComponent2 && component}
                    </Box>
                  )}
                </>
              );
            })}
        </RadioGroup>
      </FormControl>
    );
};
const styles = {
  title: {
    fontSize: 12,
  },
  text: {
    fontSize: 15,
  },
};
const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiTypography-body1': {
        fontSize: 15,
        marginRight: 0,
      },
      '& .MuiFormGroup-row': {
        flexDirection: 'row',
        gap: 49,
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 0,
      },
    },
    customRow: {
      '& .MuiFormGroup-row': {
        flexDirection: 'column',
        gap: 0,
      },
      '& .MuiTypography-body1': {
        fontSize: 15,
      },
    },
    componentContainer: {
      marginLeft: 32,
    },
  })
);
