import React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Outlet } from 'react-router-dom';

export const ActionsRoute: React.FC = () => {
  return (
    <ActionContainer>
      <Outlet />
    </ActionContainer>
  );
};
const ActionContainer = styled.div`
  background-color: ${colors.white};
  display: flex;
  height: 100dvh;
  overflow: auto;
  width: 100%;
`;
export default ActionsRoute;
