import styled from 'styled-components';
import { JSONSchema6 } from 'json-schema';
import * as React from 'react';
import { isKeyValueSchema } from './ConfigPropertyEditor';

export interface ConfigPropertyDisplayProps {
  propertySchema: JSONSchema6;
  propertyValue: unknown;
}
const getValueLabel = (value: unknown, schema: JSONSchema6) => {
  // specific case for key value pairs
  if (Array.isArray(value) && value !== null && value.length === 2) {
    return `${value[0]} -> ${value[1]}`;
  }
  return String(value);
};
const ConfigPropertyDisplay: React.FunctionComponent<ConfigPropertyDisplayProps> = (props) => {
  const { propertySchema, propertyValue } = props;
  const label = propertySchema.description;
  if (isKeyValueSchema(propertySchema)) {
    //TODO: make this look unique
    return <ConfigLabelFrame>{getValueLabel(propertyValue, propertySchema)}</ConfigLabelFrame>;
  }
  return (
    <ConfigLabelFrame>
      {label && `${label} `} =&gt;
      {getValueLabel(propertyValue, propertySchema)}
    </ConfigLabelFrame>
  );
};
export default ConfigPropertyDisplay;

//TODO: Move this anywhere else....
const BASE_HEIGHT = 24;
const ConfigLabelFrame = styled.div`
  font-size: 12px;
  margin-left: 12px;
  margin-right: 12px;
  width: 100%;
  height: ${BASE_HEIGHT}px;
  overflow: visible;
  display: flex;
  padding-left: 8px;
  font-size: ${BASE_HEIGHT / 2}px;
  display: inline-block;
`;
