import React from 'react';
import styled from 'styled-components';

import { Outlet } from 'react-router-dom';
import { PageDialogServiceProvider } from 'components/PageDialog/PageDialogService';

export const CustomPages: React.FC = () => (
  <PageDialogServiceProvider>
    <FlexContainer>
      <Outlet />
    </FlexContainer>
  </PageDialogServiceProvider>
);

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;
