import { Button, makeStyles, alpha, Theme, createStyles } from '@material-ui/core';
import { colors } from 'utils/colors';

interface Props {
  isLinked: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkButton: {
      height: 18,
      width: 64,
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '3px 8px 3px 6px',
      borderRadius: 4,
      backgroundColor: colors.black5,
      color: colors.black54,
      fontSize: 12,
      fontWeight: 400,
      border: `1px solid ${colors.black10}`,
      '&:hover': {
        backgroundColor: colors.black5,
      },
    },
    active: {
      backgroundColor: alpha(theme.palette.primary.main, 0.5),
      '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.5),
      },
    },
  })
);

const LinkButton = ({ isLinked }: Props) => {
  const classes = useStyles();
  return (
    <Button size="small" className={`${classes.linkButton} ${isLinked && classes.active}`}>
      {isLinked ? 'Linked' : 'Unlinked'}
    </Button>
  );
};

export default LinkButton;
