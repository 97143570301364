import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Tab, Tabs, Typography, Divider } from '@material-ui/core';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import TGBreadCrumbs from '../common/TGBreadCrumbs';
import {
  EVENT_CONFIGURATION_TABS,
  ROOT_RECORDS_ROUTE,
  getUrlFirstParam,
  goToWithReplace,
} from '../../../../../utils/Utils';
import { ConfigContext } from '../../../../../context/ConfigContext';
import { getAggregateIndex } from '../../../../../utils/navigationUtils';
import { errorMsg, successMsg } from '../../../../../components/SnackbarUtilsConfigurator';
import { useAggregateAPI } from '../../../../../context/fakeAPIHooks/useAggregateAPI';
import { JSONSchema6 } from 'json-schema';
import ConfigurationHeader from '../common/ConfigurationHeader';
import { useDataMapDiagram } from '../../../../../map-editor/src';
import { useSchemaLookup } from '../../../../../utils/useSchemaLookup';
import { convertV2FormTemplateToJsonSchema } from '../../../../../pages/aggregates/utils/V2FormTemplateToJsonSchema';
import { sample } from '../../../../../pages/aggregates/utils/sampleForm';
import { propertiesToSchema } from '../../../../../pages/aggregates/utils/PropertiesToSchemaConverter';
import useCommonStyles from 'views/useCommonStyles';

const EventConfiguration = () => {
  const commonClasses = useCommonStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const tempRef = useRef({});

  const AggregateAPI = useAggregateAPI();
  const { config, getEvent, getEventVersions, getEventVersion, getEventSchema } = useContext(
    ConfigContext
  );
  const { aggregate, eventName, eventVersion } = useParams() as {
    aggregate: string;
    eventName: string;
    eventVersion: string;
  };
  const aggrIndex = getAggregateIndex(config, aggregate);
  const [activeTab, setActiveTab] = useState<number>(0);
  const event = getEvent(aggrIndex, eventName);
  const inputActualSchemaRef = useRef<JSONSchema6>({});
  const eventSchemaVersion =
    getEventVersion(aggrIndex, eventName, +eventVersion)?.versionNumber || 1;

  const localSchemas = useMemo(() => {
    tempRef.current = {};
    return {
      EVENT: {
        schema:
          getEventSchema(aggrIndex, eventName, +eventVersion) ||
          convertV2FormTemplateToJsonSchema(sample),
        schemaLabel: eventName,
      },
      STATE: {
        schema: propertiesToSchema(config.aggregates[aggrIndex].properties || {}),
        schemaLabel: 'Properties',
      },
    };
  }, [aggrIndex, eventName, eventVersion, config.aggregates, getEventSchema]);

  const schemaLookup = useSchemaLookup({
    currentAggregateType: aggregate,
    localSchemas: localSchemas,
  });
  const eventMapDiagramData = useDataMapDiagram({
    scenario: 'EVENT_AGGREGATE_MAP',
    schemaLookup: schemaLookup,
    dataMap:
      config.aggregates[aggrIndex].events?.[eventName]?.versions[+eventVersion]?.aggregateMap,
  });

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    goToWithReplace(
      navigate,
      `${ROOT_RECORDS_ROUTE}${aggregate}/events/${eventName}/version/${eventVersion}/${
        newValue === 0 ? 'eventSchema' : 'mapping'
      }`
    );
  };

  const updateActiveTab = useCallback(() => {
    const pathName = location.pathname;
    const route = getUrlFirstParam(
      pathName,
      `${ROOT_RECORDS_ROUTE}${aggregate}/events/${eventName}/version/${eventVersion}/`
    );
    setActiveTab(route === 'mapping' ? 1 : 0);
  }, [location, aggregate, eventName, eventVersion]);

  useEffect(() => {
    updateActiveTab();
  }, [updateActiveTab, location]);

  const handleRemoveVersion = async (
    eventName: string,
    versionIndex: number,
    versionNumber: number
  ) => {
    const versions = getEventVersions(aggrIndex, eventName);
    if (versions?.length === 1) {
      return errorMsg(
        'At least one version of the event have to remain. Consider deleting the entire event instead'
      );
    }
    const { error } = await AggregateAPI.removeEventVersion(aggrIndex, eventName, versionIndex);
    if (error) return;
    successMsg(
      `The version "${versionNumber}" of the event "${eventName}" belogning to the "${aggregate}" has been successfully deleted.`
    );
  };

  const handleAddEventVersion = async (eventName: string, versionIndex: number) => {
    const { error } = await AggregateAPI.addEventVersion(aggrIndex, eventName, versionIndex);
    if (error) return;
    successMsg(`New version of the "${eventName}" has been successfully created`);
  };

  const save = () => {
    tempRef.current = {};
    if (activeTab === 0) {
      updateSchema();
    } else {
      updateMapping();
    }
  };

  const updateSchema = async () => {
    const { error } = await AggregateAPI.updateEventSchema(
      aggrIndex,
      eventName,
      +eventVersion,
      inputActualSchemaRef.current
    );
    if (error) return;
    successMsg('The event schema has been saved');
  };

  const onDiscard = async () => {
    eventMapDiagramData.resetMapDiagram();
  };

  const updateMapping = async () => {
    const eventMapDiagramModel = eventMapDiagramData.model?.getMapDefinition();
    if (eventMapDiagramModel) {
      const { error } = await AggregateAPI.updateAggregateMap(
        aggrIndex,
        eventName,
        +eventVersion,
        eventMapDiagramModel
      );
      if (error) return;
      successMsg('Mapping has been saved');
    }
  };

  const handleInputSchema = (schema: JSONSchema6) => {
    inputActualSchemaRef.current = schema;
  };

  return (
    <Box className={commonClasses.container}>
      <Box className={commonClasses.innerContainer}>
        <>
          <Box className={[commonClasses.titleBarContainer, commonClasses.rootContainer].join(' ')}>
            <TGBreadCrumbs
              title="Events"
              backPath={`${ROOT_RECORDS_ROUTE}${aggregate}/events`}
              selectedItem={eventName}
            />
            <ConfigurationHeader
              type="event"
              name={eventName}
              versionNos={(event?.versions || []).map((version) => version.versionNumber)}
              versionChangePath={`${ROOT_RECORDS_ROUTE}${aggregate}/events/${eventName}/version/versionIndex/${
                activeTab === 1 ? 'mapping' : 'eventSchema'
              }`}
              selectedVersion={Number(eventVersion)}
              handleAddVersion={handleAddEventVersion}
              handleRemoveVersion={handleRemoveVersion}
              showImportButton={activeTab === 0}
              hideCancelButton={true}
              aggregate={aggregate}
              aggregateIndex={aggrIndex}
              schemaVersion={eventSchemaVersion}
              actualSchema={inputActualSchemaRef.current}
              save={save}
            />
          </Box>
        </>
        <Box className={commonClasses.tabContainer}>
          <Tabs
            value={activeTab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="tabs"
          >
            {EVENT_CONFIGURATION_TABS.map((eventConfigurationTab) => (
              <Tab
                key={eventConfigurationTab}
                label={
                  <Typography className={commonClasses.label}>{eventConfigurationTab}</Typography>
                }
              />
            ))}
          </Tabs>
          <Divider />
          <Box className={`${commonClasses.panelContainer} ${commonClasses.eventPanel}`}>
            <Outlet
              context={{
                onChange: handleInputSchema,
                eventMapDiagramData,
                save,
                onDiscard,
                schemaRef: tempRef,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EventConfiguration;
