import { ConfigContext } from 'context/ConfigContext';
import { useContext, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { StorageHelper, StorageKeys } from 'utils/StorageManagement';
import { RECORDS_ROUTE, ROOT_RECORDS_ROUTE } from 'utils/Utils';

const InitialRoute = () => {
  const { getAggregates } = useContext(ConfigContext);

  const aggregate = useMemo(() => {
    const [firstAggregate] = getAggregates();
    return firstAggregate?.typeName || '';
  }, [getAggregates]);

  let path = `${ROOT_RECORDS_ROUTE}${aggregate}`;

  const data = StorageHelper.getByKey(StorageKeys.pageHistory);
  if (data) {
    const recordsRoute = RECORDS_ROUTE.replace('/', '');
    path = data?.[recordsRoute] ?? path;
  }

  return <Navigate to={path} />;
};

export default InitialRoute;
