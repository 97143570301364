import React from 'react';
import { FormControlLabel, Checkbox, Typography, styled } from '@material-ui/core';
import { colors } from '../../utils/colors';

interface CheckboxInputProps {
  title?: string;
  checked: boolean | undefined;
  onChange: (value: boolean) => void;
  labelStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  checkBoxContainer?: string;
}
const Label = styled(Typography)({
  fontSize: 14,
  color: colors.black,
});

export const CheckboxInput: React.FC<CheckboxInputProps> = ({
  title,
  checked,
  onChange,
  labelStyle,
  style,
  checkBoxContainer,
}) => (
  <div className={checkBoxContainer ?? ''}>
    <FormControlLabel
      control={
        <Checkbox
          name="rebuildOnStartup"
          checked={checked || false}
          onChange={(e) => onChange(e.target.checked)}
          color="primary"
        />
      }
      label={title ? <Label style={labelStyle}>{title}</Label> : ''}
      style={{ ...style }}
    />
  </div>
);
