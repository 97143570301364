import React, { useContext, useEffect, useMemo, useState } from 'react';
import { colors } from 'utils/colors';

export interface SymbolProps {
  preview?: number;
  name: string;
  symbolKey: string;
}
export interface SymbolValue {
  selectedSymbol: SymbolProps | null;
  setSelectedSymbol: React.Dispatch<React.SetStateAction<SymbolProps | null>>;
  symbolName: string;
  setSymbolName: React.Dispatch<React.SetStateAction<string>>;
  symbolKey: string;
  setSymbolKey: React.Dispatch<React.SetStateAction<string>>;
  symbol: string;
  setSymbol: React.Dispatch<React.SetStateAction<string>>;
  symbolColor: string;
  setSymbolColor: React.Dispatch<React.SetStateAction<string>>;
  alphaNumericCharacter: string;
  setAlphaNumericCharacter: React.Dispatch<React.SetStateAction<string>>;
  symbolScale: string;
  setSymbolScale: React.Dispatch<React.SetStateAction<string>>;
  selectedSymbolValues: { color: string; character: string; symbol: string; symbolScale: string };
}

const SymbolValueContext = React.createContext<SymbolValue>({
  selectedSymbol: {
    name: '',
    symbolKey: '',
  },
  setSelectedSymbol: () => {},
  symbolName: '',
  setSymbolName: () => {},
  symbolKey: '',
  setSymbolKey: () => {},
  symbol: '',
  setSymbol: () => {},
  symbolColor: '',
  setSymbolColor: () => {},
  alphaNumericCharacter: '',
  setAlphaNumericCharacter: () => {},
  symbolScale: '',
  setSymbolScale: () => {},
  selectedSymbolValues: { color: '', character: '', symbol: '', symbolScale: '' },
});
export const NODEONLY = 'nodeonly';
export const CIRCLE = 'circle';
export const NODEONLY_CHARACTER = '';
export const DEFAULT_SYMBOL_SCALE = '1';
export const UNICODE_PREFIX = '\\u';
export const SYMBOL_TEXT_GLYPH = 'text/glyph';
export const SYMBOL_UNICODE = 'unicode';
export const DEFAULT_SYMBOL_KEY =
  'symbol_' +
  NODEONLY +
  '_' +
  colors.black.replace('#', '') +
  '_' +
  NODEONLY_CHARACTER +
  '_' +
  DEFAULT_SYMBOL_SCALE;
const SymbolValueContextProvider: React.FunctionComponent<{
  children: React.ReactElement;
}> = (props) => {
  const [selectedSymbol, setSelectedSymbol] = useState<SymbolProps | null>(null);

  const [symbolName, setSymbolName] = useState('');
  const [symbolKey, setSymbolKey] = useState(DEFAULT_SYMBOL_KEY);
  const [symbolColor, setSymbolColor] = useState(colors.black);
  const [alphaNumericCharacter, setAlphaNumericCharacter] = useState(NODEONLY_CHARACTER);
  const [symbol, setSymbol] = useState(NODEONLY);
  const [symbolScale, setSymbolScale] = useState(DEFAULT_SYMBOL_SCALE);
  const [selectedSymbolValues, setSelectedSymbolValues] = useState({
    color: '',
    character: '',
    symbol: '',
    symbolScale: '',
  });
  const handleValues = (selectedSymbol: SymbolProps) => {
    const [, symbol, color, alphaNumericCharacter, alphaScale] = selectedSymbol.symbolKey.split(
      '_'
    );
    setSymbolKey(selectedSymbol.symbolKey);
    setSymbolName(selectedSymbol.name);
    setSymbol(symbol);
    setSymbolColor('#' + color);
    setAlphaNumericCharacter(alphaNumericCharacter);
    setSymbolScale(alphaScale || DEFAULT_SYMBOL_SCALE);
  };
  useEffect(() => {
    if (selectedSymbol) {
      const [, symbol, color, alphaNumericCharacter, alphaScale] = selectedSymbol.symbolKey.split(
        '_'
      );
      setSelectedSymbolValues({
        color: color,
        character: alphaNumericCharacter,
        symbol: symbol,
        symbolScale: alphaScale,
      });
      handleValues(selectedSymbol);
    }
  }, [selectedSymbol]);
  const ctxVal = useMemo(
    () => ({
      selectedSymbol,
      setSelectedSymbol,
      selectedSymbolValues,
      symbolName,
      setSymbolName,
      symbolKey,
      setSymbolKey,
      symbol,
      setSymbol,
      symbolColor,
      setSymbolColor,
      alphaNumericCharacter,
      setAlphaNumericCharacter,
      symbolScale,
      setSymbolScale,
    }),
    [
      selectedSymbol,
      setSelectedSymbol,
      selectedSymbolValues,
      symbolName,
      setSymbolName,
      symbolKey,
      setSymbolKey,
      symbol,
      setSymbol,
      symbolColor,
      setSymbolColor,
      alphaNumericCharacter,
      setAlphaNumericCharacter,
      symbolScale,
      setSymbolScale,
    ]
  );

  return <SymbolValueContext.Provider value={ctxVal}>{props.children}</SymbolValueContext.Provider>;
};

export const useSymbolValue = (): SymbolValue => useContext(SymbolValueContext);
export default SymbolValueContextProvider;
