export type OperatorName = 'EQUAL' | 'INCLUDE' | 'NULL' | 'NOTNULL';
export type OperatorLabel = 'Is equal' | 'Include' | 'Is Null' | 'Is Not Null';
export type Operator = { name: OperatorName; label: OperatorLabel };
export type Operators = Operator[];

export const defaultOperators: Operators = [
  { name: 'EQUAL', label: 'Is equal' },
  { name: 'INCLUDE', label: 'Include' },
  { name: 'NULL', label: 'Is Null' },
  { name: 'NOTNULL', label: 'Is Not Null' },
  // { name: 'in', label: 'in' },
  // { name: 'notIn', label: 'not in' },
  // { name: '=', label: '=' },
  // { name: '!=', label: '!=' },
  // { name: '<', label: '<' },
  // { name: '>', label: '>' },
  // { name: '<=', label: '<=' },
  // { name: '>=', label: '>=' },
  // { name: 'contains', label: 'contains' },
  // { name: 'beginsWith', label: 'begins with' },
  // { name: 'endsWith', label: 'ends with' },
  // { name: 'doesNotContain', label: 'does not contain' },
  // { name: 'doesNotBeginWith', label: 'does not begin with' },
  // { name: 'doesNotEndWith', label: 'does not end with' },
];
