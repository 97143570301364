import { Gen5Config } from '@terragotech/gen5-config-lib';
import cloneDeep from 'lodash/cloneDeep';
import objPath from 'object-path';

interface ActionButton {
  action: { commandName: string; commandVersion: number };
  button: {
    action: { commandName: string; commandVersion: number };
  };
  aggregateName: string;
}

export const useCommandActionRefChanger = () => {
  let localConfig: Gen5Config | undefined;

  const isAnyReference = (
    config: Gen5Config,
    aggrName: string,
    commandName: string,
    commandVersion?: number
  ) => {
    const searchedString = commandVersion
      ? `{"commandName":"${commandName}","commandVersion":${commandVersion}}`
      : `{"commandName":"${commandName}","commandVersion":`;
    return (
      JSON.stringify(config.mobileUIConfig.aggregateUICustomizations?.[aggrName] || '').includes(
        searchedString
      ) ||
      JSON.stringify(config.webUIConfig.aggregateUICustomizations?.[aggrName] || '').includes(
        searchedString
      )
    );
  };

  const commandActionsListsPaths = (aggrName: string) => [
    `mobileUIConfig.aggregateUICustomizations.${aggrName}.cardDefinition`,
    `mobileUIConfig.aggregateUICustomizations.${aggrName}.editorActions`,
    `mobileUIConfig.aggregateUICustomizations.${aggrName}.editorWorkflows`,
    `mobileUIConfig.aggregateUICustomizations.${aggrName}.multiselectActions`,
    `mobileUIConfig.aggregateUICustomizations.${aggrName}.cardDefinition.secondaryButtons`,
    'mobileUIConfig.fabActions',
    `webUIConfig.aggregateUICustomizations.${aggrName}.cardDefinition`,
    `webUIConfig.aggregateUICustomizations.${aggrName}.editorActions`,
    `webUIConfig.aggregateUICustomizations.${aggrName}.editorWorkflows`,
    `webUIConfig.aggregateUICustomizations.${aggrName}.multiselectActions`,
    `webUIConfig.aggregateUICustomizations.${aggrName}.cardDefinition.secondaryButtons`,
    'webUIConfig.fabActions',
  ];

  const removeReferences = (
    config: Gen5Config,
    aggrName: string,
    commandName: string,
    commandVersion?: number
  ) => {
    localConfig = cloneDeep(config);
    commandActionsListsPaths(aggrName).forEach((path) =>
      removeActionButton(path, aggrName, commandName, commandVersion, path.includes('fabActions'))
    );
    return localConfig;
  };

  const removeActionButton = (
    path: string,
    aggrName: string,
    commandName: string,
    commandVersion?: number,
    isFabAction?: boolean
  ) => {
    if (localConfig) {
      const cardDefinitionOrButtonList: object = objPath.get(localConfig, path);
      if (cardDefinitionOrButtonList) {
        if (isArray(cardDefinitionOrButtonList)) {
          const newValue = cardDefinitionOrButtonList.filter((actionButton: ActionButton) => {
            if (isFabAction) {
              if (commandVersion) {
                return !(
                  actionButton.aggregateName === aggrName &&
                  actionButton.button.action.commandName === commandName &&
                  actionButton.button.action.commandVersion === commandVersion
                );
              }
              return !(
                actionButton.aggregateName === aggrName &&
                actionButton.button.action.commandName === commandName
              );
            } else if (commandVersion) {
              return !(
                actionButton.action.commandName === commandName &&
                actionButton.action.commandVersion === commandVersion
              );
            }
            return actionButton.action.commandName !== commandName;
          });
          objPath.set(localConfig, path, newValue);
        }
      }
    }
  };

  const updateVersion = (
    config: Gen5Config,
    aggrName: string,
    commandName: string,
    commandVersion: number,
    commandTargetVersion: number
  ) => {
    localConfig = cloneDeep(config);
    commandActionsListsPaths(aggrName).forEach((path) =>
      updateActionButtonVersion(
        path,
        aggrName,
        commandName,
        commandVersion,
        commandTargetVersion,
        path.includes('fabActions')
      )
    );
    return localConfig;
  };

  const updateActionButtonVersion = (
    path: string,
    aggrName: string,
    commandName: string,
    commandVersion: number,
    commandTargetVersion: number,
    isFabAction?: boolean
  ) => {
    if (localConfig) {
      const cardDefinitionOrButtonList: object = objPath.get(localConfig, path);
      if (cardDefinitionOrButtonList) {
        if (isArray(cardDefinitionOrButtonList)) {
          const newValue = cardDefinitionOrButtonList.map((actionButton: ActionButton) => {
            if (isFabAction) {
              if (
                actionButton.aggregateName === aggrName &&
                actionButton.button.action.commandName === commandName &&
                actionButton.button.action.commandVersion === commandVersion
              ) {
                actionButton.button.action.commandVersion = commandTargetVersion;
              }
            } else {
              if (
                actionButton.action.commandName === commandName &&
                actionButton.action.commandVersion === commandVersion
              ) {
                actionButton.action.commandVersion = commandTargetVersion;
              }
            }
            return actionButton;
          });
          objPath.set(localConfig, path, newValue);
        }
      }
    }
  };

  const isArray = (obj: object): obj is ActionButton[] => {
    return isObject(obj) && obj instanceof Array;
  };

  const isObject = (obj: object) => {
    return obj && typeof obj === 'object';
  };

  return { removeReferences, isAnyReference, updateVersion };
};
