import { V2FormTemplate } from '@terragotech/form-renderer';
import { JSONSchema6 } from 'json-schema';
import React, { createContext, useContext, useMemo } from 'react';
import { convertV2FormTemplateToJsonSchema } from '../../../pages/aggregates/utils/V2FormTemplateToJsonSchema';

interface ProviderProps {
  formDefinition: V2FormTemplate;
  children: React.ReactElement | React.ReactElement[];
}
interface ContextProps {
  formSchema: JSONSchema6;
  formDefinition: V2FormTemplate;
}
export const FormContext = createContext<ContextProps>({} as ContextProps);
export const FormContextProvider: React.FC<ProviderProps> = ({ children, formDefinition }) => {
  const value = useMemo(() => {
    const formSchema = formDefinition ? convertV2FormTemplateToJsonSchema(formDefinition) : {};
    return { formDefinition, formSchema };
  }, [formDefinition]);
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
};
export const useForm = (): ContextProps => useContext<ContextProps>(FormContext);
